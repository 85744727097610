import { IncidentType } from 'src/types';
import { createReducer } from '../../utils/toolkit';

const initialState: Partial<IncidentType> = {
  id: 0,
  status: -1,

  name: '',
};

const {
  reducer,
  actions: { set: setIncident, merge: mergeIncident, reset: resetIncident },
} = createReducer('incident', initialState);

export default reducer;
export { setIncident, mergeIncident, resetIncident };
