import { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { DatePickerProps } from '@mui/x-date-pickers/DatePicker/DatePicker.types';
import { styled } from '@mui/material/styles';

type Props = {
  label?: string;
  name?: string;
  error?: boolean;
  fullWidth?: boolean;
} & DatePickerProps<Dayjs>;

const Styled = styled<React.FC<Props>>(DatePicker)`
  width: 100%;
`;

export default Styled;
