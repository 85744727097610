import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { FormikProps } from 'formik';
import { P1R } from '../Typography';

type FormikCheckboxProps = {
  formik: FormikProps<any>;
  field: string;
  label: string;
  disabled?: boolean;
};
const FormikCheckbox: React.FC<FormikCheckboxProps> = ({ formik, field, label, disabled, ...props }): JSX.Element => (
  <FormControlLabel
    name={field}
    label={<P1R>{label}</P1R>}
    disabled={disabled}
    onChange={formik.handleChange}
    control={<Checkbox checked={formik.values[field]} />}
    {...props}
  />
);

export default FormikCheckbox;
