import { ClearStatus } from 'src/shared/enums/clearStatus.enum';
import { UserStatus } from 'src/shared/enums/userStatus.enum';
import { IdNameType } from './config';

export type LoginType = {
  email: string;
  password: string;
};

export type RolesType = {
  key: string;
  value: string;
  slug: string;
  isActive?: boolean;
  types: string[];
};

export type StateType = {
  code: string;
  name: string;
};

export type AddressType = {
  street: string;
  city: string;
  state: string;
  stateCode: string;
  zipcode: string;
  streetAddress: string;
  streetAddress2: string;
  country: string;
  region: string;
};

export type AttachmentsType = {
  ssn?: UserFileType;
  medical?: UserFileType;
  union?: UserFileType;
  passport?: UserFileType;
  cdl_front?: UserFileType;
  cdl_back?: UserFileType;
  driver_license_front?: UserFileType;
  driver_license_back?: UserFileType;
  id_front?: UserFileType;
  id_back?: UserFileType;
};

export type ProfileType = {
  // Profile
  employee?: string;
  utilityContractorId?: number;
  preferredName?: string;
  ssn?: string;
  // TODO: Use one field from BE and FE side
  classification: IdNameType[]; // classification?: string[],
  unionClassification: string;

  unionConfirmed: boolean;
  unionRejectedComment?: string;
  classifications: { id: number; name: string; priority: number }[]; // classification?: string[],
  currentClassification: string; // classification?: string[],
  classificationIds: string[]; // classification?: string[],
  medicalExpiration: number;
  rubberSleeve: string;
  workVest: string;
  workGlove: string;
  rubberGlove: string;
  localUnion: string;
  unionTicket: string;
  driverLicenseStateCode: string;
  driverLicenseState: string;
  driverLicense: string;
  driverLicenseClass?: string;
  driverLicenseExpiration?: number;
  // TODO: should be removed
  cdlEndorsements?: string[];
  cdlEndorsementsSlugs?: string[];
  cdlRestrictions?: string[];
  passport: string;
  passportExpiration: number;

  // api/dist/rosters/involvedLinemanStatus.enum.d.ts
  participationStatus?: null | -1 | 0 | 1 | 2;
  attachments?: AttachmentsType;
};

export type AssignmentsType = {
  classificationId: number;
  utilityClassificationId: number;
  utilityFteClassification: string;
  timesheetFteClassification: string;
  crew: number;
  participationStatus: number;
  lodging: string;
  rosterId: number;
  userUuid: string;
  vehicleId: number;
  isLead: boolean;
  approveRequired: boolean;
  unionClassification: string;
};

export type UploadFileType = {
  file: File;
  type: string;
  cancelToken?: any;
  userUuid: string;
};

export type UserFileType = {
  id: number;
  filename?: string;
  mimetype: string;
  status: 'PENDING' | 'APPROVED' | 'FAILED';
  location: string;
  // Technical Debt: refactoring upload file
  errorText?: string;
};

export type UserSettings = {
  autoAddToRosterConfirmation?: boolean;
};

export type IncidentLinemen = {
  createdAt: string;
  declined: boolean | null;
  incidentId: number;
  participationStatus: number;
  send: boolean;
  userUuid: string;
  yardId: number;
};

export enum RosterParticipationStatus {
  SUPPROTER = -2,
  REJECTED = -1,
  PENDING = 0,
  ACCEPTED = 1,
  ACCEPTED_ON_BEHALF = 2,
}

export type UserType = {
  uuid: string;
  clearId?: string;
  clearStatus?: ClearStatus;
  firstName: string;
  middleName: string;
  dateOfBirth: string;
  prefferedName: string;
  lastName: string;
  gender?: 'male' | 'female';
  email: string;
  altEmail: string;
  emailConfirmed: boolean;
  phone: string;
  phoneConfirmed: boolean;
  password?: string;

  // TODO: Fix up the object model in this file!!!!
  contactFirstName: string;
  contactLastName: string;
  contactPhone: string;
  contactEmail: string;
  contactStreet: string;
  contactCity: string;
  contactState: string;
  contactZipcode: string;
  contactNotes: string;
  emergencyEmail: string;
  emergencyFirstName: string;
  emergencyLastName: string;
  emergencyPhone: string;
  emergencyRelationship: string;

  // isActive: boolean;
  status: UserStatus;
  step?: number; // https://github.com/Vision-Tech-ERMA/erma/blob/develop/api/src/users/enums/registrationSteps.enum.ts

  // Roles & Permissions
  // https://vision-utilities.atlassian.net/wiki/spaces/ERMA/pages/5603343/Roles+and+Permissions+Matrix
  // TODO: remove in sprint 25
  /*
  role?: string;
  roleIds?: number[];
  */
  roles?: RolesType[];
  currentRole: string;
  favorite?: boolean;
  userRoles: RolesType[] | string[];
  curPermissions?: string[];
  allPermissions?: string[];
  lodging: string;
  assignments: AssignmentsType[];
  settings: UserSettings;
  errorMessage: string;
  incidentLinemen: IncidentLinemen[];
  companyTag?: string;
  assignedVehicle?: string;
  meta: object;
  issues: string[];
} & AddressType &
  ProfileType;

export enum WorkerType {
  FTE = 'FTE',
  SUPPORT = 'SUPPORT',
}

export type PayloadLinemenType = {
  id: number;
  linemen: string[];
  type: WorkerType;
};
