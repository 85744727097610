import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { UserType } from 'src/types';

import { SagaAction } from '../../types';
import { editUser } from '../actions';
import { setUser } from '../reducers';

// @ts-ignore
function* editUserSaga({ payload: data }: SagaAction<Partial<UserType>>): SagaIterator {
  try {
    yield put(editUser.pending());
    const { payload } = yield call(API.editUser, data);
    yield put(setUser(payload));
    yield put(editUser.success());
  } catch (e) {
    yield put(editUser.failure());
  }
}

export default editUserSaga;
