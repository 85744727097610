import * as Yup from 'yup';
import { Box, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, H1, P1R, Dialog, FormikInputSelectMulti } from 'src/components';
import config from 'src/config';
import { ListType } from 'src/types';
import { themeColors } from 'src/theme';

type Props = {
  invitedYards: number[];
  onClose: () => void;
  onConfirm: (yards: number[]) => void;
};

function DeclineLinemenPopup({ invitedYards, onClose, onConfirm }: Props) {
  const { t } = useTranslation();

  const formik = useFormik<{ yard: number[] }>({
    initialValues: { yard: invitedYards },

    validationSchema: Yup.object({
      yard: Yup.array().min(1, 'Required'),
    }),

    onSubmit: async (data) => {
      onConfirm(data.yard);
      onClose();
    },
  });

  const handleConfirm = () => {
    formik.handleSubmit();
  };

  return (
    <Dialog open onClose={onClose} aria-labelledby="customized-dialog-title">
      <Box
        sx={{
          mb: 5,
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        <H1 align="center" sx={{ mt: 3, mb: 2, textTransform: 'capitalize' }}>
          {t('eventPage.declinePeopleTitle')}
        </H1>
        <P1R sx={{ mt: 3 }}> {t('eventPage.declinePeopleSubTitle')}</P1R>

        <Box sx={{ mt: 2, mb: -3, width: '100%', textAlign: 'left' }}>
          <FormikInputSelectMulti
            formik={formik}
            field="yard"
            label="Select Yard"
            options={
              config.options.yards.map((yard: ListType) => ({
                key: yard.key,
                value: yard.value,
              })) || []
            }
            disableClearable
          />
        </Box>
      </Box>

      <Grid container columnSpacing={2} sx={{ mb: 5 }}>
        <Grid item xs={6}>
          <Button
            primary={false}
            onClick={onClose}
            sx={{
              width: '100%',
              textTransform: 'capitalize',
            }}
          >
            {t('eventPage.cancel')}
          </Button>
        </Grid>

        <Grid item xs={6}>
          <Button
            onClick={handleConfirm}
            sx={{
              width: '100%',
              textTransform: 'capitalize',
              backgroundColor: themeColors.red,
            }}
          >
            {t('eventPage.confirm')}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default DeclineLinemenPopup;
