import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { H1, H3, P3, Button, FormikCalendar, FormikInputSelect, FormikInputSelectMulti, FormikInputString, Attachment } from 'src/components';
import { PATHS } from 'src/navigation';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { patchUser, loadUser, logout } from 'src/redux/user/actions';
import {
  selectPatchingUser,
  selectPatchingUserError,
  selectPatchingUserSuccess,
  selectSuccessUploadingUserFile,
  selectUser,
} from 'src/redux/user/selectors';
import config from 'src/config';
import { themeColors } from 'src/theme';
import { normalizerTimestamp, validation, getUnionClassifications, ssnMask } from 'src/utils';
import SuccessDataSaveSnackbar from 'src/components/SuccessDataSaveSnackbar';
import { BlankWrapper } from '../wrappers';

type FormValues = {
  utilityContractorId: number | undefined;
  ssn: string;
  preferredName: string;
  unionClassification: string | number;
  medicalExpiration?: number;

  rubberGlove: string;
  rubberSleeve: string;
  workGlove: string;
  workVest: string;

  localUnion: string;
  unionTicket: string;

  driverLicenseState: string;
  driverLicense: string;
  driverLicenseClass: string;
  driverLicenseExpiration?: number;

  cdlEndorsements: (string | undefined)[];
  cdlRestrictions: (string | undefined)[];

  passport: string;
  passportExpiration?: number;
};
const totalFields = [
  'utilityContractorId',
  'ssn',
  'preferredName',
  'medicalExpiration',
  'rubberGlove',
  'rubberSleeve',
  'workGlove',
  'workVest',
  'localUnion',
  'unionTicket',
  'unionClassification',
  'driverLicenseState',
  'driverLicense',
  'driverLicenseClass',
  'driverLicenseExpiration',
  'cdlEndorsements',
  'cdlRestrictions',
  'passport',
  'passportExpiration',
];

const blockStyled = {}; // {backgroundColor: themeColors.grayLight, mb: '24px'};

const DocumentsFieldWorkerContainer: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const patchingUser = useSelector(selectPatchingUser);
  const patchingUserError = useSelector(selectPatchingUserError);
  const patchingUserSuccess = useSelector(selectPatchingUserSuccess);
  const successUploadingUserFile = useSelector(selectSuccessUploadingUserFile);
  const user = useSelector(selectUser);

  // Technical Debt: Should be resolve on config level and run app
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, setConfigIsLoaded] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [activeUpload, setActiveUpload] = useState<null | string>(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleActiveUpload = (uploadName: string) => {
    setActiveUpload(uploadName);
  };

  const handleFileChange = (newFile: boolean) => {
    if (formik.dirty || newFile) {
      setIsSaveDisabled(false);
    }
  };

  useEffect(() => {
    if (successUploadingUserFile) {
      setShowSnackbar(true);
    }
  }, [successUploadingUserFile]);

  useEffect(() => {
    config.refresh().then(() => setConfigIsLoaded(true));
    dispatch(loadUser.init());
  }, []);

  useEffect(() => {
    if (patchingUserSuccess) {
      isDraft ? dispatch(logout.init()) : navigate(PATHS.CREATE_ACCOUNT_WAITING_PAGE);
    }
  }, [navigate, patchingUserSuccess]);

  const initialValues: FormValues = {
    utilityContractorId: user.utilityContractorId,
    preferredName: user.preferredName || '',
    ssn: user.ssn || '',
    unionClassification: user.unionClassification || '',
    medicalExpiration: user.medicalExpiration && normalizerTimestamp(user.medicalExpiration),
    rubberSleeve: user.rubberSleeve || '',
    rubberGlove: user.rubberGlove || '',
    workVest: user.workVest || '',
    workGlove: user.workGlove || '',

    localUnion: user.localUnion || '',
    unionTicket: user.unionTicket || '',

    driverLicenseState: user.driverLicenseState || '',
    driverLicense: user.driverLicense || '',
    driverLicenseClass: user.driverLicenseClass || '',
    driverLicenseExpiration: user.driverLicenseExpiration && normalizerTimestamp(user.driverLicenseExpiration),

    cdlEndorsements: (user.cdlEndorsements || []).map((item) => item) || [],
    cdlRestrictions: (user.cdlRestrictions || []).map((item) => item) || [],

    passport: user.passport || '',
    passportExpiration: user.passportExpiration && normalizerTimestamp(user.passportExpiration),
  };

  const defaultValidationSchema = Yup.object({
    utilityContractorId: Yup.number().required(t('validations.required')),
    unionClassification: Yup.string().required(t('validations.required')),
    ssn: Yup.string().matches(validation.ssn, t('validations.ssn')).required(t('validations.required')),
    medicalExpiration: Yup.number().required(t('validations.required')),
    rubberSleeve: Yup.string().required(t('validations.required')),
    rubberGlove: Yup.string().required(t('validations.required')),
    workVest: Yup.string().required(t('validations.required')),
    workGlove: Yup.string().required(t('validations.required')),
    localUnion: Yup.string().required(t('validations.required')),
    unionTicket: Yup.string().required(t('validations.required')),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: isDraft ? Yup.object({}) : defaultValidationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (data) => {
      const halfDay: number = 12 * 60 * 60;
      const payload = {
        ...data,
        // Technical Debts: Trouble with "Daylight Saving Time" (DST)
        // For reproducing change on profile: 31/10/2023, save, 29/10/2023, save. Will change on 28/10/2023.
        // Hotfix: Add half day
        medicalExpiration: data.medicalExpiration ? normalizerTimestamp(data.medicalExpiration) + halfDay : undefined,
        passportExpiration: data.passportExpiration ? normalizerTimestamp(data.passportExpiration) + halfDay : undefined,
        driverLicenseExpiration: data.driverLicenseExpiration ? normalizerTimestamp(data.driverLicenseExpiration) + halfDay : undefined,
        isDraft,
      };
      dispatch(patchUser.init({ data: { ...payload } }));
    },
  });

  const submitForm = async () => {
    setIsDraft(false);
    await formik.validateForm();
    formik.handleSubmit();
  };

  const saveFormAndReturnLater = async () => {
    setIsDraft(true);
    await formik.validateForm();
    formik.handleSubmit();
  };

  useEffect(() => {
    setIsSaveDisabled(!formik.dirty);
  }, [formik.dirty]);

  const totalErrors = Object.keys(formik.errors).filter((val) => totalFields.includes(val)).length;

  return (
    <BlankWrapper>
      <Grid container columnSpacing={{ xs: 0, sm: 2 }} component="form" noValidate onSubmit={submitForm} sx={{ width: '100%', margin: 'auto' }}>
        <Grid item xs={12} sx={{ mb: 5, textTransform: 'capitalize' }}>
          <H1>{t('createAccountPage.completeYourProfile')}</H1>
        </Grid>
        <Grid item xs={12} sx={{ width: '100%', marginBottom: '12px' }}>
          <Box
            sx={{
              padding: '24px 24px 1px 24px',
              margin: '0 -24px 24px -24px',
              borderRadius: '4px',
              backgroundColor: themeColors.grayLight,
            }}
          >
            <H3>{t('createAccountPage.personalDetails')}</H3>
            <Box
              sx={{
                gap: { xs: 0, sm: '14px' },
                mt: 1,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Box sx={{ width: '100%' }}>
                <FormikInputSelect
                  formik={formik}
                  field="utilityContractorId"
                  label={t('profilePage.employee')}
                  options={config.options.utilityContractors}
                  disabled={patchingUser}
                  disableClearable
                />
              </Box>
              <Box sx={{ width: '100%' }}>
                <FormikInputString formik={formik} field="preferredName" label={t('profilePage.preferredName')} disabled={patchingUser} />
              </Box>
            </Box>
            <Box
              sx={{
                gap: { xs: 0, sm: '14px' },
                mt: 1,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Box sx={{ width: '100%' }}>
                <FormikInputString
                  formik={formik}
                  field="ssn"
                  label={t('profileName.ssn')}
                  disabled={patchingUser}
                  onChange={(e) => {
                    let value = e.target.value || '';
                    value = ssnMask(value);
                    formik.setFieldValue('ssn', value);
                  }}
                />
              </Box>
              <Box sx={{ width: '100%' }}>
                <FormikCalendar formik={formik} field="medicalExpiration" label={t('profilePage.medicalExpirationData')} disabled={patchingUser} />
              </Box>
            </Box>
            <Box>
              {/* TODO: needs some refactoring */}
              {user.attachments && (
                <Attachment
                  title={t('profileName.ssn')}
                  typeName="ssn"
                  userId={user.uuid}
                  selectedAttachment={user.attachments?.ssn}
                  activeField={activeUpload}
                  handleActiveUpload={handleActiveUpload}
                  handleNewFileUpload={handleFileChange}
                />
              )}
            </Box>
            <Box>
              {/* TODO: needs some refactoring */}
              {user.attachments && (
                <Attachment
                  title={t('profileName.medicalCard')}
                  typeName="medical"
                  userId={user.uuid}
                  selectedAttachment={user.attachments?.medical}
                  activeField={activeUpload}
                  handleActiveUpload={handleActiveUpload}
                  handleNewFileUpload={handleFileChange}
                />
              )}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ width: '100%', marginBottom: '12px' }}>
          <Box
            sx={{
              padding: '24px 24px 1px 24px',
              margin: '0 -24px 24px -24px',
              borderRadius: '4px',
              backgroundColor: themeColors.grayLight,
            }}
          >
            <H3>{t('createAccountPage.ppeSizes')}</H3>
            <Box
              sx={{
                gap: { xs: 0, sm: '14px' },
                mt: 1,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Box sx={{ width: '100%' }}>
                <FormikInputSelect
                  formik={formik}
                  field="rubberGlove"
                  label={t('profilePage.rubberGlove')}
                  options={config.options.rubberGloveSize}
                  disabled={patchingUser}
                />
              </Box>
              <Box sx={{ width: '100%' }}>
                <FormikInputSelect
                  formik={formik}
                  field="rubberSleeve"
                  label={t('profilePage.rubberSleeve')}
                  options={config.options.rubberSleeveSize}
                  disabled={patchingUser}
                />
              </Box>
            </Box>
            <Box
              sx={{
                gap: { xs: 0, sm: '14px' },
                mt: 1,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Box sx={{ width: '100%' }}>
                <FormikInputSelect
                  formik={formik}
                  field="workGlove"
                  label={t('profilePage.workGlove')}
                  options={config.options.workGloveSize}
                  disabled={patchingUser}
                />
              </Box>
              <Box sx={{ width: '100%' }}>
                <FormikInputSelect
                  formik={formik}
                  field="workVest"
                  label={t('profilePage.workVest')}
                  options={config.options.workVestSize}
                  disabled={patchingUser}
                />
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ width: '100%', marginBottom: '12px' }}>
          <Box
            sx={{
              padding: '24px 24px 1px 24px',
              margin: '0 -24px 24px -24px',
              borderRadius: '4px',
              backgroundColor: themeColors.grayLight,
            }}
          >
            <H3>{t('profilePage.union')}</H3>
            <Box
              sx={{
                gap: { xs: 0, sm: '14px' },
                mt: 1,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Box sx={{ width: '100%' }}>
                <FormikInputSelect
                  formik={formik}
                  field="localUnion"
                  label={t('profilePage.localUnion')}
                  options={config.options.UNIONS.map((union: string) => ({ key: union, value: union })) || []}
                  disabled={patchingUser}
                />
              </Box>
              <Box sx={{ width: '100%' }}>
                <FormikInputString formik={formik} field="unionTicket" label={t('profilePage.unionTicket')} disabled={patchingUser} />
              </Box>
            </Box>
            <Box>
              <FormikInputSelect
                formik={formik}
                field="unionClassification"
                label={t('profilePage.classification')}
                options={
                  (formik.values.localUnion &&
                    // @ts-ignore
                    getUnionClassifications(formik.values.localUnion, 'UNION_TICKET').map((classification: string) => ({
                      key: classification,
                      value: t(`union_classifications.${formik.values.localUnion}.${classification}`),
                    }))) ||
                  []
                }
                disabled={patchingUser || formik.values.localUnion === '' || formik.values.localUnion === undefined}
              />
            </Box>
            <Box>
              {/* TODO: needs some refactoring */}
              {user.attachments && (
                <Attachment
                  title={t('profilePage.unionTicket')}
                  typeName="union"
                  userId={user.uuid}
                  selectedAttachment={user.attachments?.union}
                  activeField={activeUpload}
                  handleActiveUpload={handleActiveUpload}
                  handleNewFileUpload={handleFileChange}
                />
              )}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ width: '100%', marginBottom: '12px' }}>
          <Box
            sx={{
              padding: '24px 24px 1px 24px',
              margin: '0 -24px 24px -24px',
              borderRadius: '4px',
              backgroundColor: themeColors.grayLight,
            }}
          >
            <H3>{t('profilePage.driversLicense')}</H3>
            <Box
              sx={{
                gap: { xs: 0, md: '14px' },
                mt: 1,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', md: 'row' },
              }}
            >
              <Box sx={{ width: { xs: '100%' } }}>
                {/* <FormikInputSelect
                  formik={formik}
                  field="driverLicenseState"
                  label="State"
                  // options={(config.options.STATES || []).map((state: string) => ({ key: state, value: t(`states.${state}`) }))}
                  disabled={patchingUser}
                /> */}
              </Box>
              <Box sx={{ width: { xs: '100%' } }}>
                <FormikInputString formik={formik} field="driverLicense" label={t('profilePage.license')} disabled={patchingUser} />
              </Box>
            </Box>
            <Box
              sx={{
                gap: { xs: 0, md: '14px' },
                mt: 1,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', md: 'row' },
              }}
            >
              <Box sx={{ width: { xs: '100%' } }}>
                <FormikInputSelect
                  formik={formik}
                  field="driverLicenseClass"
                  label={t('profilePage.class')}
                  options={config.driverLicenseClass}
                  disabled={patchingUser}
                />
              </Box>
              <Box sx={{ width: { xs: '100%' } }}>
                <FormikCalendar formik={formik} field="driverLicenseExpiration" label={t('profilePage.expirationDate')} disabled={patchingUser} />
              </Box>
            </Box>
            <Box
              sx={{
                gap: { xs: 0, md: '14px' },
                mt: 1,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', md: 'row' },
              }}
            >
              <Box sx={{ width: { xs: '100%' } }}>
                <FormikInputSelectMulti
                  formik={formik}
                  field="cdlEndorsements"
                  label={t('profilePage.cdlEndorsements')}
                  options={
                    config.options.CDL_ENDORSEMENTS.map((endorsement: string) => ({
                      key: endorsement,
                      value: t(`cdlEndorsements.${endorsement}`),
                    })) || []
                  }
                />
              </Box>
              <Box sx={{ width: { xs: '100%' } }}>
                <FormikInputSelectMulti
                  formik={formik}
                  field="cdlRestrictions"
                  label={t('profilePage.cdlRestrictions')}
                  options={
                    config.options.CDL_RESTRICTIONS.map((restriction: string) => ({
                      key: restriction,
                      value: t(`cdlRestrictions.${restriction}`),
                    })) || []
                  }
                />
              </Box>
            </Box>
            <Box>
              {user.attachments && (
                <Attachment
                  title={t('profilePage.driverLicenseFront')}
                  typeName="driver_license_front"
                  userId={user.uuid}
                  selectedAttachment={user.attachments?.driver_license_front}
                  activeField={activeUpload}
                  handleActiveUpload={handleActiveUpload}
                  handleNewFileUpload={handleFileChange}
                />
              )}
            </Box>
            <Box>
              {user.attachments && (
                <Attachment
                  title={t('profilePage.driverLicenseBack')}
                  typeName="driver_license_back"
                  userId={user.uuid}
                  selectedAttachment={user.attachments?.driver_license_back}
                  activeField={activeUpload}
                  handleActiveUpload={handleActiveUpload}
                  handleNewFileUpload={handleFileChange}
                />
              )}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ width: '100%', margin: '12px 0' }}>
          <Box
            sx={{
              padding: '24px 24px 1px 24px',
              margin: '0 -24px 24px -24px',
              borderRadius: '4px',
              backgroundColor: themeColors.grayLight,
            }}
          >
            <H3>{t('profilePage.passport')}</H3>
            <Box
              sx={{
                gap: { xs: 0, sm: '14px' },
                mt: 1,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Box sx={{ width: '100%' }}>
                <FormikInputString formik={formik} field="passport" label={t('profilePage.passport')} disabled={patchingUser} />
              </Box>
              <Box sx={{ width: '100%' }}>
                <FormikCalendar formik={formik} field="passportExpiration" label={t('profilePage.expirationDate')} disabled={patchingUser} />
              </Box>
            </Box>
            <Grid item xs={12} sx={blockStyled}>
              {user.attachments && (
                <Attachment
                  title={t('profilePage.passport')}
                  typeName="passport"
                  userId={user.uuid}
                  selectedAttachment={user.attachments?.passport}
                  activeField={activeUpload}
                  handleActiveUpload={handleActiveUpload}
                  handleNewFileUpload={handleFileChange}
                />
              )}
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          {totalErrors > 0 && (
            <P3 sx={{ color: themeColors.error, mt: 3 }}>
              {totalErrors}
              {t('common.totalCountErrors')}
            </P3>
          )}
          {patchingUserError && <P3 sx={{ color: themeColors.error, mt: 3 }}>{t('common.internalServerError')}</P3>}
        </Grid>

        <Grid item xs={12}>
          <Button fullWidth loading={patchingUser} disabled={patchingUser} onClick={submitForm} sx={{ textTransform: 'capitalize' }}>
            {t('common.submit')}
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ mt: '16px', mb: '48px' }}>
          <Button
            fullWidth
            primary={false}
            loading={patchingUser}
            disabled={isSaveDisabled}
            onClick={saveFormAndReturnLater}
            sx={{ textTransform: 'capitalize' }}
          >
            {t('common.saveAndReturnLater')}
          </Button>
        </Grid>
        <SuccessDataSaveSnackbar open={showSnackbar} onClose={() => setShowSnackbar(false)} />
      </Grid>
    </BlankWrapper>
  );
};

export default DocumentsFieldWorkerContainer;
