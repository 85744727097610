import { createReduxAsyncType } from '../utils/toolkit';

const TYPES = {
  CREATE_ROLES: createReduxAsyncType('CREATE_ROLES'),
  LOAD_ROLES: createReduxAsyncType('LOAD_ROLES'),
  LOAD_FILTERED_ROLES: createReduxAsyncType('LOAD_FILTERED_ROLES'),
  UPDATE_ROLES: createReduxAsyncType('UPDATE_ROLES'),
  DELETE_ROLES: createReduxAsyncType('DELETE_ROLES'),
  LOAD_PERMISSIONS: createReduxAsyncType('LOAD_PERMISSIONS'),
};

export { TYPES };
