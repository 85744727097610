import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Autocomplete, TextField, Box } from '@mui/material';
import { ValueOptions, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { ListType, UserType, VehicleType, RosterParticipationStatus } from 'src/types';
import { themeColors } from 'src/theme';
import { Icon, P1R, P3, Table, Label } from 'src/components';
import { loadRoster, rosterLinemanSetOptions } from 'src/redux/roster/actions';
import { selectRosterLinemanSetOptionsSuccess } from 'src/redux/roster/selectors';
import config from 'src/config';
import { getUnionClassifications } from 'src/utils';
import { TableProps, NumericCell, NumericCellEditor, BooleanCell } from '../Table';

export const rosterColumnVisibilityModel = {
  fullName: false,
  firstName: false,
  lastName: false,
  inviteStatus: false,
  email: false,
  utilityFteClassification: false,
  timesheetFteClassification: false,
  unionClassification: false,
  crewId: false,
  crewLead: false,
  assignedVehicle: false,
  companyTag: false,
  gender: false,
  lodging: false,
};

type Props = {
  ibewPay: string;
  rosterId: number;
  rosterStatus: number;
  linemen: Partial<UserType>[];
  fleet?: VehicleType[];
  utilityId?: number;
  isEditable?: boolean;
} & Partial<TableProps>;

const RosterTable: FC<Props> = ({ rosterId, ibewPay, rosterStatus, linemen, fleet, utilityId, isEditable = false, ...props }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const rosterLinemanSetOptionsSuccess = useSelector(selectRosterLinemanSetOptionsSuccess);

  // @ts-ignore
  const currUtility = config.options.utilities.find((obj: ListType) => obj.key === utilityId).value;

  const trueIcon = <Icon name="foreman" />;
  const falseIcon = <Icon name="foreman" sx={{ opacity: '0.1' }} />;

  useEffect(() => {
    if (rosterLinemanSetOptionsSuccess) {
      dispatch(loadRoster.init({ id: rosterId }));
      dispatch(rosterLinemanSetOptions.reset());
    }
  }, [rosterLinemanSetOptionsSuccess]);

  const columns: GridColDef[] = [
    { field: 'fullName', headerName: t('linemen.fullName'), width: 180 },
    { field: 'firstName', headerName: t('linemen.firstName'), minWidth: 125 },
    { field: 'lastName', headerName: t('linemen.lastName'), minWidth: 175 },
    {
      field: 'inviteStatus',
      headerName: t('eventPage.inviteStatus'),
      width: 140,
      renderCell: (params: GridRenderCellParams) => {
        const { inviteStatus } = params.row;
        const inviteLabel: any = {
          [RosterParticipationStatus.ACCEPTED]: { text: 'Yes', type: 'green' },
          [RosterParticipationStatus.ACCEPTED_ON_BEHALF]: { text: 'On behalf', type: 'yellow' },
          [RosterParticipationStatus.PENDING]: { text: 'Pending', type: 'gray' },
          [RosterParticipationStatus.REJECTED]: { text: 'No', type: 'red' },
        };
        // @ts-ignore
        if (inviteStatus === RosterParticipationStatus.SUPPROTER) return null;
        return <Label type={inviteLabel[inviteStatus]?.type || 'gray'} text={inviteLabel[inviteStatus]?.text || ''} />;
      },
    },
    { field: 'email', headerName: t('users.email'), minWidth: 280 },
    {
      field: 'utilityFteClassification',
      headerName: t('linemen.utilityClassification'),
      type: 'singleSelect',
      editable: isEditable && rosterStatus !== 1 && rosterStatus !== 5,
      width: 200,
      renderCell: (params) => (params.value ? t(`utility_fte_classifications.${currUtility}.${params.value}`) : <P1R>Select</P1R>),
      valueOptions: () =>
        // @ts-ignore
        config.options.UTILITY_FTE_CLASSIFICATIONS[currUtility]?.map<ValueOptions>((classification: ListType) => ({
          value: classification,
          label: t(`utility_fte_classifications.${currUtility}.${classification}`),
        })),
    },
    {
      field: 'unionClassification',
      headerName: t('linemen.unionClassification'),
      type: 'singleSelect',
      editable: isEditable && rosterStatus !== 1 && rosterStatus !== 5,
      width: 200,
      renderCell: (params) => (params.value ? t(`union_classifications.${ibewPay}.${params.value}`) : <P1R>Select</P1R>),
      valueOptions: () =>
        // @ts-ignore
        getUnionClassifications(ibewPay, 'UNION_RATES')?.map<ValueOptions>((classification: ListType) => ({
          value: classification,
          label: t(`union_classifications.${ibewPay}.${classification}`),
        })),
    },
    {
      field: 'timesheetFteClassification',
      headerName: t('linemen.timesheetInvoiceClassification'),
      type: 'singleSelect',
      editable: isEditable && rosterStatus !== 1 && rosterStatus !== 5,
      width: 200,
      renderCell: (params) => (params.value ? t(`timesheet_fte_classifications.${currUtility}.${params.value}`) : <P1R>Select</P1R>),
      valueOptions: () =>
        // @ts-ignore
        config.options.TIMESHEET_FTE_CLASSIFICATIONS[currUtility]?.map<ValueOptions>((classification: ListType) => ({
          value: classification,
          label: t(`timesheet_fte_classifications.${currUtility}.${classification}`),
        })),
    },
    {
      field: 'crewId',
      headerName: t('linemen.crew'),
      editable: isEditable && rosterStatus !== 1 && rosterStatus !== 5,
      width: 110,
      renderCell: (params: GridRenderCellParams) => <NumericCell sx={{ justifyContent: 'left' }} {...params} />,
      renderEditCell: (params: GridRenderCellParams) => <NumericCellEditor {...params} />,
    },
    {
      field: 'crewLead',
      headerName: t('rosterPeoplePage.crewLead'),
      type: 'boolean',
      editable: isEditable && rosterStatus !== 1 && rosterStatus !== 5,
      width: 80,
      renderCell: (params: GridRenderCellParams) => <BooleanCell trueIcon={trueIcon} falseIcon={falseIcon} {...params} />,
      renderEditCell: (params: GridRenderCellParams) => {
        const { api, value, row } = params;

        if (value && row.crewId) {
          // make sure only one lead is selected for a crew
          const rowsModel = api.state.rows.dataRowIdToModelLookup;
          const sameCrewIds = Object.keys(rowsModel).filter((item) => rowsModel[item].crewId === row.crewId && rowsModel[item].id !== row.id);
          sameCrewIds.forEach((id) => {
            api.updateRows([{ id, crewLead: false }]);
          });
        }
        return <BooleanCell editable trueIcon={trueIcon} falseIcon={falseIcon} {...params} />;
      },
    },
    {
      field: 'assignedVehicle',
      headerName: t('linemen.fleet'),
      type: 'singleSelect',
      editable: isEditable && rosterStatus !== 1 && rosterStatus !== 5,
      width: 200,
      renderCell: (params) => (params.value ? <P1R>{t(`vehicles.${params.value.type}`)}</P1R> : <P1R>Select</P1R>),
      renderEditCell: (params) => {
        const options =
          fleet?.map<ValueOptions>((item: VehicleType) => ({
            id: item.id,
            type: item.type,
            tag: item.companyTag,
            yardFK: item.yardFK,
          })) || [];

        // TODO: move cell autocomplete editor to separate component
        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, value: VehicleType) => {
          params.api.setEditCellValue({
            id: params.id,
            field: params.field,
            value: value || '',
          });
        };
        return (
          <Autocomplete
            value={params.value}
            // @ts-ignore
            getOptionLabel={(option: any) => t(`vehicles.${option.type}`)}
            options={options}
            // @ts-ignore
            onChange={handleInputChange}
            renderInput={(params) => <TextField {...params} />}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{
                  padding: 0,
                  flexDirection: 'column',
                  border: `1px solid ${themeColors.grayMedium}`,
                  '&:hover': { backgroundColor: themeColors.grayLight },
                }}
                {...props}
              >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Icon name={option.type} />
                  <P1R>{t(`vehicles.${option.type}`)}</P1R>
                </Box>
                <Box display="flex" flexDirection="column">
                  <P3>
                    <b>Yard:</b> {(config.options.yards.find((yard: ListType) => yard.key === option.yardFK) as ListType | undefined)?.value}
                  </P3>
                  <P3>
                    <b>Company tag:</b> {option.tag}
                  </P3>
                </Box>
              </Box>
            )}
            fullWidth
            disableClearable
          />
        );
      },
    },
    {
      field: 'companyTag',
      headerName: t('linemen.company'),
      width: 140,
      valueGetter: (params) => {
        const selectedVehicle = fleet?.find((vehicle) => vehicle.id === params.row.assignedVehicle?.id);
        return selectedVehicle?.companyTag || '';
      },
    },
    { field: 'gender', headerName: t('linemen.gender'), width: 100 },
    {
      field: 'lodging',
      headerName: t('linemen.lodging'),
      type: 'singleSelect',
      editable: isEditable && rosterStatus !== 1 && rosterStatus !== 5,
      width: 130,
      valueOptions: (): ValueOptions[] => [
        { value: 'single', label: 'Single' },
        { value: 'double', label: 'Double' },
      ],
    },
  ];

  const sortedLinemen = linemen?.sort((a, b) => {
    if (a === linemen[0]) return -1;
    if (b === linemen[0]) return 1;

    const crewA = a.assignments && a.assignments[0] && a.assignments[0].crew;
    const crewB = b.assignments && b.assignments[0] && b.assignments[0].crew;

    if (typeof crewA !== 'number' || typeof crewB !== 'number') {
      return 0;
    }

    return crewB - crewA;
  });

  const rows = sortedLinemen.map((lineman) => {
    const assignedVehicle = fleet?.find((vehicle) => vehicle.id === lineman.assignments?.[0].vehicleId);
    return {
      id: lineman.uuid,
      fullName: `${lineman.firstName ?? ''} ${lineman.lastName ?? ''}`,
      firstName: lineman.firstName,
      lastName: lineman.lastName,
      inviteStatus: lineman.assignments?.[0] ? lineman.assignments?.[0].participationStatus : lineman?.incidentLinemen?.[0]?.participationStatus,
      email: lineman.email,
      unionClassification: lineman?.assignments?.[0]?.unionClassification,
      utilityFteClassification: lineman?.assignments?.[0]?.utilityFteClassification,
      timesheetFteClassification: lineman?.assignments?.[0]?.timesheetFteClassification,
      crewId: lineman.assignments?.[0]?.crew,
      crewLead: lineman?.assignments?.[0]?.isLead,
      assignedVehicle: assignedVehicle ? { id: assignedVehicle.id, type: assignedVehicle.type } : undefined,
      companyTag: assignedVehicle?.companyTag,
      gender: lineman.gender,
      lodging: lineman?.assignments?.[0]?.lodging,
      incidentLinemen: lineman?.incidentLinemen ? lineman?.incidentLinemen?.[0] : '',
    };
  });

  return <Table {...props} type="advanced" columns={columns} rows={rows} getRowId={(row) => row.id} disableRowSelectionOnClick />;
};

export default RosterTable;
