import { createSelector } from 'reselect';
import { TYPES } from './constants';
import { createLoadingSelector } from '../@loadings';
import { createSuccessSelector } from '../@success';
import { createErrorSelector } from '../@errors';
import { RolesDataState } from './types';
import { ReduxState } from '../types';

export const selectRolesData = (state: ReduxState): RolesDataState => state.rolesData;

export const selectRoles = createSelector(selectRolesData, ({ roles }) => roles);
export const selectPermissions = createSelector(selectRolesData, ({ permissions }) => permissions);

export const selectCreateRoles = createLoadingSelector(TYPES.CREATE_ROLES.TYPE);
export const selectCreateRolesSuccess = createSuccessSelector(TYPES.CREATE_ROLES.TYPE);
export const selectCreateRolesError = createErrorSelector(TYPES.CREATE_ROLES.TYPE);

export const selectRolesLoading = createLoadingSelector(TYPES.LOAD_ROLES.TYPE);
export const selectRolesSuccess = createSuccessSelector(TYPES.LOAD_ROLES.TYPE);
export const selectRolesError = createErrorSelector(TYPES.LOAD_ROLES.TYPE);

export const selectFilteredRoles = createLoadingSelector(TYPES.LOAD_FILTERED_ROLES.TYPE);
export const selectFilteredRolesSuccess = createSuccessSelector(TYPES.LOAD_FILTERED_ROLES.TYPE);
export const selectFilteredRolesError = createErrorSelector(TYPES.LOAD_FILTERED_ROLES.TYPE);

export const selectUpdateRoles = createLoadingSelector(TYPES.UPDATE_ROLES.TYPE);
export const selectUpdateRolesSuccess = createSuccessSelector(TYPES.UPDATE_ROLES.TYPE);
export const selectUpdateRolesError = createErrorSelector(TYPES.UPDATE_ROLES.TYPE);

export const selectDeleteRoles = createLoadingSelector(TYPES.DELETE_ROLES.TYPE);
export const selectDeleteRolesSuccess = createSuccessSelector(TYPES.DELETE_ROLES.TYPE);
export const selectDeleteRolesError = createErrorSelector(TYPES.DELETE_ROLES.TYPE);

export const selectPermissionsLoading = createLoadingSelector(TYPES.LOAD_PERMISSIONS.TYPE);
export const selectPermissionsSuccess = createSuccessSelector(TYPES.LOAD_PERMISSIONS.TYPE);
export const selectPermissionsError = createErrorSelector(TYPES.LOAD_PERMISSIONS.TYPE);
