import { Box } from '@mui/material';
import underConstructionImage from 'src/assets/underConstruction.svg';
import { useTranslation } from 'react-i18next';
import { H1, P1R } from '../Typography';

const UnderConstruction = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
        gap: '76px',
        pt: 4,
      }}
    >
      <img src={underConstructionImage} alt="Under Construction Image" />

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
        <H1>{t('underConstructionPage.title')}</H1>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <P1R>{t('underConstructionPage.subtitle')}</P1R>
          <P1R>{t('underConstructionPage.stayTuned')}</P1R>
        </Box>
      </Box>
    </Box>
  );
};

export default UnderConstruction;
