import { createReduxAsyncType } from '../utils/toolkit';

const TYPES = {
  LOAD_ROSTER: createReduxAsyncType('LOAD_ROSTER'),
  CREATE_ROSTER: createReduxAsyncType('CREATE_ROSTER'),
  SAVE_ROSTER: createReduxAsyncType('SAVE_ROSTER'),
  DELETE_ROSTER: createReduxAsyncType('DELETE_ROSTER'),
  ROSTER_ATTACH_WORKER: createReduxAsyncType('ROSTER_ATTACH_WORKER'),
  ROSTER_ATTACH_VEHICLES: createReduxAsyncType('ROSTER_ATTACH_VEHICLES'),
  ROSTER_DETACH_LINEMAN: createReduxAsyncType('ROSTER_DETACH_LINEMAN'),
  ROSTER_DETACH_VEHICLE: createReduxAsyncType('ROSTER_DETACH_VEHICLE'),
  LOAD_ROSTER_AVAILABLE_VEHICLES: createReduxAsyncType('LOAD_ROSTER_AVAILABLE_VEHICLES'),
  ROSTER_ASSIGNMENTS: createReduxAsyncType('ROSTER_ASSIGNMENTS'),
  ROSTER_LINEMAN_SET_OPTIONS: createReduxAsyncType('ROSTER_LINEMAN_SET_OPTIONS'),
  ROSTER_EQUIPMENT_SET_OPTIONS: createReduxAsyncType('ROSTER_EQUIPMENT_SET_OPTIONS'),
  ROSTER_SET_STATUS: createReduxAsyncType('ROSTER_SET_STATUS'),
  ROSTER_TIMESHEET: createReduxAsyncType('ROSTER_TIMESHEET'),
  ROSTER_TRANSFER: createReduxAsyncType('ROSTER_TRANSFER'),
  ROSTER_REVERT: createReduxAsyncType('ROSTER_REVERT'),
  ROSTER_SPLIT: createReduxAsyncType('ROSTER_SPLIT'),
};

export { TYPES };
