import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { RosterType } from 'src/types';
import { SagaAction } from '../../types';
import { loadRoster } from '../actions';
import { setRoster } from '../reducers';

function* loadRosterSaga({ payload: { id, version = 0 } }: SagaAction<Partial<RosterType>>): SagaIterator {
  try {
    yield put(loadRoster.pending());
    const { payload } = yield call(API.loadRoster, { id, version });

    const updatedPayload = { ...payload, linemen: payload?.linemen?.filter((item: any) => item !== null) };

    yield put(setRoster(updatedPayload));
    yield put(loadRoster.success());
  } catch (error) {
    yield put(loadRoster.failure(error));
  }
}

export default loadRosterSaga;
