import { createSelector } from 'reselect';
import { TYPES } from './constants';
import { createLoadingSelector } from '../@loadings';
import { createSuccessSelector } from '../@success';
import { createErrorSelector } from '../@errors';
import { VehiclesDataState } from './types';
import { ReduxState } from '../types';

export const selectVehiclesData = (state: ReduxState): VehiclesDataState => state.vehiclesData;

export const selectVehicles = createSelector(selectVehiclesData, ({ vehicles }) => vehicles);

export const selectVehiclesLoading = createLoadingSelector(TYPES.LOAD_VEHICLES.TYPE);
export const selectVehiclesSuccess = createSuccessSelector(TYPES.LOAD_VEHICLES.TYPE);
export const selectVehiclesError = createErrorSelector(TYPES.LOAD_VEHICLES.TYPE);
