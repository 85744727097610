import { UserType } from 'src/types';
import { createReducer } from '../../utils/toolkit';

const initialState: Partial<UserType[]> = [];

const {
  reducer,
  actions: { set: setFavorites, merge: mergeFavorites, reset: resetFavorites },
} = createReducer('favorites', initialState);

export default reducer;
export { setFavorites, mergeFavorites, resetFavorites };
