import React from 'react';
import { Box, ThemeProvider, createTheme } from '@mui/material';
import { GridEditCellProps } from '@mui/x-data-grid';

import { theme, themeColors } from 'src/theme';

import { P1R } from '..';
import TableAdvanced, { TableAdvancedProps } from './TableAdvanced';
import TableBasic, { TableBasicProps } from './TableBasic';

export function NumericCell(props: GridEditCellProps) {
  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', ...props.sx }}>
      {props.value ? <P1R>{props.value}</P1R> : <P1R sx={{ color: themeColors.grayDisabled }}>0</P1R>}
    </Box>
  );
}
export function NumericCellEditor(props: GridEditCellProps) {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const numericValue = inputValue === '' ? '' : +e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    props.api.setEditCellValue({
      id: props.id,
      field: props.field,
      value: numericValue,
    });
  };

  return (
    <Box sx={{ height: '100%', ...props.sx }}>
      <input
        type="text"
        value={props.value}
        onChange={handleInputChange}
        maxLength={2}
        style={{ height: '100%', border: 'none', outline: 'none', backgroundColor: 'transparent' }}
      />
    </Box>
  );
}

export function BooleanCell(props: GridEditCellProps) {
  const handleSetBoolean = () => {
    props.api.setEditCellValue({
      id: props.id,
      field: props.field,
      value: !props.value,
    });
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }} onClick={props.editable ? handleSetBoolean : undefined}>
      {props.value ? props.trueIcon : props.falseIcon}
    </Box>
  );
}

export type TableProps = {
  type: 'basic' | 'advanced';
} & TableBasicProps &
  TableAdvancedProps;

const TableCustom: React.FC<TableProps> = ({ type = 'basic', columns, rows, ...props }): JSX.Element => (
  <ThemeProvider theme={createTheme(theme)}>
    {type === 'basic' ? <TableBasic rows={rows} columns={columns} {...props} /> : <TableAdvanced rows={rows} columns={columns} {...props} />}
  </ThemeProvider>
);

export default TableCustom;
