import { UserType } from 'src/types';
import { getUrl } from 'src/utils';
import axios from '../axios';
import routes from '../routes';

// TODO: BE needs to implement the filtering
const users = {
  loadUsers: (): Promise<Partial<UserType>[]> => axios.get(routes.USERS),
  loadUsersFavorites: ({ favorites }: { favorites: boolean }): Promise<Partial<UserType>[]> =>
    axios.get(getUrl(routes.USERS_FAVORITES, { favorites })),
  loadFilterUsers: ({
    types,
    permissions,
    status,
    available,
  }: {
    types?: string[];
    permissions?: string[];
    status?: number;
    available?: boolean;
  }): Promise<Partial<UserType>[]> => {
    // TODO: refactoring it
    const queryParams = new URLSearchParams();

    if (types && types.length > 0) {
      types.forEach((type: string) => {
        queryParams.append('types', type);
      });
    }

    if (permissions && permissions.length > 0) {
      permissions.forEach((permission: string) => {
        queryParams.append('permissions', permission);
      });
    }

    if (status !== undefined) {
      queryParams.append('status', status.toString());
    }

    if (available) {
      queryParams.append('available', 'true');
    }

    return axios.get(`${routes.USERS}?${queryParams.toString()}`);
  },

  loadLinemen: ({ incidentId }: { incidentId: number }): Promise<Partial<UserType>[]> => axios.get(getUrl(routes.LOAD_LINEMEN, { incidentId })),
  loadLinemenByLocation: ({
    incidentId,
    yardId,
    favorites,
  }: {
    incidentId: number;
    yardId: number;
    favorites: boolean;
  }): Promise<Partial<UserType>[]> => axios.get(getUrl(routes.LOAD_LINEMEN_BY_LOCATION, { incidentId, yardId, favorites })),

  notifyUsers: (data: any): Promise<Partial<UserType>[]> => axios.post(routes.NOTIFY_USERS, data),
};

export default users;
