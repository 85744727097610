import { ProfileType } from 'src/types';
import { createReducer } from '../../utils/toolkit';

const initialState: Partial<ProfileType> = {
  employee: '',
  preferredName: '',
  ssn: '',
  classificationIds: [],
  medicalExpiration: 0,
  workVest: '',
  workGlove: '',
  rubberGlove: '',
  localUnion: '',
  unionTicket: '',
  driverLicenseStateCode: '',
  driverLicense: '',
  driverLicenseClass: '',
  driverLicenseExpiration: 0,
  cdlEndorsementsSlugs: [],
  cdlRestrictions: [],
  passport: '',
  passportExpiration: 0,
};

const {
  reducer,
  actions: { set: setProfile, merge: mergeProfile, reset: resetProfile },
} = createReducer('profile', initialState);

export default reducer;
export { setProfile, mergeProfile, resetProfile };
