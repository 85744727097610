import { all } from 'redux-saga/effects';
import { sagas as rolesSagas } from './roles';
import { sagas as userSagas } from './user';
import { sagas as usersSagas } from './users';
import { sagas as incidentSagas } from './incident';
import { sagas as incidentsSagas } from './incidents';
import { sagas as rosterSagas } from './roster';
import { sagas as rostersSagas } from './rosters';
import { sagas as vehicleSagas } from './vehicle';
import { sagas as vehiclesSagas } from './vehicles';
import { sagas as utilityClassificationsSagas } from './utilityClassifications';
import { sagas as optionsSaga } from './options';
import { sagas as clearmeSaga } from './clearme';

export default function* rootSaga(): Generator {
  const sagas = [
    rolesSagas(),
    userSagas(),
    usersSagas(),
    incidentSagas(),
    incidentsSagas(),
    rosterSagas(),
    rostersSagas(),
    vehicleSagas(),
    vehiclesSagas(),
    utilityClassificationsSagas(),
    optionsSaga(),
    clearmeSaga(),
  ];
  yield all(sagas);
}
