import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { Button, Icon } from 'src/components';
import { selectClearmeToken } from 'src/redux/clearme/selectors';
import { useEffect, useState } from 'react';
import { loadClearme } from 'src/redux/clearme/actions';
import { useNavigate, useLocation } from 'react-router-dom';
import { PATHS } from 'src/navigation';
import { useTranslation } from 'react-i18next';
import { BlankWrapper } from '../wrappers';

type OnExitData = {
  name: string;
  timestamp: number;
  exitReason?: 'user_cancelled' | 'server_error' | 'identity_denied';
};

const ClearmeAuthContainer: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const token = useSelector(selectClearmeToken);
  const { state } = useLocation();
  const [isClearPopupOpen, setIsClearPopupOpen] = useState(false);

  useEffect(() => {
    dispatch(loadClearme.init());
  }, []);

  useEffect(() => {
    if (state?.initClear && token) {
      handleSubmit();
    }
  }, [state, token]);

  const goToProfile = () => {
    navigate(PATHS.MY_PROFILE_PAGE, { replace: true });
  };

  const handleSubmit = async () => {
    try {
      setIsClearPopupOpen(true);
      // @ts-ignore
      const clearUI = CLEAR.createUI(token, {
        onSuccess: (data: any) => {
          console.log('onSuccess');
          console.log(data);
          navigate(PATHS.CREATE_ACCOUNT_CONFIRMATION_PAGE, { replace: true });
        },
        onExit: (data: OnExitData) => {
          const { exitReason } = data;
          console.log('onExit', data);

          setIsClearPopupOpen(false);
          if (exitReason && exitReason !== 'user_cancelled') {
            navigate(PATHS.CREATE_ACCOUNT_CONFIRMATION_PAGE, { replace: true });
          }
        },
        onEvent: ({ name }: { name: string }) => {
          console.log('onEvent');
          console.log(name);
          if (name === 'manual_review') {
            navigate(PATHS.CREATE_ACCOUNT_CONFIRMATION_PAGE, { replace: true });
            // @ts-ignore
            CLEAR.abort();
          }
        },
      });
      clearUI.launch();
    } catch (err) {
      navigate(PATHS.CLEARME_NO_RESPONSE);
    }
  };

  return (
    <BlankWrapper>
      {!isClearPopupOpen ? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="100vh">
          <Button primary={false} onClick={handleSubmit} sx={{ width: '280px', textTransform: 'none', ':hover img': { filter: 'invert(100%)' } }}>
            <Icon name="clearmeIcon" sx={{ mr: 2 }} />
            {t('emailVerifiedPage.buttonText')}
          </Button>
          <Button onClick={goToProfile} sx={{ width: '280px', textTransform: 'none', mt: 2 }}>
            Go To Profile
          </Button>
        </Box>
      ) : null}
    </BlankWrapper>
  );
};

export default ClearmeAuthContainer;
