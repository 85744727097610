import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { IncidentType } from 'src/types';
import { setIncident } from '../reducers';
import { saveIncident } from '../actions';
import { SagaAction } from '../../types';

function* saveIncidentSaga({ payload: incident }: SagaAction<Partial<IncidentType>>): SagaIterator {
  try {
    yield put(saveIncident.pending());
    if (incident.fleet) {
      incident.fleet = incident.fleet.map((fleet: any) => ({
        amount: fleet.amount,
        vehicleType: fleet.vehicleType,
      }));
    }

    // Technica Debt: #ERMA-1368: Should be remove
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    incident.classifications = (incident.classifications || []).map(({ id, incidentId, ...rest }: any) => rest);
    // @ts-ignore
    delete incident.classification;

    const { payload } = yield call(API.saveIncident, incident);

    yield put(setIncident(payload));

    yield put(saveIncident.success());
  } catch (e) {
    yield put(setIncident(incident));
    yield put(saveIncident.failure(e));
  }
}

export default saveIncidentSaga;
