import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Box, Grid } from '@mui/material';
import { H1, H3, P3, Button, FormikInputString, FormikInputSelect, Attachment } from 'src/components';
import { PATHS } from 'src/navigation';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { patchUser, loadUser, logout } from 'src/redux/user/actions';
import { selectPatchingUser, selectPatchingUserError, selectPatchingUserSuccess, selectUser } from 'src/redux/user/selectors';
import config from 'src/config';
import { themeColors } from 'src/theme';
import { validation, ssnMask } from 'src/utils';
import { useTranslation } from 'react-i18next';
import { BlankWrapper } from '../wrappers';

type FormValues = {
  utilityContractorId: number | undefined;
  preferredName: string;
  ssn: string;
};
const totalFields = ['utilityContractorId', 'preferredName', 'ssn'];

const DocumentsOfficeWorkerContainer: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const patchingUser = useSelector(selectPatchingUser);
  const patchingUserError = useSelector(selectPatchingUserError);
  const patchingUserSuccess = useSelector(selectPatchingUserSuccess);
  const user = useSelector(selectUser);

  // Technical Debt: Should be resolve on config level and run app
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, setConfigIsLoaded] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [activeUpload, setActiveUpload] = useState<null | string>(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const handleActiveUpload = (uploadName: string) => {
    setActiveUpload(uploadName);
  };

  const handleFileChange = (newFile: boolean) => {
    if (formik.dirty || newFile) {
      setIsSaveDisabled(false);
    }
  };

  useEffect(() => {
    config.refresh().then(() => setConfigIsLoaded(true));
    dispatch(loadUser.init());
  }, []);

  useEffect(() => {
    if (patchingUserSuccess) {
      isDraft ? dispatch(logout.init()) : navigate(PATHS.CREATE_ACCOUNT_WAITING_PAGE);
    }
  }, [navigate, patchingUserSuccess]);

  const initialValues: FormValues = {
    utilityContractorId: user.utilityContractorId,
    preferredName: user.preferredName || '',
    ssn: user.ssn || '',
  };

  const defaultValidationSchema = Yup.object({
    utilityContractorId: Yup.number().required(t('validations.required')),
    ssn: Yup.string().matches(validation.ssn, t('validations.ssn')).required(t('validations.required')),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: isDraft ? Yup.object({}) : defaultValidationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (data) => {
      dispatch(patchUser.init({ data: { ...data, isDraft } }));
    },
  });

  const submitForm = async () => {
    setIsDraft(false);
    await formik.validateForm();
    formik.handleSubmit();
  };

  const saveFormAndReturnLater = async () => {
    setIsDraft(true);
    await formik.validateForm();
    formik.handleSubmit();
  };

  useEffect(() => {
    setIsSaveDisabled(!formik.dirty);
  }, [formik.dirty]);

  const totalErrors = Object.keys(formik.errors).filter((val) => totalFields.includes(val)).length;

  return (
    <BlankWrapper>
      <Grid container columnSpacing={{ xs: 0, sm: 2 }} component="form" noValidate onSubmit={submitForm} sx={{ width: '100%', margin: 'auto' }}>
        <Grid item xs={12} sx={{ mb: 4, textTransform: 'capitalize' }}>
          <H1>{t('createAccountPage.completeYourProfile')}</H1>
        </Grid>
        <Grid item xs={12} sx={{ width: '100%', marginBottom: '12px' }}>
          <Box
            sx={{
              padding: '24px 24px 1px 24px',
              margin: '0 -24px 24px -24px',
              borderRadius: '4px',
              backgroundColor: themeColors.grayLight,
            }}
          >
            <H3>{t('createAccountPage.personalDetails')}</H3>
            <Box
              sx={{
                gap: { xs: 0, sm: '14px' },
                mt: 1,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Box sx={{ width: '100%' }}>
                <FormikInputSelect
                  formik={formik}
                  field="utilityContractorId"
                  label={t('profilePage.employee')}
                  options={config.options.utilityContractors}
                  disabled={patchingUser}
                  disableClearable
                />
              </Box>
              <Box sx={{ width: '100%' }}>
                <FormikInputString
                  formik={formik}
                  field="ssn"
                  label={t('profilePage.ssn')}
                  disabled={patchingUser}
                  onChange={(e) => {
                    let value = e.target.value || '';
                    value = ssnMask(value);
                    formik.setFieldValue('ssn', value);
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                gap: { xs: 0, sm: '14px' },
                mt: 1,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Box sx={{ width: '100%' }}>
                <FormikInputString formik={formik} field="preferredName" label={t('profilePage.preferredName')} disabled={patchingUser} />
              </Box>
            </Box>
            <Box>
              {user.attachments && (
                <Attachment
                  title={t('profilePage.ssn')}
                  typeName="ssn"
                  userId={user.uuid}
                  selectedAttachment={user.attachments?.ssn}
                  activeField={activeUpload}
                  handleActiveUpload={handleActiveUpload}
                  handleNewFileUpload={handleFileChange}
                />
              )}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          {totalErrors > 0 && (
            <P3 sx={{ color: themeColors.error, mt: 3 }}>
              {totalErrors}
              {t('common.totalCountErrors')}
            </P3>
          )}
          {patchingUserError && <P3 sx={{ color: themeColors.error, mt: 3 }}>{t('common.internalServerError')}</P3>}
        </Grid>

        <Grid item xs={12} sx={{ mt: 1 }}>
          <Button fullWidth loading={patchingUser} disabled={patchingUser} onClick={submitForm} sx={{ textTransform: 'capitalize' }}>
            {t('common.submit')}
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ mt: '16px', mb: '48px' }}>
          <Button
            fullWidth
            primary={false}
            loading={patchingUser}
            disabled={isSaveDisabled}
            onClick={saveFormAndReturnLater}
            sx={{ textTransform: 'capitalize' }}
          >
            {t('profilePage.saveAndReturnLater')}
          </Button>
        </Grid>
      </Grid>
    </BlankWrapper>
  );
};

export default DocumentsOfficeWorkerContainer;
