import { useSelector } from 'react-redux';
import { FormikProps } from 'formik';
import { Box, Grid } from '@mui/material';

import { selectVehicleAssignees } from 'src/redux/users/selectors';
import { FormikCheckbox, FormikInputSelect, FormikInputString } from 'src/components';
import { ListType } from 'src/types';
import config from 'src/config';
import { useTranslation } from 'react-i18next';

import { PermissionEnum } from 'src/shared/enums/permission.enum';
import { FormValues } from '../VehicleDialog';

type Props = {
  formik: FormikProps<FormValues>;
  hidden?: boolean;
};

function Operational({ formik, hidden = false }: Props) {
  const { t } = useTranslation();
  const availableAssignees = useSelector(selectVehicleAssignees);
  const options: ListType[] = availableAssignees.map((lineman) => ({
    key: lineman.uuid,
    value: `${lineman.firstName} ${lineman.lastName}`,
  }));
  options.push({ key: '', value: '<Unassigned>' });

  return (
    <Grid container sx={{ margin: '32px 0 16px 0', display: hidden ? 'none' : 'block' }}>
      <Grid item xs={12} sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
        <Box role="group" aria-labelledby="checkbox-group" sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <FormikCheckbox
            formik={formik}
            field="availability"
            label={t('rosterFleetPage.availability')}
            disabled={!config.can(PermissionEnum.ADD_VEHICLES)}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormikInputString formik={formik} field="fuelCard" label={t('rosterFleetPage.fuelCardNumber')} />
        </Box>
        <Box sx={{ width: '100%' }}>
          <FormikInputString formik={formik} field="gpsId" label={t('rosterFleetPage.gpsIdNumber')} />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormikInputSelect
            formik={formik}
            disabled={!config.can(PermissionEnum.ASSIGN_VEHICLE)}
            field="assignedUuid"
            label={t('rosterFleetPage.assignedTo')}
            options={options}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormikInputString formik={formik} field="operationalNotes" label={t('rosterFleetPage.operationalNotes')} />
        </Box>
      </Grid>
    </Grid>
  );
}

export default Operational;
