import i18n from '.';

const loadTranslations = async (url: string, language: string) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Failed to fetch translations');
    }
    const translations = await response.json();
    i18n.addResourceBundle(language, 'translation', translations);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error loading translations:', error);
  }
};

export default loadTranslations;
