import API from 'src/api';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { SagaAction } from '../../types';
import { resetUserPassword } from '../actions';

function* resetUserPasswordSaga({ payload }: SagaAction<{ email: string }>): SagaIterator {
  try {
    yield put(resetUserPassword.pending());
    yield call(API.resetUserPassword, payload);
    yield put(resetUserPassword.success());
  } catch (error) {
    yield put(resetUserPassword.failure(error));
  }
}

export default resetUserPasswordSaga;
