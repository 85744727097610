import { FormikProps } from 'formik';
import { Box, Grid } from '@mui/material';
import { FormikCalendar, FormikCheckbox, FormikInputNumber, FormikInputString, FormikInputSelect } from 'src/components';
import config from 'src/config';
import { useTranslation } from 'react-i18next';
import { FormValues } from '../VehicleDialog';

type Props = {
  formik: FormikProps<FormValues>;
  hidden?: boolean;
};

function Administrative({ formik, hidden = false }: Props) {
  const { t } = useTranslation();

  return (
    <Grid container sx={{ margin: '32px 0 16px 0', display: hidden ? 'none' : 'block' }}>
      <Box role="group" aria-labelledby="checkbox-group" sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <FormikCheckbox formik={formik} field="administrativeFlag" label={t('rosterFleetPage.administrativeFlag')} />
      </Box>
      <Grid item xs={12} sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormikInputString formik={formik} field="companyTag" label={t('rosterFleetPage.companyTag')} />
        </Box>
        <Box sx={{ width: '100%' }}>
          <FormikInputSelect
            formik={formik}
            field="ownerStatus"
            label={t('rosterFleetPage.ownerStatus')}
            options={config.options.vehicleOwnerType}
            disableClearable
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <FormikInputString formik={formik} field="ownerField" label={t('rosterFleetPage.owner')} />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormikInputSelect
            formik={formik}
            field="insuranceStatus"
            label={t('rosterFleetPage.insuranceStatus')}
            options={config.options.vehicleStatusType}
            disableClearable
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <FormikCalendar formik={formik} field="insuranceExpirationDate" label={t('rosterFleetPage.insuranceExpirationDate')} />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormikInputSelect
            formik={formik}
            field="registrationType"
            label={t('rosterFleetPage.registrationType')}
            options={config.options.vehicleRegistrationType}
            disableClearable
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <FormikInputSelect
            formik={formik}
            field="registrationState"
            label={t('rosterFleetPage.registrationState')}
            // TODO: select state or province
            options={(config.options.STATES.US || []).map((state: string) => ({ key: state, value: t(`states.${state}`) }))}
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <FormikInputString formik={formik} field="registrationPlate" label={t('rosterFleetPage.registrationPlate')} />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormikInputSelect
            formik={formik}
            field="registrationStatus"
            label={t('rosterFleetPage.registrationStatus')}
            options={config.options.vehicleStatusType}
            disableClearable
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <FormikCalendar formik={formik} field="registrationExpirationDate" label={t('rosterFleetPage.registrationExpirationDate')} />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormikInputSelect
            formik={formik}
            field="fmcsaStatus"
            label={t('rosterFleetPage.fmcsaStatus')}
            options={config.options.vehicleStatusType}
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <FormikCalendar formik={formik} field="fmcsaExpirationDate" label={t('rosterFleetPage.fmcsaExpirationDate')} />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormikInputSelect
            formik={formik}
            field="dielectricStatus"
            label={t('rosterFleetPage.diElectricStatus')}
            options={config.options.vehicleStatusType}
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <FormikCalendar formik={formik} field="dielectricExpirationDate" label={t('rosterFleetPage.diElectricExpirationDate')} />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormikInputSelect
            formik={formik}
            field="ansiStatus"
            label={t('rosterFleetPage.ansiStatus')}
            options={config.options.vehicleStatusType}
            disableClearable
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <FormikCalendar formik={formik} field="ansiExpirationDate" label={t('rosterFleetPage.ansiExpirationDate')} />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormikInputSelect
            formik={formik}
            field="iftaTagStatus"
            label={t('rosterFleetPage.iftaTagStatus')}
            options={config.options.vehicleStatusType}
            disableClearable
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <FormikCalendar formik={formik} field="iftaTagExpirationDate" label={t('rosterFleetPage.iftaTagExpirationDate')} />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormikInputNumber formik={formik} field="iftaTagNumber" label={t('rosterFleetPage.iftaTagNumber')} />
        </Box>
        <Box sx={{ width: '100%' }}>
          <FormikInputNumber formik={formik} field="ezPassNumber" label={t('rosterFleetPage.ezpassNumber')} />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormikInputString formik={formik} field="administrativeNotes" label={t('rosterFleetPage.administrativeNotes')} />
        </Box>
      </Grid>
    </Grid>
  );
}

export default Administrative;
