import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { RosterType } from 'src/types';
import { SagaAction } from '../../types';
import { deleteRoster } from '../actions';

function* deleteRosterSaga({ payload: roster }: SagaAction<Partial<RosterType>>): SagaIterator {
  try {
    yield put(deleteRoster.pending());
    yield call(API.deleteRoster, { id: roster.id || 0 });
    yield put(deleteRoster.success());
  } catch (e) {
    yield put(deleteRoster.failure(e));
  }
}

export default deleteRosterSaga;
