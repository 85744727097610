import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { PayloadVehiclesType } from 'src/types';
import { SagaAction } from '../../types';
import { rosterDetachVehicles } from '../actions';

// type DetachType = {
//   id: number;
//   vehicles: <Partial<PayloadVehiclesType>>;
// };

function* rosterDiasattachVehicleSaga({ payload: { id, vehicles } }: SagaAction<PayloadVehiclesType>): SagaIterator {
  try {
    yield put(rosterDetachVehicles.pending());
    yield call(API.rosterDetachVehicles, { id, vehicles });

    yield put(rosterDetachVehicles.success());
  } catch (e) {
    yield put(rosterDetachVehicles.failure(e));
  }
}

export default rosterDiasattachVehicleSaga;
