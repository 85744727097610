import Box, { BoxProps } from '@mui/material/Box';
// Technical Debt: It is not clear component
import { ReactComponent as LogoSvg } from 'src/assets/logoS360.svg';
import { ReactComponent as LogoMini } from 'src/assets/logoMini.svg';

type LogoProps = {
  // colored?: boolean;
  width?: number;
  isMinimised?: boolean;
} & BoxProps;

function Logo({ isMinimised, ...boxProps }: LogoProps) {
  // Technical Debt: removed from SVG height={size}
  return <Box {...boxProps}>{isMinimised ? <LogoMini data-testid="mini-logo" /> : <LogoSvg data-testid="standard-logo" />}</Box>;
}

export default Logo;
