import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const PulsatingSvgIcon = styled('svg')({
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(0.7)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  animation: 'pulse 1s infinite ',
});

const dotPulseBefore = keyframes`
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
`;

const dotPulse = keyframes`
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
`;

const dotPulseAfter = keyframes`
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
`;

const DotPulse = styled.div`
  position: relative;
  left: -9999px;
  width: 1px;
  height: 1px;
  border-radius: 5px;
  background-color: black;
  color: black;
  animation: ${dotPulse} 1.5s infinite linear;
  animation-delay: 0.25s;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 1px;
    height: 1px;
    border-radius: 5px;
    background-color: black;
    color: black;
  }

  &::before {
    animation: ${dotPulseBefore} 1.5s infinite linear;
    animation-delay: 0s;
  }

  &::after {
    animation: ${dotPulseAfter} 1.5s infinite linear;
    animation-delay: 0.5s;
  }
`;

export { PulsatingSvgIcon, DotPulse };
