import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Tab, Tabs, styled } from '@mui/material';
import { createVehicle, saveVehicle } from 'src/redux/vehicle/actions';
import { loadFilterUsers } from 'src/redux/users/actions';
import { Button, H1, Icon, P3 } from 'src/components';
import { themeColors } from 'src/theme';
import { selectVehicleId, selectCreateVehicleSuccess, selectSaveVehicleSuccess } from 'src/redux/vehicle/selectors';
import { VehicleType, ListType } from 'src/types';
import config from 'src/config';
import { normalizerTimestamp } from 'src/utils';
import { RoleType } from 'src/shared/enums/roleType.enum';

import General from './VehicleDialogTabs/General';
import Administrative from './VehicleDialogTabs/Administrative';
import Maintenance from './VehicleDialogTabs/Maintenance';
import Operational from './VehicleDialogTabs/Operational';

// TODO: Can we use VehicleType somehow so we don't copy/paste?
export type FormValues = {
  id: number;

  // General
  type?: string;
  year?: number;
  make?: string;
  model?: string;
  vin?: string;
  color?: string;
  gvwr?: number;

  // Administrative
  companyTag?: string;
  registrationType?: string;
  registrationStatus?: string;
  registrationExpirationDate?: number;
  registrationState?: string;
  registrationPlate?: string;
  fmcsaStatus?: string;
  fmcsaExpirationDate?: number;
  dielectricStatus?: string;
  dielectricExpirationDate?: number;
  ansiStatus?: string;
  ansiExpirationDate?: number;
  iftaTagStatus?: string;
  iftaTagExpirationDate?: number;
  iftaTagNumber?: number;
  ownerStatus?: string;
  ownerField?: string;
  insuranceStatus?: string;
  insuranceExpirationDate?: number;
  ezPassNumber?: number;
  administrativeFlag: boolean;
  administrativeNotes?: string;

  // Maintenance
  maintenanceFlag: boolean;
  repairFlag: boolean;
  yardFK?: number;
  nextServiceDate?: number;
  maintenanceNotes?: string;

  // operational
  availability: boolean;
  fuelCard?: string;
  gpsId?: string;
  assignedUuid?: string;
  operationalNotes?: string;
};

const firstTabFields = ['type', 'year', 'make', 'model', 'color', 'vin', 'gvwr'];
const secondTabFields = [
  'administrativeFlag',
  'companyTag',
  'ownerStatus',
  'ownerField',
  'registrationType',
  'registrationState',
  'registrationPlate',
  'registrationStatus',
  'registrationExpirationDate',
  'tagStatus',
  'tagExpirationDate',
  'fmcsaStatus',
  'fmcsaExpirationDate',
  'dielectricStatus',
  'dielectricExpirationDate',
  'ansiStatus',
  'ansiExpirationDate',
  'insuranceStatus',
  'insuranceExpirationDate',
  'iftaTagStatus',
  'iftaTagExpirationDate',
  'iftaTagNumber',
  'ezPassNumber',
  'administrativeNotes',
];
const thirdTabFields = ['maintenanceFlag', 'repairFlag', 'yardFK', 'nextServiceDate', 'maintenanceNotes'];
const fourthTabFields = ['availability', 'fuelCard', 'gpsId', 'assignedUuid', 'operationalNotes'];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '600px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(5),
    paddingBottom: theme.spacing(1),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: '8px',
            top: '8px',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon name="close" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

type VehicleDialogProps = {
  onClose: () => void;
  isEdit?: boolean;
  vehicle?: VehicleType;
};

const VehicleDialog: React.FC<VehicleDialogProps> = ({ onClose, vehicle, isEdit = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dispatchTo = isEdit ? saveVehicle : createVehicle;
  const vehicleSuccess = useSelector(isEdit ? selectSaveVehicleSuccess : selectCreateVehicleSuccess);
  const [tabsValue, setTabsValue] = useState<number>(0);
  const vehicleId = useSelector(selectVehicleId);

  const handleTabsChange = (event: React.SyntheticEvent, newValue: number) => setTabsValue(newValue);

  // @ts-ignore
  const tbdStatus = config.options.vehicleStatusType.find((vehicle: ListType) => vehicle.key === 'TBD').key;
  // @ts-ignore
  const regType = (config.options.vehicleRegistrationType.find((vehicle: ListType) => vehicle?.key === 'APPORTIONED') as unknown as ListType)?.key;
  const initialValues: FormValues = {
    id: vehicle?.id || vehicleId || -1,

    // General
    type: vehicle?.type,
    year: vehicle?.year,
    make: vehicle?.make,
    model: vehicle?.model,
    color: vehicle?.color,
    vin: vehicle?.vin,
    gvwr: vehicle?.gvwr,

    // Administrative
    companyTag: vehicle?.companyTag,
    registrationType: vehicle?.registrationType || String(regType),
    registrationStatus: vehicle?.registrationStatus || tbdStatus,
    registrationExpirationDate: normalizerTimestamp(vehicle?.registrationExpirationDate),
    registrationState: vehicle?.registrationState,
    registrationPlate: vehicle?.registrationPlate,
    fmcsaStatus: vehicle?.fmcsaStatus || tbdStatus,
    fmcsaExpirationDate: normalizerTimestamp(vehicle?.fmcsaExpirationDate),
    dielectricStatus: vehicle?.dielectricStatus || tbdStatus,
    dielectricExpirationDate: normalizerTimestamp(vehicle?.dielectricExpirationDate),
    ansiStatus: vehicle?.dielectricStatus || tbdStatus,
    ansiExpirationDate: normalizerTimestamp(vehicle?.ansiExpirationDate),
    iftaTagStatus: vehicle?.iftaTagStatus,
    iftaTagNumber: vehicle?.iftaTagNumber,
    iftaTagExpirationDate: normalizerTimestamp(vehicle?.iftaTagExpirationDate),
    ownerStatus: vehicle?.ownerStatus,
    ownerField: vehicle?.ownerField,
    insuranceStatus: vehicle?.insuranceStatus || tbdStatus,
    insuranceExpirationDate: normalizerTimestamp(vehicle?.insuranceExpirationDate),
    ezPassNumber: vehicle?.ezPassNumber,
    administrativeFlag: vehicle?.administrativeFlag || false,
    administrativeNotes: vehicle?.administrativeNotes,

    // Maintenance
    maintenanceFlag: vehicle?.maintenanceFlag || false,
    repairFlag: vehicle?.repairFlag || false,
    yardFK: vehicle?.yardFK,
    nextServiceDate: normalizerTimestamp(vehicle?.nextServiceDate),
    maintenanceNotes: vehicle?.maintenanceNotes,

    // Operationaal
    availability: vehicle?.availability || false,
    fuelCard: vehicle?.fuelCard,
    gpsId: vehicle?.gpsId,
    assignedUuid: vehicle?.assignedUuid,
    operationalNotes: vehicle?.operationalNotes,
  };
  const validationSchema = Yup.object({
    type: Yup.string().required('Required'),
    vin: Yup.string().required('Required'),
    gvwr: Yup.number().nullable().min(0),
    companyTag: Yup.string().required('Required'),
    yardFK: Yup.string().required('Required'),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (data) => {
      const submitData = {
        ...data,
        assignedUuid: !data.assignedUuid ? null : data.assignedUuid === '' ? null : data.assignedUuid,
      };
      dispatch(dispatchTo.init(submitData));
    },
  });

  useEffect(() => {
    dispatch(
      loadFilterUsers.init({
        activeUsersOnly: true,
        activeRolesOnly: true,
        types: [RoleType.ASSIGNEE_VEHICLE],
      }),
    );

    // formik.resetForm();
    setTabsValue(0);
  }, []);

  useEffect(() => {
    // Is this really necessary?
    formik.setValues({ ...formik.values, id: vehicleId || -1 });
  }, [vehicleId]);

  useEffect(() => {
    if (vehicleSuccess) {
      formik.resetForm();
      dispatch(dispatchTo.reset());
      onClose();
    }
  }, [vehicleSuccess]);

  const firstTabError = Object.keys(formik.errors).filter((val) => firstTabFields.includes(val)).length;
  const secondTabError = Object.keys(formik.errors).filter((val) => secondTabFields.includes(val)).length;
  const thirdTabError = Object.keys(formik.errors).filter((val) => thirdTabFields.includes(val)).length;
  const fourthTabError = Object.keys(formik.errors).filter((val) => fourthTabFields.includes(val)).length;

  return (
    <Box>
      <BootstrapDialog open onClose={onClose} aria-labelledby="customized-dialog-title">
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
          <H1 align="center" sx={{ mt: 3 }}>
            {isEdit ? t('rosterFleetPage.editVehicle') : t('rosterFleetPage.createVehicle')}
          </H1>
        </BootstrapDialogTitle>

        <DialogContent>
          <Tabs value={tabsValue} onChange={handleTabsChange}>
            <Tab
              label={
                <Box sx={{ position: 'relative' }}>
                  {t('rosterFleetPage.general')}
                  {firstTabError && Object.keys(formik.touched).length > 0 ? (
                    <Box
                      sx={{
                        top: '-20px',
                        position: 'absolute',
                      }}
                    >
                      <P3 sx={{ color: 'red' }}>
                        {firstTabError} {firstTabError === 1 ? 'issue' : 'issues'}
                      </P3>
                    </Box>
                  ) : null}
                </Box>
              }
              sx={{
                textTransform: 'none',
                fontSize: '16px',
                color: firstTabError && Object.keys(formik.touched).length > 0 ? themeColors.red : themeColors.grayDark,
                '&.Mui-selected': { color: 'black' },
              }}
            />

            <Tab
              label={
                <Box sx={{ position: 'relative' }}>
                  {t('rosterFleetPage.administrative')}
                  {secondTabError && Object.keys(formik.touched).length > 0 ? (
                    <Box
                      sx={{
                        top: '-20px',
                        position: 'absolute',
                      }}
                    >
                      <P3 sx={{ color: 'red' }}>
                        {secondTabError} {secondTabError === 1 ? 'issue' : 'issues'}
                      </P3>
                    </Box>
                  ) : null}
                </Box>
              }
              sx={{
                textTransform: 'none',
                fontSize: '16px',
                color: secondTabError && Object.keys(formik.touched).length > 0 ? themeColors.red : themeColors.grayDark,
                '&.Mui-selected': { color: 'black' },
              }}
            />

            <Tab
              label={
                <Box sx={{ position: 'relative' }}>
                  {t('rosterFleetPage.maintenance')}
                  {thirdTabError && Object.keys(formik.touched).length > 0 ? (
                    <Box
                      sx={{
                        top: '-20px',
                        position: 'absolute',
                      }}
                    >
                      <P3 sx={{ color: 'red' }}>
                        {thirdTabError} {thirdTabError === 1 ? 'issue' : 'issues'}
                      </P3>
                    </Box>
                  ) : null}
                </Box>
              }
              sx={{
                textTransform: 'none',
                fontSize: '16px',
                color: thirdTabError && Object.keys(formik.touched).length > 0 ? themeColors.red : themeColors.grayDark,
                '&.Mui-selected': { color: 'black' },
              }}
            />

            <Tab
              label={
                <Box sx={{ position: 'relative' }}>
                  {t('rosterFleetPage.operational')}
                  {fourthTabError && Object.keys(formik.touched).length > 0 ? (
                    <Box
                      sx={{
                        top: '-20px',
                        position: 'absolute',
                      }}
                    >
                      <P3 sx={{ color: 'red' }}>
                        {fourthTabError} {fourthTabError === 1 ? 'issue' : 'issues'}
                      </P3>
                    </Box>
                  ) : null}
                </Box>
              }
              sx={{
                textTransform: 'none',
                fontSize: '16px',
                color: fourthTabError && Object.keys(formik.touched).length > 0 ? themeColors.red : themeColors.grayDark,
                '&.Mui-selected': { color: 'black' },
              }}
            />
          </Tabs>

          <General formik={formik} hidden={tabsValue !== 0} />
          <Administrative formik={formik} hidden={tabsValue !== 1} />
          <Maintenance formik={formik} hidden={tabsValue !== 2} />
          <Operational formik={formik} hidden={tabsValue !== 3} />

          <Box
            sx={{
              mb: 5,
              gap: 2,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ width: '100%' }}>
              <Button primary={false} onClick={onClose} sx={{ width: '100%', textTransform: 'capitalize' }}>
                {t('editVehicle.cancel')}
              </Button>
            </Box>
            <Box sx={{ width: '100%' }}>
              <Button
                onClick={() => {
                  formik.handleSubmit();
                }}
                sx={{ width: '100%', textTransform: 'capitalize' }}
              >
                {isEdit ? t('editVehicle.update') : t('editVehicle.create')}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
};

export default VehicleDialog;
