import React from 'react';
import { Autocomplete, Box, MenuItem } from '@mui/material';

import { themeColors } from 'src/theme';
import { ListType } from 'src/types';
import { P1R } from '../Typography';
import Input from '../Input';
import Icon from '../Icon';

type Props = {
  name?: string;
  label?: string;
  placeholder?: string;
  isOptional?: boolean;
  options: ListType[];
  disabled?: boolean;
  disableClearable?: boolean;
  renderOption?: () => {};
  getOptionLabel?: () => {};
  getValue: () => {};
  onChange?: () => {};
  error?: boolean;
  helperText?: React.ReactNode;
};

const InputSelect: React.FC<Props> = ({
  name,
  label,
  placeholder,
  options,
  isOptional,
  renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: ListType) => (
    <MenuItem {...props} key={option.key} sx={{ height: '50px' }}>
      <P1R>{option.value}</P1R>
    </MenuItem>
  ),
  getOptionLabel = (option: ListType) => option.value,
  getValue,
  error,
  helperText,
  ...props
}): JSX.Element => (
  <Autocomplete
    // TODO: MUI complains that the empty value isn't found in the options
    //       Consider adding a 'NULL' entry, but make sure its' fully contained here
    value={
      options.find((option) => option.key === getValue()) || {
        key: '',
        value: '',
      }
    }
    options={options}
    isOptionEqualToValue={(option, value) => option?.key === value?.key}
    // @ts-ignore
    getOptionLabel={getOptionLabel}
    // @ts-ignore
    renderOption={renderOption}
    renderInput={(params) => (
      <Input
        {...params}
        name={name}
        label={label}
        placeholder={placeholder || 'Select'}
        error={error}
        isOptional={isOptional}
        helperText={helperText}
        sx={{
          '& .MuiAutocomplete-popupIndicator': {
            marginRight: '10px',
            backgroundColor: error ? themeColors.redError : themeColors.grayLight,
          },
        }}
      />
    )}
    popupIcon={
      <Box
        sx={{
          width: '18px',
          height: '18px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Icon name="popupIcon" />
      </Box>
    }
    autoComplete
    fullWidth
    {...props}
  />
);

export default InputSelect;
