/**
 * This is Role types, we decided to use them instead of attributes to reduce complexity
 */
export enum RoleType {
  SYSTEM_ADMIN = 'system_admin',
  COMPANY_ADMIN = 'company_admin',
  SUPER_ADMIN = 'super_admin',
  FIELD_WORKER = 'field_worker',
  ASSIGNEE_ROSTER = 'assignee_roster',
  LINEMAN = 'lineman',
  SUPPORT = 'support',
  HIDDEN = 'hidden',
  ASSIGNEE_VEHICLE = 'assignee_vehicle',
  FINANCE = 'finance',
  SAFETY = 'safety',

  // Deprecated
  GENERAL_FOREMAN = 'general_foreman',
}
