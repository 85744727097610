import * as React from 'react';

import { Icon, P3 } from 'src/components';
import config from 'src/config';

type Props = {
  status: number;
  isEditable?: boolean;
};

const RosterStatus: React.FC<Props> = ({ status, isEditable }): JSX.Element => {
  let value = '';
  const configItem = config.options.rosterStatus.find((item) => item.key === status);
  if (configItem) {
    value = configItem.value;
  }
  const statusColor = config.rosterStatusColors[status];

  if (!isEditable) {
    return (
      <P3
        sx={{
          p: '5px 10px',
          height: '24px',
          display: 'flex',
          alignItems: 'center',
          textTransform: 'lowercase',
          borderRadius: '5px',
          backgroundColor: statusColor,
        }}
      >
        {value?.replace(/_/g, ' ')}
      </P3>
    );
  }
  return (
    <P3
      sx={{
        p: '5px 0 5px 10px',
        height: '24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '5px',
        textTransform: 'lowercase',
        backgroundColor: statusColor,
      }}
    >
      {value?.replace(/_/g, ' ')}
      <Icon name="expandDown" />
    </P3>
  );
};

export default RosterStatus;
