import { createReduxAsyncType } from '../utils/toolkit';

const TYPES = {
  LOAD_USERS: createReduxAsyncType('LOAD_USERS'),
  LOAD_USERS_FAVORITES: createReduxAsyncType('LOAD_USERS_FAVORITES'),
  LOAD_FILTER_USERS: createReduxAsyncType('LOAD_FILTER_USERS'),
  LOAD_LINEMEN: createReduxAsyncType('LOAD_LINEMEN'),
  LOAD_LINEMEN_BY_LOCATION: createReduxAsyncType('LOAD_LINEMEN_BY_LOCATION'),
  NOTIFY_USERS: createReduxAsyncType('NOTIFY_USERS'),
};

export { TYPES };
