import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Logo, H1, P1R, Button } from 'src/components';
import config from 'src/config';
import { PATHS } from 'src/navigation';
import { logout } from 'src/redux/user/actions';
import { clearmeVerify } from 'src/redux/clearme/actions';
import somethingWentWrongIcon from 'src/assets/SomethingWentWrongIcon.svg';

function ClearmeSomethingWentWrongPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(clearmeVerify.init());
  }, []);

  const handleSubmit = async () => {
    navigate(PATHS.CREATE_ACCOUNT_EMAIL_VERIFIED_PAGE, { state: { initClear: true } });
  };

  const handleLogout = () => dispatch(logout.init());

  return (
    <Box display="flex" height="100vh" flexDirection="column">
      <Box sx={{ p: 7 }}>
        <Logo title={config.getVersion()} />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" sx={{ flexGrow: 1, textAlign: 'center', p: 10 }}>
        <Box>
          <img src={somethingWentWrongIcon} alt="somethingWentWrongIcon" />
        </Box>
        <H1 sx={{ mb: 3, mt: 8 }}>{t('clearmeSomethingWentWrongPage.title')}</H1>
        <P1R sx={{ whiteSpace: 'pre-line' }}>{t('clearmeSomethingWentWrongPage.subTitle')}</P1R>
        <Button onClick={handleSubmit} sx={{ textTransform: 'none', my: 5, width: '170px' }}>
          {t('clearmeSomethingWentWrongPage.buttonTitle')}
        </Button>
      </Box>
      <Box sx={{ p: 7 }}>
        <Button primary={false} onClick={handleLogout} style={{ textTransform: 'capitalize' }}>
          {t('common.goToLogout')}
        </Button>
      </Box>
    </Box>
  );
}

export default ClearmeSomethingWentWrongPage;
