import { createReduxAsyncAction } from '../utils/toolkit';
import { TYPES } from './constants';

export const loadRoster = createReduxAsyncAction(TYPES.LOAD_ROSTER);
export const createRoster = createReduxAsyncAction(TYPES.CREATE_ROSTER);
export const saveRoster = createReduxAsyncAction(TYPES.SAVE_ROSTER);
export const deleteRoster = createReduxAsyncAction(TYPES.DELETE_ROSTER);
export const rosterAttachLinemen = createReduxAsyncAction(TYPES.ROSTER_ATTACH_WORKER);
export const rosterAttachVehicles = createReduxAsyncAction(TYPES.ROSTER_ATTACH_VEHICLES);
export const rosterDetachLineman = createReduxAsyncAction(TYPES.ROSTER_DETACH_LINEMAN);
export const rosterDetachVehicles = createReduxAsyncAction(TYPES.ROSTER_DETACH_VEHICLE);
export const loadRosterAvailableVehicles = createReduxAsyncAction(TYPES.LOAD_ROSTER_AVAILABLE_VEHICLES);
export const rosterAssignments = createReduxAsyncAction(TYPES.ROSTER_ASSIGNMENTS);
export const rosterLinemanSetOptions = createReduxAsyncAction(TYPES.ROSTER_LINEMAN_SET_OPTIONS);
export const rosterEquipmentSetOptions = createReduxAsyncAction(TYPES.ROSTER_EQUIPMENT_SET_OPTIONS);
export const rosterSetStatus = createReduxAsyncAction(TYPES.ROSTER_SET_STATUS);
export const rosterTimesheet = createReduxAsyncAction(TYPES.ROSTER_TIMESHEET);
export const rosterTransfer = createReduxAsyncAction(TYPES.ROSTER_TRANSFER);
export const rosterRevert = createReduxAsyncAction(TYPES.ROSTER_REVERT);
export const rosterSplit = createReduxAsyncAction(TYPES.ROSTER_SPLIT);
