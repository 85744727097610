import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { Button, H1, P1R } from 'src/components';
import { PATHS } from 'src/navigation';
import { loadUser, logout } from 'src/redux/user/actions';
import { selectUser } from 'src/redux/user/selectors';
import clock from 'src/assets/clockAnimated.svg';
import { BlankWrapper } from '../wrappers';

const WaitingContainer: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { t } = useTranslation();

  const handleSubmit = () => {
    navigate(PATHS.MY_PROFILE_PAGE, { replace: true });
  };

  const handleLogout = () => dispatch(logout.init());

  useEffect(() => {
    dispatch(loadUser.init());
  }, []);

  return (
    <BlankWrapper>
      <Grid container textAlign="center" sx={{ pt: { xs: '50px', sm: '200px' } }}>
        <Grid item xs={12}>
          <img src={clock} style={{ margin: '0 0 38px 0', width: '96px' }} alt="clock" />
        </Grid>
        <Grid item xs={12} sx={{ textTransform: 'capitalize' }}>
          <H1>
            {t('waitingPage.accountCreated')}
            <br /> {t('waitingPage.waitingApproval')}
          </H1>
        </Grid>
        <Grid item xs={12} sx={{ mt: 5 }}>
          <P1R>{user.email}</P1R>
        </Grid>
        <Grid item xs={12}>
          <Button primary={false} onClick={handleSubmit} sx={{ mt: '25px', width: '245px', textTransform: 'none' }}>
            {t('waitingPage.goToMyProfile')}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', flexGrow: 1, alignItems: 'end' }}>
        <Button primary={false} onClick={handleLogout} style={{ textTransform: 'capitalize' }}>
          {t('common.goToLogout')}
        </Button>
      </Grid>
    </BlankWrapper>
  );
};

export default WaitingContainer;
