import { FormikProps } from 'formik';
import { Box, Grid } from '@mui/material';
import { FormikCalendar, FormikInputString, FormikInputSelect } from 'src/components';
import config from 'src/config';
import { useTranslation } from 'react-i18next';
import { FormValues } from '../IncidentDialog';

type Props = {
  formik: FormikProps<FormValues>;
  hidden?: boolean;
};

function ReportTo({ formik, hidden = false }: Props) {
  const { t } = useTranslation();

  return (
    <Grid container sx={{ margin: '32px 0 16px 0', display: hidden ? 'none' : 'block' }}>
      <Grid item xs={12} sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormikInputString formik={formik} field="estimatedDuration" label={t('eventsPage.estimatedDuration')} />
        </Box>

        <Box sx={{ width: '100%' }}>
          <FormikInputString formik={formik} field="dailyHours" label={t('eventsPage.dailyHours')} />
        </Box>

        <Box sx={{ width: '100%' }}>
          <FormikCalendar formik={formik} field="expectedStartDate" label={t('eventPage.expectedStartDate')} />
        </Box>
      </Grid>

      <Grid item xs={12} sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormikInputSelect formik={formik} field="yardFK" label={t('eventsPage.yardFK')} options={config.options.yards} disableClearable />
        </Box>

        <Box sx={{ width: '250px' }}>
          <FormikInputSelect
            formik={formik}
            field="time"
            label={t('eventsPage.time')}
            options={config.times.map((value: string) => ({
              key: value,
              value,
            }))}
            disableClearable
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default ReportTo;
