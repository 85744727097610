import { styled } from '@mui/material/styles';
import { P3 } from 'src/components';
import { themeColors } from 'src/theme';

const StyledStatus = styled(P3)({
  padding: '0 10px',
  display: 'flex',
  alignItems: 'center',
  textTransform: 'lowercase',
  borderRadius: '4px',
});

type Props = {
  verificationStatus: boolean | null;
};

function VerificationStatus({ verificationStatus }: Props) {
  const status = typeof verificationStatus === 'boolean' ? (verificationStatus ? 1 : 2) : 0;

  const getStatus = (status: number) => {
    const statuses = {
      0: 'pending',
      1: 'verified',
      2: 'rejected',
    };
    // @ts-ignore
    return statuses[status];
  };
  const getStatusColor = (status: number) => {
    const statuses = {
      0: themeColors.lightYellow,
      1: themeColors.greenLight,
      2: themeColors.redLight,
    };
    // @ts-ignore
    return statuses[status];
  };
  return (
    <StyledStatus
      sx={{
        backgroundColor: () => getStatusColor(status),
      }}
    >
      {getStatus(status)}
    </StyledStatus>
  );
}

export default VerificationStatus;
