import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { VehicleType, RosterType } from 'src/types';
import { selectUser } from 'src/redux/user/selectors';
import { rosterEquipmentSetOptions } from 'src/redux/roster/actions';
import { FleetTable, fleetColumnVisibilityModel, EmptyState } from 'src/components';
import config from 'src/config';

type Props = {
  rosterVehicles?: VehicleType[];
  roster?: RosterType;
  utilityId?: number;
  vehiclesToRemove: string[];
  // TODO: add types for actions
  setVehiclesToRemove: any;
  readOnly: boolean;
};

const EquipmentTab = ({ rosterVehicles, roster, utilityId, vehiclesToRemove, setVehiclesToRemove, readOnly }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const isEditable = !readOnly && config.can('edit_roster', { user, roster });
  const columnsVisible: Record<string, boolean> = { companyTag: true, type: true, yardFK: true, assignedUuid: true, utilityClassification: true };

  const handleUpdateEquipment = (newRow: any) => {
    dispatch(
      rosterEquipmentSetOptions.init({
        rosterId: roster?.id,
        equipmentUuid: newRow.id,
        options: {
          utilityClassification: newRow.utilityClassification,
          timesheetClassification: newRow.timesheetClassification,
        },
      }),
    );
  };

  const columnEditableOverride = {
    utilityClassification: isEditable,
    timesheetClassification: isEditable,
  };

  return rosterVehicles?.length ? (
    <FleetTable
      isEditable={false}
      toolbarProps={{ options: 'all' }}
      fleet={rosterVehicles}
      assignableUsers={roster?.linemen || []}
      user={user}
      utilityId={utilityId}
      columnVisibilityModel={{
        ...fleetColumnVisibilityModel,
        ...columnsVisible,
      }}
      columnEditableOverride={columnEditableOverride}
      onRowSelectionModelChange={(selected: any) => {
        setVehiclesToRemove(selected);
      }}
      rowSelectionModel={vehiclesToRemove}
      processRowUpdate={(newRow) => {
        handleUpdateEquipment(newRow);
        return newRow;
      }}
      checkboxSelection={isEditable}
      slotProps={{
        filterPanel: {
          filterFormProps: {
            filterColumns: ({ columns }) => columns.filter((col) => columnsVisible[col.field]).map((col) => col.field),
          },
        },
      }}
    />
  ) : (
    <EmptyState type="fleet" title={t('rosterPage.equipmentEmptyTitle')} subTitle={t('rosterPage.equipmentEmptySubtitle')} />
  );
};

export default EquipmentTab;
