import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import config from 'src/config';
import { LoginType } from 'src/types';
import { SagaAction } from 'src/redux/types';
import { setUser } from '../reducers';
import { login } from '../actions';

function* loginSaga({ payload: { email, password } }: SagaAction<LoginType>): SagaIterator {
  try {
    yield put(login.pending());
    try {
      const {
        payload: { token, user },
      } = yield call(API.login, email, password);
      config.update({ TOKEN: token });

      // Technical Debt: permissions should be in the user object
      try {
        const {
          payload: { curPermissions, allPermissions },
        } = yield call(API.getUserPermissions);
        config.curPermissions = user.curPermissions = curPermissions;
        config.allPermissions = user.allPermissions = allPermissions;
      } catch (e) {
        config.curPermissions = user.curPermissions = [];
        config.allPermissions = user.allPermissions = [];
      }

      yield put(setUser(user));
      yield put(login.success());
    } catch (e) {
      yield put(login.failure());
    }
  } catch (e) {
    yield put(login.failure());
  }
}

export default loginSaga;
