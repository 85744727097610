import Snackbar from '@mui/material/Snackbar';
import { Icon, P2 } from 'src/components';
import { themeColors } from 'src/theme';
import { Alert, Box } from '@mui/material';
import { formatIncidentId as formatRosterId } from 'src/utils';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClick: () => void;
  onClose: () => void;
  rosterId: number;
};

function RosterSnackbar({ rosterId, open, onClick, onClose }: Props) {
  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={onClose}
    >
      <Alert
        sx={{
          padding: '0 16px',
          height: '52px',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: themeColors.black,
        }}
        severity="success"
      >
        <Box sx={{ gap: 5, display: 'flex', alignItems: 'center' }}>
          <P2 sx={{ color: themeColors.white, textTransform: 'none' }}>
            {t('eventPage.addedToRoster')} {formatRosterId(rosterId)}
          </P2>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <P2
              sx={{
                mr: 2,
                color: themeColors.yellowMain,
                cursor: 'pointer',
                textTransform: 'none',
              }}
              onClick={onClick}
            >
              {t('rosterPage.goToRoster')}
            </P2>

            <Box sx={{ cursor: 'pointer' }} onClick={onClick}>
              <Icon name="close" />
            </Box>
          </Box>
        </Box>
      </Alert>
    </Snackbar>
  );
}

export default RosterSnackbar;
