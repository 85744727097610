import { UserType, ClearmeType } from 'src/types';
import routes from '../routes';
import axios from '../axios';

const user = {
  login: (email: string, password: string): Promise<Partial<UserType>> => axios.post(routes.AUTH_LOGIN, { email, password }),
  logout: (): Promise<Partial<UserType>> => axios.post(routes.AUTH_LOGOUT),
  loadClearme: (): Promise<Partial<ClearmeType>> => axios.get(routes.AUTH_GET_CLEARME),
  clearmeVerify: (): Promise<Partial<ClearmeType>> => axios.get(routes.AUTH_CLEARME_VERIFY),
};

export default user;
