import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { PATHS } from 'src/navigation';
import { Box, Container, Grid } from '@mui/material';
import { Button, H1, P1R } from 'src/components';
import { useTranslation } from 'react-i18next';
import { themeColors } from 'src/theme';
import { logout } from 'src/redux/user/actions';
import { useDispatch } from 'react-redux';
import config from 'src/config';
import { BlankWrapper } from '../wrappers';

const ForgotPasswordContainer: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleViaEmail = (): void => {
    navigate(PATHS.RESET_PASSWORD_VIA_EMAIL);
  };

  const handleLogout = () => {
    if (config.TOKEN) {
      dispatch(logout.init());
    }
  };

  return (
    <BlankWrapper>
      <Container>
        <Grid container sx={{ padding: { xs: 0, md: 7 }, margin: '0 auto' }} maxWidth="sm">
          <Grid item xs={12} sx={{ pt: { xs: 0, md: 7 } }}>
            <H1 sx={{ marginBottom: '24px' }}>{t('resetPasswordPage.title')}</H1>
            <P1R sx={{ marginBottom: '36px' }}>{t('resetPasswordPage.subTitle')}</P1R>
            <Box
              sx={{
                gap: 3,
                margin: '40px 0',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Button sx={{ width: '100%', textTransform: 'none' }} onClick={handleViaEmail}>
                {t('resetPasswordPage.email')}
              </Button>
              <Button disabled sx={{ width: '100%', textTransform: 'none' }}>
                {t('resetPasswordPage.phone')}
              </Button>
            </Box>
            <P1R>
              {t('resetPasswordPage.backTo')}
              <Link
                onClick={handleLogout}
                component={RouterLink}
                to="/login"
                variant="body2"
                sx={{
                  ml: 0.5,
                  fontSize: '14px',
                  textDecoration: 'none',
                  color: themeColors.blue,
                  textTransform: 'capitalize',
                }}
              >
                {t('resetPasswordPage.linkLogin')}
              </Link>
            </P1R>
          </Grid>
        </Grid>
      </Container>
    </BlankWrapper>
  );
};

export default ForgotPasswordContainer;
