import { Icon, P3 } from 'src/components';
import { themeColors } from 'src/theme';

type Props = {
  status: number;
  isEditable?: boolean;
};

function IncidentStatus({ status, isEditable }: Props) {
  let statusColor = '';
  let statusText = '';

  if (status === 0) {
    statusColor = themeColors.redLight;
    statusText = 'draft';
  } else if (status === 1) {
    statusColor = themeColors.greenLight;
    statusText = 'activated';
  } else if (status === 2) {
    statusColor = themeColors.grayLight;
    statusText = 'closed';
  }

  if (!isEditable) {
    return (
      <P3
        sx={{
          p: '5px 10px',
          height: '24px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '5px',
          backgroundColor: statusColor,
        }}
      >
        {statusText}
      </P3>
    );
  }
  return (
    <P3
      sx={{
        p: '5px 0 5px 10px',
        height: '24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '5px',
        backgroundColor: statusColor,
      }}
    >
      {statusText}
      <Icon name="expandDown" />
    </P3>
  );
}

export default IncidentStatus;
