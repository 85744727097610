import { createSelector } from 'reselect';
import config from 'src/config';
import { RoleType } from 'src/shared/enums/roleType.enum';
import { RosterStatus } from 'src/types';
import { TYPES } from './constants';
import { createLoadingSelector } from '../@loadings';
import { createSuccessSelector } from '../@success';
import { createErrorSelector } from '../@errors';
import { selectUser } from '../user/selectors';
import { ReduxState } from '../types';
import { RostersDataState } from './types';

export const selectRostersData = (state: ReduxState): RostersDataState => state.rostersData;

export const selectRosters = createSelector(selectRostersData, ({ rosters }) => rosters);
export const selectMyRosters = createSelector(selectRosters, selectUser, (rosters, user) =>
  // @ts-ignore
  rosters.filter((roster) => roster.ownerUuid === user.uuid),
);
export const selectMyApprovalRosters = createSelector(selectRosters, selectUser, (rosters, user) =>
  rosters.filter(
    (roster) =>
      (roster!.incident!.owner === user.uuid || config.can(RoleType.SYSTEM_ADMIN)) &&
      (roster.status === RosterStatus.PENDING_APPROVAL || roster.status === RosterStatus.CLOSED),
  ),
);

export const selectAvailableRosters = createSelector(selectRosters, (rosters) =>
  rosters.filter((roster) => ![RosterStatus.PENDING_APPROVAL, RosterStatus.CLOSED].includes(roster.status)),
);
export const selectMyAvailableRosters = createSelector(selectMyRosters, (rosters) =>
  rosters.filter((roster) => ![RosterStatus.PENDING_APPROVAL, RosterStatus.CLOSED].includes(roster.status)),
);

export const selectRostersLoading = createLoadingSelector(TYPES.LOAD_ROSTERS.TYPE);
export const selectRostersSuccess = createSuccessSelector(TYPES.LOAD_ROSTERS.TYPE);
export const selectRostersError = createErrorSelector(TYPES.LOAD_ROSTERS.TYPE);
