import React, { useEffect, useState, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GridRowParams, GridActionsCellItem, GridActionsCellItemProps } from '@mui/x-data-grid';
import { Box } from '@mui/material';

import fleetImg from 'src/assets/fleet.svg';
import { loadUsers } from 'src/redux/users/actions';
import { selectUser } from 'src/redux/user/selectors';
import { selectAvailableGeneralForemen } from 'src/redux/users/selectors';
import { loadVehicles } from 'src/redux/vehicles/actions';
import { selectVehicles, selectVehiclesLoading } from 'src/redux/vehicles/selectors';
import { deleteVehicle, loadVehicle, saveVehicle } from 'src/redux/vehicle/actions';
import { H1, P1R, Icon, Button, Toolbar, FleetTable, Loader } from 'src/components';
import { fleetColumnVisibilityModel } from 'src/components/FleetTable';
import { VehicleType } from 'src/types';
import config from 'src/config';

import { useTranslation } from 'react-i18next';
import { PermissionEnum } from 'src/shared/enums/permission.enum';
import { DashboardWrapper } from '../wrappers';
import VehicleDialog from './VehicleDialog';
import DeletePopup from '../../components/Popup/DeletePopup';

export default function Fleet() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const fleet = useSelector(selectVehicles);
  const isFleetLoading = useSelector(selectVehiclesLoading);
  const user = useSelector(selectUser);
  const users = useSelector(selectAvailableGeneralForemen);
  const [editingVehicle, setEditingVehicle] = useState<undefined | VehicleType>(undefined);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [vehicleToDelete, setVehicleToDelete] = useState<undefined | VehicleType>(undefined);
  const hasAvailableActions = [PermissionEnum.EDIT_VEHICLES, PermissionEnum.DELETE_VEHICLES].some((permission) => config.can(permission));

  useEffect(() => {
    dispatch(loadUsers.init());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadVehicles.init());
  }, []);

  const onEditClick = (event: MouseEvent<HTMLElement>, vehicle: VehicleType) => {
    dispatch(loadVehicle.init(vehicle));
    event.stopPropagation();
    setEditingVehicle(vehicle);
  };

  const onDeleteClick = (event: MouseEvent<HTMLElement>, vehicle: VehicleType) => {
    event.stopPropagation();
    setVehicleToDelete(vehicle);
  };

  const handleDeleteVehicle = () => {
    if (vehicleToDelete?.id) {
      dispatch(deleteVehicle.init(vehicleToDelete));
    }
  };

  const handleUpdateVehicle = (vehicle: VehicleType) => {
    // TODO: Save only updated fields
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { createdUuid, createdAt, updatedUuid, updatedAt, ...data } = vehicle as any;
    const payload: Partial<VehicleType> = {
      ...data,
      assignedUuid: !data.assignedUuid ? null : data.assignedUuid === '' ? null : data.assignedUuid,
    };
    dispatch(saveVehicle.init(payload));
  };

  const getActions = (params: GridRowParams<any>): React.ReactElement<GridActionsCellItemProps>[] => {
    const actions: React.ReactElement<GridActionsCellItemProps>[] = [];
    config.can([PermissionEnum.EDIT_VEHICLES]) &&
      actions.push(
        <GridActionsCellItem
          key="editCell"
          icon={<Icon name="edit" />}
          label="Edit"
          disabled={editMode}
          onClick={(event: MouseEvent<HTMLElement>) => onEditClick(event, params.row)}
          showInMenu
        />,
      );

    config.can([PermissionEnum.DELETE_VEHICLES]) &&
      actions.push(
        <GridActionsCellItem
          key="deleteCell"
          icon={<Icon name="delete" />}
          label="Delete"
          onClick={(event: MouseEvent<HTMLElement>) => onDeleteClick(event, params.row)}
          showInMenu
        />,
      );
    return actions;
  };

  const columnsVisibility = {
    ...fleetColumnVisibilityModel,
    companyTag: true,
    type: true,
    yardFK: true,
    assignedUuid: true,
    administrativeFlag: true,
    maintenanceFlag: true,
    availability: true,
  };

  return (
    <DashboardWrapper
      breadcrumbs={[['Fleet Management Inventory']]}
      navigationChildren={<NavigationWidget editingVehicle={editingVehicle} editMode={editMode} showToolbar={!!fleet?.length} />}
    >
      {isFleetLoading ? (
        <Loader />
      ) : fleet && fleet.length ? (
        <Box component="main" sx={{ height: 'calc(100vh - 100px)', pt: 4 }}>
          <FleetTable
            fleet={fleet}
            user={user}
            assignableUsers={users}
            isEditable={config.can(PermissionEnum.EDIT_VEHICLES)}
            columnVisibilityModel={columnsVisibility}
            getActions={hasAvailableActions ? getActions : undefined}
            processRowUpdate={(newRow) => {
              handleUpdateVehicle(newRow);
              return newRow;
            }}
            noDiscard
            onBulkEditStart={hasAvailableActions ? () => setEditMode(true) : undefined}
            onBulkEditStop={hasAvailableActions ? () => setEditMode(false) : undefined}
          />

          {editingVehicle && <VehicleDialog isEdit onClose={() => setEditingVehicle(undefined)} vehicle={editingVehicle} />}
          {vehicleToDelete && (
            <DeletePopup
              onClose={() => setVehicleToDelete(undefined)}
              onDelete={handleDeleteVehicle}
              title={t('fleetAdminPage.deleteVehicle')}
              text={`${t('fleetAdminPage.aboutToDeleteVehicle')} ${vehicleToDelete.companyTag}`}
            />
          )}
        </Box>
      ) : (
        <Box
          sx={{
            mt: 14,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ ml: 1 }}>
            <img src={fleetImg} alt="Empty list" />
          </Box>
          <H1 sx={{ mb: 3 }}>{t('rosterFleetPage.noFleet')}</H1>
          <P1R>{t('rosterFleetPage.noVehicels')}</P1R>
        </Box>
      )}
    </DashboardWrapper>
  );
}

type Props = {
  editingVehicle?: VehicleType;
  editMode: boolean;
  showToolbar: boolean;
};

const NavigationWidget: React.FC<Props> = ({ editingVehicle, editMode, showToolbar, ...props }) => {
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(loadUsers.init());
  }, [dispatch]);

  return (
    <Box sx={{ gap: 3, display: 'flex' }}>
      {showToolbar && config.can(PermissionEnum.EDIT_VEHICLES) ? <Toolbar /> : <Toolbar options="mini" />}
      {config.can(PermissionEnum.ADD_VEHICLES) && (
        <Button
          disabled={editMode}
          onClick={() => setShowDialog(true)}
          sx={{
            padding: '0 16px',
            textTransform: 'none',
            whiteSpace: 'nowrap',
          }}
        >
          {t('rosterFleetPage.createVehicle')}
        </Button>
      )}
      {showDialog && <VehicleDialog onClose={() => setShowDialog(false)} vehicle={editingVehicle} />}
    </Box>
  );
};
