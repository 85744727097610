import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, styled, Radio } from '@mui/material';
import { H1, Icon, Button, Table } from 'src/components';
import IncidentStatus from 'src/components/IncidentsTable/IncidentStatus';
import { formatIncidentId } from 'src/utils';
import { loadIncidents } from 'src/redux/incidents/actions';
import { selectIncidents } from 'src/redux/incidents/selectors';
import { selectIncident } from 'src/redux/incident/selectors';
import { themeColors } from 'src/theme';
import { useTranslation } from 'react-i18next';

type Props = {
  id?: number;
  name?: string;
  onClose: () => void;
  onSelectIncident: (id: number) => void;
  onNextClick: () => void;
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '600px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(5),
    paddingBottom: theme.spacing(1),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: '8px',
            top: '8px',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon name="close" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function TransferPopup({ id, name, onClose, onSelectIncident, onNextClick }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const incidentCurrent = useSelector(selectIncident);
  const incidents = useSelector(selectIncidents);
  const [selectedRow, setSelectedRow] = useState<number>(0);

  // TODO: better to implement BE filtering
  const filteredIncidents = incidents.filter((incident) => incident.id !== incidentCurrent.id && incident.status !== 2);

  useEffect(() => {
    dispatch(loadIncidents.init());
  }, [dispatch]);

  const handleselect = (id: number) => {
    setSelectedRow(id);
    onSelectIncident(id);
  };

  const columns = [
    { field: 'radio', name: '' },
    { field: 'name', name: '' },
    { field: 'status', name: '' },
  ];

  const rows = filteredIncidents.map((incident) => ({
    id: incident.id,
    // eslint-disable-next-line react/no-unstable-nested-components
    radio: () => (
      <Radio
        color="primary"
        size="small"
        sx={{
          '&, &.Mui-checked': { color: themeColors.yellowMain },
          '&, &.Mui-root': { color: themeColors.grayDisabled },
        }}
        checked={selectedRow === incident.id}
      />
    ),
    name: `${incident.name} / ${formatIncidentId(incident.id)}`,
    // eslint-disable-next-line react/no-unstable-nested-components
    status: () => (
      <Box sx={{ display: 'flex', justifyContent: 'left' }}>
        <IncidentStatus status={incident.status} />
      </Box>
    ),
  }));

  return (
    <Box>
      <BootstrapDialog open onClose={onClose} aria-labelledby="customized-dialog-title">
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose} />
        <DialogContent>
          <H1 align="center" sx={{ mt: 5, mb: 3 }}>
            {t('eventPage.transferRoster')}
            {name} {formatIncidentId(id)}
          </H1>

          <Box sx={{ height: 230, overflowX: 'scroll' }}>
            <Table
              type="basic"
              rows={rows}
              columns={columns}
              displayHeader={false}
              // TODO: makes row id a mandatory field
              selectedRowId={selectedRow}
              onRowClick={(row: any) => handleselect(row.id)}
            />
          </Box>

          <Box
            sx={{
              mb: 5,
              mt: 4,
              gap: 2,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ width: '100%' }}>
              <Button onClick={onClose} primary={false} sx={{ width: '100%', textTransform: 'capitalize' }}>
                {t('common.cancel')}
              </Button>
            </Box>
            <Box sx={{ width: '100%' }}>
              <Button onClick={onNextClick} disabled={!selectedRow} sx={{ width: '100%', textTransform: 'capitalize' }}>
                {t('eventPage.next')}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
}

export default TransferPopup;
