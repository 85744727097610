import { useEffect } from 'react';
import Link from '@mui/material/Link';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Container, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, H1, FormikInputString, P1R, P3 } from 'src/components';
import { themeColors } from 'src/theme';
import { useDispatch, useSelector } from 'react-redux';
import { logout, resetUserPassword } from 'src/redux/user/actions';
import { selectSuccessResettingUserPassword, selectErrorResettingUserPassword } from 'src/redux/user/selectors';
import { PATHS } from 'src/navigation';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import config from 'src/config';
import { BlankWrapper } from '../wrappers';

function ResetPasswordViaEmail() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const resetPasswordSuccess = useSelector(selectSuccessResettingUserPassword);
  const resetPasswordError = useSelector(selectErrorResettingUserPassword) as Record<string, any>;

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: Yup.object({
      email: Yup.string().email(t('validations.email')).required(t('validations.required')),
    }),
    onSubmit: async ({ email }) => {
      dispatch(resetUserPassword.reset());
      dispatch(resetUserPassword.init({ email }));
    },
  });

  const onSubmit = () => {
    localStorage.setItem('email', formik.values.email);
    formik.handleSubmit();
  };

  const handleLogout = () => {
    if (config.TOKEN) {
      dispatch(logout.init());
    }
  };

  useEffect(() => {
    if (resetPasswordSuccess) {
      navigate(PATHS.RESET_PASSWORD_CHECK_EMAIL);
      dispatch(resetUserPassword.reset());
    }
  }, [resetPasswordSuccess]);

  return (
    <BlankWrapper>
      <Container>
        <Grid container sx={{ padding: { xs: 0, md: 7 }, margin: '0 auto' }} maxWidth="sm">
          <Grid item xs={12} sx={{ pt: { xs: 0, md: 7 } }}>
            <H1 sx={{ marginBottom: '24px' }}>{t('resetPasswordViaEmail.title')}</H1>
            <P1R>{t('resetPasswordViaEmail.subTitle')}</P1R>
            <Box sx={{ margin: '15px 0 40px 0' }}>
              <FormikInputString
                formik={formik}
                field="email"
                label={t('resetPasswordViaEmail.email')}
                onChange={(e) => {
                  const value = e.target.value || '';
                  formik.setFieldValue('email', value.toLowerCase());
                }}
              />
              {resetPasswordError?.[0] && (
                <P3 component="p" sx={{ color: themeColors.error, mb: 2, mt: 1 }}>
                  {resetPasswordError?.[0]}
                </P3>
              )}
              {resetPasswordError?.email && (
                <P3 component="p" sx={{ color: themeColors.error, mb: 2, mt: 1 }}>
                  {t('resetPasswordViaEmail.invalidEmailError')}
                </P3>
              )}
              <Button onClick={onSubmit} sx={{ width: '100%', textTransform: 'none' }}>
                {t('resetPasswordViaEmail.request')}
              </Button>
            </Box>
            <P1R>
              {t('resetPasswordPage.backTo')}
              <Link
                onClick={handleLogout}
                component={RouterLink}
                to="/login"
                variant="body2"
                sx={{
                  ml: 0.5,
                  fontSize: '14px',
                  textDecoration: 'none',
                  color: themeColors.blue,
                  textTransform: 'capitalize',
                }}
              >
                {t('resetPasswordPage.linkLogin')}
              </Link>
            </P1R>
          </Grid>
        </Grid>
      </Container>
    </BlankWrapper>
  );
}

export default ResetPasswordViaEmail;
