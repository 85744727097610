import { VehicleType } from 'src/types';
import { createReducer } from '../../utils/toolkit';

const initialState: Partial<VehicleType> = {
  id: 0,
};

const {
  reducer,
  actions: { set: setVehicle, merge: mergeVehicle, reset: resetVehicle },
} = createReducer('vehicle', initialState);

export default reducer;
export { setVehicle, mergeVehicle, resetVehicle };
