import { useEffect, useState } from 'react';
import { useParams, useNavigate, useBlocker } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import { Button, Stepper, UnsavedChangesPopup } from 'src/components';
import { loadRoster, rosterSplit } from 'src/redux/roster/actions';
import { selectRoster, selectRosterSuccess, selectRosterSplitSuccess } from 'src/redux/roster/selectors';
import { DashboardWrapper } from 'src/containers/wrappers';
import { formatIncidentId, setParams } from 'src/utils';
import { PATHS } from 'src/navigation';
import { UserType, VehicleType } from 'src/types';
import { themeColors } from 'src/theme';
import { useBeforeUnload } from 'src/hooks';

import FTEsStep from './SplitSteps/FTEsStep';
import EquipmentStep from './SplitSteps/EquipmentStep';
import RosterDetailsStep from './SplitSteps/DetailsStep';

export type FormValues = {
  name: string;
  yardFK: number;
  startDate: number;
  startTime: string;
  contactFullName: string;
  contactPhone: string;
  contactEmail: string;
  newOwner: string;
};

const RosterSplit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id = '0' } = useParams();
  const rosterId = parseInt(id, 10);
  const roster = useSelector(selectRoster);
  const loadRosterSuccess = useSelector(selectRosterSuccess);
  const splitRosterSuccess = useSelector(selectRosterSplitSuccess);
  const [activeStep, setActiveStep] = useState(0);
  const [fteSelected, setFteSelected] = useState([]);
  const [fteToMoveSelected, setFteToMoveSelected] = useState([]);
  const [vehiclesToAdd, setVehiclesToAdd] = useState([]);
  const [vehiclesToRemove, setVehiclesToRemove] = useState([]);
  const [linemenTable, setLinemenTable] = useState<UserType[]>([]);
  const [fteTable, setFteTable] = useState<UserType[]>([]);
  const [availableToAddTable, setAvailableToAddTable] = useState<VehicleType[]>([]);
  const [vehiclesInRosterTable, setVehiclesInRosterTable] = useState<VehicleType[]>([]);
  const splitSteps = [t('splitRosterPage.ftes'), t('splitRosterPage.equipment'), t('splitRosterPage.rosterDetails')];

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => !!(fteSelected.length || vehiclesToRemove.length) && currentLocation.pathname !== nextLocation.pathname,
  );
  useBeforeUnload(!!(vehiclesToRemove.length || fteSelected.length));

  const initialValues: FormValues = {
    name: roster?.name || '',
    yardFK: roster?.yardFK,
    startDate: +(roster?.startDate || ''),
    startTime: roster?.startTime || '',
    contactFullName: roster?.contactFullName || '',
    contactPhone: roster?.contactPhone || '',
    contactEmail: roster?.contactEmail || '',
    newOwner: '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      newOwner: Yup.string().required(t('validations.required')),
    }),
    onSubmit: async (data) => {
      const { newOwner, ...restData } = data;
      const rosterData = {
        roster: {
          ...restData,
          incidentId: roster?.incidentId,
        },
        assignments: fteSelected,
        equipment: vehiclesToRemove,
        owner: newOwner,
      };
      dispatch(rosterSplit.init({ rosterId: roster.id, rosterData }));
    },
  });

  const resetData = () => {
    setVehiclesToRemove([]);
    setFteSelected([]);
  };

  const moveEquipmentWithCrew = (ids: number[]) => {
    const eqpToMove = [];
    const eqpToStay = [];
    const rosterFilteredEquipment = roster.vehicles?.filter((vehicle) => vehicle.assignments?.length) || [];

    for (const vehicle of rosterFilteredEquipment) {
      if (ids.includes(vehicle.id)) {
        eqpToMove.push(vehicle);
      } else {
        eqpToStay.push(vehicle);
      }
    }
    setAvailableToAddTable(eqpToMove);
    setVehiclesInRosterTable(eqpToStay);
    // @ts-ignore
    setVehiclesToRemove(ids);
  };

  useEffect(() => {
    dispatch(loadRoster.init({ id: rosterId }));
  }, []);

  useEffect(() => {
    if (loadRosterSuccess) {
      const rosterFilteredLinemen = roster.linemen?.filter((lineman) => lineman.assignments?.length);
      setLinemenTable([] as UserType[]);
      setFteTable(rosterFilteredLinemen as UserType[]);

      const rosterFilteredEquipment = roster.vehicles?.filter((vehicle) => vehicle.assignments?.length);
      setAvailableToAddTable([]);
      setVehiclesInRosterTable(rosterFilteredEquipment as VehicleType[]);
    }
  }, [loadRosterSuccess]);

  useEffect(() => {
    if (splitRosterSuccess) {
      if (vehiclesToRemove.length || fteSelected.length) {
        resetData();
      } else {
        navigate(setParams(PATHS.INCIDENT_PAGE, { id: roster?.incidentId?.toString() || '' }), {
          state: { tab: 'all-rosters', splitRoster: `"${roster.name} ${formatIncidentId(rosterId)}"` },
        });
        dispatch(rosterSplit.reset());
      }
    }
  }, [splitRosterSuccess, vehiclesToRemove.length, fteSelected.length]);

  useEffect(() => {
    // automaticaly move equipment if all its crew is moved
    const assignedEqp = fteTable.reduce((acc: { [key: string]: boolean }, item) => {
      const vehicleId = item.assignments?.[0]?.vehicleId;
      if (vehicleId) {
        acc[vehicleId] = true;
      }
      return acc;
    }, {});
    const assignedEqpToMove = linemenTable.reduce((acc: { [key: string]: boolean }, item) => {
      const vehicleId = item.assignments?.[0]?.vehicleId;
      if (vehicleId) {
        acc[vehicleId] = true;
      }
      return acc;
    }, {});
    const eqpIdsToMove = Object.keys(assignedEqpToMove);
    const eqpIdsWithCrew = eqpIdsToMove.filter((id) => !assignedEqp[id]).map((id) => parseInt(id, 10));

    if (eqpIdsWithCrew.length) {
      moveEquipmentWithCrew(eqpIdsWithCrew);
    }
  }, [fteSelected]);

  return (
    <DashboardWrapper
      breadcrumbs={[[`Split Roster "${roster.name} ${formatIncidentId(rosterId)}"`]]}
      navigationChildren={
        <Button
          primary={false}
          onClick={() => navigate(setParams(PATHS.ROSTER_PAGE, { id: `${roster.id}` }), { state: { tab: 'fte' } })}
          sx={{ textTransform: 'capitalize' }}
        >
          {t('splitRosterPage.cancel')}
        </Button>
      }
    >
      <Box
        component="main"
        sx={{
          height: 'calc(100vh - 100px)',
          pt: 4,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Stepper steps={splitSteps} activeStep={activeStep} />
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            position: 'relative',
            mt: 2,
          }}
        >
          {activeStep === 0 && (
            <FTEsStep
              linemenTable={linemenTable}
              fteTable={fteTable}
              setLinemenTable={setLinemenTable}
              setFteTable={setFteTable}
              setSelectedLinemen={setFteToMoveSelected}
              selectedLinemen={fteToMoveSelected}
              selectedFte={fteSelected}
              setSelectedFte={setFteSelected}
            />
          )}
          {activeStep === 1 && (
            <EquipmentStep
              vehiclesInRosterTable={vehiclesInRosterTable}
              availableToAddTable={availableToAddTable}
              setVehiclesInRosterTable={setVehiclesInRosterTable}
              setAvailableToAddTable={setAvailableToAddTable}
              vehiclesToAdd={vehiclesToAdd}
              setVehiclesToAdd={setVehiclesToAdd}
              vehiclesToRemove={vehiclesToRemove}
              setVehiclesToRemove={setVehiclesToRemove}
            />
          )}
          {activeStep === 2 && <RosterDetailsStep formik={formik} ownerOptions={linemenTable} />}

          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: '50%',
              transform: 'translate(-50%, 0)',
              display: 'flex',
              justifyContent: 'center',
              p: 2,
              backgroundColor: themeColors.grayLight,
            }}
          >
            <Button
              primary={false}
              disabled={!activeStep}
              onClick={() => setActiveStep((prev) => prev - 1)}
              sx={{ textTransform: 'capitalize', mr: 2, backgroundColor: themeColors.white }}
            >
              <ArrowBackIcon sx={{ height: '18px', width: '16px', mr: 1 }} />
              {t('splitRosterPage.previous')}
            </Button>
            {activeStep !== 2 ? (
              <Button onClick={() => setActiveStep((prev) => prev + 1)} sx={{ textTransform: 'capitalize' }}>
                {t('splitRosterPage.nextStep')} <ArrowForwardIcon sx={{ height: '18px', width: '16px', ml: 1 }} />
              </Button>
            ) : (
              <Button onClick={() => formik.handleSubmit()} sx={{ textTransform: 'capitalize' }}>
                {t('splitRosterPage.saveAndPublish')}
                <CheckIcon sx={{ height: '18px', width: '16px', ml: 1 }} />
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      {blocker.state === 'blocked' && <UnsavedChangesPopup onProceed={blocker.proceed} onCancel={blocker.reset} />}
    </DashboardWrapper>
  );
};

export default RosterSplit;
