import { UtilityType } from 'src/types/utility';
import { createReducer } from '../../utils/toolkit';

const initialState: Partial<UtilityType[]> = [];

const {
  reducer,
  actions: { set: setUtilityClassifications, merge: mergeUtilityClassifications, reset: resetUtilityClassifications },
} = createReducer('utiliytClassifications', initialState);

export default reducer;
export { setUtilityClassifications, mergeUtilityClassifications, resetUtilityClassifications };
