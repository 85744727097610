import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, H1, P1R } from 'src/components';
import { PATHS } from 'src/navigation';
import { loadUser } from 'src/redux/user/actions';
import { selectUser } from 'src/redux/user/selectors';
import { clearmeVerify } from 'src/redux/clearme/actions';
import config from 'src/config';
import { ClearStatus } from 'src/shared/enums/clearStatus.enum';
import PulsatingLogo from 'src/components/Loader/pulsatingLogo';
import { BlankWrapper } from '../wrappers';

const ConfirmationContainer: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [countVerifyRequests, setCountVerifyRequests] = useState(0);
  const intervalRef = useRef<number | null>(null);
  const goToProfile = () => {
    navigate(PATHS.MY_PROFILE_PAGE, { replace: true });
  };

  useEffect(() => {
    dispatch(clearmeVerify.init());
    dispatch(loadUser.init());
  }, []);

  useEffect(() => {
    if (user.clearStatus !== ClearStatus.NOT_STARTED) {
      goToProfile();
    }
  }, [user.clearStatus]);

  useEffect(() => {
    intervalRef.current = window.setInterval(() => {
      setCountVerifyRequests(countVerifyRequests + 1);
      dispatch(clearmeVerify.init());
      dispatch(loadUser.init());
    }, config.CLEARME_DELAY);

    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
    };
  }, [dispatch]);

  return (
    <BlankWrapper>
      <Grid container spacing={2} textAlign="center" sx={{ display: 'flex', flexGrow: 1 }}>
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 10 }}>
          <Box>
            <PulsatingLogo />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
            <H1>{t('createAccountWaitingPage.awaitingDataVerification')}</H1>
            <P1R>{t('createAccountWaitingPage.subtitle')}</P1R>
            <Button primary={false} onClick={goToProfile} style={{ textTransform: 'capitalize' }}>
              Go To My Profile
            </Button>
          </Box>
        </Grid>
      </Grid>
    </BlankWrapper>
  );
};

export default ConfirmationContainer;
