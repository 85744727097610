import { combineReducers } from 'redux';
import user from './user';
import userUuid from './userUuid';
import profile from './profile';

const userData = combineReducers({
  user,
  userUuid,
  profile,
});

export * from './user';
export * from './userUuid';
export * from './profile';

export default userData;
