import { UserType, IncidentType, RosterType, IncidentStatus, RosterStatus, RosterApprovalStatus } from 'src/types';
import { PermissionEnum } from 'src/shared/enums/permission.enum';

interface EntitiesType {
  user?: UserType;
  incident?: IncidentType;
  roster?: RosterType;
  permissions?: string[];
  skipStatus?: boolean;
}

type AliasesType = {
  [key: string]: string[];
};

// Don't change positions. Positions is important for perfomance and logic
const aliases: AliasesType = {
  add_roster: [PermissionEnum.ADD_NEW_ROSTER_IN_ANY_EVENT, PermissionEnum.ADD_NEW_ROSTER_AS_ASSIGNED_GF],
  edit_roster: ['edit_any_roster', 'edit_own_roster'],
  split_roster: ['split_any_roster', 'split_own_roster', 'split_any_roster_of_own_event'],
  transfer_roster: ['transfer_any_roster', 'transfer_own_roster'],
  delete_roster: ['delete_any_roster', 'delete_own_roster'],
  send_roster_for_approval: ['send_any_roster_for_approval', 'send_own_roster_for_approval', 'send_any_roster_for_approval_of_own_incident'],
  approve_and_reject_roster: ['approve_and_reject_any_roster', 'approve_and_reject_any_roster_of_own_incident'],
  edit_event: [PermissionEnum.EDIT_ANY_EVENT, PermissionEnum.EDIT_OWN_EVENT],
  delete_event: ['delete_any_event', 'delete_own_event'],
  is_admin: ['is_company_admin', 'system_admin'],
};

// Examples how can use it:
//  - can('edit_roster')
//  - can('edit_roster', { roster })
//  - can(['edit_any_roster', 'edit_own_roster'], { user, roster })
//  - can(['edit_own_incident', 'edit_own_roster'], { user, roster }) // incident take from roster
//  - can('edit_any_roster_of_own_incident', { user, incident })
//  - can('edit_any_roster_of_own_incident', { user, incident, permissions }) // override permissions

export function can(action: string | string[] = '', entities: EntitiesType = {}): boolean {
  // @ts-ignore
  const temp = entities.permissions || this?.curPermissions || [];
  return canCommon(action, { ...entities, permissions: temp });
}

export function canAll(action: string | string[] = '', entities: EntitiesType = {}): boolean {
  // @ts-ignore
  const temp = entities.permissions || this?.allPermissions || [];
  return canCommon(action, { ...entities, permissions: temp });
}

function canCommon(action: string | string[] = '', entities: EntitiesType = {}): boolean {
  // export function can(action: string | string[] = '', entities: EntitiesType = {}): boolean {
  const actions = (Array.isArray(action) ? action : [action]).flatMap((act) => aliases[act] || [act]);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const { user, roster, incident = roster?.incident, permissions = permissions || [], skipStatus = false } = entities || {};

  return actions.some((act: string) => {
    const hasPermission: boolean = permissions.includes(act);
    const isOwnerOfRoster: boolean = user && roster && user?.uuid === (roster?.ownerUuid || roster?.ownedBy?.uuid);
    const isAssignedToIncident: boolean = user && incident && incident?.assignedTo?.some((gf: any) => gf.uuid === user?.uuid);
    const isOwnerOfIncident: boolean = user && incident && user?.uuid === (incident?.ownerUuid || incident?.ownedBy?.uuid);

    if (!hasPermission || (!act.includes('_own_') && !incident && !roster)) {
      // Skip
    } else if (incident && act.includes('event') && !act.includes('roster')) {
      if ((incident.status === IncidentStatus.CLOSED || skipStatus) && act.includes('edit_')) {
        return false;
      }

      if (act.includes('_own_')) {
        return hasPermission && isOwnerOfIncident;
      }
    } else if (incident && aliases.add_roster.includes(act)) {
      // ADD_NEW_ROSTER_IN_ANY_EVENT, ADD_NEW_ROSTER_AS_ASSIGNED_GF
      return (
        !(incident && !skipStatus && incident.status !== IncidentStatus.ACTIVATED) &&
        (aliases.add_roster[0] === act || (aliases.add_roster[1] === act && isAssignedToIncident))
      );
    } else if (roster && act.includes('roster')) {
      if (aliases.edit_roster.includes(act)) {
        return (
          !(roster && !skipStatus && [RosterStatus.PENDING_APPROVAL, RosterStatus.CLOSED].includes(roster.status)) &&
          (aliases.edit_roster[0] === act || (aliases.edit_roster[1] === act && isOwnerOfRoster))
        );
      }

      if (aliases.split_roster.includes(act)) {
        return (
          !(roster && !skipStatus && [RosterStatus.PENDING_APPROVAL, RosterStatus.CLOSED].includes(roster.status)) &&
          (aliases.split_roster[0] === act ||
            (aliases.split_roster[1] === act && isOwnerOfRoster) ||
            (aliases.split_roster[2] === act && isOwnerOfIncident))
        );
      }

      if (aliases.transfer_roster.includes(act)) {
        return aliases.transfer_roster[0] === act || (aliases.transfer_roster[1] === act && isOwnerOfRoster);
      }

      if (aliases.delete_roster.includes(act)) {
        return (
          !(
            roster &&
            !skipStatus &&
            (RosterApprovalStatus.PENDING === roster.approvalStatus || [RosterStatus.PENDING_APPROVAL, RosterStatus.CLOSED].includes(roster.status))
          ) &&
          (aliases.delete_roster[0] === act || (aliases.delete_roster[1] === act && isOwnerOfRoster))
        );
      }

      if (aliases.send_roster_for_approval.includes(act)) {
        const canApprove = [RosterStatus.DRAFT, RosterStatus.MOBILIZED, RosterStatus.ACTIVATED, RosterStatus.DEMOBILIZED];

        // canSendForApproval
        return (
          !(
            roster &&
            !skipStatus &&
            roster.status !== RosterStatus.DRAFT &&
            canApprove.includes(roster.status) &&
            RosterApprovalStatus.PENDING !== roster.approvalStatus
          ) &&
          (aliases.send_roster_for_approval[0] === act ||
            (aliases.send_roster_for_approval[1] === act && isOwnerOfRoster) ||
            (aliases.send_roster_for_approval[2] === act && isOwnerOfIncident))
        );
      }

      if (aliases.approve_and_reject_roster.includes(act)) {
        return (
          !(roster && !skipStatus && [RosterStatus.CLOSED].includes(roster.status)) &&
          (aliases.approve_and_reject_roster[0] === act || (aliases.approve_and_reject_roster[1] === act && isOwnerOfIncident)) &&
          [RosterStatus.PENDING_APPROVAL].includes(roster.status)
        );
      }
    }

    return hasPermission;
  });
}
