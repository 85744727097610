// TODO: replace all localStorages on it after 31 Sprint

// Examples how can use it:
//  - storage('newUserModalShown') // get value
//  - storage('newUserModalShown', []) // set value
//  - storage('newUserModalShown', null) // remove value from storage

type ValuesType = null | number | string | string[];

export function storage(name: string, newValue?: ValuesType): ValuesType {
  const value = localStorage.getItem(name);

  if (newValue === null) {
    localStorage.removeItem(name);
  }

  if (['NEW_USER_MODAL_SHOWN'].includes(name)) {
    let parsedValue: string[] = [];

    try {
      parsedValue = JSON.parse((value ?? '[]') as string);
    } catch (error) {
      console.error('Error parsing stored value: ', error);
      console.table({ name, value });
      localStorage.removeItem(name);
      console.info(`Value %c${name}%c was removed from storage`, 'font-weight: bold', 'font-weight: normal');
      return [];
    }

    if (typeof newValue === 'string') {
      parsedValue = typeof parsedValue !== 'object' ? [] : parsedValue;
      parsedValue?.push(newValue);
      localStorage.setItem(name, JSON.stringify(parsedValue));
    } else if (Array.isArray(newValue) && newValue.length === 0) {
      localStorage.setItem(name, JSON.stringify(['']));
    } else if (Array.isArray(newValue) && newValue.length) {
      localStorage.setItem(name, JSON.stringify([parsedValue]));
    } else if (newValue) {
      throw new Error(`Invalid type for ${name}`);
    }
  }

  return value;
}
