import Snackbar from '@mui/material/Snackbar';
import { Button, P2 } from 'src/components';
import { themeColors } from 'src/theme';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  versionDate?: string;
  isRestorable?: boolean;
  onClose: () => void;
  onRestore: () => void;
};

function VersionSnackbar({ open, versionDate, isRestorable = false, onClose, onRestore }: Props) {
  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Box
        sx={{
          padding: '0 16px',
          height: '56px',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: themeColors.grayLight,
        }}
      >
        <Box sx={{ gap: 5, display: 'flex', alignItems: 'center' }}>
          <P2 sx={{ textTransform: 'none' }}>
            {t('rosterPage.viewing')} {versionDate}
          </P2>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            <Button primary={false} sx={{ height: '32px', padding: '10px' }}>
              <P2
                onClick={onClose}
                sx={{
                  cursor: 'pointer',
                  textTransform: 'none',
                }}
              >
                {t('rosterPage.cancel')}
              </P2>
            </Button>

            {isRestorable && (
              <Button onClick={onRestore} sx={{ height: '32px', padding: '10px' }}>
                <P2
                  sx={{
                    color: themeColors.white,
                    cursor: 'pointer',
                    textTransform: 'none',
                  }}
                >
                  {t('rosterPage.restoreVersion')}
                </P2>
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Snackbar>
  );
}

export default VersionSnackbar;
