import API from 'src/api';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { SagaAction } from '../../types';
import { verifyCode } from '../actions';

type VerifyCodeType = {
  verificationCode: string;
};

function* verifyCodeSaga({ payload: { verificationCode } }: SagaAction<VerifyCodeType>): SagaIterator {
  try {
    yield put(verifyCode.pending());
    yield call(API.verifyCode, verificationCode);
    yield put(verifyCode.success());
  } catch (error) {
    yield put(verifyCode.failure(error));
  }
}

export default verifyCodeSaga;
