import {
  GridColTypeDef,
  GridColDef,
  GridColumnNode,
  GridColumnGroupingModel,
  ValueOptions,
  GridComparatorFn,
  gridStringOrNumberComparator,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import { ListType, VehicleType } from 'src/types';
import config from 'src/config';

// We want to keep the "Total" row at the bottom
const totalComparator: GridComparatorFn<string> = (v1, v2, cellParams1, cellParams2) => {
  if (cellParams1.rowNode.id === -1 || cellParams2.rowNode.id === -1) return 0;
  return gridStringOrNumberComparator(v1, v2, cellParams1, cellParams2);
};

export const percentCol: GridColTypeDef = {
  type: 'number',
  headerAlign: 'center',
  align: 'right',
  width: 65,
  valueGetter(params) {
    if (!params.value) return params.value;
    return params.value * 100;
  },
  valueFormatter(params) {
    if (params.value == null) return '';
    return `${params.value.toFixed(0).toLocaleString()} %`;
  },
  sortComparator: totalComparator,
};

export const numberCol: GridColTypeDef = {
  type: 'number',
  headerAlign: 'center',
  align: 'center',
  width: 65,
  sortComparator: totalComparator,
};

export type ReadinessReportRowType = {
  yardFK: number;
  bucketsOperational: number;
  bucketsTotal: number;
  bucketsReadiness: number;
  diggersOperational: number;
  diggersTotal: number;
  diggersReadiness: number;
  pickupsOperational: number;
  pickupsTotal: number;
  pickupsReadiness: number;
  trailersOperational: number;
  trailersTotal: number;
  trailersReadiness: number;
  otherOperational: number;
  otherTotal: number;
  otherReadiness: number;
};
export const defaultReadinessReportRow: ReadinessReportRowType = {
  yardFK: -1,
  bucketsOperational: 0,
  bucketsTotal: 0,
  bucketsReadiness: 0,
  diggersOperational: 0,
  diggersTotal: 0,
  diggersReadiness: 0,
  pickupsOperational: 0,
  pickupsTotal: 0,
  pickupsReadiness: 0,
  trailersOperational: 0,
  trailersTotal: 0,
  trailersReadiness: 0,
  otherOperational: 0,
  otherTotal: 0,
  otherReadiness: 0,
};

export const getGridColumnDef = (
  locale: string,
  columnFields: string[],
  columnAttributes: { id: string; type: GridColTypeDef }[],
  fixedColumns: GridColDef[] = [],
) => {
  const { t } = useTranslation();

  const gridColumns: GridColDef[] = [];
  columnFields.forEach((item) =>
    columnAttributes.forEach((attr) => {
      const lcId = attr.id.toLowerCase();
      gridColumns.push({
        field: `${item}${attr.id}`,
        headerName: t(`${locale}.${lcId}`),
        description: t(`${locale}.${lcId}_desc`),
        ...attr.type,
      });
    }),
  );

  const defaultColumns: GridColDef[] = [
    {
      field: 'yardFK',
      type: 'singleSelect',
      headerName: t('fleetDefaultColumns.yardFK'),
      description: t('fleetDefaultColumns.yardFK_desc'),
      minWidth: 100,
      flex: 1,
      valueOptions: () => {
        const ret = config.options.yards.map<ValueOptions>((item: ListType) => ({
          value: item.key,
          label: item.value,
        }));
        ret.push({
          value: -1,
          label: 'Total',
        });
        return ret;
      },
      sortComparator: totalComparator,
    },
  ];

  return defaultColumns.concat(fixedColumns, gridColumns);
};

export const getGridColumGroupingModelDef = (locale: string, groupFields: string[], columnAttributes: { id: string; type: GridColTypeDef }[]) => {
  const { t } = useTranslation();

  const columnGroupingModel: GridColumnGroupingModel = [];
  groupFields.forEach((item: string) => {
    const children: GridColumnNode[] = [];
    columnAttributes.forEach((attr) => children.push({ field: `${item}${attr.id}` }));
    columnGroupingModel.push({
      groupId: `${item}_data`,
      headerName: t(`${locale}.${item}`),
      headerAlign: 'center',
      description: t(`${locale}.${item}_desc`),
      children,
    });
  });
  return columnGroupingModel;
};

export const processVehicle = (currRow: ReadinessReportRowType, total: ReadinessReportRowType, item: VehicleType) => {
  switch (item.type) {
    case 'BUCKET_TRUCK':
    case 'SQUIRT_BUCKET': {
      currRow.bucketsTotal += 1;
      currRow.bucketsOperational += item.administrativeFlag || item.maintenanceFlag ? 0 : 1;
      currRow.bucketsReadiness = currRow.bucketsOperational / currRow.bucketsTotal;
      total.bucketsTotal += 1;
      total.bucketsOperational += item.administrativeFlag || item.maintenanceFlag ? 0 : 1;
      total.bucketsReadiness = total.bucketsOperational / total.bucketsTotal;
      break;
    }

    case 'DIGGER_DERRICK': {
      currRow.diggersTotal += 1;
      currRow.diggersOperational += item.administrativeFlag || item.maintenanceFlag ? 0 : 1;
      currRow.diggersReadiness = currRow.diggersOperational / currRow.diggersTotal;
      total.diggersTotal += 1;
      total.diggersOperational += item.administrativeFlag || item.maintenanceFlag ? 0 : 1;
      total.diggersReadiness = total.diggersOperational / total.diggersTotal;
      break;
    }

    case 'PICKUP_TRUCK': {
      currRow.pickupsTotal += 1;
      currRow.pickupsOperational += item.administrativeFlag || item.maintenanceFlag ? 0 : 1;
      currRow.pickupsReadiness = currRow.pickupsOperational / currRow.pickupsTotal;
      total.pickupsTotal += 1;
      total.pickupsOperational += item.administrativeFlag || item.maintenanceFlag ? 0 : 1;
      total.pickupsReadiness = total.pickupsOperational / total.pickupsTotal;
      break;
    }

    case 'BACKYARD_MACHINE':
    case 'TRAILER_POLE':
    case 'TRAILER_SAFETY':
    case 'TRAILER_MATERIAL':
    case 'TRAILER_CARGO':
    case 'TRAILER_LOWBOY':
    case 'TRAILER_UTILITY': {
      currRow.trailersTotal += 1;
      currRow.trailersOperational += item.administrativeFlag || item.maintenanceFlag ? 0 : 1;
      currRow.trailersReadiness = currRow.trailersOperational / currRow.trailersTotal;
      total.trailersTotal += 1;
      total.trailersOperational += item.administrativeFlag || item.maintenanceFlag ? 0 : 1;
      total.trailersReadiness = total.trailersOperational / total.trailersTotal;
      break;
    }

    case 'MECHANIC_TRUCK':
    case 'VAN':
    case 'SUV':
    default: {
      currRow.otherTotal += 1;
      currRow.otherOperational += item.administrativeFlag || item.maintenanceFlag ? 0 : 1;
      currRow.otherReadiness = currRow.otherOperational / currRow.otherTotal;
      total.otherTotal += 1;
      total.otherOperational += item.administrativeFlag || item.maintenanceFlag ? 0 : 1;
      total.otherReadiness = total.otherOperational / total.otherTotal;
      break;
    }
  }
};
