import { combineReducers } from 'redux';
import roles from './roles';
import permissions from './permissions';

const rolesData = combineReducers({
  roles,
  permissions,
});

export * from './roles';
export * from './permissions';

export default rolesData;
