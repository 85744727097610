import { Box, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import config from 'src/config';
import { PATHS } from 'src/navigation';
import { themeColors } from 'src/theme';
import { Button, H1, Logo, P1R } from 'src/components';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import expirationPicture from '../../assets/linkExpired.svg';

function ExpirationPageContainer() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box display="flex" height="100vh" flexDirection="column">
      <Box sx={{ p: 7 }}>
        <Logo title={config.getVersion()} onClick={() => navigate(PATHS.MY_PROFILE_PAGE)} />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" gap={5} sx={{ flexGrow: 1, textAlign: 'center', p: 10 }}>
        <Box>
          <img src={expirationPicture} alt="link expired" />
        </Box>
        <H1 sx={{ my: 3, textTransform: 'capitalize' }}>{t('expirationPage.title')}</H1>
        <P1R>
          {t('expirationPage.getNewLink')}{' '}
          <Link
            component={RouterLink}
            to="/forgot-password"
            variant="body2"
            sx={{
              ml: 0.5,
              fontSize: '14px',
              textDecoration: 'none',
              color: themeColors.blue,
              textTransform: 'none',
            }}
          >
            {t('expirationPage.forgotPassword')}
          </Link>{' '}
          {t('expirationPage.again')}
        </P1R>
        <Button onClick={() => navigate(PATHS.LOGIN_PAGE)} sx={{ textTransform: 'capitalize' }}>
          Return to sign in
        </Button>
      </Box>
    </Box>
  );
}

export default ExpirationPageContainer;
