import React from 'react';
import { FormikProps } from 'formik';
import Password from '../Password';

type FormikInputStringProps = {
  formik: FormikProps<any>;
  field: string;
  label: string;
  disabled?: boolean;
  errorHelper?: Record<string, string[]>;
  autoComplete?: string;
};
const FormikPassword: React.FC<FormikInputStringProps> = ({ formik, field, errorHelper, autoComplete, ...props }): JSX.Element => (
  <Password
    name={field}
    value={formik.values[field]}
    onChange={formik.handleChange}
    error={(formik.touched[field] && Boolean(formik.errors[field])) || (errorHelper && !!errorHelper[field])}
    // @ts-ignore
    helperText={(formik.touched[field] && formik.errors[field]) || (errorHelper && errorHelper[field])}
    autoComplete={autoComplete}
    {...props}
  />
);

export default FormikPassword;
