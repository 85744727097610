import { Box, Dialog, DialogContent, DialogTitle, IconButton, styled } from '@mui/material';
import { Button, H1, Icon, P1R } from 'src/components';
import { useTranslation } from 'react-i18next';
import warningIcon from '../../assets/warningSign.svg';

type Props = {
  onClose: () => void;
  errors: string;
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '600px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(5),
    paddingBottom: theme.spacing(1),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: '8px',
            top: '8px',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon name="close" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function IncidentCannotClosePopup({ onClose, errors }: Props) {
  const cantBeClosed = Boolean(errors);
  const { t } = useTranslation();

  return (
    <Box>
      <BootstrapDialog open onClose={onClose} aria-labelledby="customized-dialog-title">
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose} />
        <DialogContent>
          <Box
            sx={{
              mb: 5,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Box>
              <img src={warningIcon} alt="warning" />
            </Box>
            {cantBeClosed ? (
              <>
                <H1 align="center" sx={{ mt: 5, mb: 3, textTransform: 'capitalize' }}>
                  {t('incidentCannotClose.cantClose')}
                </H1>
                <P1R>{t('incidentCannotClose.cantCloseSubtitle')}</P1R>
                <P1R>{t('incidentCannotClose.cantCloseAskSubtitle')}</P1R>
              </>
            ) : (
              <>
                <H1 align="center" sx={{ mt: 5, mb: 3, textTransform: 'capitalize' }}>
                  {t('incidentCannotClose.cantActivate')}
                </H1>
                <P1R>{t('incidentCannotClose.cantActivateSubtitle')}</P1R>
              </>
            )}
          </Box>

          <Box sx={{ marginBottom: 5, display: 'flex', justifyContent: 'center' }}>
            <Button primary onClick={onClose} sx={{ width: '240px', textTransform: 'capitalize' }}>
              {t('incidentCannotClose.buttonTitle')}
            </Button>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
}

export default IncidentCannotClosePopup;
