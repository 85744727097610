import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';
import { Icon } from 'src/components';
import { themeColors } from 'src/theme';
import { Alert, Box } from '@mui/material';

type Props = {
  open: boolean;
  children?: React.ReactNode;
  onClose: () => void;
  severity?: 'success' | 'error';
} & SnackbarProps;

function Notification({ open, onClose, children, severity = 'success', ...props }: Props) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={onClose}
      {...props}
    >
      <Alert
        sx={{
          padding: '0 16px',
          height: '52px',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: themeColors.black,
        }}
        severity={severity}
      >
        <Box sx={{ gap: 5, display: 'flex', alignItems: 'center' }}>
          {children}
          <Box sx={{ cursor: 'pointer' }} onClick={onClose}>
            <Icon name="close" />
          </Box>
        </Box>
      </Alert>
    </Snackbar>
  );
}

export default Notification;
