import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { SagaAction } from '../../types';
import { rosterEquipmentSetOptions } from '../actions';

type LinkRosterEquipmentType = {
  rosterId: number;
  equipmentUuid: string;
  options: unknown;
};

function* rosterEquipmentSetOptionsSaga({
  payload: { rosterId, equipmentUuid, options },
}: SagaAction<Partial<LinkRosterEquipmentType>>): SagaIterator {
  try {
    yield put(rosterEquipmentSetOptions.pending());
    yield call(API.rosterEquipmentSetOptions, {
      id: rosterId || 0,
      uuid: equipmentUuid || '',
      options,
    });
    yield put(rosterEquipmentSetOptions.success());
  } catch (e) {
    yield put(rosterEquipmentSetOptions.failure());
  }
}

export default rosterEquipmentSetOptionsSaga;
