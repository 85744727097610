import { useState } from 'react';

import { Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Icon, P1R, P2 } from 'src/components';
import { themeColors } from 'src/theme';
import { IncidentType, ListType } from 'src/types';
import IncidentStatus from 'src/components/IncidentsTable/IncidentStatus';
import { useTranslation } from 'react-i18next';
import { formatIncidentId } from 'src/utils';
import config from 'src/config';

type IncidentHeaderProps = {
  incident?: Partial<IncidentType>;
};

function RosterOverviewHeader({ incident }: IncidentHeaderProps) {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const EmptyState = <P1R sx={{ color: themeColors.grayDisabled }}>{t('common.noData')}</P1R>;

  const showMoreButton = (
    <Box sx={{ display: 'flex', alignItems: 'end' }}>
      <Box
        onClick={() => setIsExpanded((prevState) => !prevState)}
        sx={{
          gap: 0.5,
          display: 'flex',
          justifyContent: 'end',
          cursor: 'pointer',
        }}
      >
        <P1R sx={{ fontSize: '14px', color: themeColors.blue }}>
          {isExpanded ? t('rosterOverviewHeader.showLess') : t('rosterOverviewHeader.showMore')}
        </P1R>
        {isExpanded ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              transform: 'rotate(180deg)',
            }}
          >
            <Icon name="arrowDownBlue" />
          </Box>
        ) : (
          <Icon name="arrowDownBlue" />
        )}
      </Box>
    </Box>
  );

  const headerInfo = (
    <Box sx={{ gap: 1, display: 'flex' }}>
      <Box sx={{ width: '50px' }}>
        <Icon name="incidentLight" />
      </Box>

      <Grid container>
        <Grid item xs={2} sx={{ gap: 1.5, display: 'flex', flexDirection: 'column' }}>
          <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.displayName')}</P2>
          <P1R sx={{ maxWidth: '100%', color: themeColors.grayDark }}>
            {incident?.name} / {formatIncidentId(incident?.id)}
          </P1R>
        </Grid>

        <Grid item xs={2} sx={{ gap: 1.5, display: 'flex', flexDirection: 'column' }}>
          <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.status')}</P2>
          <Box sx={{ display: 'flex' }}>
            <IncidentStatus status={incident?.status ?? 0} />
          </Box>
        </Grid>

        <Grid item xs={2} sx={{ gap: 1.5, display: 'flex', flexDirection: 'column' }}>
          <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.utility')}</P2>
          <P1R sx={{ color: themeColors.grayDark }}>
            {/* @ts-ignore */}
            {config.options.utilities.find((utility: ListType) => utility.key === incident?.utilityId)?.value || EmptyState}
          </P1R>
        </Grid>

        <Grid item xs={2} sx={{ gap: 1.5, display: 'flex', flexDirection: 'column' }}>
          <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.utilityContractor')}</P2>
          <P1R sx={{ color: themeColors.grayDark }}>
            {/* @ts-ignore */}
            {config.options.utilityContractors.find((utility: ListType) => utility.key === incident?.utilityContractorId)?.value || EmptyState}
          </P1R>
        </Grid>

        <Grid item xs={2} sx={{ gap: 1.5, display: 'flex', flexDirection: 'column' }}>
          <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.ibewPay')}</P2>
          <P1R sx={{ color: themeColors.grayDark }}>{incident?.ibewPay}</P1R>
        </Grid>

        <Grid item xs={2} sx={{ display: 'flex' }}>
          {showMoreButton}
        </Grid>
      </Grid>
    </Box>
  );

  const extendedInfo = (
    <Box sx={{ gap: 1, display: 'flex' }}>
      <Box sx={{ width: '50px' }} />

      <Grid container>
        <Grid item xs={2} sx={{ gap: 1.5, display: 'flex', flexDirection: 'column' }}>
          <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.benefitsPay')}</P2>
          <P1R sx={{ color: themeColors.grayDark }}>{incident?.benefitsPay || EmptyState}</P1R>
        </Grid>

        <Grid item xs={2} sx={{ gap: 1.5, display: 'flex', flexDirection: 'column' }}>
          <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.travelReimbursement')}</P2>
          <P1R sx={{ color: themeColors.grayDark }}>{incident?.travelReimbursement || EmptyState}</P1R>
        </Grid>

        <Grid item xs={2} sx={{ gap: 1.5, display: 'flex', flexDirection: 'column' }}>
          <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.lodgingProvided')}</P2>
          <P1R sx={{ color: themeColors.grayDark }}>
            {incident?.lodgingProvided?.toString() === 'true' ? 'Yes' : incident?.lodgingProvided?.toString() === 'false' ? 'No' : EmptyState}
          </P1R>
        </Grid>
      </Grid>
    </Box>
  );

  const headerInfoMobile = (
    <Box sx={{ gap: 1, display: 'flex' }}>
      <Box sx={{ width: '50px' }}>
        <Icon name="incidentLight" />
      </Box>

      <Box sx={{ gap: 1.5, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
          <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.displayName')}</P2>
          <IncidentStatus status={incident?.status ?? 0} />
        </Box>

        <P1R sx={{ color: themeColors.grayDark }}>
          {incident?.name} / {formatIncidentId(incident?.id)}
        </P1R>

        {showMoreButton}
      </Box>
    </Box>
  );

  const extendedInfoMobile = (
    <Grid container rowGap={3}>
      <Grid item xs={6} sx={{ gap: 1.5, display: 'flex', flexDirection: 'column' }}>
        <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.utility')}</P2>
        <P1R sx={{ color: themeColors.grayDark }}>
          {/* @ts-ignore */}
          {config.options.utilities.find((utility: ListType) => utility.key === incident?.utilityId)?.value || EmptyState}
        </P1R>
      </Grid>
      <Grid item xs={6} sx={{ gap: 1.5, display: 'flex', flexDirection: 'column' }}>
        <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.utilityContractor')}</P2>
        <P1R sx={{ color: themeColors.grayDark }}>
          {/* @ts-ignore */}
          {config.options.utilityContractors.find((utility: ListType) => utility.key === incident?.utilityContractorId)?.value || EmptyState}
        </P1R>
      </Grid>
      <Grid item xs={6} sx={{ gap: 1.5, display: 'flex', flexDirection: 'column' }}>
        <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.ibewPay')}</P2>
        <P1R sx={{ color: themeColors.grayDark }}>{incident?.ibewPay || EmptyState}</P1R>
      </Grid>
      <Grid item xs={6} sx={{ gap: 1.5, display: 'flex', flexDirection: 'column' }}>
        <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.benefitsPay')}</P2>
        <P1R sx={{ color: themeColors.grayDark }}>{incident?.benefitsPay || EmptyState}</P1R>
      </Grid>
      <Grid item xs={6} sx={{ gap: 1.5, display: 'flex', flexDirection: 'column' }}>
        <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.travelReimbursement')}</P2>
        <P1R sx={{ color: themeColors.grayDark }}>{incident?.travelReimbursement || EmptyState}</P1R>
      </Grid>
      <Grid item xs={6} sx={{ gap: 1.5, display: 'flex', flexDirection: 'column' }}>
        <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.lodgingProvided')}</P2>
        <P1R sx={{ color: themeColors.grayDark }}>
          {incident?.lodgingProvided?.toString() === 'true' ? 'Yes' : incident?.lodgingProvided?.toString() === 'false' ? 'No' : EmptyState}
        </P1R>
      </Grid>
    </Grid>
  );

  if (!isExpanded) {
    return (
      <Box
        sx={{
          padding: 3,
          backgroundColor: themeColors.grayLight,
          borderRadius: '5px',
        }}
      >
        {isMobile ? headerInfoMobile : headerInfo}
      </Box>
    );
  }
  return (
    <Box
      sx={{
        padding: 3,
        backgroundColor: themeColors.grayLight,
        borderRadius: '5px',
      }}
    >
      {isMobile ? headerInfoMobile : headerInfo}
      <Box
        sx={{
          margin: '24px 0',
          borderBottom: `1px solid ${themeColors.grayMedium}`,
        }}
      />
      <Box sx={{ gap: 4, display: 'flex', flexDirection: 'column' }}>{isMobile ? extendedInfoMobile : extendedInfo}</Box>
    </Box>
  );
}

export default RosterOverviewHeader;
