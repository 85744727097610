import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { loadClearme } from '../actions';
import { setClearme } from '../reducers';

function* loadClearmeSaga(): SagaIterator {
  try {
    yield put(loadClearme.pending());

    const { payload } = yield call(API.loadClearme);

    yield put(setClearme(payload));
    yield put(loadClearme.success());
  } catch (e) {
    yield put(loadClearme.failure());
  }
}

export default loadClearmeSaga;
