import { RosterType } from 'src/types';
import { createReducer } from '../../utils/toolkit';

const initialState: Partial<RosterType[]> = [];

const {
  reducer,
  actions: { set: setRosters, merge: mergeRosters, reset: resetRosters },
} = createReducer('rosters', initialState);

export default reducer;
export { setRosters, mergeRosters, resetRosters };
