import { FormikProps } from 'formik';
import { Box, Grid } from '@mui/material';
import { FormikInputNumber, FormikInputString, FormikInputSelect } from 'src/components';
import config from 'src/config';
import { useTranslation } from 'react-i18next';
import { FormValues } from '../VehicleDialog';

type Props = {
  formik: FormikProps<FormValues>;
  hidden?: boolean;
};

function General({ formik, hidden = false }: Props) {
  const { t } = useTranslation();
  return (
    <Grid container sx={{ margin: '32px 0 16px 0', display: hidden ? 'none' : 'block' }}>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormikInputSelect
            formik={formik}
            field="type"
            label={t('rosterFleetPage.vehicleType')}
            options={config.options.vehicleType}
            disableClearable
          />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormikInputString formik={formik} field="make" label={t('rosterFleetPage.make')} />
        </Box>
        <Box sx={{ width: '100%' }}>
          <FormikInputString formik={formik} field="model" label={t('rosterFleetPage.model')} />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormikInputNumber formik={formik} field="year" label={t('rosterFleetPage.year')} />
        </Box>
        <Box sx={{ width: '100%' }}>
          <FormikInputString formik={formik} field="color" label={t('rosterFleetPage.color')} />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormikInputString formik={formik} field="vin" label={t('rosterFleetPage.vin')} />
        </Box>
        <Box sx={{ width: '100%' }}>
          <FormikInputNumber formik={formik} field="gvwr" label={t('rosterFleetPage.gvwr')} />
        </Box>
      </Grid>
    </Grid>
  );
}

export default General;
