import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { RosterType } from 'src/types';
import { SagaAction } from '../../types';
import { loadRosters } from '../actions';
import { setRosters } from '../reducers';

function* loadRostersSaga({ payload: { incidentId } }: SagaAction<Partial<RosterType>>): SagaIterator {
  try {
    yield put(loadRosters.pending());
    const { payload } = yield call(API.loadRosters, { incidentId });

    yield put(setRosters(payload));
    yield put(loadRosters.success());
  } catch (e) {
    yield put(loadRosters.failure());
  }
}

export default loadRostersSaga;
