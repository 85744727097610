import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useBlocker } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Link } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  P1R,
  H2,
  Button,
  Toolbar,
  UnsavedChangesPopup,
  rosterColumnVisibilityModel,
  TableAdvancedMetaColumn,
  Icon,
  RosterTable,
  EmptyState,
} from 'src/components';
import { incidentDeclineLinemen } from 'src/redux/incident/actions';
import { selectUser } from 'src/redux/user/selectors';
import { loadLinemenByLocationSuccess } from 'src/redux/users/selectors';
import { selectRoster } from 'src/redux/roster/selectors';
import { UserType } from 'src/types';
import { useBeforeUnload } from 'src/hooks';
import { themeColors } from 'src/theme';
import config from 'src/config';
import { PATHS } from 'src/navigation';

const boxStyle = {
  gap: '4px',
  display: 'flex',
  alignItems: 'center',
};

type Props = {
  rosterStatus: number;
  linemen: Partial<UserType>[];
  fte: Partial<UserType>[];
  selectedLinemen: string[];
  setSelectedLinemen: (selected: any) => void;
  selectedFte: string[];
  setSelectedFte: (selected: any) => void;
};

const LinemenView = ({ rosterStatus, fte, linemen, selectedLinemen, setSelectedLinemen, selectedFte, setSelectedFte }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const roster = useSelector(selectRoster);
  const user = useSelector(selectUser);
  const loadLinemanSuccess = useSelector(loadLinemenByLocationSuccess);
  const [linemenIds, setLinemenIds] = useState<string[]>([]);
  const [fteIds, setFteIds] = useState<string[]>([]);
  const [linemenTable, setLinemenTable] = useState<UserType[]>([]);
  const [fteTable, setFteTable] = useState<UserType[]>([]);
  const isEditable = config.can('edit_roster', { user, roster });

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => !!(selectedLinemen.length || selectedFte.length) && currentLocation.pathname !== nextLocation.pathname,
  );
  useBeforeUnload(!!(selectedLinemen.length || selectedFte.length));

  const removeIntersection = (intersect: string[], array: string[]) => array.filter((item) => !intersect.includes(item));

  const moveToRosterTable = () => {
    const peopleToMove = linemenTable.filter((item) => linemenIds.includes(item.uuid));

    setFteTable((people) => [...people, ...peopleToMove]);
    setLinemenTable((people) => people.filter((item) => !linemenIds.includes(item.uuid)));

    const intersection = selectedFte.filter((id) => linemenIds.includes(id));
    setSelectedLinemen((peopleIds: string[]) => removeIntersection(intersection, [...peopleIds, ...linemenIds]));
    setSelectedFte((peopleIds: string[]) => removeIntersection(intersection, peopleIds));
    setLinemenIds([]);
  };

  const removeFromRosterTable = () => {
    const peopleToMove = fteTable.filter((item) => fteIds.includes(item.uuid));

    setLinemenTable((people) => [...people, ...peopleToMove]);
    setFteTable((people) => people.filter((item) => !fteIds.includes(item.uuid)));

    const intersection = selectedLinemen.filter((id) => fteIds.includes(id));
    setSelectedFte((peopleIds: string[]) => removeIntersection(intersection, [...peopleIds, ...fteIds]));
    setSelectedLinemen((peopleIds: string[]) => removeIntersection(intersection, peopleIds));
    setFteIds([]);
  };

  const handleDeclineLinemen = (uuid: string) => {
    dispatch(incidentDeclineLinemen.init({ id: roster.incident?.id, uuids: [uuid], yards: [roster.yardFK] }));
  };

  const toolbarOptionsVisible = { quickFilter: true, filterBtn: true, columnsBtn: true };
  const metaColumnsLn: TableAdvancedMetaColumn[] = [
    {
      index: 4,
      column: {
        field: 'action',
        headerName: t('users.action'),
        width: 120,
        renderCell: (params: any) => {
          const { incidentLinemen } = params.row;
          const isDeclined = incidentLinemen && params.row.incidentLinemen.declined;
          if (!incidentLinemen) return null;
          return (
            <Button
              disabled={isDeclined}
              onClick={() => handleDeclineLinemen(params.row.id)}
              sx={{
                color: themeColors.error,
                background: 'transparent',
                textTransform: 'none',
                px: 0,
                justifyContent: 'flex-start',
                '&:hover, &.Mui-disabled': {
                  background: 'transparent',
                  boxShadow: 'none',
                },
              }}
            >
              {isDeclined ? t('eventPage.declined') : t('eventPage.decline')}
            </Button>
          );
        },
      },
    },
  ];

  useEffect(() => {
    setLinemenTable(linemen as UserType[]);
    setFteTable(fte as UserType[]);
  }, []);

  useEffect(() => {
    if (loadLinemanSuccess) {
      // update linemen table state after decline action
      setLinemenTable((prevLinemen) => {
        const linemenByUuid: { [key: string]: UserType } = {};
        for (const ln of prevLinemen) {
          linemenByUuid[ln.uuid] = ln;
        }
        for (const i of linemen as UserType[]) {
          if (linemenByUuid[i.uuid]) linemenByUuid[i.uuid] = { ...linemenByUuid[i.uuid], ...i };
        }
        return Object.values(linemenByUuid);
      });
    }
  }, [loadLinemanSuccess]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box mb={1} mt={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', minHeight: '50px' }}>
            <H2 sx={{ textTransform: 'capitalize' }}>{t('rosterStandByPage.availableToAdd')}</H2>
            <Box display="flex">
              {linemenTable?.length ? (
                <Box sx={{ maxWidth: '250px' }}>
                  <Toolbar id="lineman-roster" options={toolbarOptionsVisible} />
                </Box>
              ) : null}
              {isEditable && (
                <Button
                  disabled={!linemenIds.length}
                  onClick={moveToRosterTable}
                  sx={{
                    padding: 0,
                    ml: 1,
                    '&.MuiButtonBase-root': {
                      height: '48px',
                      width: '48px',
                      minWidth: '48px',
                    },
                  }}
                >
                  <ArrowForwardIcon sx={{ height: '18px', width: '16px' }} />
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box mb={1} mt={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', minHeight: '50px' }}>
            <H2 sx={{ textTransform: 'capitalize' }}>
              {t('rosterFleetPage.addedToRoster')} ({fteTable?.length})
            </H2>
            <Box display="flex">
              {fteTable?.length ? (
                <Box sx={{ maxWidth: '250px' }}>
                  <Toolbar id="fte-roster" options={toolbarOptionsVisible} />
                </Box>
              ) : null}
              {isEditable && (
                <Button
                  disabled={!fteIds.length}
                  onClick={removeFromRosterTable}
                  sx={{
                    padding: 0,
                    ml: 1,
                    '&.MuiButtonBase-root': {
                      height: '48px',
                      width: '48px',
                      minWidth: '48px',
                    },
                  }}
                >
                  <ArrowBackIcon sx={{ height: '18px', width: '16px' }} />
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ gap: '24px', display: 'flex', mb: 1 }}>
            <Box style={boxStyle}>
              <Icon name="foreman" />
              <P1R>{t('fteStatuses.crewLead')}</P1R>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ maxHeight: '50vh' }}>
          {linemenTable?.length ? (
            <RosterTable
              id="lineman-roster"
              toolbarProps={{ id: 'lineman-roster', options: toolbarOptionsVisible }}
              linemen={linemenTable as UserType[]}
              ibewPay={roster.incident?.ibewPay || ''}
              rosterId={roster.id as number}
              rosterStatus={rosterStatus}
              fleet={roster.vehicles}
              utilityId={roster.incident?.utilityId}
              metaColumns={metaColumnsLn}
              columnVisibilityModel={{
                ...rosterColumnVisibilityModel,
                firstName: true,
                lastName: true,
                inviteStatus: true,
                email: true,
              }}
              onRowSelectionModelChange={(selected: any) => {
                setLinemenIds(selected);
              }}
              rowSelectionModel={linemenIds}
              checkboxSelection={config.can('edit_roster')}
              hideFooter
              sx={{ border: `1px solid ${themeColors.grayMedium}` }}
            />
          ) : (
            <EmptyState
              type="people"
              size="small"
              withBorder
              subTitle={
                <P1R>
                  {t('rosterPage.listIsEmpty')} <br />
                  {t('rosterPage.goTo')}
                  <Link
                    component={RouterLink}
                    to={`${PATHS.INCIDENTS_PAGE}/${roster.incident?.id}/available-linemen`}
                    sx={{ textDecoration: 'none', color: themeColors.blue }}
                  >
                    {' Resourses '}
                  </Link>{' '}
                  {t('rosterPage.tabToInvote')}
                </P1R>
              }
            />
          )}
        </Grid>
        <Grid item xs={6} sx={{ maxHeight: '50vh' }}>
          {fteTable.length ? (
            <RosterTable
              id="fte-roster"
              toolbarProps={{ id: 'fte-roster', options: toolbarOptionsVisible }}
              ibewPay={roster.incident?.ibewPay || ''}
              rosterId={roster.id as number}
              rosterStatus={rosterStatus}
              linemen={fteTable}
              fleet={roster.vehicles}
              utilityId={roster.incident?.utilityId}
              columnVisibilityModel={{
                ...rosterColumnVisibilityModel,
                firstName: true,
                lastName: true,
                inviteStatus: true,
                unionClassification: true,
              }}
              onRowSelectionModelChange={(selected: any) => {
                setFteIds(selected);
              }}
              rowSelectionModel={fteIds}
              checkboxSelection={config.can('edit_roster')}
              hideFooter
              sx={{ border: `1px solid ${themeColors.grayMedium}` }}
            />
          ) : (
            <EmptyState
              type="people"
              size="small"
              withBorder
              subTitle={
                <P1R>
                  {t('rosterPage.listIsEmpty')} <br />
                  {t('rosterPage.addPeople')}
                  <br /> {t('rosterPage.or')}{' '}
                  <Link
                    component={RouterLink}
                    to={`${PATHS.INCIDENTS_PAGE}/${roster.incident?.id}/available-linemen`}
                    sx={{ textDecoration: 'none', color: themeColors.blue }}
                  >
                    {' Resourses '}
                  </Link>
                </P1R>
              }
            />
          )}
        </Grid>
      </Grid>
      {blocker.state === 'blocked' && <UnsavedChangesPopup onProceed={blocker.proceed} onCancel={blocker.reset} />}
    </>
  );
};

export default LinemenView;
