import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { VehicleType } from 'src/types';
import { setVehicle } from '../reducers';
import { createVehicle } from '../actions';
import { SagaAction } from '../../types';
import loadVehiclesSaga from '../../vehicles/sagas/loadVehiclesSaga';

function* createVehicleSaga({ payload: vehicle }: SagaAction<Partial<VehicleType>>): SagaIterator {
  try {
    yield put(createVehicle.pending());
    const { payload } = yield call(API.createVehicle, vehicle);

    yield put(setVehicle(payload));
    // @ts-ignore
    yield call(loadVehiclesSaga);

    yield put(createVehicle.success());
  } catch (e) {
    yield put(createVehicle.failure(e));
  }
}

export default createVehicleSaga;
