import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { loadOptions } from '../actions';
import { setOptions } from '../reducers';

function* loadOptionsSaga(): SagaIterator {
  try {
    yield put(loadOptions.pending());

    const { payload } = yield call(API.loadOptions);

    yield put(setOptions(payload));
    yield put(loadOptions.success());
  } catch (e) {
    yield put(loadOptions.failure());
  }
}

export default loadOptionsSaga;
