import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GridValueGetterParams, GridRenderCellParams, ValueOptions, GridColDef } from '@mui/x-data-grid';

import { formatTimestamp } from 'src/utils';
import { VehicleType, ListType, UserType } from 'src/types';
import { Icon, P1R, Table } from 'src/components';
import { loadUsers } from 'src/redux/users/actions';
import { selectUsers, selectVehicleAssignees } from 'src/redux/users/selectors';
import config from 'src/config';

import { TableProps, BooleanCell } from '../Table';

export const fleetColumnVisibilityModel = {
  // Following are from VehicleType
  type: false,
  year: false,
  make: false,
  model: false,
  color: false,
  vin: false,
  gvwr: false,
  companyTag: false,
  ownerStatus: false,
  ownerField: false,
  insuranceStatus: false,
  insuranceExpirationDate: false,
  registrationType: false,
  registrationState: false,
  registrationPlate: false,
  registrationStatus: false,
  registrationExpirationDate: false,
  fmcsaStatus: false,
  fmcsaExpirationDate: false,
  dielectricStatus: false,
  dielectricExpirationDate: false,
  ansiStatus: false,
  ansiExpirationDate: false,
  iftaTagStatus: false,
  iftaTagExpirationDate: false,
  iftaTagNumber: false,
  ezPassNumber: false,
  administrativeNotes: false,
  repairFlag: false,
  yardFK: false,
  nextServiceDate: false,
  maintenanceNotes: false,
  fuelCard: false,
  gpsId: false,
  assignedUuid: false,
  operationalNotes: false,
  utilityClassification: false,
  timesheetClassification: false,
  linemen: false,

  // Non-VehicleType columns
  administrativeFlag: false,
  maintenanceFlag: false,
  availability: false,
};

type FleetTableProps = {
  fleet: VehicleType[];
  user: UserType;
  utilityId?: number;
  assignableUsers?: UserType[];
  isEditable?: boolean;
} & Partial<TableProps>;

const FleetTable: FC<FleetTableProps> = ({ fleet, utilityId, assignableUsers, isEditable = false, ...props }): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const availableAssignees = useSelector(selectVehicleAssignees);
  const assigneesOptions = availableAssignees.map((lineman) => ({
    value: lineman.uuid,
    label: `${lineman.firstName} ${lineman.lastName}`,
  }));
  assigneesOptions.push({ value: '', label: '<Unassigned>' });

  const getTime = (timestamp: number) => (timestamp ? formatTimestamp(timestamp) : null);
  const redFlagIcon = <Icon name="redFlag" />;
  const greenFlagIcon = <Icon name="greenFlag" />;
  const redCheckIcon = <Icon name="rejected" />;
  const greenCheckIcon = <Icon name="accepted" />;

  useEffect(() => {
    dispatch(loadUsers.init());
  }, []);

  // TODO: the table should look up users from the global directory but only allow options
  //       from the assginableUsers list. Different user may have different groups of users
  //       that they can assign to.
  const options: ValueOptions[] = assignableUsers
    ? assignableUsers.map<ValueOptions>((lineman) => ({
        value: lineman.uuid,
        label: `${lineman.firstName} ${lineman.lastName}`,
      }))
    : users.map<ValueOptions>((lineman) => ({
        value: lineman.uuid,
        label: `${lineman.firstName} ${lineman.lastName}`,
      }));
  options.push({ value: '', label: '<Unassigned>' });

  // @ts-ignore
  const currUtility = config.options.utilities.find((obj: ListType) => obj.key === utilityId)?.value;

  const columns: GridColDef[] = [
    {
      field: 'companyTag',
      headerName: t('fleetManagementPage.companyTag'),
      editable: isEditable,
      minWidth: 160,
    },
    {
      field: 'type',
      headerName: t('fleetManagementPage.type'),
      type: 'singleSelect',
      editable: isEditable,
      minWidth: 200,
      flex: 1,
      // TODO: Revisit the Icon component since it doesn't render well in the dropdown
      //       list due to the style wrappers.
      renderCell: (params: GridRenderCellParams) => (
        <>
          <Icon name={params.value} fontSize="small" />
          {t(`vehicles.${params.value}`)}
        </>
      ),
      valueOptions: () =>
        config.options.vehicleType.map<ValueOptions>((item: ListType) => ({
          value: item.key,
          label: item.value,
        })),
    },
    {
      field: 'yardFK',
      headerName: t('fleetManagementPage.yard'),
      type: 'singleSelect',
      editable: isEditable,
      minWidth: 200,
      flex: 1,
      valueOptions: () =>
        config.options.yards.map<ValueOptions>((item: ListType) => ({
          value: item.key,
          label: item.value,
        })),
    },
    {
      field: 'administrativeFlag',
      headerName: t('fleetManagementPage.administrativeFlag'),
      type: 'boolean',
      editable: isEditable,
      width: 90,
      renderCell: (params: GridRenderCellParams) => <BooleanCell trueIcon={redFlagIcon} falseIcon={greenFlagIcon} {...params} />,
      renderEditCell: (params: GridRenderCellParams) => <BooleanCell editable trueIcon={redFlagIcon} falseIcon={greenFlagIcon} {...params} />,
    },
    {
      field: 'maintenanceFlag',
      headerName: t('fleetManagementPage.maintenanceFlag'),
      type: 'boolean',
      editable: isEditable,
      width: 90,
      renderCell: (params: GridRenderCellParams) => <BooleanCell trueIcon={redFlagIcon} falseIcon={greenFlagIcon} {...params} />,
      renderEditCell: (params: GridRenderCellParams) => <BooleanCell editable trueIcon={redFlagIcon} falseIcon={greenFlagIcon} {...params} />,
    },
    {
      field: 'repairFlag',
      headerName: t('fleetManagementPage.repairFlag'),
      type: 'boolean',
      editable: isEditable,
      width: 90,
      renderCell: (params: GridRenderCellParams) => <BooleanCell trueIcon={redFlagIcon} falseIcon={greenFlagIcon} {...params} />,
      renderEditCell: (params: GridRenderCellParams) => <BooleanCell editable trueIcon={redFlagIcon} falseIcon={greenFlagIcon} {...params} />,
    },
    {
      field: 'availability',
      headerName: t('fleetManagementPage.availability'),
      type: 'boolean',
      width: 90,
      renderCell: (params: GridRenderCellParams) => <BooleanCell trueIcon={greenCheckIcon} falseIcon={redCheckIcon} {...params} />,
    },
    {
      field: 'year',
      headerName: t('fleetManagementPage.year'),
      type: 'number',
      editable: isEditable,
      renderCell: (params: GridRenderCellParams) => params.row.year || '',
    },
    {
      field: 'make',
      headerName: t('fleetManagementPage.make'),
      type: 'string',
      editable: isEditable,
    },
    {
      field: 'model',
      headerName: t('fleetManagementPage.model'),
      type: 'string',
      editable: isEditable,
    },
    {
      field: 'color',
      headerName: t('fleetManagementPage.color'),
      type: 'string',
      editable: isEditable,
    },
    {
      field: 'vin',
      headerName: t('fleetManagementPage.vin'),
      type: 'string',
      editable: isEditable,
      minWidth: 180,
    },
    {
      field: 'gvwr',
      headerName: t('fleetManagementPage.gvwr'),
      type: 'number',
      editable: isEditable,
    },
    {
      field: 'ownerStatus',
      headerName: t('fleetManagementPage.ownerStatus'),
      type: 'singleSelect',
      editable: isEditable,
      valueOptions: () =>
        config.options.vehicleOwnerType.map<ValueOptions>((item: ListType) => ({
          value: item.key,
          label: item.value,
        })),
    },
    {
      field: 'ownerField',
      headerName: t('fleetManagementPage.ownerField'),
      type: 'string',
      editable: isEditable,
      minWidth: 140,
    },
    {
      field: 'insuranceStatus',
      headerName: t('fleetManagementPage.insuranceStatus'),
      type: 'singleSelect',
      editable: isEditable,
      valueOptions: () =>
        config.options.vehicleStatusType.map<ValueOptions>((item: ListType) => ({
          value: item.key,
          label: item.value,
        })),
    },
    {
      field: 'insuranceExpirationDate',
      headerName: t('fleetManagementPage.insuranceExpirationDate'),
      minWidth: 140,
      valueGetter: (params: GridValueGetterParams) => getTime(params.row.insuranceExpirationDate),
    },
    {
      field: 'registrationType',
      headerName: t('fleetManagementPage.registrationType'),
      type: 'singleSelect',
      editable: isEditable,
      minWidth: 140,
      valueOptions: () =>
        config.options.vehicleRegistrationType.map<ValueOptions>((item: ListType) => ({
          value: item.key,
          label: item.value,
        })),
    },
    {
      field: 'registrationState',
      headerName: t('fleetManagementPage.registrationState'),
      type: 'singleSelect',
      editable: isEditable,
      minWidth: 120,
      // TODO: select state or province
      valueOptions: () =>
        config.options.STATES.US.map<ValueOptions>((item: ListType) => ({
          value: item.key,
          label: item.value,
        })),
    },
    {
      field: 'registrationPlate',
      headerName: t('fleetManagementPage.registrationPlate'),
      type: 'string',
      editable: isEditable,
      minWidth: 120,
    },
    {
      field: 'registrationStatus',
      headerName: t('fleetManagementPage.registrationStatus'),
      type: 'singleSelect',
      editable: isEditable,
      minWidth: 120,
      valueOptions: () =>
        config.options.vehicleStatusType.map<ValueOptions>((item: ListType) => ({
          value: item.key,
          label: item.value,
        })),
    },
    {
      field: 'registrationExpirationDate',
      headerName: t('fleetManagementPage.registrationExpirationDate'),
      minWidth: 120,
      valueGetter: (params: GridValueGetterParams) => getTime(params.row.registrationExpirationDate),
    },
    {
      field: 'fmcsaStatus',
      headerName: t('fleetManagementPage.fmcsaStatus'),
      type: 'singleSelect',
      editable: isEditable,
      valueOptions: () =>
        config.options.vehicleStatusType.map<ValueOptions>((item: ListType) => ({
          value: item.key,
          label: item.value,
        })),
    },
    {
      field: 'fmcsaExpirationDate',
      headerName: t('fleetManagementPage.fmcsaExpirationDate'),
      minWidth: 120,
      valueGetter: (params: GridValueGetterParams) => getTime(params.row.fmcsaExpirationDate),
    },
    {
      field: 'dielectricStatus',
      headerName: t('fleetManagementPage.dielectricStatus'),
      type: 'singleSelect',
      editable: isEditable,
      valueOptions: () =>
        config.options.vehicleStatusType.map<ValueOptions>((item: ListType) => ({
          value: item.key,
          label: item.value,
        })),
    },
    {
      field: 'dielectricExpirationDate',
      headerName: t('fleetManagementPage.dielectricExpirationDate'),
      minWidth: 120,
      valueGetter: (params: GridValueGetterParams) => getTime(params.row.dielectricExpirationDate),
    },
    {
      field: 'ansiStatus',
      headerName: t('fleetManagementPage.ansiStatus'),
      type: 'singleSelect',
      editable: isEditable,
      valueOptions: () =>
        config.options.vehicleStatusType.map<ValueOptions>((item: ListType) => ({
          value: item.key,
          label: item.value,
        })),
    },
    {
      field: 'ansiExpirationDate',
      headerName: t('fleetManagementPage.ansiExpirationDate'),
      minWidth: 120,
      valueGetter: (params: GridValueGetterParams) => getTime(params.row.ansiExpirationDate),
    },
    {
      field: 'iftaTagStatus',
      headerName: t('fleetManagementPage.iftaTagStatus'),
      type: 'singleSelect',
      editable: isEditable,
      valueOptions: () =>
        config.options.vehicleStatusType.map<ValueOptions>((item: ListType) => ({
          value: item.key,
          label: item.value,
        })),
    },
    {
      field: 'iftaTagExpirationDate',
      headerName: t('fleetManagementPage.iftaTagExpirationDate'),
      minWidth: 120,
      valueGetter: (params: GridValueGetterParams) => getTime(params.row.iftaTagExpirationDate),
    },
    {
      field: 'iftaTagNumber',
      headerName: t('fleetManagementPage.iftaTagNumber'),
      type: 'number',
      editable: isEditable,
    },
    {
      field: 'ezPassNumber',
      headerName: t('fleetManagementPage.ezPassNumber'),
      type: 'number',
      editable: isEditable,
    },
    {
      field: 'administrativeNotes',
      headerName: t('fleetManagementPage.administrativeNotes'),
      type: 'string',
      editable: isEditable,
      minWidth: 120,
    },
    {
      field: 'nextServiceDate',
      headerName: t('fleetManagementPage.nextServiceDate'),
      minWidth: 120,
      valueGetter: (params: GridValueGetterParams) => getTime(params.row.nextServiceDate),
    },
    {
      field: 'maintenanceNotes',
      headerName: t('fleetManagementPage.maintenanceNotes'),
      type: 'string',
      editable: isEditable,
      minWidth: 120,
    },
    {
      field: 'fuelCard',
      headerName: t('fleetManagementPage.fuelCard'),
      type: 'string',
      editable: isEditable,
      minWidth: 120,
    },
    {
      field: 'gpsId',
      headerName: t('fleetManagementPage.gpsId'),
      type: 'string',
      editable: isEditable,
      minWidth: 140,
    },
    {
      field: 'assignedUuid',
      headerName: t('fleetManagementPage.assignedTo'),
      type: 'singleSelect',
      editable: isEditable,
      renderCell: (params) => (params.value ? params.row?.assignedTo : <P1R>{'<Unassigned>'}</P1R>),
      valueOptions: () => assigneesOptions,
      minWidth: 200,
    },

    {
      field: 'utilityClassification',
      headerName: t('linemen.utilityClassification'),
      type: 'singleSelect',
      editable: isEditable,
      width: 200,
      renderCell: (params) => (params.value ? t(`utility_vehicle_classifications.${currUtility}.${params.value}`) : <P1R>Select</P1R>),
      valueOptions: () =>
        // @ts-ignore
        config.options.UTILITY_VEHICLE_CLASSIFICATIONS[currUtility]?.map<ValueOptions>((classification: ListType) => ({
          value: classification,
          label: t(`utility_vehicle_classifications.${currUtility}.${classification}`),
        })),
    },
    {
      field: 'timesheetClassification',
      headerName: t('linemen.timesheetInvoiceClassification'),
      type: 'singleSelect',
      editable: isEditable,
      width: 200,
      renderCell: (params) => (params.value ? t(`timesheet_vehicle_classifications.${currUtility}.${params.value}`) : <P1R>Select</P1R>),
      valueOptions: () =>
        // @ts-ignore
        config.options.TIMESHEET_VEHICLE_CLASSIFICATIONS[currUtility]?.map<ValueOptions>((classification: ListType) => ({
          value: classification,
          label: t(`timesheet_vehicle_classifications.${currUtility}.${classification}`),
        })),
    },
    {
      field: 'operationalNotes',
      headerName: t('fleetManagementPage.operationalNotes'),
      type: 'string',
      editable: isEditable,
      minWidth: 120,
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const rows = fleet.map(({ yard, ...equipment }) => {
    return {
      ...equipment,
      utilityClassification: equipment?.assignments?.[0]?.utilityClassification,
      timesheetClassification: equipment?.assignments?.[0]?.timesheetClassification,
      linemen: equipment.assignedTo,
    };
  });

  // We don't want user to overide key props, so add after
  return <Table {...props} type="advanced" columns={columns} rows={rows} getRowId={(row) => row.id} disableRowSelectionOnClick />;
};

export default FleetTable;
