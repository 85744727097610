import { PulsatingSvgIcon } from './styled';

const PulsatingLogo = () => {
  return (
    <PulsatingSvgIcon width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 15.4457V34.6196H14.3804V49L38.8804 24.5H23.9674L49 0L0 15.4457Z" fill="#FF5000" />
    </PulsatingSvgIcon>
  );
};

export default PulsatingLogo;
