import { Grid, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectFilteredIncidentsLoading, selectOpenIncidents } from 'src/redux/incidents/selectors';
import { useEffect } from 'react';
import { loadFilteredIncidents } from 'src/redux/incidents/actions';
import { selectUser } from 'src/redux/user/selectors';
import { H1, Loader, P1R } from 'src/components';
import { useTranslation } from 'react-i18next';
import emptyList from 'src/assets/emptyList.svg';
import config from 'src/config';
import { PermissionEnum } from 'src/types';
import IncidentCard from '../IncidentCard';

const tabFilter = 'ALL';

function AllJobsTab() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const incidents = useSelector(selectOpenIncidents);
  const isLoadingFilteredIncidents = useSelector(selectFilteredIncidentsLoading);

  useEffect(() => {
    dispatch(loadFilteredIncidents.init({ uuid: user.uuid, filter: tabFilter }));
  }, [dispatch]);

  return isLoadingFilteredIncidents ? (
    <Box sx={{ minHeight: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Loader />
    </Box>
  ) : incidents?.length ? (
    <Grid container spacing={3.5}>
      {config.can(PermissionEnum.VIEW_LIST_INVITATIONS) &&
        incidents.map((incident) => (
          <Grid item key={incident.id} xs={12} md={6} lg={4}>
            <IncidentCard incident={incident} filteredTypeIncidents={tabFilter} />
          </Grid>
        ))}
    </Grid>
  ) : (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        flexDirection: 'column',
        mt: 14,
      }}
    >
      <Box>
        <img src={emptyList} alt="No jobs" />
      </Box>
      <H1 sx={{ my: 3 }}>{t('broadcastPage.noJobs')}</H1>
      <P1R>{t('broadcastPage.checkLater')}</P1R>
    </Box>
  );
}

export default AllJobsTab;
