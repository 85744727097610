import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Logo, H1, P1R, Button } from 'src/components';
import config from 'src/config';
import { PATHS } from 'src/navigation';
import errorConnection from 'src/assets/errorConnection.svg';

function ErrorConnectionPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box display="flex" height="100vh" flexDirection="column">
      <Box sx={{ p: 7 }}>
        <Logo title={config.getVersion()} onClick={() => navigate(PATHS.MY_PROFILE_PAGE)} />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" sx={{ flexGrow: 1, textAlign: 'center', p: 10 }}>
        <Box>
          <img src={errorConnection} alt="error connection" />
        </Box>
        <H1 sx={{ my: 3 }}>{t('errorConnectionPage.title')}</H1>
        <P1R sx={{ whiteSpace: 'pre-line', width: '280px' }}>{t('errorConnectionPage.subTitle')}</P1R>
        <Button onClick={() => navigate(-1)} sx={{ my: 4, width: '170px' }}>
          {t('errorConnectionPage.buttonTitle')}
        </Button>
      </Box>
    </Box>
  );
}

export default ErrorConnectionPage;
