import { Permission, Role } from 'src/types';
import axios from '../axios';
import routes from '../routes';

const roles = {
  createRoles: (roles: Role[]): Promise<Partial<Role>[]> => axios.post(routes.ROLES, roles),

  loadRoles: (): Promise<Partial<Role>[]> => axios.get(routes.ROLES),
  loadFilteredRoles: ({ permissions }: { permissions?: string[] }): Promise<Partial<Role>[]> => {
    const queryParams = new URLSearchParams();

    if (permissions && permissions.length > 0) {
      permissions.forEach((permission: string) => {
        queryParams.append('permissions', permission);
      });
    }

    return axios.get(`${routes.ROLES}?${queryParams.toString()}`);
  },

  loadPermissions: (): Promise<Partial<Permission>[]> => axios.get(routes.ROLES_PERMISSIONS),

  updateRoles: (roles: Role[]): Promise<Partial<Role>[]> => axios.patch(routes.ROLES, roles),

  // Why does this have a Role return type (based on deleteIncident)?
  deleteRoles: ({ slugs }: { slugs: string[] }): Promise<Partial<Role>[]> => {
    const queryParams = new URLSearchParams();

    if (slugs && slugs.length > 0) {
      slugs.forEach((slug: string) => {
        queryParams.append('slugs', slug);
      });
    }

    return axios.delete(`${routes.ROLES}?${queryParams.toString()}`);
  },
};

export default roles;
