import { getUrl } from 'src/utils';
import { UtilityType } from 'src/types/utility';
import axios from '../axios';
import routes from '../routes';

const utility = {
  getUtilityClassifications: ({ id }: { id: number }): Promise<Partial<UtilityType>[]> => axios.get(getUrl(routes.UTILITY_CLASSIFICATIONS, { id })),
};

export default utility;
