import { combineReducers } from 'redux';
import vehicle from './vehicle';

const vehicleData = combineReducers({
  vehicle,
});

export * from './vehicle';

export default vehicleData;
