import { takeLatest } from 'redux-saga/effects';
import createRosterSaga from './createRosterSaga';
import saveRosterSaga from './saveRosterSaga';
import loadRosterSaga from './loadRosterSaga';
import deleteRosterSaga from './deleteRosterSaga';
import rosterAttachLinemenSaga from './rosterAttachLinemenSaga';
import rosterDetachLinemanSaga from './rosterDetachLinemanSaga';
import rosterAttachVehiclesSaga from './rosterAttachVehiclesSaga';
import rosterDetachVehiclesSaga from './rosterDetachVehiclesSaga';
import loadRosterAvailableVehiclesSaga from './loadRosterAvailableVehiclesSaga';
import rosterAssignmentsSaga from './rosterAssignmentsSaga';
import rosterLinemanSetOptionsSaga from './rosterLinemanSetOptionsSaga';
import rosterEquipmentSetOptionsSaga from './rosterEquipmentSetOptionSaga';
import rosterSetStatusSaga from './rosterSetStatusSaga';
import rosterTimesheetSaga from './rosterTimesheetSaga';
import rosterTransferSaga from './rosterTransferSaga';
import rosterRevertSaga from './rosterRevertSaga';
import rosterSplitSaga from './rosterSplitSaga';
import { TYPES } from '../constants';

export default function* rosterSagas(): Generator {
  yield takeLatest(TYPES.CREATE_ROSTER.INIT, createRosterSaga);
  yield takeLatest(TYPES.SAVE_ROSTER.INIT, saveRosterSaga);
  yield takeLatest(TYPES.LOAD_ROSTER.INIT, loadRosterSaga);
  yield takeLatest(TYPES.DELETE_ROSTER.INIT, deleteRosterSaga);
  yield takeLatest(TYPES.ROSTER_ATTACH_WORKER.INIT, rosterAttachLinemenSaga);
  yield takeLatest(TYPES.ROSTER_ATTACH_VEHICLES.INIT, rosterAttachVehiclesSaga);
  yield takeLatest(TYPES.ROSTER_DETACH_LINEMAN.INIT, rosterDetachLinemanSaga);
  yield takeLatest(TYPES.ROSTER_DETACH_VEHICLE.INIT, rosterDetachVehiclesSaga);
  yield takeLatest(TYPES.LOAD_ROSTER_AVAILABLE_VEHICLES.INIT, loadRosterAvailableVehiclesSaga);
  yield takeLatest(TYPES.ROSTER_ASSIGNMENTS.INIT, rosterAssignmentsSaga);
  yield takeLatest(TYPES.ROSTER_LINEMAN_SET_OPTIONS.INIT, rosterLinemanSetOptionsSaga);
  yield takeLatest(TYPES.ROSTER_EQUIPMENT_SET_OPTIONS.INIT, rosterEquipmentSetOptionsSaga);
  yield takeLatest(TYPES.ROSTER_SET_STATUS.INIT, rosterSetStatusSaga);
  yield takeLatest(TYPES.ROSTER_TIMESHEET.INIT, rosterTimesheetSaga);
  yield takeLatest(TYPES.ROSTER_TRANSFER.INIT, rosterTransferSaga);
  yield takeLatest(TYPES.ROSTER_REVERT.INIT, rosterRevertSaga);
  yield takeLatest(TYPES.ROSTER_SPLIT.INIT, rosterSplitSaga);
}
