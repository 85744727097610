import { ClearmeType } from 'src/types/clearme';
import { createReducer } from '../../utils/toolkit';

export const initialState: Partial<ClearmeType> = {};

const {
  reducer,
  actions: { set: setClearme, merge: mergeClearme, reset: resetClearme },
} = createReducer('clearme', initialState);

export default reducer;
export { setClearme, mergeClearme, resetClearme };
