import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { GridValueGetterParams } from '@mui/x-data-grid';
import {
  FleetTable,
  H2,
  Button,
  Toolbar,
  UnsavedChangesPopup,
  fleetColumnVisibilityModel,
  TableAdvancedMetaColumn,
  EmptyState,
} from 'src/components';
import { selectVehicles } from 'src/redux/vehicles/selectors';
import { selectUser } from 'src/redux/user/selectors';
import { selectRoster } from 'src/redux/roster/selectors';
import { VehicleType } from 'src/types';
import { useBeforeUnload } from 'src/hooks';
import { themeColors } from 'src/theme';
import config from 'src/config';

type Props = {
  rosterVehicles?: VehicleType[];
  availableFleet?: VehicleType[];
  vehiclesToAdd: number[];
  // TODO: add types for actions
  setVehiclesToAdd: (selected: any) => void;
  vehiclesToRemove: number[];
  setVehiclesToRemove: (selected: any) => void;
};

function VehiclesView({ rosterVehicles = [], vehiclesToAdd, setVehiclesToAdd, vehiclesToRemove, setVehiclesToRemove, ...props }: Props) {
  const { t } = useTranslation();
  const roster = useSelector(selectRoster);
  const user = useSelector(selectUser);
  const availableVehicles = useSelector(selectVehicles);
  const [availIds, setAvailIds] = useState<number[]>([]);
  const [inRosterIds, setInRosterIds] = useState<number[]>([]);
  const [availableToAddTable, setAvailableToAddTable] = useState<VehicleType[]>([]);
  const [vehiclesInRosterTable, setVehiclesInRosterTable] = useState<VehicleType[]>([]);
  const isEditable = config.can('edit_roster', { user, roster });

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => !!(vehiclesToAdd.length || vehiclesToRemove.length) && currentLocation.pathname !== nextLocation.pathname,
  );
  useBeforeUnload(!!(vehiclesToAdd.length || vehiclesToRemove.length));

  const removeIntersection = (intersect: number[], array: number[]) => array.filter((item) => !intersect.includes(item));

  const moveToRosterTable = () => {
    const vehiclesToMove = availableToAddTable.filter((item) => availIds.includes(item.id));

    setVehiclesInRosterTable((vehicles) => [...vehicles, ...vehiclesToMove]);
    setAvailableToAddTable((vehicles) => vehicles.filter((item) => !availIds.includes(item.id)));

    const intersection = vehiclesToRemove.filter((x) => availIds.includes(x));
    setVehiclesToAdd((vehicleIds: number[]) => removeIntersection(intersection, [...vehicleIds, ...availIds]));
    setVehiclesToRemove((vehicleIds: number[]) => removeIntersection(intersection, vehicleIds));
    setAvailIds([]);
  };

  const removeFromRosterTable = () => {
    const vehiclesToMove = vehiclesInRosterTable.filter((item) => inRosterIds.includes(item.id));

    setAvailableToAddTable((vehicles) => [...vehicles, ...vehiclesToMove]);
    setVehiclesInRosterTable((vehicles) => vehicles.filter((item) => !inRosterIds.includes(item.id)));

    const intersection = vehiclesToAdd.filter((x) => inRosterIds.includes(x));
    setVehiclesToRemove((vehicleIds: number[]) => removeIntersection(intersection, [...vehicleIds, ...inRosterIds]));
    setVehiclesToAdd((vehicleIds: number[]) => removeIntersection(intersection, vehicleIds));
    setInRosterIds([]);
  };

  const toolbarOptionsVisible = { quickFilter: true, filterBtn: true, columnsBtn: true };
  const columnsVisible: Record<string, boolean> = { companyTag: true, type: true, location: true };
  const metaColumns: TableAdvancedMetaColumn[] = [
    {
      index: 3,
      column: {
        field: 'yard',
        headerName: t('rosterFleetPage.yard'),
        width: 120,
        valueGetter: (params: GridValueGetterParams) => params.row.yard?.city,
      },
    },
  ];

  useEffect(() => {
    setAvailableToAddTable(availableVehicles);
    setVehiclesInRosterTable(rosterVehicles);
  }, []);

  return (
    <>
      <Grid container spacing={4} sx={{ flexGrow: 1 }}>
        <Grid item xs={6} sx={{ maxHeight: '50vh' }}>
          <Box mb={3} mt={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', minHeight: '50px' }}>
            <H2 sx={{ textTransform: 'capitalize' }}>{t('rosterFleetPage.availableToAdd')}</H2>
            <Box display="flex">
              {availableToAddTable?.length ? (
                <Box sx={{ maxWidth: '250px' }}>
                  <Toolbar id="fleet-avail" options={toolbarOptionsVisible} />
                </Box>
              ) : null}
              {isEditable && (
                <Button
                  disabled={!availIds.length}
                  onClick={moveToRosterTable}
                  sx={{
                    padding: 0,
                    ml: 1,
                    '&.MuiButtonBase-root': {
                      height: '48px',
                      width: '48px',
                      minWidth: '48px',
                    },
                  }}
                >
                  <ArrowForwardIcon sx={{ height: '18px', width: '16px' }} />
                </Button>
              )}
            </Box>
          </Box>
          {availableToAddTable?.length ? (
            <FleetTable
              id="fleet-avail"
              toolbarProps={{ id: 'fleet-avail', options: toolbarOptionsVisible }}
              fleet={availableToAddTable}
              user={user}
              columnVisibilityModel={{
                ...fleetColumnVisibilityModel,
                ...columnsVisible,
              }}
              metaColumns={metaColumns}
              onRowSelectionModelChange={(selected: any) => {
                setAvailIds(selected);
              }}
              rowSelectionModel={availIds}
              checkboxSelection
              hideFooter
              slotProps={{
                filterPanel: {
                  filterFormProps: {
                    filterColumns: ({ columns }) => columns.filter((col) => columnsVisible[col.field]).map((col) => col.field),
                  },
                },
              }}
              sx={{ border: `1px solid ${themeColors.grayMedium}` }}
            />
          ) : (
            <EmptyState type="fleet" size="small" withBorder subTitle={t('rosterPage.vehiclesEmptyAvailable')} />
          )}
        </Grid>
        <Grid item xs={6} sx={{ maxHeight: '50vh' }}>
          <Box mb={3} mt={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', minHeight: '50px' }}>
            <H2 sx={{ textTransform: 'capitalize' }}>
              {t('rosterFleetPage.addedToRoster')} ({vehiclesInRosterTable?.length})
            </H2>
            <Box display="flex">
              {vehiclesInRosterTable?.length ? (
                <Box sx={{ maxWidth: '250px' }}>
                  <Toolbar id="fleet-roster" options={toolbarOptionsVisible} />
                </Box>
              ) : null}
              {isEditable && (
                <Button
                  disabled={!inRosterIds.length}
                  onClick={removeFromRosterTable}
                  sx={{
                    padding: 0,
                    ml: 1,
                    '&.MuiButtonBase-root': {
                      height: '48px',
                      width: '48px',
                      minWidth: '48px',
                    },
                  }}
                >
                  <ArrowBackIcon sx={{ height: '18px', width: '16px' }} />
                </Button>
              )}
            </Box>
          </Box>
          {vehiclesInRosterTable?.length ? (
            <FleetTable
              id="fleet-roster"
              toolbarProps={{ id: 'fleet-roster', options: toolbarOptionsVisible }}
              fleet={vehiclesInRosterTable}
              user={user}
              columnVisibilityModel={{
                ...fleetColumnVisibilityModel,
                ...columnsVisible,
              }}
              metaColumns={metaColumns}
              onRowSelectionModelChange={(selected: any) => {
                setInRosterIds(selected);
              }}
              rowSelectionModel={inRosterIds}
              checkboxSelection
              hideFooter
              slotProps={{
                filterPanel: {
                  filterFormProps: {
                    filterColumns: ({ columns }) => columns.filter((col) => columnsVisible[col.field]).map((col) => col.field),
                  },
                },
              }}
              sx={{ border: `1px solid ${themeColors.grayMedium}` }}
            />
          ) : (
            <EmptyState type="fleet" size="small" withBorder subTitle={t('rosterPage.vehiclesEmptyAdded')} />
          )}
        </Grid>
      </Grid>
      {blocker.state === 'blocked' && <UnsavedChangesPopup onProceed={blocker.proceed} onCancel={blocker.reset} />}
    </>
  );
}

export default VehiclesView;
