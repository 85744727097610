import { SagaIterator } from 'redux-saga';
import { SagaAction } from 'src/redux/types';
import { call, put } from 'redux-saga/effects';
import API from 'src/api';
import { verifyPasswordToken } from '../actions';

function* verifyPasswordTokenSaga({ payload: resetPasswordToken }: SagaAction<{ resetPasswordToken: string }>): SagaIterator {
  try {
    yield put(verifyPasswordToken.pending());
    yield call(API.verifyPasswordToken, resetPasswordToken);
    yield put(verifyPasswordToken.success());
  } catch (error) {
    yield put(verifyPasswordToken.failure(error));
  }
}

export default verifyPasswordTokenSaga;
