import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { deleteRoles } from '../actions';
import { SagaAction } from '../../types';
import loadRolesSaga from './loadRolesSaga';

type DeletePayloadType = {
  slugs: string[];
};

function* deleteRolesSaga({ payload: { slugs } }: SagaAction<DeletePayloadType>): SagaIterator {
  try {
    yield put(deleteRoles.pending());
    yield call(API.deleteRoles, { slugs });

    yield call(loadRolesSaga);
    yield put(deleteRoles.success());
  } catch (e) {
    yield put(deleteRoles.failure(e));
  }
}

export default deleteRolesSaga;
