import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { RosterType } from 'src/types';
import { SagaAction } from '../../types';

import * as actions from '../actions';

function* rosterTransferSaga({
  payload: { rosterId, incidentId, rosterData },
}: SagaAction<
  Partial<{
    rosterId: number;
    incidentId: number;
    rosterData: Partial<RosterType>;
  }>
>): SagaIterator {
  try {
    yield put(actions.rosterTransfer.pending());
    yield call(API.rosterTransfer, { id: rosterId, incidentId, rosterData });

    yield put(actions.rosterTransfer.success());
  } catch (e) {
    yield put(actions.rosterTransfer.failure());
  }
}

export default rosterTransferSaga;
