import API from 'src/api';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { SagaAction } from '../../types';
import { deleteUserFile } from '../actions';

function* deleteUserUploadSaga({ payload }: SagaAction<{ id: number }>): SagaIterator {
  try {
    yield put(deleteUserFile.pending());
    yield call(API.deleteUserUpload, payload);

    yield put(deleteUserFile.success());
  } catch (error) {
    yield put(deleteUserFile.failure(error));
  }
}

export default deleteUserUploadSaga;
