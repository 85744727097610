import auth from './services/auth';
import roles from './services/roles';
import user from './services/user';
import users from './services/users';
import incidents from './services/incidents';
import rosters from './services/rosters';
import vehicles from './services/vehicles';
import utility from './services/utility';
import options from './services/options';

const services = {
  ...auth,
  ...roles,
  ...user,
  ...users,
  ...incidents,
  ...rosters,
  ...vehicles,
  ...utility,
  ...options,
};

export default services;
