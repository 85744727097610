import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box } from '@mui/material';
import { selectFilteredIncidentsLoading, selectIncidents } from 'src/redux/incidents/selectors';
import { loadFilteredIncidents } from 'src/redux/incidents/actions';
import { selectUser } from 'src/redux/user/selectors';
import { H1, P1R, Button, Loader } from 'src/components';
import { selectSetInviteIncidentSuccess } from 'src/redux/incident/selectors';
import emptyList from 'src/assets/emptyList.svg';
import { useTranslation } from 'react-i18next';
import { IncidentType } from 'src/types';
import IncidentCard from '../IncidentCard';

type ActiveTab = 'upcoming' | 'active' | 'past';

type MyJobsInfo = {
  upcoming: IncidentType[];
  active: IncidentType[];
  past: IncidentType[];
};

const tabFilter = 'MY_JOBS';

function MyJobsTab() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const incidents = useSelector(selectIncidents) as unknown as MyJobsInfo;
  const isLoadingFilteredIncidents = useSelector(selectFilteredIncidentsLoading);
  const setInviteIncidentSuccess = useSelector(selectSetInviteIncidentSuccess);
  const [activeTab, setActiveTab] = useState<ActiveTab>('upcoming');

  useEffect(() => {
    dispatch(
      loadFilteredIncidents.init({
        uuid: user.uuid,
        filter: tabFilter,
      }),
    );
  }, [dispatch, user.uuid]);

  useEffect(() => {
    if (setInviteIncidentSuccess) {
      dispatch(
        loadFilteredIncidents.init({
          uuid: user.uuid,
          filter: tabFilter,
        }),
      );
    }
  }, [dispatch, user.uuid, setInviteIncidentSuccess]);

  return isLoadingFilteredIncidents ? (
    <Box sx={{ minHeight: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Loader />
    </Box>
  ) : (
    <Grid container spacing={3.5}>
      <Grid item xs={12}>
        <Button
          buttonType="group"
          items={[
            {
              title: t('broadcastPage.upcoming'),
              onClick: () => {
                setActiveTab('upcoming');
              },
              active: activeTab === 'upcoming',
            },
            {
              title: t('broadcastPage.active'),
              onClick: () => {
                setActiveTab('active');
              },
              active: activeTab === 'active',
            },
            {
              title: t('broadcastPage.past'),
              onClick: () => {
                setActiveTab('past');
              },
              active: activeTab === 'past',
            },
          ]}
        />
      </Grid>
      {
        // @ts-ignore
        !incidents[activeTab]?.length ? (
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                flexDirection: 'column',
                mt: 14,
              }}
            >
              <Box>
                <img src={emptyList} alt="No jobs" />
              </Box>
              <H1 sx={{ my: 3 }}>
                {t('broadcastPage.no')} {activeTab} {t('broadcastPage.jobsYet')}
              </H1>
              <P1R>
                {t('broadcastPage.thereAreNo')} {activeTab} {t('broadcastPage.checkIt')}
              </P1R>
            </Box>
          </Grid>
        ) : null
      }
      {// @ts-ignore
      incidents[activeTab]?.map((incident: IncidentType) => (
        <Grid item key={incident.id} xs={12} md={6} lg={4}>
          <IncidentCard incident={incident} filteredTypeIncidents={tabFilter} />
        </Grid>
      ))}
    </Grid>
  );
}

export default MyJobsTab;
