import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { RosterType } from 'src/types';

import { SagaAction } from '../../types';
import { createRoster } from '../actions';

function* createRosterSaga({ payload: roster }: SagaAction<Partial<RosterType>>): SagaIterator {
  try {
    yield put(createRoster.pending());
    yield call(API.createRoster, roster);

    yield put(createRoster.success());
  } catch (e) {
    yield put(createRoster.failure(e));
  }
}

export default createRosterSaga;
