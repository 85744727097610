import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { setInviteIncident } from '../actions';
import { SagaAction } from '../../types';
// import getUserInvitesSaga from '../../user/sagas/getUserInvitesSaga';

type InviteIncidentType = {
  id: number;
  status: '' | 'ACCEPTED' | 'REJECTED';
  yardId: number;
  rosterId?: number;
};

function* setInviteIncidentSaga({ payload: { id, status = '', yardId, rosterId } }: SagaAction<Partial<InviteIncidentType>>): SagaIterator {
  try {
    yield put(setInviteIncident.pending());

    if (status) {
      yield call(API.setInviteIncident, {
        id,
        status: status === 'ACCEPTED' ? 1 : -1,
        yardId,
        rosterId,
      });
    }

    // yield put(setIncident(payload));
    // @ts-ignore
    // yield call(getUserInvitesSaga);

    yield put(setInviteIncident.success());
  } catch (e) {
    yield put(setInviteIncident.failure(e));
  }
}

export default setInviteIncidentSaga;
