import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { SagaAction } from '../../types';
import { rosterSetStatus } from '../actions';

function* rosterSetStatusSaga({
  payload: { id = 0, status = 0, message },
}: SagaAction<Partial<{ id: number; status: number; message: string }>>): SagaIterator {
  try {
    yield put(rosterSetStatus.pending());
    yield call(API.rosterSetStatus, { id, status, message });

    yield put(rosterSetStatus.success());
  } catch (e) {
    yield put(rosterSetStatus.failure());
  }
}

export default rosterSetStatusSaga;
