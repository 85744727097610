import React, { useState } from 'react';
import { Box } from '@mui/material';
import { H3, Icon, P1R } from 'src/components';
import { themeColors } from 'src/theme';
import { IncidentType } from 'src/types';

import { useTranslation } from 'react-i18next';
import { formatIncidentId } from 'src/utils';
import config from 'src/config';
import JobDetailsDialog from './JobDetailsDialog';

type Props = {
  incident: IncidentType;
  filteredTypeIncidents: string;
};

function IncidentCard({ incident, filteredTypeIncidents }: Props) {
  const { t } = useTranslation();
  const [showDialog, setShowDialog] = useState(false);

  const { name: incidentName, id, yard, time, ibewPay, participations, rosterDetails, onRoster, rosterOwnersFullNamesByYards } = incident;

  return (
    <>
      <Box
        sx={{
          borderRadius: '8px',
          border: `1px solid ${themeColors.grayMedium}`,
          cursor: 'pointer',
        }}
        onClick={() => setShowDialog(true)}
      >
        <Box
          sx={{
            height: '150px',
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: themeColors.grayLight,
          }}
        >
          <Box
            sx={{
              gap: 3,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <H3>
                {incidentName} / {formatIncidentId(id)}
              </H3>
              {participations?.participationStatus === 1 && (
                <Box sx={{ padding: '0 5px 0 5px', background: themeColors.greenLight, borderRadius: '5px' }}>
                  <P1R sx={{ color: themeColors.green }}>{t('common.yes')}</P1R>
                </Box>
              )}
              {participations?.participationStatus === -1 && (
                <Box sx={{ padding: '0 5px 0 5px', background: themeColors.redLight, borderRadius: '5px' }}>
                  <P1R sx={{ color: themeColors.red }}>{t('common.no')}</P1R>
                </Box>
              )}
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {rosterOwnersFullNamesByYards &&
                rosterOwnersFullNamesByYards.map((owner, index) => (
                  <P1R key={index}>
                    {owner}
                    {index < rosterOwnersFullNamesByYards.length - 1 && ', \u00A0'}
                  </P1R>
                ))}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ gap: 1, display: 'flex', alignItems: 'center' }}>
              <Icon name="yardFK" />
              <P1R>
                {
                  // @ts-ignore
                  // eslint-disable-next-line
                  config.options.yards?.[yard?.id - 1]?.value
                }
              </P1R>
            </Box>
            <Box sx={{ gap: 1, display: 'flex', alignItems: 'center' }}>
              <Icon name="time" />
              <P1R>{time}</P1R>
              {/* <P1R>{formatTimestamp(+expectedStartDate)}</P1R> */}
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}>
          <P1R sx={{ fontWeight: 600 }}>
            {t('broadcastPage.ibewPay')}: {ibewPay}
          </P1R>
          {participations?.declined && !rosterDetails && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Icon name="rejected" />
              <P1R>{t('broadcastPage.declined')}</P1R>
            </Box>
          )}
          {onRoster && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Icon name="accepted" />
              <P1R>{t('broadcastPage.onRoster')}</P1R>
            </Box>
          )}
        </Box>
      </Box>
      {showDialog && <JobDetailsDialog onClose={() => setShowDialog(false)} incident={incident} filteredTypeIncidents={filteredTypeIncidents} />}
    </>
  );
}

export default IncidentCard;
