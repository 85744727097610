import { FC, ReactNode, ElementType } from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';

type componentProps = {
  children: ReactNode;
  component?: ElementType;
  // XXX: Don't use type 'SxProps' from @mui/material. It's big trouble with leak memory
  sx?: { [key: string]: any };
} & TypographyProps;

export const H1: FC<componentProps> = ({ children, sx = {}, component, ...props }): JSX.Element => (
  <Typography variant="h1" component={component || 'span'} sx={{ display: 'block', ...sx }} {...props}>
    {children}
  </Typography>
);

export const H2: FC<componentProps> = ({ children, ...props }): JSX.Element => (
  <Typography variant="h2" component="span" {...props}>
    {children}
  </Typography>
);

export const H3: FC<componentProps> = ({ children, ...props }): JSX.Element => (
  <Typography variant="h3" component="span" {...props}>
    {children}
  </Typography>
);

export const P1M: FC<componentProps> = ({ children, sx, ...props }): JSX.Element => (
  <Typography variant="h4" component="span" sx={{ fontWeight: 600, ...sx }} {...props}>
    {children}
  </Typography>
);

export const P1R: FC<componentProps> = ({ children, ...props }): JSX.Element => (
  <Typography variant="h4" component="span" {...props}>
    {children}
  </Typography>
);

export const P2: FC<componentProps> = ({ children, sx = {}, component, ...props }): JSX.Element => (
  <Typography variant="h5" component={component || 'span'} sx={{ textTransform: 'uppercase', ...sx }} {...props}>
    {children}
  </Typography>
);

export const P2M: FC<componentProps> = ({ children, sx = {}, ...props }): JSX.Element => (
  <Typography variant="h5" component="span" sx={{ lineHeight: 1.3, ...sx }} {...props}>
    {children}
  </Typography>
);

export const P2R: FC<componentProps> = ({ children, sx = {}, ...props }): JSX.Element => (
  <Typography variant="h5" component="span" sx={{ fontWeight: 400, ...sx }} {...props}>
    {children}
  </Typography>
);

export const P3: FC<componentProps> = ({ children, ...props }): JSX.Element => (
  <Typography variant="h6" component="span" {...props}>
    {children}
  </Typography>
);

export const Button: FC<componentProps> = ({ children, component, ...props }): JSX.Element => (
  <Typography variant="button" component={component || 'button'} {...props}>
    {children}
  </Typography>
);

export const TextLink: FC<componentProps> = ({ children, ...props }): JSX.Element => (
  <Typography variant="overline" {...props}>
    {children}
  </Typography>
);
