import MuiAccordion, { accordionClasses } from '@mui/material/Accordion';
import MuiAccordionSummary, { accordionSummaryClasses } from '@mui/material/AccordionSummary';
import MuiAccordionDetails, { accordionDetailsClasses } from '@mui/material/AccordionDetails';
import MuiAccordionActions, { accordionActionsClasses } from '@mui/material/AccordionActions';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Icon } from 'src/components';
import { themeColors } from 'src/theme';

const expandIcon = (
  <Box
    sx={{
      width: '20px',
      height: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      backgroundColor: themeColors.white,
    }}
  >
    <Icon name="popupIcon" />
  </Box>
);

const StyledAccordion = styled(MuiAccordion)(() => ({
  [`&.${accordionClasses.root}`]: {
    backgroundColor: themeColors.grayLight,
    boxShadow: 'none',
    borderRadius: '4px',
  },
  [`&.${accordionClasses.expanded}`]: {
    border: `1px solid ${themeColors.grayMedium}`,
  },
}));

const StyledSummary = styled(MuiAccordionSummary)(() => ({
  [`&.${accordionSummaryClasses.root}`]: {
    minHeight: '72px',
    padding: '0 24px ',
  },
  [`& .${accordionSummaryClasses.content}`]: {
    marginRight: '14px',
    [`&.${accordionSummaryClasses.expanded}`]: {
      marginRight: '14px',
    },
  },
}));

const StyledDetails = styled(MuiAccordionDetails)(() => ({
  [`&.${accordionDetailsClasses.root}`]: {
    padding: '0 24px',
  },
}));

const StyledActions = styled(MuiAccordionActions)(() => ({
  [`&.${accordionActionsClasses.root}`]: {
    backgroundColor: themeColors.white,
    padding: '24px',
  },
}));

type Props = {
  title?: React.ReactNode;
  actions?: React.ReactNode;
  children?: React.ReactNode;
  defaultExpanded?: boolean;
  expanded?: boolean;
  onChange?: () => void;
  sx?: { [key: string]: any };
};

const Accordion = ({ title = '', actions, children, defaultExpanded = false, expanded = false, onChange, ...props }: Props) => {
  return (
    <StyledAccordion {...props} defaultExpanded={defaultExpanded} expanded={expanded} onChange={onChange}>
      <StyledSummary expandIcon={expandIcon}>{title}</StyledSummary>
      <StyledDetails>{children}</StyledDetails>
      {actions ? <StyledActions>{actions}</StyledActions> : null}
    </StyledAccordion>
  );
};

export default Accordion;
