import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { UserType } from 'src/types';

import { SagaAction } from '../../types';
import { createUser } from '../actions';
import loginSaga from './loginSaga';

function* createUserSaga({ payload }: SagaAction<Partial<UserType>>): SagaIterator {
  try {
    yield put(createUser.pending());
    yield call(API.createUser, payload);

    // @ts-ignore
    yield call(loginSaga, {
      payload: { email: payload.email, password: payload.password },
    });

    yield put(createUser.success());
  } catch (error) {
    yield put(createUser.failure(error));
  }
}

export default createUserSaga;
