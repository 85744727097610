import { Box } from '@mui/material';
import { P1R } from '../Typography';
import { DotPulse } from './styled';
import PulsatingLogo from './pulsatingLogo';

type Props = {
  message?: string;
};

const Loader = ({ message = '' }: Props) => {
  return (
    <Box
      sx={{
        gap: 2,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <PulsatingLogo />

      <Box sx={{ gap: 3, display: 'flex', alignItems: 'center' }}>
        <P1R>{message || 'Loading Data'}</P1R>
        <DotPulse />
      </Box>
    </Box>
  );
};

export default Loader;
