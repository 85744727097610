import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, styled } from '@mui/material';

import { Button, H1, Icon, P1M, P1R } from 'src/components';
import { themeColors } from 'src/theme';

import { useTranslation } from 'react-i18next';
import trashIcon from '../../assets/trashRedIcon.svg';

type Props = {
  name: string;
  open: boolean;
  onDetach: () => void;
  onClose: () => void;
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '600px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(5),
    paddingBottom: theme.spacing(1),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(1),
  },
}));
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: '8px',
            top: '8px',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon name="close" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function DetachPopup({
  name,
  open,
  onDetach,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose = () => {},
}: Props) {
  const { t } = useTranslation();

  return (
    <BootstrapDialog open={open} aria-labelledby="customized-dialog-titile">
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose} />
      <DialogContent>
        <Box
          sx={{
            mb: 5,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Box>
            <img src={trashIcon} alt="delete" />
          </Box>
          <H1 align="center" sx={{ mt: 5, mb: 3 }}>
            {`Delete ${name}?`}
          </H1>
          <P1R>
            {t('detachPopup.aboutDelete')} <P1M>{name}</P1M> {t('detachPopup.fromThisRoster')}
          </P1R>
        </Box>
        <Grid container columnSpacing={2} sx={{ mb: 5 }}>
          <Grid item xs={6}>
            <Button primary={false} onClick={onClose} sx={{ width: '100%', textTransform: 'capitalize' }}>
              {t('common.cancel')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                onDetach();
                onClose();
              }}
              sx={{
                backgroundColor: themeColors.red,
                width: '100%',
                textTransform: 'capitalize',
              }}
            >
              {t('detachPopup.yesDelete')}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </BootstrapDialog>
  );
}

export default DetachPopup;
