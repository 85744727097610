import * as React from 'react';
import { SyntheticEvent } from 'react';
import { FormikProps } from 'formik';
import { ListType } from 'src/types';
import { InputSelectMulti } from '..';

type FormikInputSelectProps = {
  formik: FormikProps<any>;
  field: string;
  label: string;
  placeholder?: string;
  isOptional?: boolean;
  options: ListType[];
  disabled?: boolean;
  disableClearable?: boolean;
  renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: ListType) => React.ReactNode;
  getOptionLabel?: (option: ListType) => string;
  isChipDisabled?: (option: ListType) => boolean;
  errorHelper?: Record<string, string[]>;
  readOnly?: boolean;
  sx?: object;
};

const FormikInputSelectMulti: React.FC<FormikInputSelectProps> = ({ formik, field, placeholder, options, errorHelper, ...props }): JSX.Element => (
  <InputSelectMulti
    name={field}
    placeholder={placeholder || 'Select'}
    getValue={() => formik.values[field]}
    options={options}
    onChange={(event: SyntheticEvent, value: ListType[]) => {
      formik.setFieldValue(
        field,
        value.map((item) => item.key),
      );
    }}
    error={(formik.touched[field] && Boolean(formik.errors[field])) || (errorHelper && !!errorHelper[field])}
    // @ts-ignore
    helperText={(formik.touched[field] && formik.errors[field]) || (errorHelper && errorHelper[field])}
    sx={{ '& .MuiInputBase-root': { background: 'white' } }}
    {...props}
  />
);

export default FormikInputSelectMulti;
