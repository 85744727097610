import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Logo, H1, P1R } from 'src/components';
import config from 'src/config';
import { PATHS } from 'src/navigation';
import error403 from 'src/assets/error403.svg';

function SettingsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box display="flex" height="100vh" flexDirection="column">
      <Box sx={{ p: 7 }}>
        <Logo title={config.getVersion()} onClick={() => navigate(PATHS.MY_PROFILE_PAGE)} />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" sx={{ flexGrow: 1, textAlign: 'center', p: 10 }}>
        <Box>
          <img src={error403} alt="error 403" />
        </Box>
        <H1 sx={{ my: 3 }}>{t('error403Page.title')}</H1>
        <P1R sx={{ whiteSpace: 'pre-line' }}>{t('error403Page.subTitle')}</P1R>
      </Box>
    </Box>
  );
}

export default SettingsPage;
