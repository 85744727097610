import { FC } from 'react';

import { VehicleType } from 'src/types';
import { Table } from 'src/components';
import { TableProps } from 'src/components/Table';

import {
  percentCol,
  numberCol,
  getGridColumnDef,
  getGridColumGroupingModelDef,
  ReadinessReportRowType,
  defaultReadinessReportRow,
  processVehicle,
} from './utils';

type FleetReadinessTableProps = {
  fleet: VehicleType[];
} & Partial<TableProps>;

const FleetReadinessTable: FC<FleetReadinessTableProps> = ({ fleet, ...props }): JSX.Element => {
  // Configure the columns
  const columnFields: string[] = ['buckets', 'diggers', 'pickups', 'trailers', 'other'];
  const columnAttributes = [
    { id: 'Operational', type: numberCol },
    { id: 'Total', type: numberCol },
    { id: 'Readiness', type: percentCol },
  ];
  const columns = getGridColumnDef('fleetReadinessPage', columnFields, columnAttributes);
  const columnGroupingModel = getGridColumGroupingModelDef('fleetReadinessPage', columnFields, columnAttributes);

  const rows: ReadinessReportRowType[] = [];
  const total: ReadinessReportRowType = { ...defaultReadinessReportRow };

  fleet.forEach((item: VehicleType) => {
    let currRow = rows.find((yard) => yard.yardFK === item.yardFK);
    if (!currRow) {
      const len = rows.push({
        ...defaultReadinessReportRow,
        yardFK: item.yardFK,
      });
      currRow = rows[len - 1];
    }
    processVehicle(currRow, total, item);
  });
  rows.push(total);

  // We don't want user to overide key props, so add after
  return (
    <Table
      {...props}
      type="advanced"
      columns={columns}
      rows={rows}
      getRowId={(row) => row.yardFK}
      experimentalFeatures={{ columnGrouping: true }}
      columnGroupingModel={columnGroupingModel}
      slots={{ toolbar: undefined }}
    />
  );
};

export default FleetReadinessTable;
