import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { SagaAction } from '../../types';

import * as actions from '../actions';

function* rosterRevertSaga({ payload: { rosterId = 0 } }: SagaAction<Partial<{ rosterId: number }>>): SagaIterator {
  try {
    yield put(actions.rosterRevert.pending());
    yield call(API.rosterRevertVersion, { id: rosterId });

    yield put(actions.rosterRevert.success());
  } catch (e) {
    yield put(actions.rosterRevert.failure());
  }
}

export default rosterRevertSaga;
