import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef } from '@mui/x-data-grid';

import { Role } from 'src/types';

import { Table } from '..';
import { TableProps } from '../Table';

export const rolesColumnVisibilityModel = {
  name: false,
  slug: false,
  description: false,
};

type RolesTableProps = {
  roles: Role[];
  editable?: boolean;
} & Partial<TableProps>;

const RolesTable: FC<RolesTableProps> = ({ roles, editable = false, ...props }): JSX.Element => {
  const { t } = useTranslation();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('roles.name'),
      editable,
      minWidth: 225,
    },
    {
      field: 'slug',
      headerName: t('roles.slug'),
      type: 'singleSelect',
      minWidth: 225,
    },
    {
      field: 'description',
      headerName: t('roles.description'),
      editable,
      flex: 1,
      renderCell: (params) => <div style={{ whiteSpace: 'pre-wrap' }}>{params.value}</div>,
    },
  ];
  const rows = roles;

  // We don't want user to overide key props, so add after
  return <Table {...props} type="advanced" columns={columns} rows={rows} getRowId={(row) => row.slug} disableRowSelectionOnClick />;
};

export default RolesTable;
