import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';

import { VehicleType } from 'src/types';
import { Table } from 'src/components';
import { loadUsers } from 'src/redux/users/actions';

import { themeColors } from 'src/theme';
import { Box } from '@mui/material';
import { formatTimestamp } from 'src/utils';
import { TableProps } from '../Table';

function checkTimestamp(timestamp: number) {
  const currentTime = Date.now();
  const twoMonthsFromNow = currentTime + 60 * 24 * 60 * 60 * 1000; // Adjust for 60 days (approx. 2 months)

  const timestampDatetime = new Date(timestamp);

  if (timestamp) {
    if (timestampDatetime.getTime() < currentTime) return 2;

    if (timestampDatetime.getTime() <= twoMonthsFromNow) return 1;

    return 0;
  }

  return 0;
}

const cellColorVariants = ['white', `${themeColors.lightYellow}`, `${themeColors.redLight}`];
const textColorVariants = ['black', `${themeColors.yellowMain}`, `${themeColors.red}`];

type FleetTableProps = {
  fleet: VehicleType[];
  isEditable?: boolean;
} & Partial<TableProps>;

export const fleetDueColumnVisibilityModel = {
  companyTag: false,
  registrationExpirationDate: false,
  fmcsaExpirationDate: false,
  dielectricExpirationDate: false,
  ansiExpirationDate: false,
  iftaTagExpirationDate: false,
  insuranceExpirationDate: false,
  yard: false,
  nextServiceDate: false,
};

const FleetDueTable: FC<FleetTableProps> = ({ fleet, isEditable, ...props }): JSX.Element => {
  const dispatch = useDispatch();
  const getTime = (timestamp: number) => (timestamp ? formatTimestamp(timestamp) : null);

  useEffect(() => {
    dispatch(loadUsers.init());
  }, []);

  const getExpirationCellContent = (value: number) => {
    const status = checkTimestamp(value);

    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: cellColorVariants[status],
          color: textColorVariants[status],
        }}
      >
        {getTime(value)}
      </Box>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'companyTag',
      headerName: 'Company Tag',
      editable: isEditable,
      minWidth: 160,
    },
    {
      field: 'registrationExpirationDate',
      headerName: 'registration',
      editable: isEditable,
      renderCell: (params: GridRenderCellParams) => getExpirationCellContent(params.value),
      minWidth: 160,
    },
    {
      field: 'fmcsaExpirationDate',
      headerName: 'fmcsa',
      editable: isEditable,
      renderCell: (params: GridRenderCellParams) => getExpirationCellContent(params.value),
      minWidth: 160,
    },
    {
      field: 'dielectricExpirationDate',
      headerName: 'dielectric',
      editable: isEditable,
      renderCell: (params: GridRenderCellParams) => getExpirationCellContent(params.value),
      minWidth: 160,
    },
    {
      field: 'ansiExpirationDate',
      headerName: 'ansi',
      editable: isEditable,
      renderCell: (params: GridRenderCellParams) => getExpirationCellContent(params.value),
      minWidth: 160,
    },
    {
      field: 'iftaTagExpirationDate',
      headerName: 'ifta',
      editable: isEditable,
      renderCell: (params: GridRenderCellParams) => getExpirationCellContent(params.value),
      minWidth: 160,
    },
    {
      field: 'insuranceExpirationDate',
      headerName: 'Insurance',
      editable: isEditable,
      renderCell: (params: GridRenderCellParams) => getExpirationCellContent(params.value),
      minWidth: 160,
    },
    {
      field: 'yard',
      headerName: 'Yard Assignment',
      editable: isEditable,
      valueGetter: (params: GridValueGetterParams) => params.row.yard.name,
      minWidth: 160,
    },
    {
      field: 'nextServiceDate',
      headerName: 'Service Date',
      editable: isEditable,
      renderCell: (params: GridRenderCellParams) => getExpirationCellContent(params.value),
      minWidth: 160,
    },
  ];

  const rows = fleet.map((equipment) => {
    return {
      ...equipment,
      utilityClassification: equipment?.assignments?.[0]?.utilityClassification,
      timesheetClassification: equipment?.assignments?.[0]?.timesheetClassification,
      linemen: equipment.assignedTo,
    };
  });

  return <Table {...props} type="advanced" columns={columns} rows={rows} getRowId={(row) => row.id} disableRowSelectionOnClick />;
};

export default FleetDueTable;
