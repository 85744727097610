import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { UserType } from 'src/types';

import { SagaAction } from '../../types';
import { patchUser } from '../actions';

function* patchUserSaga({
  // @ts-ignore
  payload: { data },
}: SagaAction<Partial<UserType>>): SagaIterator {
  try {
    yield put(patchUser.pending());
    yield call(API.patchUser, data);
    yield put(patchUser.success());
  } catch (e) {
    yield put(patchUser.failure());
  }
}

export default patchUserSaga;
