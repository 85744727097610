import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';

import { SagaAction } from '../../types';
import { activateUser } from '../actions';

type UserPayloadType = {
  uuid: string;
};

function* activateUserSaga({ payload: { uuid } }: SagaAction<UserPayloadType>): SagaIterator {
  try {
    yield put(activateUser.pending());
    yield call(API.activateUser, uuid);
    yield put(activateUser.success());
  } catch (e) {
    yield put(activateUser.failure());
  }
}

export default activateUserSaga;
