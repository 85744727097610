import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { setUsers } from '../reducers';
import { loadUsers } from '../actions';

function* loadUsersSaga(): SagaIterator {
  try {
    yield put(loadUsers.pending());
    const { payload } = yield call(API.loadUsers);

    yield put(setUsers(payload));
    yield put(loadUsers.success());
  } catch (e) {
    yield put(loadUsers.failure());
  }
}

export default loadUsersSaga;
