import { createSelector } from 'reselect';
import { IncidentStatus } from 'src/types';
import { TYPES } from './constants';
import { createLoadingSelector } from '../@loadings';
import { createSuccessSelector } from '../@success';
import { createErrorSelector } from '../@errors';
import { IncidentsDataState } from './types';
import { ReduxState } from '../types';

export const selectIncidentsData = (state: ReduxState): IncidentsDataState => state.incidentsData;
export const selectIncidents = createSelector(selectIncidentsData, ({ incidents }) => incidents);

// It should be removed after adding pagination.
// /incidents/?openOnly=true
export const selectOpenIncidents = createSelector(selectIncidents, (incidents) =>
  // Technical Debts: incident shoudn't have groups: upcoming, activate, past
  incidents.filter ? incidents.filter((incident) => incident.status !== IncidentStatus.CLOSED) : [],
);
export const selectIncidentsLoading = createLoadingSelector(TYPES.LOAD_INCIDENTS.TYPE);
export const selectIncidentsSuccess = createSuccessSelector(TYPES.LOAD_INCIDENTS.TYPE);
export const selectIncidentsError = createErrorSelector(TYPES.LOAD_INCIDENTS.TYPE);

export const selectFilteredIncidentsLoading = createLoadingSelector(TYPES.LOAD_FILTERED_INCIDENTS.TYPE);
export const selectFilteredIncidentsSuccess = createSuccessSelector(TYPES.LOAD_FILTERED_INCIDENTS.TYPE);
export const selectFilteredIncidentsError = createErrorSelector(TYPES.LOAD_FILTERED_INCIDENTS.TYPE);
