import { useTranslation } from 'react-i18next';
import { Pagination, PaginationItem, MenuItem, Select, SelectChangeEvent, Box } from '@mui/material';
import { gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector, gridPaginationModelSelector } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { P1R } from 'src/components';

import { themeColors } from 'src/theme';

const StyledPageOptions = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  minWidth: '200px',
  '& .MuiInputBase-root': {
    fontSize: '12px',
    marginLeft: '16px',
    borderColor: themeColors.grayLight,
    borderRadius: '4px',
    '& .MuiSelect-select': {
      padding: '10px 32px 10px 20px',
    },
  },
});

const StyledPagination = styled(Pagination)({
  width: '100%',
  height: '24px',
  fontSize: '12px',
  display: 'flex',
  justifyContent: 'end',
  marginRight: '-16px',
  '& .MuiPaginationItem-root': {
    fontSize: '12px',
    fontWeight: 400,
    '&.Mui-selected': {
      backgroundColor: themeColors.yellowMain,
      color: themeColors.black,
    },
  },
  '& .MuiPaginationItem-previousNext': {
    margin: '0 16px',
  },
});

export function TablePagination(props: any) {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const { pageSize } = useGridSelector(apiRef, gridPaginationModelSelector);

  const handleChange = (event: SelectChangeEvent) => {
    apiRef.current.setPageSize(+event.target.value);
  };

  return (
    <>
      {props.pageSizeOptions?.length ? (
        <StyledPageOptions>
          <P1R component="p">{t('pagination.itemsPerPage')}</P1R>
          <Select value={pageSize.toString()} onChange={handleChange}>
            {props.pageSizeOptions.map((size: number, index: number) => (
              <MenuItem key={index} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
        </StyledPageOptions>
      ) : null}

      <StyledPagination
        shape="rounded"
        page={page + 1}
        count={pageCount}
        renderItem={(props) => (
          // @ts-ignore
          <PaginationItem {...props} size="small" disableRipple />
        )}
        onChange={(event: React.ChangeEvent<unknown>, value: number) => apiRef.current.setPage(value - 1)}
      />
    </>
  );
}
