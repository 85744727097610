import { takeLatest } from 'redux-saga/effects';
import loadUsersSaga from './loadUsersSaga';
import loadFilterUsersSaga from './loadFilterUsersSaga';
import loadLinemenSaga from './loadLinemenSaga';
import loadLinemenByLocationSaga from './loadLinemenByLocationSaga';
import notifyUsersSaga from './notifyUsersSaga';
import { TYPES } from '../constants';
import loadUsersFavoritesSaga from './loadUsersFavoritesSaga';

export default function* usersSagas(): Generator {
  yield takeLatest(TYPES.LOAD_USERS.INIT, loadUsersSaga);
  yield takeLatest(TYPES.LOAD_USERS_FAVORITES.INIT, loadUsersFavoritesSaga);
  yield takeLatest(TYPES.LOAD_FILTER_USERS.INIT, loadFilterUsersSaga);
  yield takeLatest(TYPES.LOAD_LINEMEN.INIT, loadLinemenSaga);
  yield takeLatest(TYPES.LOAD_LINEMEN_BY_LOCATION.INIT, loadLinemenByLocationSaga);
  yield takeLatest(TYPES.NOTIFY_USERS.INIT, notifyUsersSaga);
}
