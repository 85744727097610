import { createSelector } from 'reselect';
import { TYPES } from './constants';
import { createLoadingSelector } from '../@loadings';
import { createSuccessSelector } from '../@success';
import { createErrorSelector, createErrorPayloadSelector } from '../@errors';
import { RosterDataState } from './types';
import { ReduxState } from '../types';

export const selectRosterData = (state: ReduxState): RosterDataState => state.rosterData;

export const selectRoster = createSelector(selectRosterData, ({ roster }) => {
  // Technical Debt: normalizing structure of data
  roster?.vehicles?.forEach((vehicle) => {
    vehicle.linemen = roster.linemen?.filter((lineman) => lineman.assignments?.some((assignment) => assignment.vehicleId === vehicle.id));
  });
  return roster;
});

export const selectRosterLoading = createLoadingSelector(TYPES.LOAD_ROSTER.TYPE);
export const selectRosterSuccess = createSuccessSelector(TYPES.LOAD_ROSTER.TYPE);
export const selectRosterError = createErrorSelector(TYPES.LOAD_ROSTER.TYPE);
export const selectRosterErrors = createErrorPayloadSelector(TYPES.LOAD_ROSTER.TYPE);

export const selectCreatingRoster = createLoadingSelector(TYPES.CREATE_ROSTER.TYPE);
export const selectCreatingRosterSuccess = createSuccessSelector(TYPES.CREATE_ROSTER.TYPE);
export const selectCreatingRosterErrors = createErrorPayloadSelector(TYPES.CREATE_ROSTER.TYPE);

export const selectSavingRoster = createLoadingSelector(TYPES.SAVE_ROSTER.TYPE);
export const selectSavingRosterSuccess = createSuccessSelector(TYPES.SAVE_ROSTER.TYPE);
export const selectSavingRosterErrors = createErrorPayloadSelector(TYPES.SAVE_ROSTER.TYPE);

export const selectDeletingRoster = createLoadingSelector(TYPES.DELETE_ROSTER.TYPE);
export const selectDeletingRosterSuccess = createSuccessSelector(TYPES.DELETE_ROSTER.TYPE);
export const selectDeletingRosterErrors = createErrorPayloadSelector(TYPES.DELETE_ROSTER.TYPE);

export const selectRosterAttachingLinemen = createLoadingSelector(TYPES.ROSTER_ATTACH_WORKER.TYPE);
export const selectRosterAttachingLinemenSuccess = createSuccessSelector(TYPES.ROSTER_ATTACH_WORKER.TYPE);
export const selectRosterAttachingLinemenError = createErrorSelector(TYPES.ROSTER_ATTACH_WORKER.TYPE);
export const selectRosterAttachingLinemenErrors = createErrorPayloadSelector(TYPES.ROSTER_ATTACH_WORKER.TYPE);

export const selectRosterDetachLineman = createLoadingSelector(TYPES.ROSTER_DETACH_LINEMAN.TYPE);
export const selectRosterDetachLinemanSuccess = createSuccessSelector(TYPES.ROSTER_DETACH_LINEMAN.TYPE);
export const selectRosterDetachLinemanError = createErrorSelector(TYPES.ROSTER_DETACH_LINEMAN.TYPE);

export const selectRosterAttachingVehicles = createLoadingSelector(TYPES.ROSTER_ATTACH_VEHICLES.TYPE);
export const selectRosterAttachingVehiclesSuccess = createSuccessSelector(TYPES.ROSTER_ATTACH_VEHICLES.TYPE);
export const selectRosterAttachingVehiclesError = createErrorSelector(TYPES.ROSTER_ATTACH_VEHICLES.TYPE);

export const selectrosterDetachVehicles = createLoadingSelector(TYPES.ROSTER_DETACH_VEHICLE.TYPE);
export const selectrosterDetachVehiclesSuccess = createSuccessSelector(TYPES.ROSTER_DETACH_VEHICLE.TYPE);
export const selectrosterDetachVehiclesError = createErrorSelector(TYPES.ROSTER_DETACH_VEHICLE.TYPE);

export const selectRosterSettingStatus = createLoadingSelector(TYPES.ROSTER_SET_STATUS.TYPE);
export const selectRosterSetStatusSuccess = createSuccessSelector(TYPES.ROSTER_SET_STATUS.TYPE);
export const selectRosterSetStatusError = createErrorSelector(TYPES.ROSTER_SET_STATUS.TYPE);

export const selectRosterAssignments = createLoadingSelector(TYPES.ROSTER_ASSIGNMENTS.TYPE);
export const selectRosterAssignmentsSuccess = createSuccessSelector(TYPES.ROSTER_ASSIGNMENTS.TYPE);
export const selectRosterAssignmentsError = createErrorSelector(TYPES.ROSTER_ASSIGNMENTS.TYPE);

export const selectRosterLinemanSettingOptions = createLoadingSelector(TYPES.ROSTER_LINEMAN_SET_OPTIONS.TYPE);
export const selectRosterLinemanSetOptionsSuccess = createSuccessSelector(TYPES.ROSTER_LINEMAN_SET_OPTIONS.TYPE);
export const selectRosterLinemanSetOptionsError = createErrorSelector(TYPES.ROSTER_LINEMAN_SET_OPTIONS.TYPE);

export const selectRosterEquipmentSettingOptions = createLoadingSelector(TYPES.ROSTER_EQUIPMENT_SET_OPTIONS.TYPE);
export const selectRosterEquipmentSetOptionsSuccess = createSuccessSelector(TYPES.ROSTER_EQUIPMENT_SET_OPTIONS.TYPE);
export const selectRosterEquipmentSetOptionsError = createErrorSelector(TYPES.ROSTER_EQUIPMENT_SET_OPTIONS.TYPE);

export const selectRosterTransfer = createLoadingSelector(TYPES.ROSTER_TRANSFER.TYPE);
export const selectRosterTransferSuccess = createSuccessSelector(TYPES.ROSTER_TRANSFER.TYPE);
export const selectRosterTransferError = createErrorSelector(TYPES.ROSTER_TRANSFER.TYPE);

export const selectRosterRevert = createLoadingSelector(TYPES.ROSTER_REVERT.TYPE);
export const selectRosterRevertSuccess = createSuccessSelector(TYPES.ROSTER_REVERT.TYPE);
export const selectRosterRevertError = createErrorSelector(TYPES.ROSTER_REVERT.TYPE);

export const selectRosterSplit = createLoadingSelector(TYPES.ROSTER_SPLIT.TYPE);
export const selectRosterSplitSuccess = createSuccessSelector(TYPES.ROSTER_SPLIT.TYPE);
export const selectRosterSplitError = createErrorSelector(TYPES.ROSTER_SPLIT.TYPE);
