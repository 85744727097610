import API from 'src/api';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { SagaAction } from '../../types';
import { updateUserPassword } from '../actions';

function* updateUserPasswordSaga({ payload }: SagaAction<{ oldPassword: string; newPassword: string }>): SagaIterator {
  try {
    yield put(updateUserPassword.pending());
    yield call(API.updateUserPassword, payload);
    yield put(updateUserPassword.success());
  } catch (error) {
    yield put(updateUserPassword.failure(error));
  }
}

export default updateUserPasswordSaga;
