import { FormikProps } from 'formik';
import { Box, Grid } from '@mui/material';
import { FormikCalendar, FormikCheckbox, FormikInputString, FormikInputSelect } from 'src/components';
import config from 'src/config';
import { useTranslation } from 'react-i18next';
import { FormValues } from '../VehicleDialog';

type Props = {
  formik: FormikProps<FormValues>;
  hidden?: boolean;
};

function Maintenance({ formik, hidden = false }: Props) {
  const { t } = useTranslation();
  return (
    <Grid container sx={{ margin: '32px 0 16px 0', display: hidden ? 'none' : 'block' }}>
      <Grid item xs={12} sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
        <Box role="group" aria-labelledby="checkbox-group" sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <FormikCheckbox formik={formik} field="maintenanceFlag" label={t('rosterFleetPage.maintenanceFlag')} />
          <FormikCheckbox formik={formik} field="repairFlag" label={t('rosterFleetPage.repairNeeded')} />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormikInputSelect
            formik={formik}
            field="yardFK"
            label={t('rosterFleetPage.yardAssignment')}
            options={config.options.yards}
            disableClearable
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <FormikCalendar formik={formik} field="nextServiceDate" label={t('rosterFleetPage.nextServiceDate')} />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }} />
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormikInputString formik={formik} field="maintenanceNotes" label={t('rosterFleetPage.maintenanceNotes')} />
        </Box>
      </Grid>
    </Grid>
  );
}

export default Maintenance;
