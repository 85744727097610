import { RosterType, VehicleType } from 'src/types';
import { getUrl } from 'src/utils';
import axios from '../axios';
import routes from '../routes';

const vehicles = {
  // TODO: create param passing to BE for filtering, currently availability is ignored and
  //       BE returns everything and filtering occurs in loadAvailableVehiclesSaga.
  availableVehicles: (): Promise<Partial<RosterType>[]> => axios.get(`${routes.VEHICLES}?availability=true`),

  loadVehicles: (): Promise<Partial<VehicleType>[]> => axios.get(routes.VEHICLES),

  loadFilteredVehicles: ({
    assignedUuid,
    administrativeFlag,
    maintenanceFlag,
    availability,
  }: {
    assignedUuid?: string;
    administrativeFlag?: boolean;
    maintenanceFlag?: boolean;
    availability?: boolean;
  }): Promise<Partial<VehicleType>[]> => {
    const queryParams = new URLSearchParams();

    if (assignedUuid !== undefined) {
      queryParams.append('assignedUuid', assignedUuid);
    }
    if (administrativeFlag !== undefined) {
      queryParams.append('administrativeFlag', administrativeFlag.toString());
    }
    if (maintenanceFlag !== undefined) {
      queryParams.append('maintenanceFlag', maintenanceFlag.toString());
    }
    if (availability !== undefined) {
      queryParams.append('availability', availability.toString());
    }
    return axios.get(`${routes.VEHICLES}?${queryParams.toString()}`);
  },

  loadVehicle: ({ id = '' }: { id: string }): Promise<Partial<VehicleType>> => axios.get(getUrl(routes.VEHICLE, { id })),
  createVehicle: ({ ...vehicle }: Partial<VehicleType>): Promise<Partial<VehicleType>> => {
    const { id, ...v } = vehicle;
    vehicle.id = id;
    return axios.post(routes.VEHICLES, v);
  },
  saveVehicle: ({ ...vehicle }: Partial<VehicleType>): Promise<Partial<VehicleType>> => {
    const { id, ...v } = vehicle;
    return axios.patch(getUrl(routes.VEHICLE, { id }), v);
  },
  deleteVehicle: ({ id }: { id: number }): Promise<Partial<VehicleType>> => {
    return axios.delete(getUrl(routes.VEHICLE, { id }));
  },
};

export default vehicles;
