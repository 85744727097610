import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';

import { SagaAction } from '../../types';
import { blockUser } from '../actions';

type UserPayloadType = {
  uuid: string;
};

function* blockUserSaga({ payload: { uuid } }: SagaAction<UserPayloadType>): SagaIterator {
  try {
    yield put(blockUser.pending());
    yield call(API.blockUser, uuid);
    yield put(blockUser.success());
  } catch (e) {
    yield put(blockUser.failure());
  }
}

export default blockUserSaga;
