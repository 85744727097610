import { Box } from '@mui/material';
import { P1R } from 'src/components';
import { themeColors } from 'src/theme';

type Props = {
  text: string;
  type: 'green' | 'red' | 'yellow' | 'gray';
};

const labelStyle = {
  green: {
    textColor: themeColors.green,
    labelColor: themeColors.greenLight,
  },
  red: {
    textColor: themeColors.red,
    labelColor: themeColors.redLight,
  },
  yellow: {
    textColor: themeColors.yellowMain,
    labelColor: themeColors.lightYellow,
  },
  gray: {
    textColor: '#9f9e9e',
    labelColor: themeColors.grayLight,
  },
};

const Label = ({ text, type }: Props) => (
  <Box sx={{ m: 0.5, marginLeft: 0, padding: '4px 10px', background: labelStyle[type].labelColor, borderRadius: '4px', whiteSpace: 'nowrap' }}>
    <P1R sx={{ color: labelStyle[type].textColor, fontSize: '12px' }}>{text}</P1R>
  </Box>
);

export default Label;
