import { combineReducers } from 'redux';
import incident from './incident';

const incidentData = combineReducers({
  incident,
});

export * from './incident';

export default incidentData;
