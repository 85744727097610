import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, FormHelperText, InputAdornment, ThemeProvider, createTheme, useMediaQuery } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePickerProps } from '@mui/x-date-pickers/DatePicker/DatePicker.types';
import { theme, themeColors } from 'src/theme';
import EventIcon from '@mui/icons-material/Event';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import StyledCalendar from './styled';
import { P2R } from '../Typography';

dayjs.extend(utc);
dayjs.extend(timezone);

const addLocalTimezoneOffset = (unixTimestamp: number): number => {
  const date = dayjs.unix(unixTimestamp);

  const localTimezoneOffset = date.utcOffset();
  const adjustedDate = date.add(localTimezoneOffset, 'minute');
  const adjustedTimestamp = adjustedDate.unix();

  return adjustedTimestamp;
};

type OmitOnChange<T> = Omit<T, 'onChange'>;

type Props = {
  disabled?: boolean;
  disablePast?: boolean;
  label?: string;
  name?: string;
  onChange?: (name: string, value: number) => Promise<void>;
  fullWidth?: boolean;
  value?: number & Dayjs;
  error?: boolean;
  isOptional?: boolean;
  disableDefaultDate?: boolean;
  containerSx?: object;
  helperText?: React.ReactNode;
} & OmitOnChange<DatePickerProps<Dayjs>>;

/* eslint-disable react/prop-types */
const Calendar: React.FC<Props> = ({
  label,
  name,
  disabled,
  value = 0,
  fullWidth = true,
  onChange = () => undefined,
  error,
  isOptional,
  disableDefaultDate,
  containerSx = {},
  helperText,
  ...props
}): JSX.Element => {
  const [curDate, setCurDate] = useState<Dayjs>(dayjs());

  useEffect(() => {
    if (value) {
      setCurDate(dayjs(value * 1000));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (newDate: Dayjs) => {
    setCurDate(newDate);
    if (onChange && name) {
      onChange(name, addLocalTimezoneOffset(Math.floor(newDate.valueOf() / 1000)));
    }
  };

  return (
    <ThemeProvider theme={createTheme(theme)}>
      <Box sx={{ pb: 3, ...containerSx }}>
        <P2R sx={{ color: error ? themeColors.red : themeColors.black }}>
          {label}
          {isOptional ? <P2R sx={{ color: themeColors.grayDark }}> (Optional)</P2R> : null}
        </P2R>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StyledCalendar
            {...props}
            value={value ? dayjs(value * 1000) : disableDefaultDate ? null : curDate}
            onChange={(newDate) => {
              handleChange(newDate as Dayjs);
            }}
            disabled={disabled}
            fullWidth={fullWidth}
            name={name}
            format="DD MMM YYYY"
            slotProps={
              // Checking if user is using touch device
              useMediaQuery('(hover: none)')
                ? {
                    textField: {
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <EventIcon />
                          </InputAdornment>
                        ),
                      },
                    },
                  }
                : undefined
            }
            sx={{
              backgroundColor: themeColors.white,
              '& .MuiInputBase-root': { height: 53 },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: error ? themeColors.red : null },
                '&:hover fieldset': {
                  borderColor: error ? themeColors.red : null,
                },
                '&.Mui-focused fieldset': {
                  borderColor: error ? themeColors.red : null,
                },
              },
            }}
          />
        </LocalizationProvider>
        {error && <FormHelperText style={{ color: themeColors.red }}>{helperText}</FormHelperText>}
      </Box>
    </ThemeProvider>
  );
};

export default Calendar;
