import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';

import { SagaAction } from '../../types';
import { createUser } from '../actions';

type UserPayloadType = {
  role: string;
};

function* setUserCurrentRoleSaga({ payload: { role } }: SagaAction<UserPayloadType>): SagaIterator {
  try {
    yield put(createUser.pending());
    yield call(API.setUserCurrentRole, role);
    window.location.reload();
    yield put(createUser.success());
  } catch (e) {
    yield put(createUser.failure());
  }
}

export default setUserCurrentRoleSaga;
