import { FC } from 'react';

import { VehicleType } from 'src/types';
import { Table } from 'src/components';
import { TableProps } from 'src/components/Table';

import {
  percentCol,
  getGridColumnDef,
  getGridColumGroupingModelDef,
  ReadinessReportRowType,
  defaultReadinessReportRow,
  processVehicle,
} from './utils';

type ReportRowType = {
  bucketsTarget: number;
  diggersTarget: number;
  pickupsTarget: number;
  trailersTarget: number;
  otherTarget: number;
} & ReadinessReportRowType;

const defaultRow: ReportRowType = {
  ...defaultReadinessReportRow,
  bucketsTarget: 0.8,
  diggersTarget: 0.8,
  pickupsTarget: 0.8,
  trailersTarget: 0.8,
  otherTarget: 0.8,
};

type FleetObjectivesTableProps = {
  fleet: VehicleType[];
} & Partial<TableProps>;

const FleetObjectivesTable: FC<FleetObjectivesTableProps> = ({ fleet, ...props }): JSX.Element => {
  // Configure the columns
  const columnFields: string[] = ['buckets', 'diggers', 'pickups', 'trailers', 'other'];
  const columnAttributes = [
    { id: 'Target', type: percentCol },
    { id: 'Readiness', type: percentCol },
  ];
  const columns = getGridColumnDef('fleetObjectivesPage', columnFields, columnAttributes);
  const columnGroupingModel = getGridColumGroupingModelDef('fleetObjectivesPage', columnFields, columnAttributes);

  // Setup our rows for the DataGrid
  const rows: ReportRowType[] = [];
  const total: ReportRowType = { ...defaultRow };
  fleet.forEach((item: VehicleType) => {
    let currRow = rows.find((yard) => yard.yardFK === item.yardFK);
    if (!currRow) {
      const len = rows.push({
        ...defaultRow,
        yardFK: item.yardFK,
      });
      currRow = rows[len - 1];
    }
    processVehicle(currRow, total, item);
  });
  rows.push(total);

  // We don't want user to overide key props, so add after
  return (
    <Table
      {...props}
      type="advanced"
      columns={columns}
      rows={rows}
      getRowId={(row) => row.yardFK}
      experimentalFeatures={{ columnGrouping: true }}
      columnGroupingModel={columnGroupingModel}
      slots={{ toolbar: undefined }}
    />
  );
};

export default FleetObjectivesTable;
