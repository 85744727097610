import { VehicleType } from 'src/types';
import { createReducer } from '../../utils/toolkit';

const initialState: Partial<VehicleType[]> = [];

const {
  reducer,
  actions: { set: setVehicles, merge: mergeVehicles, reset: resetVehicles },
} = createReducer('vehicles', initialState);

export default reducer;
export { setVehicles, mergeVehicles, resetVehicles };
