import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { clearmeVerify } from '../actions';

function* clearmeVerifySaga(): SagaIterator {
  try {
    yield put(clearmeVerify.pending());
    yield call(API.clearmeVerify);
    // Technical Debt: send request loadUser
    // const { payload } = yield call(API.clearmeVerify);
    // yield put(mergeUser({payload.clearStatus}));
    yield put(clearmeVerify.success());
  } catch (e) {
    yield put(clearmeVerify.failure(e));
  }
}

export default clearmeVerifySaga;
