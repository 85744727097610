import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { H1, H2, H3, P1R, P2, P3, Button, TextLink } from 'src/components';
import { PATHS } from 'src/navigation';
import { DashboardWrapper } from '../wrappers';

export default function Dashboard() {
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(PATHS.INCIDENTS_PAGE);
  }, [navigate]);

  return (
    <DashboardWrapper>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]),
          flexGrow: 1,
          // height: '100vh',
          overflow: 'auto',
        }}
      >
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <H3>Dashboard</H3>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <H1>Typography H1</H1>
            </Grid>
            <Grid item xs={12}>
              <H2>Typography H2</H2>
            </Grid>
            <Grid item xs={12}>
              <H3>Typography H3</H3>
            </Grid>
            <Grid item xs={12}>
              <P1R>Typography P1R</P1R>
            </Grid>
            <Grid item xs={12}>
              <P2>Typography P2</P2>
            </Grid>
            <Grid item xs={12}>
              <P3>Typography P3</P3>
            </Grid>
            <Grid item xs={12}>
              <Button>Typography Button</Button>
            </Grid>
            <Grid item xs={12}>
              <TextLink>Typography Link - TextLink</TextLink>
            </Grid>
            <Grid item md={12} style={{ paddingRight: '50px' }}>
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => 0}>
                Create account
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </DashboardWrapper>
  );
}
