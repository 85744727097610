import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { UserStatus } from 'src/shared/enums/userStatus.enum';
import { SagaAction } from '../../types';
import { setUserStatus } from '../actions';

type UserPayloadType = {
  uuid: string;
  status: UserStatus;
};

function* setUserStatusSaga({ payload: { uuid, status } }: SagaAction<UserPayloadType>): SagaIterator {
  try {
    yield put(setUserStatus.pending());
    yield call(API.setUserStatus, uuid, status);
    yield put(setUserStatus.success());
  } catch (e) {
    yield put(setUserStatus.failure());
  }
}

export default setUserStatusSaga;
