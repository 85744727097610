import React from 'react';
import { TableProps, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { themeColors } from 'src/theme';

export type TableBasicProps = {
  columns: GridColDef[];
  rows: any[];
  displayHeader?: boolean;
  selectedRowId?: number;
  onRowClick?: (row: any) => void;
} & TableProps;

const StyledTable = styled(Table)({
  border: 'none',
  fontSize: '14px',
  '& .MuiTableCell-root': {
    height: '56px',
    fontSize: '14px',
    padding: '0 16px',
  },
  '& .MuiTableCell-head': {
    border: 'none',
    backgroundColor: themeColors.grayLight,
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: 600,
    height: '42px',
  },
  '& .Mui-selected': {
    backgroundColor: themeColors.grayLight,
    '&:hover': {
      backgroundColor: themeColors.grayLight,
    },
  },
});

const TableBasic: React.FC<TableBasicProps> = ({ columns, rows, displayHeader = true, selectedRowId = null, ...props }): JSX.Element => {
  const onRowClick = (row: any) => {
    if (props.onRowClick) {
      props.onRowClick(row);
    }
  };

  return (
    <StyledTable>
      {displayHeader ? (
        <TableHead>
          <TableRow>
            {columns.map((column: any) => (
              <TableCell key={column.field} width={column.width}>
                {column.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      ) : null}
      <TableBody>
        {rows.map((row: any, index: number) => (
          <TableRow key={index} onClick={() => onRowClick(row)} selected={selectedRowId === row.id}>
            {columns.map((column: any) => (
              <TableCell key={column.field}>{typeof row[column.field] === 'function' ? row[column.field]() : row[column.field]}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </StyledTable>
  );
};

export default TableBasic;
