import { Box, Button } from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';
import SaveIcon from '@mui/icons-material/Save';
import { styled } from '@mui/material/styles';
import { Portal } from '@mui/base';
import {
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { themeColors } from 'src/theme';
import { LoadingButton } from '@mui/lab';

export type ToolbarProps = {
  // specifying id is needed in case we want to use more than one table per page with toolbar
  id?: string;
  options?: ToolbarOptions;
  hasUnsavedRows?: boolean;
  isSaving?: boolean;
  saveChanges?: any;
  discardChanges?: any;
};

type ToolbarOptions =
  | 'all'
  | 'mini'
  | 'save-discard'
  | {
      quickFilter?: boolean;
      exportBtn?: boolean;
      densityBtn?: boolean;
      columnsBtn?: boolean;
      filterBtn?: boolean;
      saveBtn?: boolean;
      discardBtn?: boolean;
    };

const StyledToolbarButton = styled(Box)({
  border: `1px solid ${themeColors.grayMedium}`,
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  '& .MuiButtonBase-root': {
    height: '48px',
    width: '48px',
    minWidth: '48px',
  },
  '& .MuiButton-startIcon': {
    margin: 0,
  },
});

const StyledQuickFilter = styled(Box)({
  height: '48px',
  maxWidth: '413px',
  width: '100%',
  backgroundColor: themeColors.grayLight,
  borderRadius: '4px',
  '& .MuiFormControl-root': {
    width: '100%',
  },
  '& .MuiInputBase-root': {
    borderWidth: '0',
    height: '48px',
    '& fieldset': {
      borderWidth: '0',
    },
    '&.MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiSvgIcon-root': {
      margin: '0 8px',
    },
    '& .MuiButtonBase-root': {
      width: '30px',
    },
  },
});

const getOptionsToShow = (options: ToolbarOptions) => {
  const mini = { quickFilter: true, exportBtn: true, densityBtn: true, columnsBtn: true, filterBtn: true };
  const saveDiscard = { saveBtn: true, discardBtn: true };
  const all = { ...mini, ...saveDiscard };
  const optionsSelect = {
    mini,
    'save-discard': saveDiscard,
    all,
  };
  return typeof options === 'string' ? { ...optionsSelect[options] } : { ...options };
};

export function Toolbar({ id = '', options = 'all', ...props }: ToolbarProps) {
  const showOptions = getOptionsToShow(options);

  return (
    <Box
      sx={{
        width: '100%',
        gap: 1,
        display: 'flex',
        justifyContent: 'end',
        maxHeight: '48px',
      }}
    >
      {showOptions.quickFilter && <StyledQuickFilter id={`quick-filter_field-${id}`} />}
      {showOptions.exportBtn && <StyledToolbarButton id={`export_btn-${id}`} />}
      {showOptions.densityBtn && <StyledToolbarButton id={`density-selector_btn-${id}`} />}
      {showOptions.columnsBtn && <StyledToolbarButton id={`columns_btn-${id}`} />}
      {showOptions.filterBtn && <StyledToolbarButton id={`filter_btn-${id}'`} />}
      {showOptions.saveBtn && <StyledToolbarButton id={`save_btn-${id}`} />}
      {showOptions.discardBtn && <StyledToolbarButton id={`discard_btn-${id}`} />}
    </Box>
  );
}

export function ToolbarAdapter({ id = '', options = 'all', ...props }: ToolbarProps) {
  const showOptions = getOptionsToShow(options);

  return (
    <>
      {showOptions.quickFilter && (
        <Portal container={() => document.getElementById(`quick-filter_field-${id}`)!}>
          <GridToolbarQuickFilter />
        </Portal>
      )}
      {showOptions.exportBtn && (
        <Portal container={() => document.getElementById(`export_btn-${id}`)!}>
          <GridToolbarExport />
        </Portal>
      )}
      {showOptions.densityBtn && (
        <Portal container={() => document.getElementById(`density-selector_btn-${id}`)!}>
          <GridToolbarDensitySelector />
        </Portal>
      )}
      {showOptions.columnsBtn && (
        <Portal container={() => document.getElementById(`columns_btn-${id}`)!}>
          <GridToolbarColumnsButton />
        </Portal>
      )}
      {showOptions.filterBtn && (
        <Portal container={() => document.getElementById(`filter_btn-${id}'`)!}>
          <GridToolbarFilterButton
            componentsProps={{
              button: {
                startIcon: <FilterAltIcon />,
              },
            }}
          />
        </Portal>
      )}
      {showOptions.saveBtn && (
        <Portal container={() => document.getElementById(`save_btn-${id}`)!}>
          <LoadingButton
            startIcon={<SaveIcon sx={{ color: props.hasUnsavedRows ? 'green' : 'black' }} />}
            disabled={!props.hasUnsavedRows}
            loading={props.isSaving}
            onClick={props.saveChanges}
          />
        </Portal>
      )}
      {showOptions.discardBtn && (
        <Portal container={() => document.getElementById(`discard_btn-${id}`)!}>
          <Button
            startIcon={<RestoreIcon sx={{ color: props.hasUnsavedRows ? 'red' : 'black' }} />}
            disabled={!props.hasUnsavedRows || props.isSaving}
            onClick={props.discardChanges}
          />
        </Portal>
      )}
    </>
  );
}
