import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';

import { SagaAction } from '../../types';
import { favoriteUser } from '../actions';

type UserPayloadType = {
  favoriteUuid: string;
  action: string;
};

function* favoriteUserSaga({ payload: { favoriteUuid, action } }: SagaAction<UserPayloadType>): SagaIterator {
  try {
    yield put(favoriteUser.pending());
    yield call(API.favoriteUser, { favoriteUuid, action });
    yield put(favoriteUser.success());
  } catch (e) {
    yield put(favoriteUser.failure());
  }
}

export default favoriteUserSaga;
