import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { SagaAction } from 'src/redux/types';
import { setIncidents } from '../reducers';
import { loadIncidents } from '../actions';

const incidentsDefaultQuery: { [key: string]: boolean } = {
  ownedBy: true,
  createdBy: true,
  classifications: true,
  fleet: true,
  rosters: true,
  assignedTo: true,
  utility: true,
  utilityContractor: true,
  rostersOwnedBy: true,
  openOnly: false,
};

function* loadIncidentsSaga({ payload: { query = null } }: SagaAction<{ query?: { [key: string]: boolean } | null }>): SagaIterator {
  try {
    yield put(loadIncidents.pending());

    const queryOptions = { ...incidentsDefaultQuery, ...query };
    const queryString = Object.keys(queryOptions)
      .map((key) => `${key}=${queryOptions[key]}`)
      .join('&');

    const { payload } = yield call(API.loadIncidents, { query: queryString });

    yield put(setIncidents(payload));
    yield put(loadIncidents.success());
  } catch (e) {
    yield put(loadIncidents.failure(e));
  }
}

export default loadIncidentsSaga;
