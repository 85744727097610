import { createReduxAsyncAction } from '../utils/toolkit';
import { TYPES } from './constants';

export const createRoles = createReduxAsyncAction(TYPES.CREATE_ROLES);
export const loadRoles = createReduxAsyncAction(TYPES.LOAD_ROLES);
export const loadFilteredRoles = createReduxAsyncAction(TYPES.LOAD_FILTERED_ROLES);
export const updateRoles = createReduxAsyncAction(TYPES.UPDATE_ROLES);
export const deleteRoles = createReduxAsyncAction(TYPES.DELETE_ROLES);

export const loadPermissions = createReduxAsyncAction(TYPES.LOAD_PERMISSIONS);
