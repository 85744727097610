import React, { useState } from 'react';
import { Box, TextField, TextFieldProps, InputAdornment, IconButton } from '@mui/material';
import { themeColors } from 'src/theme';
import { P2R } from '../Typography';
import Icon from '../Icon';

type Props = {
  default?: boolean;
  error?: boolean;
  label?: string;
  value?: string;
  disabled?: boolean;
} & TextFieldProps;

const Password: React.FC<Props> = ({ error, label, disabled, value, ...props }): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box>
      <P2R sx={{ color: error ? themeColors.red : themeColors.black }}>{label}</P2R>
      <TextField
        {...props}
        error={error}
        placeholder={label}
        disabled={disabled}
        defaultValue={value}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleTogglePassword} sx={{ margin: '0 -5px 0 0' }}>
                {showPassword ? <Icon name="visibility" /> : <Icon name="visibilityOff" />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          '& .MuiInputBase-input[type="password"]': {
            letterSpacing: '3px',
          },
          '& input::placeholder': {
            letterSpacing: '0px',
          },
          '& .MuiFormHelperText-root': { marginLeft: '0px' },
        }}
        fullWidth
      />
    </Box>
  );
};

export default Password;
