import React from 'react';
import { FormikProps } from 'formik';
import Calendar from '../Calendar';

type FormikCalendarProps = {
  formik: FormikProps<any>;
  field: string;
  label: string;
  disabled?: boolean;
  isOptional?: boolean;
  errorHelper?: Record<string, string[]>;
};

const FormikCalendar: React.FC<FormikCalendarProps> = ({ formik, field, errorHelper, ...props }): JSX.Element => (
  <Calendar
    name={field}
    disableDefaultDate
    value={formik.values[field] ?? null}
    // @ts-ignore
    onChange={(name: string, value: number) => formik.setFieldValue(name, Number.isNaN(value) ? null : value)}
    // @ts-ignore
    error={(formik.touched[field] && Boolean(formik.errors[field])) || (errorHelper && !!errorHelper[field])}
    // @ts-ignore
    helperText={(formik.touched[field] && formik.errors[field]) || (errorHelper && errorHelper[field])}
    {...props}
  />
);

export default FormikCalendar;
