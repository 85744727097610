import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { CssBaseline, Drawer, Box, Toolbar, List, Grid, IconButton, Container } from '@mui/material';
import { logout, setUserCurrentRole, loadUser } from 'src/redux/user/actions';
import { selectUser } from 'src/redux/user/selectors';
import { Icon, Logo } from 'src/components';
import { PATHS } from 'src/navigation';
import { themeColors } from 'src/theme';
import config from 'src/config';
import Breadcrumb from 'src/components/Breadcrumb';
import { MainListItems, SecondaryListItems } from './listItems';

const drawerWidth = 250;

const MuiDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  height: 'inherit',
  '& .MuiDrawer-paper': {
    border: 'none',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: 104,
      },
    }),
  },
}));

type DashboardProps = {
  children: React.ReactNode;
  navigationChildren?: React.ReactNode;
  breadcrumbs?: [string, string?][];
};

// eslint-disable-next-line react/prop-types
const DashboardWrapper: React.FC<DashboardProps> = ({ children, navigationChildren, breadcrumbs }): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [open, setOpen] = useState(true);
  const [openHamburger, setOpenHamburger] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const toggleHamburgerDrawer = (isOpen: boolean) => {
    setOpenHamburger(isOpen);
  };

  const handleLogout = () => {
    dispatch(logout.init());
  };

  const changeRole = (role: string) => {
    dispatch(setUserCurrentRole.init({ role }));
    navigate(PATHS.MY_PROFILE_PAGE);
  };

  useEffect(() => {
    dispatch(loadUser.init());
  }, [dispatch]);

  useEffect(() => {
    // @ts-ignore
    if (!user?.uuid || user?.step > 1) {
      // Skip
    } else if (!user.emailConfirmed) {
      navigate(PATHS.CREATE_ACCOUNT_VERIFY_EMAIL_PAGE);
    }
  }, [dispatch, navigate, user]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box
        sx={{
          height: '100vh',
          width: open ? 256 : 114,
          position: 'relative',
          display: { xs: 'none', md: 'none', lg: 'block' },
        }}
      >
        <IconButton
          onClick={toggleDrawer}
          sx={{
            width: '32px',
            height: '32px',
            border: `1px solid ${themeColors.grayMedium}`,
            backgroundColor: themeColors.white,
            position: 'absolute',
            right: open ? '-14px' : '-12px',
            top: '16px',
            zIndex: 1300,
            '&:hover': {
              backgroundColor: themeColors.white,
            },
          }}
        >
          {open ? <Icon name="minimizeArrow" /> : <Icon name="maximizeArrow" />}
        </IconButton>
        <MuiDrawer variant="permanent" open={open} sx={{ display: { xs: 'none', md: 'block' } }}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              backgroundColor: themeColors.grayLight,
              px: [1],
            }}
          >
            <Logo isMinimised={!open} sx={{ margin: '10px auto 0' }} title={config.getVersion()} onClick={() => navigate(PATHS.MY_PROFILE_PAGE)} />
          </Toolbar>
          <List component="nav" sx={{ display: 'contents' }}>
            {MainListItems(open, user)}
            {SecondaryListItems(user, handleLogout, changeRole, open)}
          </List>
        </MuiDrawer>
      </Box>

      <Drawer anchor="right" open={openHamburger} onClose={() => setOpenHamburger(false)}>
        <Toolbar
          sx={{
            width: 340,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            backgroundColor: themeColors.grayLight,
            px: [1],
          }}
        >
          {open && <Logo sx={{ margin: '10px auto 0' }} title={config.getVersion()} onClick={() => navigate(PATHS.MY_PROFILE_PAGE)} />}
          <IconButton onClick={() => setOpenHamburger(false)}>
            <Icon name="close" />
          </IconButton>
        </Toolbar>
        <List component="nav" sx={{ display: 'contents' }}>
          {MainListItems(open, user)}
          {SecondaryListItems(user, handleLogout, changeRole, open)}
        </List>
      </Drawer>
      <Container
        maxWidth="xl"
        sx={{
          pt: 2,
          pb: 4,
          height: '100vh',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              gap: 3,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ whiteSpace: 'nowrap' }}>
              <Breadcrumb breadcrumbs={breadcrumbs} navigate={navigate} />
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
              {navigationChildren}
              <Box sx={{ display: { md: 'block', lg: 'none' } }} onClick={() => toggleHamburgerDrawer(true)}>
                <Icon name="hamburger" />
              </Box>
            </Box>
          </Grid>
        </Grid>
        {children}
      </Container>
    </Box>
  );
};

export default DashboardWrapper;
