import Hotjar from '@hotjar/browser';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import axios from 'axios';
import ROUTES from 'src/api/routes';
import { Config } from 'src/types';

type ServiceType = {
  name: string;
  status: string;
  value: string;
  test: () => void;
  run: () => void;
};

type S360Type = {
  config: Config;
  service: ServiceType;
};

const inetTest = async (s360: S360Type) => {
  // @ts-ignore
  const { config, inet: service } = s360 || window.s360;
  const startTime = performance.now();

  try {
    const response = await fetch(config.URL);
    const endTime = performance.now();

    if (response.ok) {
      service.status = 'online';
      service.value = `${config.URL}: ${(endTime - startTime).toFixed(2)} ms`;
    } else {
      service.status = 'offline';
      service.value = `${config.URL}: ${response.status}`;
    }
  } catch (error) {
    service.status = 'error';
    service.value = `${config.URL}: ${error}`;
    console.error(`Error connecting to ${config.URL}:`, error);
  }
};

const dnsPing = (s360: S360Type) => {
  // @ts-ignore
  const { dns: service } = s360 || window.s360;
  const performanceData = window.performance.getEntriesByType('navigation')[0];
  // @ts-ignore
  const dnsTime = performanceData.domainLookupEnd - performanceData.domainLookupStart;
  service.status = 'online';
  service.value = `DNS delay: ${dnsTime.toFixed(2)} ms`;
};

const storageTest = (s360: S360Type) => {
  // @ts-ignore
  const { storage: service } = s360 || window.s360;
  const keys = Object.keys(localStorage);

  service.status = 'online';
  service.value = `Storage(${keys.length}): ${keys}`;
};

const apiTest = async (s360: S360Type) => {
  // @ts-ignore
  const { api: service, config } = s360 || window.s360;

  try {
    const { data } = await axios(`${s360.config.API}${ROUTES.HEALTH}`);
    const { details } = data.payload;

    service.status = 'online';
    service.value = `API(${details?.http?.status}), DB(${details?.database?.status}), elastic(${details?.elastic?.status})`;
  } catch (error) {
    service.status = 'error';
    service.value = `API, DB, elastic no answer: ${config.URL}`;
    console.error(`Error connecting to ${config.API}:`, error);
  }
};

const hotJarRun = (s360: S360Type) => {
  // @ts-ignore
  const { config, hotJar: service } = s360 || window.s360;
  if (config.HOTJAR_ENABLED) {
    Hotjar.init(config.HOTJAR_SITE_ID, config.HOTJAR_VERSION);
    service.status = 'run';
  }
};

const logRocketRun = (s360: S360Type) => {
  // @ts-ignore
  const { config, hotJar: service } = s360 || window.s360;
  if (config.LOGROCKET_ENABLED) {
    LogRocket.init(config.LOGROCKET_APP_ID);
    setupLogRocketReact(LogRocket);
    service.status = 'run';
  }
};

const switcher = (name: string, mode = false) => {
  localStorage.setItem(name, mode ? 'true' : 'false');
  window.location.reload();
};

export const s360 = {
  config: {} as Config,
  clearme: {} as ServiceType,
  hotJar: {} as ServiceType,
  logrocket: {} as ServiceType,

  init: (config: Config) => {
    // @ts-ignore
    window.s360 = s360;
    s360.config = config;

    s360.services.forEach((service) => {
      // @ts-ignore
      s360[service.name] = service;
    });

    // Auto run
    if (config.HOTJAR_ENABLED) {
      s360.hotJar.run();
    }

    if (config.LOGROCKET_ENABLED) {
      s360.logrocket.run();
    }

    s360.status(true);
  },

  status: async (silent = false) => {
    s360.services.forEach((service) => {
      // @ts-ignore
      service.test && service.test(s360);
    });

    !silent && console.table(s360.services);
  },

  clean: () => {
    localStorage.clear();
    window.location.reload();
  },

  reload: () => {
    window.location.reload();
  },

  services: [
    // name, test(), run(), enable(), disable()
    ['inet', inetTest],
    ['dns', dnsPing],
    ['storage', storageTest],
    ['api', apiTest],
    ['clearme'],
    ['hotJar', , hotJarRun, () => switcher('HOTJAR_ENABLED', true), () => switcher('HOTJAR_ENABLED')],
    ['logrocket', , logRocketRun, () => switcher('LOGROCKET_ENABLED', true), () => switcher('LOGROCKET_ENABLED')],
    // 'Google Analytics'
  ].map((service) => ({
    name: service[0],
    status: 'offline',
    value: '',
    test: service[1],
    run: service[2],
    enable: service[3],
    disable: service[4],
  })),
};
