export type DataCSVType = (String | Number)[][];
export type ParamsCSVType = {
  separator?: string;
};

export const generateCSV = (table: DataCSVType, params: ParamsCSVType = {}): string => {
  const { separator = ',' } = params;
  const convertToCSV = (data: DataCSVType) => data.map((row) => row.join(separator)).join('\n');

  const csvData = convertToCSV(table);
  const blob = new Blob([csvData], { type: 'text/csv' });
  const href = URL.createObjectURL(blob);

  return href;
};
