import React, { SyntheticEvent } from 'react';
import { Autocomplete, Box, Chip, MenuItem } from '@mui/material';
import { themeColors } from 'src/theme';
import { ListType } from 'src/types';
import ClearIcon from '@mui/icons-material/Clear';
import { P1R, P3 } from '../Typography';
import Input from '../Input';
import Icon from '../Icon';

type Props = {
  name?: string;
  label?: string;
  placeholder?: string;
  isOptional?: boolean;
  options: ListType[];
  defaultValue?: ListType[];
  disabled?: boolean;
  disableClearable?: boolean;
  renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: ListType) => React.ReactNode;
  getOptionLabel?: (option: ListType) => string;
  getValue: () => Array<any>;
  isChipDisabled?: (option: ListType) => boolean;
  onChange?: (event: SyntheticEvent, value: ListType[]) => void;
  error?: boolean;
  helperText?: React.ReactNode;
  sx?: object;
  readOnly?: boolean;
};

const InputSelectMulti: React.FC<Props> = ({
  name,
  label,
  placeholder,
  options,
  isOptional,
  renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: ListType) => (
    <MenuItem {...props} key={option.key} sx={{ minHeight: '50px' }}>
      <P1R sx={{ whiteSpace: 'normal' }}>{option.value}</P1R>
    </MenuItem>
  ),
  // ROLLED BACK CHANGES #ERMA-1255
  /* renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: ListType, { selected }) => (
    <MenuItem {...props} sx={{ height: '50px' }}>
      <Checkbox
        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        style={{ marginRight: 8 }}
        checked={selected}
      />
      <P1R sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{option.value}</P1R>
    </MenuItem>
  ), */
  getOptionLabel = (option: ListType) => option.value,
  getValue,
  isChipDisabled = () => false,
  error,
  helperText,
  ...props
}): JSX.Element => (
  <Autocomplete
    multiple
    disableCloseOnSelect
    options={options}
    value={options.filter((option) => getValue().find((item) => item === option.key))}
    isOptionEqualToValue={(option, value) => option?.key === value?.key}
    getOptionLabel={getOptionLabel}
    renderOption={renderOption}
    sx={{
      '&>.MuiBox-root': {
        paddingBottom: 0,
      },
    }}
    renderInput={(params) => (
      <Input
        {...params}
        name={name}
        label={label}
        error={error}
        isOptional={isOptional}
        helperText={helperText}
        placeholder={placeholder || 'Select'}
        sx={{
          backgroundColor: 'transparent',
          '& .MuiInputBase-root': {
            minHeight: 53,
            padding: '5px 10px',
          },
          '& .MuiInputBase-input': {
            display: props.disabled || props.readOnly ? 'none' : 'block',
          },
          '& .MuiAutocomplete-popupIndicator': {
            marginRight: '10px',
            backgroundColor: error ? themeColors.redError : themeColors.grayLight,
          },
        }}
      />
    )}
    popupIcon={
      props.disabled || props.readOnly ? (
        ''
      ) : (
        <Box
          sx={{
            width: '18px',
            height: '18px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon name="popupIcon" />
        </Box>
      )
    }
    renderTags={(tagValue, getTagProps) =>
      tagValue.map((item, index) => (
        <Chip
          {...getTagProps({ index })}
          key={item.key}
          label={<P3 sx={{ display: 'flex' }}>{item.value}</P3>}
          disabled={isChipDisabled(item)}
          deleteIcon={isChipDisabled(item) ? <span /> : <ClearIcon />}
          sx={{
            height: '100%',
            color: themeColors.black,
            borderRadius: '4px',
            backgroundColor: themeColors.grayLight,
            '& .MuiChip-label .MuiTypography-root': {
              fontSize: '14px',
              whiteSpace: 'normal',
            },
            '&.Mui-disabled': {
              opacity: 1,
            },
            '& .MuiChip-deleteIcon': {
              width: '12px',
              height: '12px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: themeColors.black,
            },
          }}
        />
      ))
    }
    filterSelectedOptions
    autoComplete
    fullWidth
    {...props}
  />
);

export default InputSelectMulti;
