import { takeLatest } from 'redux-saga/effects';
import loadVehiclesSaga from './loadVehiclesSaga';
import loadAvailableVehicles from './loadAvailableVehiclesSaga';
import loadFilteredVehicles from './loadFilteredVehiclesSaga';
import { TYPES } from '../constants';

export default function* vehiclesSagas(): Generator {
  yield takeLatest(TYPES.LOAD_VEHICLES.INIT, loadVehiclesSaga);
  yield takeLatest(TYPES.LOAD_AVAILABLE_VEHICLES.INIT, loadAvailableVehicles);
  yield takeLatest(TYPES.LOAD_FILTERED_VEHICLES.INIT, loadFilteredVehicles);
}
