import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { SagaAction } from '../../types';
import { setIncidents } from '../reducers';
import { loadFilteredIncidents } from '../actions';

function* loadFilteredIncidentsSaga({ payload: { uuid = '', filter = '' } }: SagaAction<Partial<{ uuid: string; filter: string }>>): SagaIterator {
  try {
    yield put(loadFilteredIncidents.pending());
    const { payload } = yield call(API.loadFilteredIncidents, { uuid, filter });

    yield put(setIncidents(payload));
    yield put(loadFilteredIncidents.success());
  } catch (e) {
    yield put(loadFilteredIncidents.failure());
  }
}

export default loadFilteredIncidentsSaga;
