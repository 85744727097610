import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { IncidentType } from 'src/types';
import { deleteVehicle } from '../actions';
import { SagaAction } from '../../types';
import loadVehiclesSaga from '../../vehicles/sagas/loadVehiclesSaga';

function* deleveVehicleSaga({ payload: vehicle }: SagaAction<Partial<IncidentType>>): SagaIterator {
  try {
    yield put(deleteVehicle.pending());
    yield call(API.deleteVehicle, { id: vehicle.id || 0 });

    // @ts-ignore
    yield call(loadVehiclesSaga);

    yield put(deleteVehicle.success());
  } catch (e) {
    yield put(deleteVehicle.failure(e));
  }
}

export default deleveVehicleSaga;
