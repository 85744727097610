import { combineReducers } from 'redux';
import roster from './roster';

const rosterData = combineReducers({
  roster,
});

export * from './roster';

export default rosterData;
