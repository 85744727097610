import { StatusActionState, ReduxAction } from '../types';

const successReducer = (state: StatusActionState = {}, action: ReduxAction): StatusActionState => {
  const { type } = action;
  const matches = /(.*)_(PENDING|SUCCESS|FAILURE|RESET)/.exec(type);

  // not a *_PENDING / *_SUCCESS / *_FAILURE actions, so we ignore them
  if (!matches) {
    return state;
  }

  const [, requestName, requestState] = matches;
  return {
    ...state,
    [requestName]: requestState === 'SUCCESS',
  };
};

export default successReducer;
