import * as React from 'react';
import { FormikProps } from 'formik';
import { ListType } from 'src/types';
import { InputSelect } from '..';

type FormikInputSelectProps = {
  formik: FormikProps<any>;
  field: string;
  label: string;
  placeholder?: string;
  isOptional?: boolean;
  options: ListType[];
  disabled?: boolean;
  disableClearable?: boolean;
  renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: ListType) => {};
  getOptionLabel?: () => {};
  errorHelper?: Record<string, string[]>;
};

const FormikInputSelect: React.FC<FormikInputSelectProps> = ({ formik, field, options, errorHelper, ...props }): JSX.Element => (
  <InputSelect
    name={field}
    getValue={() => formik.values[field]}
    options={options}
    // @ts-ignore
    onChange={(event: SyntheticEvent, value: ListType | null) => {
      formik.setFieldValue(field, value?.key ?? null);
    }}
    error={(formik.touched[field] && Boolean(formik.errors[field])) || (errorHelper && !!errorHelper[field])}
    // @ts-ignore
    helperText={(formik.touched[field] && formik.errors[field]) || (errorHelper && errorHelper[field])}
    {...props}
  />
);

export default FormikInputSelect;
