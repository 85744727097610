import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { setPermissions } from '../reducers';
import { loadPermissions } from '../actions';

function* loadPermissionsSaga(): SagaIterator {
  try {
    yield put(loadPermissions.pending());
    const { payload } = yield call(API.loadPermissions);

    yield put(setPermissions(payload));
    yield put(loadPermissions.success());
  } catch (e) {
    yield put(loadPermissions.failure());
  }
}

export default loadPermissionsSaga;
