import { Box, Grid } from '@mui/material';
import { Button, Dialog, H1, P1M, P1R } from 'src/components';
import { themeColors } from 'src/theme';
import trashIcon from '../../assets/trashRedIcon.svg';

type Props = {
  name: string;
  open: boolean;
  onDelete: () => void;
  onClose: () => void;
};

const DeleteConfirmationPopup = ({ name, open, onDelete, onClose }: Props) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="customized-dialog-title">
      <Box
        sx={{
          mb: 5,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ mt: 5 }}>
          <img src={trashIcon} alt="delete" />
        </Box>
        <H1 align="center" sx={{ mt: 4, mb: 3 }}>
          Delete Role?
        </H1>
        <P1R>
          You are about to delete&nbsp;<P1M>{name}</P1M> role. Are You sure?
        </P1R>
      </Box>

      <Grid container columnSpacing={2} sx={{ mb: 4 }}>
        <Grid item xs={6}>
          <Button
            primary={false}
            onClick={onClose}
            sx={{
              width: '100%',
              textTransform: 'capitalize',
            }}
          >
            Cancel
          </Button>
        </Grid>

        <Grid item xs={6}>
          <Button
            onClick={() => {
              onDelete();
              onClose();
            }}
            sx={{
              width: '100%',
              textTransform: 'capitalize',
              backgroundColor: themeColors.red,
            }}
          >
            Yes, Delete
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DeleteConfirmationPopup;
