import UnderConstruction from 'src/components/UnderConstruction';
import { DashboardWrapper } from '../wrappers';

const MyAccountContainer = () => {
  return (
    <DashboardWrapper>
      <UnderConstruction />
    </DashboardWrapper>
  );
};

export default MyAccountContainer;
