import MuiSnackbar from '@mui/material/Snackbar';
import { Icon, P2 } from 'src/components';
import { themeColors } from 'src/theme';
import { Alert, Box } from '@mui/material';

type Props = {
  message: string;
  onClose: () => void;
};

function Snackbar({ message, onClose }: Props) {
  return (
    <MuiSnackbar
      open
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={onClose}
    >
      <Alert
        sx={{
          padding: '0 16px',
          height: '52px',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: themeColors.black,
        }}
        severity="success"
      >
        <Box sx={{ gap: 5, display: 'flex', alignItems: 'center' }}>
          <P2 sx={{ color: themeColors.white, textTransform: 'none' }}>{message}</P2>

          <Box sx={{ cursor: 'pointer' }} onClick={onClose}>
            <Icon name="close" />
          </Box>
        </Box>
      </Alert>
    </MuiSnackbar>
  );
}

export default Snackbar;
