import API from 'src/api';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { SagaAction } from '../../types';
import { changeUserPassword } from '../actions';

function* changeUserPasswordSaga({ payload }: SagaAction<{ password: string; resetPasswordToken: string }>): SagaIterator {
  try {
    yield put(changeUserPassword.pending());
    yield call(API.changeUserPassword, payload);
    yield put(changeUserPassword.success());
  } catch (error) {
    yield put(changeUserPassword.failure(error));
  }
}

export default changeUserPasswordSaga;
