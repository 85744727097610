import Snackbar from '@mui/material/Snackbar';
import { Icon, P2 } from 'src/components';
import { themeColors } from 'src/theme';
import { Alert, Box } from '@mui/material';

type SnackbarProps = {
  open: boolean;
  onClose: () => void;
};

function SuccessDataSaveSnackbar({ open, onClose }: SnackbarProps) {
  return (
    <Snackbar
      open={open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <Alert
        sx={{
          padding: '0 16px',
          height: '52px',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: themeColors.black,
        }}
        severity="success"
      >
        <Box sx={{ gap: 5, display: 'flex', alignItems: 'center' }}>
          <P2 sx={{ color: themeColors.white, textTransform: 'none' }}>Your data is saved.</P2>

          <Box sx={{ cursor: 'pointer' }} onClick={onClose}>
            <Icon name="close" />
          </Box>
        </Box>
      </Alert>
    </Snackbar>
  );
}

export default SuccessDataSaveSnackbar;
