import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import config from 'src/config';
import { SagaAction } from '../../types';
import { loadProfile } from '../actions';
import { setProfile } from '../reducers';

type UserPayloadType = {
  uuid: string;
};

function* loadProfileSaga({ payload: { uuid } }: SagaAction<UserPayloadType>): SagaIterator {
  try {
    yield put(loadProfile.pending());
    const { payload: user } = yield call(API.getUser);

    // Technical Debt: permissions should be in the user object
    try {
      const {
        payload: { curPermissions, allPermissions },
      } = yield call(API.getUserPermissions);
      config.curPermissions = user.curPermissions = curPermissions;
      config.allPermissions = user.allPermissions = allPermissions;
    } catch (e) {
      config.curPermissions = user.curPermissions = [];
      config.allPermissions = user.allPermissions = [];
    }

    yield put(setProfile(user));

    yield put(loadProfile.success());
  } catch (error) {
    yield put(loadProfile.failure(error));
  }
}

export default loadProfileSaga;
