import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { IncidentType } from 'src/types';
import { setIncident } from '../reducers';
import { getIdIncident } from '../actions';

function* getIdIncidentSaga(): SagaIterator {
  try {
    yield put(getIdIncident.pending());
    const { payload } = yield call(API.getIdIncident);

    yield put(setIncident({ id: payload } as IncidentType));
    yield put(getIdIncident.success());
  } catch (e) {
    yield put(getIdIncident.failure());
  }
}

export default getIdIncidentSaga;
