import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import { DashboardWrapper } from 'src/containers/wrappers';
import { themeColors } from 'src/theme';
import { useTranslation } from 'react-i18next';
import InvitedTab from './IncidentTabs/InvitedTab';
import AllJobsTab from './IncidentTabs/AllJobsTab';
import MyJobsTab from './IncidentTabs/MyJobsTab';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ padding: '24px 0' }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function BroadcastsContainer() {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(1);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <DashboardWrapper breadcrumbs={[['Broadcasts']]}>
      <Box sx={{ marginTop: 3, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab
            label={t('broadcastPage.allJobs')}
            {...a11yProps(0)}
            sx={{
              width: { xs: '33%', md: 'auto' },
              textTransform: 'none',
              fontSize: '16px',
              color: themeColors.grayDark,
              '&.Mui-selected': { color: 'black' },
            }}
          />
          <Tab
            label={t('broadcastPage.invited')}
            {...a11yProps(1)}
            sx={{
              width: { xs: '33%', md: 'auto' },
              textTransform: 'none',
              fontSize: '16px',
              color: themeColors.grayDark,
              '&.Mui-selected': { color: 'black' },
            }}
          />
          <Tab
            label={t('broadcastPage.myJobs')}
            {...a11yProps(2)}
            sx={{
              width: { xs: '33%', md: 'auto' },
              textTransform: 'none',
              fontSize: '16px',
              color: themeColors.grayDark,
              '&.Mui-selected': { color: 'black' },
            }}
          />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <AllJobsTab />
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <InvitedTab />
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        <MyJobsTab />
      </TabPanel>
    </DashboardWrapper>
  );
}

export default BroadcastsContainer;
