import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { setUsers } from '../reducers';
import { loadFilterUsers } from '../actions';
import { SagaAction } from '../../types';

type LoadFilterPayloadType = {
  status?: number;
  available?: boolean;
  types?: string[];
  permissions?: string[];
};

function* loadFilterUsersSaga({
  payload: { types = [], permissions = [], status, available = false },
}: SagaAction<LoadFilterPayloadType>): SagaIterator {
  try {
    yield put(loadFilterUsers.pending());
    const { payload } = yield call(API.loadFilterUsers, {
      status,
      available,
      types,
      permissions,
    });

    yield put(setUsers(payload));
    yield put(loadFilterUsers.success());
  } catch (e) {
    yield put(loadFilterUsers.failure());
  }
}

export default loadFilterUsersSaga;
