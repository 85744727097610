import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, styled } from '@mui/material';
import { Button, FormikPassword, H1, Icon, P3 } from 'src/components';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { themeColors } from 'src/theme';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { updateUserPassword } from 'src/redux/user/actions';

type ChangePasswordPopupProps = {
  onClose: () => void;
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '600px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(5),
    paddingBottom: theme.spacing(1),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: '8px',
            top: '8px',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon name="close" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function ChangePasswordPopup({ onClose }: ChangePasswordPopupProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formik = useFormik<{ oldPassword: string; newPassword: string }>({
    initialValues: {
      oldPassword: '',
      newPassword: '',
    },

    validationSchema: Yup.object({
      oldPassword: Yup.string().min(7, 'Password must be 7 characters or longer').required(t('validations.required')),
      newPassword: Yup.string().min(7, 'Password must be 7 characters or longer').required(t('validations.required')),
    }),

    onSubmit: async (data) => {
      dispatch(updateUserPassword.init(data));
      onClose();
    },
  });

  const onModalClose = (event?: React.SyntheticEvent, reason?: string | undefined) => {
    if (reason === 'backdropClick' && formik.dirty) {
      return;
    }

    onClose();
  };

  return (
    <Box>
      {/* @ts-ignore */}
      <BootstrapDialog open onClose={onModalClose} aria-labelledby="customized-dialog-title">
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onModalClose}>
          <H1 align="center" sx={{ mt: 5, mb: 3 }}>
            {t('profilePage.changePassword')}
          </H1>
        </BootstrapDialogTitle>

        <DialogContent>
          <form autoComplete="off">
            <FormikPassword formik={formik} field="oldPassword" label="Old Password" />

            <Box sx={{ textAlign: 'right' }}>
              <Link
                component={RouterLink}
                to="/forgot-password"
                variant="body2"
                sx={{
                  textDecoration: 'none',
                  color: themeColors.blue,
                  textTransform: 'capitalize',
                }}
              >
                <P3>{t('loginPage.forgotPassword')}</P3>
              </Link>
            </Box>

            <FormikPassword formik={formik} field="newPassword" label="New Password" />
          </form>

          <Grid container columnSpacing={2} sx={{ mt: 3, mb: 4 }}>
            <Grid item xs={6}>
              <Button primary={false} onClick={onModalClose} sx={{ width: '100%', textTransform: 'capitalize' }}>
                {t('common.cancel')}
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Button
                onClick={() => {
                  formik.handleSubmit();
                }}
                sx={{ width: '100%', textTransform: 'capitalize' }}
              >
                {t('common.save')}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
} //

export default ChangePasswordPopup;
