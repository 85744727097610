import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { GridActionsCellItem, GridActionsCellItemProps, GridRowParams } from '@mui/x-data-grid';
import { H2, P1R, RostersTable, Toolbar, Icon, RosterStatus, EmptyState } from 'src/components';
import { themeColors } from 'src/theme';
import { PATHS } from 'src/navigation';
import { setParams } from 'src/utils';
import { RosterType, RosterStatus as RosterStatusEnum, UserType } from 'src/types';
import { saveRoster, deleteRoster } from 'src/redux/roster/actions';
import { PermissionEnum } from 'src/shared/enums/permission.enum';
import config from 'src/config';
import DeletePopup from '../DeletePopup';
import TransferPopup from '../TransferPopup';
import EditRosterDialog from '../../RosterContainer/Dialog';

export const rosterStatusesOptions: { [key: number]: number[] } = {
  [RosterStatusEnum.DRAFT]: [RosterStatusEnum.CLOSED],
  [RosterStatusEnum.PENDING_APPROVAL]: [],
  [RosterStatusEnum.MOBILIZED]: [RosterStatusEnum.ACTIVATED],
  [RosterStatusEnum.ACTIVATED]: [RosterStatusEnum.DEMOBILIZED],
  [RosterStatusEnum.DEMOBILIZED]: [RosterStatusEnum.ACTIVATED, RosterStatusEnum.CLOSED],
};

const AllRostersTab = ({ rosters, user }: { rosters: RosterType[]; user: UserType }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [rosterToDelete, setRosterToDelete] = useState<null | {
    id: number | undefined;
    name: string | undefined;
  }>(null);
  const [rosterToTransfer, setRosterToTransfer] = useState<null | RosterType>(null);
  const [isTransfering, setIsTransfering] = useState<boolean>(false);
  const [isTransferingEdit, setIsTransferingEdit] = useState<boolean>(false);
  const [incidentIdToTransfer, setIncidentIdToTransfer] = useState<number>(0);
  const hasAnyPermissions = [PermissionEnum.EDIT_ANY_ROSTER, PermissionEnum.DELETE_ANY_ROSTER, PermissionEnum.TRANSFER_ANY_ROSTER].some(
    (permission) => config.can(permission),
  );
  const hasOwnerPermissions = [PermissionEnum.EDIT_OWN_ROSTER, PermissionEnum.DELETE_OWN_ROSTER, PermissionEnum.TRANSFER_OWN_ROSTER].some(
    (permission) => config.can(permission),
  );
  const isOwnerOfAnyRoster = rosters.some((roster) => roster.ownerUuid === user.uuid);
  const hasAvailableActions = hasAnyPermissions || (hasOwnerPermissions && isOwnerOfAnyRoster);

  const handleStatusChange = (newStatus: number, rosterId: number) => {
    dispatch(saveRoster.init({ id: rosterId, status: newStatus }));
  };
  const handleDeleteRoster = () => {
    if (rosterToDelete?.id) {
      dispatch(deleteRoster.init({ id: rosterToDelete?.id }));
    }
  };
  const handleTransferToClick = (roster: RosterType) => {
    setRosterToTransfer(roster);
    setIsTransfering(true);
  };

  const handleTransferCancel = () => {
    setIsTransfering(false);
    setIsTransferingEdit(false);
    setIncidentIdToTransfer(0);
    setRosterToTransfer(null);
  };

  const getActions = (params: GridRowParams<any>): React.ReactElement<GridActionsCellItemProps>[] =>
    config.can('edit_roster', { user, roster: params.row }) || RosterStatusEnum.PENDING_APPROVAL === 1
      ? [
          ...(params.row.status === RosterStatusEnum.DRAFT && config.can('delete_roster', { user, roster: params.row })
            ? [
                <GridActionsCellItem
                  sx={{ fontSize: '14px', height: '50px' }}
                  key="deleteCell"
                  icon={<Icon name="delete" />}
                  label={t('eventPage.delete')}
                  onClick={() =>
                    setRosterToDelete({
                      id: params.row.id,
                      name: params.row.name,
                    })
                  }
                  showInMenu
                />,
              ]
            : []),
          ...(config.can('transfer_roster', { user, roster: params.row }) && Object.keys(rosterStatusesOptions).includes(params.row.status.toString())
            ? [
                <GridActionsCellItem
                  sx={{ fontSize: '14px', height: '50px' }}
                  key="transferCell"
                  icon={<Icon name="duplicate" />}
                  label={t('eventPage.transferTo')}
                  onClick={() => handleTransferToClick(params.row)}
                  showInMenu
                />,
              ]
            : []),
          ...(!config.can('edit_roster', { user, roster: params.row }) ||
          [RosterStatusEnum.PENDING_APPROVAL, RosterStatusEnum.CLOSED].includes(params.row.status)
            ? []
            : [
                <GridActionsCellItem
                  key="changeCell"
                  label={t('eventPage.changeStatusTo')}
                  disabled
                  sx={{
                    fontSize: '14px',
                    height: '50px',
                    opacity: '1 !important',
                  }}
                  showInMenu
                />,
                ...(rosterStatusesOptions[params.row.status] || []).map((status) => (
                  <GridActionsCellItem
                    sx={{
                      fontSize: '14px',
                      height: '50px',
                      '& .MuiListItemIcon-root': { color: themeColors.grayDark },
                    }}
                    key={status}
                    icon={<RosterStatus status={status} />}
                    label=""
                    onClick={() => handleStatusChange(status, params.row.id)}
                    showInMenu
                  />
                )),
              ]),
        ]
      : [];

  return (
    <>
      <Box sx={{ gap: 2, display: 'flex', flexDirection: 'column', flexGrow: 1, overflowX: 'hidden' }}>
        <H2
          sx={{
            gap: '16px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box sx={{ whiteSpace: 'nowrap' }}>{t('eventPage.allRosters')}</Box>
          <P1R
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '5px 15px',
              height: '24px',
              borderRadius: '45px',
              backgroundColor: themeColors.grayMedium,
            }}
          >
            {rosters.length}
          </P1R>
          {rosters && rosters.length > 0 ? <Toolbar options="mini" /> : null}
        </H2>
        {rosters && rosters.length > 0 ? (
          <RostersTable
            rosters={rosters}
            getActions={hasAvailableActions ? getActions : undefined}
            setRoster={(rid: number): void =>
              navigate(
                setParams(PATHS.ROSTER_PAGE, {
                  id: rid.toString(),
                }),
              )
            }
          />
        ) : (
          <Box
            sx={{
              margin: 'auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              flexDirection: 'column',
            }}
          >
            <EmptyState type="list" title={t('eventPage.noRosters')} />
          </Box>
        )}
      </Box>
      {rosterToDelete && (
        <DeletePopup id={rosterToDelete.id} name={rosterToDelete.name} onClose={() => setRosterToDelete(null)} onDelete={handleDeleteRoster} />
      )}
      {isTransfering && (
        <TransferPopup
          id={rosterToTransfer?.id || 0}
          name={rosterToTransfer?.name}
          onClose={handleTransferCancel}
          onSelectIncident={setIncidentIdToTransfer}
          onNextClick={() => setIsTransferingEdit(true)}
        />
      )}
      {isTransferingEdit && (
        <EditRosterDialog
          template="edit-roster"
          onClose={handleTransferCancel}
          incidentId={rosterToTransfer?.incidentId as number}
          roster={rosterToTransfer || undefined}
          incidentIdToTransfer={incidentIdToTransfer}
        />
      )}
    </>
  );
};

export default AllRostersTab;
