import { Dialog, DialogContent, DialogTitle, IconButton, styled, DialogProps } from '@mui/material';
import { Icon } from 'src/components';

type Props = {
  children?: React.ReactNode;
  open: boolean;
  onClose: () => void;
} & DialogProps;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '600px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(5),
    paddingBottom: theme.spacing(1),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function StyledDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: '8px',
            top: '8px',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon name="close" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function DialogCustom({ onClose, children, ...props }: Props) {
  return (
    <StyledDialog onClose={onClose} aria-labelledby="customized-dialog-title" {...props}>
      <StyledDialogTitle id="customized-dialog-title" onClose={onClose} />
      <DialogContent>{children}</DialogContent>
    </StyledDialog>
  );
}

export default DialogCustom;
