import { UserType } from 'src/types';
import { createReducer } from '../../utils/toolkit';

const initialState: Partial<UserType[]> = [];

const {
  reducer,
  actions: { set: setLinemen, merge: mergeLinemen, reset: resetLinemen },
} = createReducer('linemen', initialState);

export default reducer;
export { setLinemen, mergeLinemen, resetLinemen };
