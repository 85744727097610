import React, { FC, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Link } from '@mui/material';

import { P2R, P3, Icon } from 'src/components';
import { uploadUserFile, deleteUserFile, cancelUploadingUserFile } from 'src/redux/user/actions';
import { selectErrorUploadingUserFile, selectSuccessUploadingUserFile } from 'src/redux/user/selectors';
import { UserFileType } from 'src/types/user';
import { themeColors } from 'src/theme';

type AttachmentProps = {
  title: string;
  typeName: string;
  selectedAttachment?: UserFileType;
  activeField: string | null;
  userId: string;
  disabled?: boolean;
  disabledActs?: {
    remove?: boolean;
    upload?: boolean;
  };
  handleActiveUpload: (uploadName: string) => void;
  handleNewFileUpload?: (isNewFile: boolean) => void;
};

const Attachment: FC<AttachmentProps> = ({
  title,
  typeName,
  activeField,
  handleActiveUpload,
  handleNewFileUpload,
  selectedAttachment,
  userId,
  disabled = false,
  disabledActs = {},
  ...props
}): JSX.Element => {
  const dispatch = useDispatch();
  const errorUploadingUserFile = useSelector(selectErrorUploadingUserFile);
  const successUploadingUserFile = useSelector(selectSuccessUploadingUserFile);
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState<Partial<UserFileType> | null>(selectedAttachment || null);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleFileUpload = () => {
    handleActiveUpload(typeName);
    if (fileInputRef.current) {
      // @ts-ignore
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files && files.length > 0) {
      const file = files[0];
      setSelectedFile({ filename: file.name });
      dispatch(uploadUserFile.init({ userUuid: userId, file, type: typeName }));
    }
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
    setIsError(false);
    setIsSuccess(false);
    handleNewFileUpload?.(true);
    if (fileInputRef.current) {
      // @ts-ignore
      fileInputRef.current.value = '';
    }

    if (selectedAttachment?.id) {
      dispatch(deleteUserFile.init({ id: selectedAttachment?.id }));
    } else {
      dispatch(cancelUploadingUserFile.init({ type: typeName }));
    }
  };

  useEffect(() => {
    if (errorUploadingUserFile && activeField === typeName) {
      setIsError(true);
    }

    if (
      (successUploadingUserFile && activeField === typeName) ||
      (typeof selectedAttachment?.location === 'boolean' && selectedAttachment?.location === false)
    ) {
      setIsSuccess(true);
      handleNewFileUpload?.(true);
    }
  }, [errorUploadingUserFile, successUploadingUserFile]);

  useEffect(() => {
    if (selectedAttachment?.errorText) {
      setIsError(true);
    }
  }, [selectedAttachment]);

  return (
    <>
      <Box
        sx={{
          margin: '-5px 0 0',
          padding: '16px 19px 16px 12px',
          display: 'flex',
          alignItems: 'center',
          borderRadius: '5px',
          backgroundColor: isError ? themeColors.redError : isSuccess ? themeColors.greenLight : 'white',
        }}
      >
        {selectedFile ? null : (
          <Box sx={{ opacity: 0.25, filter: 'grayscale(100%)' }}>
            <Icon name={isError ? 'attachRed' : 'attachBlue'} fontSize="small" color="white" />
          </Box>
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: { xs: 'left', sm: 'center' },
          }}
        >
          <Box>
            {isSuccess || selectedFile ? (
              <P2R>
                {selectedFile?.location || selectedAttachment?.location ? (
                  <Link
                    href={selectedFile?.location || selectedAttachment?.location}
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="none"
                    sx={{ display: 'flex' }}
                  >
                    {selectedFile?.location && <Icon name="attachBlack" fontSize="small" color="white" />}
                    {selectedFile?.filename}
                  </Link>
                ) : selectedFile && !selectedFile?.location && !isSuccess && !isError ? (
                  <P2R>Uploading...</P2R>
                ) : (
                  selectedFile?.filename
                )}
              </P2R>
            ) : disabled || disabledActs.upload ? (
              <P2R sx={{ color: themeColors.grayDisabled, fontSize: '14px' }}> {`Attach ${title}`}</P2R>
            ) : (
              <Link
                component="label"
                onClick={handleFileUpload}
                sx={{
                  fontSize: '14px',
                  color: isError ? themeColors.red : isSuccess ? 'black' : themeColors.blue,
                  textDecoration: 'none',
                  '&:hover': { cursor: 'pointer' },
                }}
              >
                {`Attach ${title}`}
              </Link>
            )}
            <input hidden type="file" accept=".pdf, .jpg, .png" ref={fileInputRef} onChange={handleFileChange} />
          </Box>
          <Box sx={{ gap: 3, display: 'flex', alignItems: 'center' }}>
            {selectedFile && !selectedFile?.location && !isSuccess && !isError ? <Icon size={19} name="circularProgress" /> : null}
            {!disabled && !disabledActs.remove ? (
              selectedFile ? (
                <Box
                  onClick={handleFileRemove}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '18px',
                    height: '18px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    backgroundColor: 'white',
                  }}
                >
                  <Icon name="closeSmall" />
                </Box>
              ) : (
                <P2R sx={{ color: themeColors.grayDark }}>Max. 50MB. jpg. png. pdf</P2R>
              )
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box>
        <P3
          sx={{
            color: themeColors.error,
            margin: '0 0 24px',
            display: 'inline-block',
          }}
        >
          {(isError && selectedAttachment?.errorText) || ''}
        </P3>
      </Box>
    </>
  );
};

export default Attachment;
