import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { resend } from '../actions';
import { SagaAction } from '../../types';

type UserPayloadType = {
  uuid: string;
};

function* resendSaga({ payload: { uuid } }: SagaAction<UserPayloadType>): SagaIterator {
  try {
    yield put(resend.pending());
    yield call(API.resend, uuid);
    yield put(resend.success());
  } catch (e) {
    yield put(resend.failure());
  }
}

export default resendSaga;
