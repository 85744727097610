import { ReduxAction } from '../../../types';
import { isObject } from '../../../../utils';
import { StateType, ActionTypesMap, ReducerActionsMap } from '../types';

type BuildReducerActions = <State>(
  actionTypes: ActionTypesMap,
  initState: State | null,
  customActions: ReducerActionsMap<State>,
) => ReducerActionsMap;

type BuildReducer = (actions: ReducerActionsMap, initState: StateType) => (state: StateType, { type, payload }: ReduxAction) => StateType;

export const buildReducer: BuildReducer =
  (actions, initState) =>
  // eslint-disable-next-line default-param-last
  (state = initState, { type, payload }) =>
    actions[type] ? actions[type](state, payload) : state;

export const buildReducerActions: BuildReducerActions = (
  actionTypes,
  // eslint-disable-next-line default-param-last
  initState = null,
  customActions,
) => {
  const { SET, RESET, MERGE, ...customTypes } = actionTypes;

  const setAction = (_: StateType, payload: StateType): StateType => payload;
  const resetAction = (): StateType | null => {
    if (isObject(initState)) {
      if (Array.isArray(initState)) {
        return [...initState];
      }
      return { ...(initState as {}) };
    }
    return initState;
  };
  const mergeAction = (state: StateType, payload: object | []): StateType | null => {
    if (isObject(state)) {
      if (Array.isArray(initState) && Array.isArray(payload)) {
        return [...(state as []), ...payload];
      }
      return { ...(state as {}), ...payload };
    }
    return state;
  };

  const customReducers = Object.entries(customTypes).reduce(
    (mapActions, [key, value]) => ({
      ...mapActions,
      [value]: customActions[key],
    }),
    {},
  );

  return {
    [SET]: setAction,
    [RESET]: resetAction,
    [MERGE]: mergeAction,
    ...customReducers,
  };
};
