import { UserType } from 'src/types';
import { createReducer } from '../../utils/toolkit';

const initialState: Partial<UserType[]> = [];

const {
  reducer,
  actions: { set: setLinemenByYard, merge: mergeLinemenByYard, reset: resetLinemenByYard },
} = createReducer('linemenByYard', initialState);

export default reducer;
export { setLinemenByYard, mergeLinemenByYard, resetLinemenByYard };
