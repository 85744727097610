import { RosterType } from './roster';
import { UserType } from './user';

export enum IncidentStatus {
  DRAFT = 0,
  ACTIVATED,
  CLOSED,
}

// TODO: migrate it in user.ts
export type ClassificationType = {
  classificationSlug: string;
  classificationId?: number;
  amount: number;
  id?: number; // Tecdhnical Debt: Should be remove. #ERMA-1368
  incidentId?: number; // Tecdhnical Debt: Should be remove. #ERMA-1368
};

export type FleetType = {
  amount: number;
  vehicleType: string;
  id?: number; // Tecdhnical Debt: Should be remove. #ERMA-1368
  incidentId?: number; // Tecdhnical Debt: Should be remove. #ERMA-1368
};

export type ParticipantType = {
  incidentId: number;
  userUuid: string;
  declined: boolean;
  participationStatus: number;
  yards: { id: number; name: string; state: string }[];
};

export type RosterDetails = {
  confirmedBy?: string;
  startTime?: string;
  yard?: string;
  status?: number;
  rosterId?: number;
  version?: number;
};

export type IncidentType = {
  id: number;
  status: IncidentStatus;
  time: string;
  name: string;
  createdBy: UserType;
  ownedBy: UserType;
  rosterDetails: RosterDetails | null;
  owner: string;
  ownerUuid: string;
  expectedStartDate: number;
  utility: { id: number; name: string };
  utilityContractor: { id: number; name: string }; // Utility contractor
  yardFK: number; // Work location
  yardId?: number;
  rosterId?: number;
  // Assign to
  rates: string;
  lodgingProvided: string;
  meals: string;
  benefitsPay: string;
  travelReimbursement: string;
  ibewPay: string;
  onRoster: boolean;
  assignedTo: UserType[];
  estimatedDuration: string;
  dailyHours: string;
  yard: { id: number; name: string };
  rosterOwnersFullNamesByYards: string[];
  receipts: {
    fuel: boolean;
    travel: boolean;
    lodging: boolean;
    meals: boolean;
  };
  fteRequested: number;
  eqptRequested: number;
  classifications: ClassificationType[];
  participations: ParticipantType;
  fleet: FleetType[];
  workLocation: string;
  rosters: RosterType[];
  utilityId: number;
  utilityContractorId: number;
};
