import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { SagaAction } from '../../types';
import { loadUtilityClassifications } from '../actions';
import { setUtilityClassifications } from '../reducers';

function* loadUtilityClassificationsSaga({ payload: { id = 0 } }: SagaAction<Partial<{ id: number }>>): SagaIterator {
  try {
    yield put(loadUtilityClassifications.pending());
    const { payload } = yield call(API.getUtilityClassifications, { id });

    yield put(setUtilityClassifications(payload));
    yield put(loadUtilityClassifications.success());
  } catch (e) {
    yield put(loadUtilityClassifications.failure());
  }
}

export default loadUtilityClassificationsSaga;
