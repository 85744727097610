import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { H3, P1R, Toolbar } from 'src/components';
import FleetDueTable, { fleetDueColumnVisibilityModel } from 'src/components/FleetDueTable';
import { themeColors } from 'src/theme';
import { VehicleType } from 'src/types';

type AdministrativeProps = {
  fleet: VehicleType[];
};

const MaintenanceTab = ({ fleet }: AdministrativeProps) => {
  const { t } = useTranslation();
  const columnsVisibility = {
    ...fleetDueColumnVisibilityModel,

    companyTag: true,
    yard: true,
    nextServiceDate: true,
  };

  return (
    <>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <H3>{t('fleetReportsPage.breakdownByVehicle')}</H3>

        <Box sx={{ display: 'flex', gap: 4 }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Box sx={{ gap: 1, display: 'flex', alignItems: 'center' }}>
              <Box sx={{ height: '8px', width: '8px', bgcolor: themeColors.yellowMain, borderRadius: '100%' }} />
              <P1R>{t('fleetReportsPage.expire')}</P1R>
            </Box>

            <Box sx={{ gap: 1, display: 'flex', alignItems: 'center' }}>
              <Box sx={{ height: '8px', width: '8px', bgcolor: themeColors.red, borderRadius: '100%' }} />
              <P1R>{t('fleetReportsPage.expired')}</P1R>
            </Box>
          </Box>

          <Toolbar options={{ exportBtn: true, densityBtn: true, columnsBtn: true, filterBtn: true }} />
        </Box>
      </Box>

      <FleetDueTable fleet={fleet} columnVisibilityModel={columnsVisibility} />
    </>
  );
};

export default MaintenanceTab;
