import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { SagaAction } from 'src/redux/types';
import { setUsers } from '../reducers';
import { loadUsersFavorites } from '../actions';

type loadUsersFavoritesType = {
  favorites: boolean;
};

function* loadUsersFavoritesSaga({ payload: { favorites } }: SagaAction<loadUsersFavoritesType>): SagaIterator {
  try {
    yield put(loadUsersFavorites.pending());
    const { payload } = yield call(API.loadUsersFavorites, { favorites });

    yield put(setUsers(payload));
    yield put(loadUsersFavorites.success());
  } catch (e) {
    yield put(loadUsersFavorites.failure());
  }
}

export default loadUsersFavoritesSaga;
