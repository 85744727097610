import { UserType } from 'src/types';
import { createReducer } from '../../utils/toolkit';

const initialState: Partial<UserType[]> = [];

const {
  reducer,
  actions: { set: setUsers, merge: mergeUsers, reset: resetUsers },
} = createReducer('users', initialState);

export default reducer;
export { setUsers, mergeUsers, resetUsers };
