import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Icon, P1R, P2, H3 } from 'src/components';
import IncidentStatus from 'src/components/IncidentsTable/IncidentStatus';
import { themeColors } from 'src/theme';
import { IncidentType } from 'src/types';
import { formatIncidentId, formatTimestamp } from 'src/utils';
import config from 'src/config';

type IncidentHeaderProps = {
  incident?: Partial<IncidentType>;
};

function IncidentHeader({ incident }: IncidentHeaderProps) {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const receipts = incident?.receipts || {};
  const requiredReceipts = Object.keys(receipts)
    .filter((key) => receipts[key as keyof typeof receipts])
    .join(', ');

  const defaultOrderClasifications = ['Journeyman Lineman', 'Operator', 'Groundman', 'Safety'];
  // @ts-ignore
  const sortClasifications = (a, b) => {
    // TODO: duplicate. move sorting to separate file
    if (defaultOrderClasifications.indexOf(a.classification?.name) === -1) return 1;
    if (defaultOrderClasifications.indexOf(b.classification?.name) === -1) return -1;
    if (defaultOrderClasifications.indexOf(a.classification?.name) < defaultOrderClasifications.indexOf(b.classification?.name)) return -1;
    if (defaultOrderClasifications.indexOf(a.classification?.name) < defaultOrderClasifications.indexOf(b.classification?.name)) return 1;
    return 0;
  };
  const sortedClassifications = (incident?.classifications || []).filter((item) => item.amount !== 0).sort(sortClasifications);

  const EmtptyState = <P1R sx={{ color: themeColors.grayDisabled }}>No Data</P1R>;

  const headerInfo = (
    <Grid container columnGap={{ xs: 1, md: 4 }} rowGap={{ xs: 2, md: 0 }}>
      <Grid item xs={5} sm={2}>
        <Box sx={{ gap: { xs: 1, md: 2 }, display: 'flex' }}>
          <Box sx={{ width: '50px' }}>
            <Icon name="incidentLight" />
          </Box>
          <Box sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
            <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('eventPage.eventDelete')}</P2>
            <P1R sx={{ color: themeColors.grayDark }}>{formatIncidentId(incident?.id)}</P1R>
          </Box>
        </Box>
      </Grid>

      <Grid
        item
        xs={2}
        sx={{
          gap: 1,
          display: { xs: 'none', sm: 'flex' },
          flexDirection: 'column',
        }}
      >
        <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('eventPage.workLocation')}</P2>
        <P1R sx={{ color: themeColors.grayDark }}>{incident?.workLocation || EmtptyState}</P1R>
      </Grid>

      <Grid
        item
        xs={2}
        sx={{
          gap: 1,
          display: { xs: 'none', sm: 'flex' },
          flexDirection: 'column',
        }}
      >
        <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('eventPage.reportToYard')}</P2>
        <P1R sx={{ color: themeColors.grayDark }}>
          {
            // @ts-ignore
            // eslint-disable-next-line no-unsafe-optional-chaining
            config.options.yards[incident?.yardFK - 1]?.value
          }
        </P1R>
      </Grid>

      <Grid item xs={2} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
        <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('eventPage.utility')}</P2>
        <P1R sx={{ color: themeColors.grayDark }}>{incident?.utility?.name || EmtptyState}</P1R>
      </Grid>

      <Grid item xs sx={{ gap: 1, display: 'flex' }}>
        <Box sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
          <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('eventPage.utilityContractor')}</P2>
          <P1R sx={{ color: themeColors.grayDark }}>{incident?.utilityContractor?.name || EmtptyState}</P1R>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'end' }}>
          <Box
            onClick={() => setIsExpanded((prevState) => !prevState)}
            sx={{
              gap: 0.5,
              display: 'flex',
              justifyContent: 'end',
              cursor: 'pointer',
            }}
          >
            <P1R sx={{ fontSize: '14px', color: themeColors.blue }}>{isExpanded ? t('eventPage.showLess') : t('eventPage.showMore')}</P1R>
            {isExpanded ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  transform: 'rotate(180deg)',
                }}
              >
                <Icon name="arrowDownBlue" />
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Icon name="arrowDownBlue" />
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );

  const extendedInfo = (
    <>
      <Grid container columnGap={4}>
        <Grid item xs={2}>
          <Box sx={{ gap: 2, display: 'flex' }}>
            <Box sx={{ width: '50px' }} />
            <Box sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
              <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.status')}</P2>
              {incident?.status !== undefined ? <IncidentStatus status={incident?.status} /> : EmtptyState}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={2} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
          <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.ibewPay')}</P2>
          <P1R sx={{ color: themeColors.grayDark }}>{incident?.ibewPay || EmtptyState}</P1R>
        </Grid>

        <Grid item xs={2} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
          <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.benefitsPay')}</P2>
          <P1R sx={{ color: themeColors.grayDark }}>{incident?.benefitsPay || EmtptyState}</P1R>
        </Grid>

        <Grid item xs={2} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
          <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.travelReimbursement')}</P2>
          <P1R sx={{ color: themeColors.grayDark }}>{incident?.travelReimbursement || EmtptyState}</P1R>
        </Grid>

        <Grid item xs={2} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
          <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.lodgingProvided')}</P2>
          <P1R sx={{ color: themeColors.grayDark }}>
            {incident?.lodgingProvided?.toString() === 'true' ? 'Yes' : incident?.lodgingProvided?.toString() === 'false' ? 'No' : EmtptyState}
          </P1R>
        </Grid>
      </Grid>

      <Grid container columnGap={4}>
        <Grid item xs={2}>
          <Box sx={{ gap: 2, display: 'flex' }}>
            <Box sx={{ width: '50px' }} />
            <Box sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
              <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('eventPage.estDuration')}</P2>
              <P1R sx={{ color: themeColors.grayDark }}>{incident?.estimatedDuration || EmtptyState}</P1R>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={2} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
          <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('eventPage.dailyDuration')}</P2>
          <P1R sx={{ color: themeColors.grayDark }}>{incident?.dailyHours || EmtptyState}</P1R>
        </Grid>

        <Grid item xs={2} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
          <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('eventPage.expectedStartDate')}</P2>
          <P1R sx={{ color: themeColors.grayDark }}>
            {(incident && incident.expectedStartDate && formatTimestamp(+incident.expectedStartDate)) || EmtptyState}
          </P1R>
        </Grid>

        <Grid item xs={2} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
          <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('eventPage.reportTime')}</P2>
          <P1R sx={{ color: themeColors.grayDark }}>{incident?.time || EmtptyState}</P1R>
        </Grid>
      </Grid>

      <Grid container columnGap={8}>
        <Grid item xs={4}>
          <Box sx={{ gap: 2, display: 'flex' }}>
            <Box sx={{ width: '50px' }} />
            <Box sx={{ gap: 0.5, display: 'flex', flexDirection: 'column' }}>
              {sortedClassifications.map((classification, index) => (
                <Box key={index} sx={{ gap: 1.5, display: 'flex', alignItems: 'center' }}>
                  <P1R
                    sx={{
                      width: '15px',
                      color: themeColors.grayDark,
                      textAlign: 'center',
                    }}
                  >
                    {classification.amount}
                  </P1R>
                  <P2 sx={{ fontWeight: '600' }}>
                    {t(`utility_fte_classifications.${incident?.utility?.name}.${classification.classificationSlug}`)}
                  </P2>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={4} sx={{ gap: 0.5, display: 'flex', flexDirection: 'column' }}>
          {incident?.fleet
            ?.filter((item) => item.amount !== 0)
            .map((vehicle, index) => (
              <Box key={index} sx={{ gap: 1.5, display: 'flex', alignItems: 'center' }}>
                <P1R
                  sx={{
                    width: '15px',
                    color: themeColors.grayDark,
                    textAlign: 'center',
                  }}
                >
                  {vehicle.amount}
                </P1R>
                <P2 sx={{ fontWeight: '600' }}>{t(`utility_vehicle_classifications.${incident.utility?.name}.${vehicle.vehicleType}`)}</P2>
              </Box>
            ))}
        </Grid>
      </Grid>
    </>
  );

  const extendedInfoMobile = (
    <Grid container columnGap={4} rowGap={3}>
      <Grid item xs={5} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
        <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>work location</P2>
        <P1R sx={{ color: themeColors.grayDark }}>{incident?.workLocation || EmtptyState}</P1R>
      </Grid>

      <Grid item xs={5} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
        <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.ibewPay')}</P2>
        <P1R sx={{ color: themeColors.grayDark }}>{incident?.ibewPay || EmtptyState}</P1R>
      </Grid>

      <Grid item xs={5} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
        <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.benefitsPay')}</P2>
        <P1R sx={{ color: themeColors.grayDark }}>{incident?.benefitsPay || EmtptyState}</P1R>
      </Grid>

      <Grid item xs={5} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
        <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.travelReimbursement')}</P2>
        <P1R sx={{ color: themeColors.grayDark }}>{incident?.travelReimbursement || EmtptyState}</P1R>
      </Grid>

      <Grid item xs={5} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
        <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('rosterOverviewHeader.lodgingProvided')}</P2>
        <P1R sx={{ color: themeColors.grayDark }}>
          {incident?.lodgingProvided?.toString() === 'true' ? 'Yes' : incident?.lodgingProvided?.toString() === 'false' ? 'No' : EmtptyState}
        </P1R>
      </Grid>

      <Grid item xs={5} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
        <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('eventPage.estDuration')}</P2>
        <P1R sx={{ color: themeColors.grayDark }}>{incident?.estimatedDuration || EmtptyState}</P1R>
      </Grid>

      <Grid item xs={5} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
        <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('eventPage.dailyHours')}</P2>
        <P1R sx={{ color: themeColors.grayDark }}>{incident?.dailyHours || EmtptyState}</P1R>
      </Grid>

      <Grid item xs={5} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
        <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('eventPage.reportToYard')}</P2>
        <P1R sx={{ color: themeColors.grayDark }}>
          {
            // @ts-ignore
            // eslint-disable-next-line no-unsafe-optional-chaining
            config.options.yards[incident?.yardFK - 1]?.value
          }
        </P1R>
      </Grid>

      <Grid item xs={5} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
        <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('eventPage.time')}</P2>
        <P1R sx={{ color: themeColors.grayDark }}>{incident?.time || EmtptyState}</P1R>
      </Grid>

      <Grid item xs={5} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
        <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('eventPage.receiptsRequired')}</P2>
        <P1R sx={{ color: themeColors.grayDark }}>{requiredReceipts || EmtptyState}</P1R>
      </Grid>
    </Grid>
  );

  const generalInfoMobile = (
    <Accordion
      sx={{
        my: 3,
        border: `1px solid ${themeColors.grayMedium}`,
        borderRadius: '5px',
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <H3>{t('eventPage.generalInfo')}</H3>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container columnGap={4} rowGap={3}>
          <Grid item xs={5} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
            <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('eventPage.startDay')}</P2>
            <P1R sx={{ color: themeColors.grayDark }}>
              {(incident && incident.expectedStartDate && formatTimestamp(+incident.expectedStartDate)) || EmtptyState}
            </P1R>
          </Grid>

          <Grid item xs={5} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
            <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('eventPage.startTime')}</P2>
            <P1R sx={{ color: themeColors.grayDark }}>{incident?.time || EmtptyState}</P1R>
          </Grid>

          <Grid item xs={5} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
            <P2 sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{t('eventPage.reportToYard')}</P2>
            <P1R sx={{ color: themeColors.grayDark }}>
              {
                // @ts-ignore
                config.options.yards[incident.yardFK - 1]?.value
              }
            </P1R>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );

  return (
    <>
      <Box
        sx={{
          padding: 3,
          backgroundColor: themeColors.grayLight,
          borderRadius: '5px',
        }}
      >
        {!isExpanded ? (
          headerInfo
        ) : (
          <Box>
            {headerInfo}
            <Box
              sx={{
                margin: '24px 0',
                borderBottom: `1px solid ${themeColors.grayMedium}`,
              }}
            />
            <Box sx={{ gap: 4, display: 'flex', flexDirection: 'column' }}>{isMobile ? extendedInfoMobile : extendedInfo}</Box>
          </Box>
        )}
      </Box>
      {isMobile ? generalInfoMobile : null}
    </>
  );
}

export default IncidentHeader;
