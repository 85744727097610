import { combineReducers } from 'redux';
import rosters from './rosters';

const rostersData = combineReducers({
  rosters,
});

export * from './rosters';

export default rostersData;
