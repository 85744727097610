import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { UserSettings } from 'src/types';

import { SagaAction } from '../../types';
import { updateUserSettings } from '../actions';

function* updateUserSettingsSaga({ payload }: SagaAction<Partial<UserSettings>>): SagaIterator {
  try {
    yield put(updateUserSettings.pending());
    yield call(API.updateUserSettings, payload);
    yield put(updateUserSettings.success());
  } catch (error) {
    yield put(updateUserSettings.failure(error));
  }
}

export default updateUserSettingsSaga;
