import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { incidentDeclineLinemen } from '../actions';
import { SagaAction } from '../../types';

function* incidentDeclineLinemenSaga({
  payload: { id = '', uuids = [], yards = [] },
}: SagaAction<Partial<{ id: string; uuids: string[]; yards: number[] }>>): SagaIterator {
  try {
    yield put(incidentDeclineLinemen.pending());

    yield call(API.incedentDeclineLinemen, { id, users: uuids, yards });

    yield put(incidentDeclineLinemen.success());
  } catch (e) {
    yield put(incidentDeclineLinemen.failure(e));
  }
}

export default incidentDeclineLinemenSaga;
