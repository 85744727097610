import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box } from '@mui/material';
import { selectFilteredIncidentsLoading, selectIncidents } from 'src/redux/incidents/selectors';
import { loadFilteredIncidents } from 'src/redux/incidents/actions';
import { selectUser } from 'src/redux/user/selectors';
import { H1, Loader, P1R } from 'src/components';
import { selectSetInviteIncidentSuccess } from 'src/redux/incident/selectors';
import emptyList from 'src/assets/emptyList.svg';
import { useTranslation } from 'react-i18next';
import IncidentCard from '../IncidentCard';

const tabFilter = 'WITH_INVITATION';

function InvitedTab() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const incidents = useSelector(selectIncidents);
  const isLoadingFilteredIncidents = useSelector(selectFilteredIncidentsLoading);
  const setInviteIncidentSuccess = useSelector(selectSetInviteIncidentSuccess);

  useEffect(() => {
    if (user.uuid) {
      dispatch(
        loadFilteredIncidents.init({
          uuid: user.uuid,
          filter: tabFilter,
        }),
      );
    }
  }, [dispatch, user.uuid]);

  useEffect(() => {
    if (setInviteIncidentSuccess) {
      dispatch(
        loadFilteredIncidents.init({
          uuid: user.uuid,
          filter: tabFilter,
        }),
      );
    }
  }, [dispatch, user.uuid, setInviteIncidentSuccess]);

  return isLoadingFilteredIncidents ? (
    <Box sx={{ minHeight: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Loader />
    </Box>
  ) : incidents?.length ? (
    <Grid container spacing={3.5}>
      {incidents.map((incident) => (
        <Grid item key={incident.id + incident.yard.id} xs={12} md={6} lg={4}>
          <IncidentCard incident={incident} filteredTypeIncidents={tabFilter} />
        </Grid>
      ))}
    </Grid>
  ) : (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        flexDirection: 'column',
        mt: 14,
      }}
    >
      <Box>
        <img src={emptyList} alt="No jobs" />
      </Box>
      <H1 sx={{ my: 3 }}>{t('broadcastPage.noJobs')}</H1>
      <P1R>{t('broadcastPage.checkLater')}</P1R>
    </Box>
  );
}

export default InvitedTab;
