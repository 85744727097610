import { Alert, Box, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Icon, P1M, P2 } from 'src/components';
import { themeColors } from 'src/theme';

type SnackbarProps = {
  open: boolean;
  onClose: () => void;
};

const ActionsAreForbiddenSnackbar = ({ open, onClose }: SnackbarProps) => {
  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <Alert
        sx={{
          padding: '16px',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: themeColors.black,
          '& .MuiAlert-icon': {
            alignSelf: 'flex-start',
          },
        }}
        severity="error"
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <P1M sx={{ color: themeColors.white, textTransform: 'none' }}>{t('actionsForbiddenSnackbar.actionsForbidden')}</P1M>

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <P2 sx={{ color: themeColors.white, textTransform: 'none' }}>{t('actionsForbiddenSnackbar.rosterClosed')}</P2>
              <P2 sx={{ color: themeColors.white, textTransform: 'none' }}>{t('actionsForbiddenSnackbar.noActions')}</P2>
            </Box>
          </Box>

          <Box sx={{ cursor: 'pointer' }} onClick={onClose}>
            <Icon name="close" />
          </Box>
        </Box>
      </Alert>
    </Snackbar>
  );
};

export default ActionsAreForbiddenSnackbar;
