export const parseDate = (timestamp: number): { date: string; time?: string } => {
  const dateObj = new Date(timestamp);

  const date = dateObj.toLocaleDateString();
  const time = dateObj.toLocaleTimeString();

  return { date, time };
};

// Technical Debt: Should fix all bugs with incorrect timestamps
export const normalizerTimestamp = (timestamp?: number, type?: string): number => {
  if (!timestamp) {
    return 0;
  }

  let result = timestamp;

  if (timestamp < 946677600) {
    // 2000.01.01
    result = timestamp * 1000;
  }

  if (timestamp > 946677600000) {
    // timestamp with milliseconds
    result = Math.floor(timestamp / 1000);
  }

  if (type === 'ms') {
    result *= 1000;
  }

  return result;
};

export const tomorrowDate = (): number => Math.floor(new Date().getTime() / 1000 + 24 * 60 * 60);

export const formatTimestamp = (timestamp: number | string): string => {
  // Technical Debt: Should be remove from it
  const date = new Date(timestamp);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  if (date.getFullYear() < 2000) return '';
  return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
};

export const getHoursAndMinutes = (timestamp: number | string) => {
  // Technical Debt: make generic format function with passing time format option
  const date = new Date(timestamp);
  const hoursAndMinutes = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  });
  return hoursAndMinutes;
};

export const timeList = (): string[] => {
  const list: string[] = [];

  for (let hour = 0; hour < 24; hour += 1) {
    for (let minute = 0; minute < 60; minute += 30) {
      const formattedHour = (hour % 12 === 0 ? 12 : hour % 12).toString().padStart(2, '0');
      const formattedMinute = minute === 0 ? '00' : minute.toString();
      const ampm = hour < 12 ? 'AM' : 'PM';

      const time = `${formattedHour}:${formattedMinute} ${ampm}`;
      list.push(time);
    }
  }

  return list;
};
