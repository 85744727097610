/* eslint-disable prettier/prettier */
import { RosterType, PayloadLinemenType, PayloadVehiclesType, VehicleType } from 'src/types';
import { getUrl } from 'src/utils';
import axios from '../axios';
import routes from '../routes';

const rosters = {
  // Rosters
  loadRosters: ({ incidentId }: Partial<RosterType>): Promise<Partial<RosterType>[]> =>
    axios.get(routes.ROSTERS + (incidentId ? `?incidentId=${incidentId}` : '')),

  // Roster
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createRoster: async ({
    // status,
    ...roster
  }: Partial<RosterType>): Promise<Partial<RosterType>> => axios.post(routes.ROSTERS, roster),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  saveRoster: async ({
    // incidentId,
    id,
    ...roster
  }: Partial<RosterType>): Promise<Partial<RosterType>> => axios.patch(getUrl(routes.ROSTER, { id }), roster),
  loadRoster: ({ id, version }: Partial<RosterType>): Promise<Partial<RosterType>[]> =>
    version ? axios.get(getUrl(routes.ROSTER_WITH_VERSION, { id, version })) : axios.get(getUrl(routes.ROSTER, { id })),
  deleteRoster: ({ id }: { id: number }): Promise<Partial<RosterType>> => axios.delete(getUrl(routes.ROSTER, { id })),
  rosterAttachLinemen: ({ id, linemen, type }: Partial<PayloadLinemenType>): Promise<Partial<RosterType>> =>
    axios.post(getUrl(routes.ROSTER_ASSIGNMENTS, { id }), { uuids: linemen, type }),
  rosterAttachVehicles: ({ id, vehicles }: Partial<PayloadVehiclesType>): Promise<Partial<RosterType>> =>
    axios.post(
      getUrl(routes.ROSTER_EQUIPMENTS, { id }),
      vehicles?.map(({ id }) => id),
    ),
  rosterDetachLineman: ({ id, uuids }: { id: number; uuids: string[] }): Promise<Partial<RosterType>> =>
    axios.delete(getUrl(routes.ROSTER_ASSIGNMENTS, { id }), { data: { uuids } }),
  rosterDetachVehicles: ({ id, vehicles }: Partial<PayloadVehiclesType>): Promise<Partial<RosterType>> =>
    axios.delete(getUrl(routes.ROSTER_EQUIPMENTS, { id }), { data: vehicles?.map(({ id }) => id) }),
  rosterAvailableVehicles: ({ id }: { id: number }): Promise<Partial<VehicleType>> =>
    axios.get(routes.ROSTER_AVAILABLE_VEHICLES, { params: { rosterId: id } }),
  rosterAssignments: ({ id, assignments }: { id: number; assignments: unknown }): Promise<Partial<RosterType>> =>
    axios.patch(getUrl(routes.ROSTER_ASSIGNMENTS, { id }), { assignments }),
  rosterLinemanSetOptions: ({ id, uuid, options }: { id: number; uuid: string; options: unknown }): Promise<Partial<RosterType>> =>
    axios.patch(getUrl(routes.ROSTER_LINEMAN_SET_OPTIONS, { id, uuid }), options),
  rosterEquipmentSetOptions: ({ id, uuid, options }: { id: number; uuid: string; options: unknown }): Promise<Partial<RosterType>> =>
    axios.patch(getUrl(routes.ROSTER_EQUIPMENT_SET_OPTIONS, { id, uuid }), options),
  rosterSetStatus: ({ id, status, message }: { id: number; status: number; message?: string }): Promise<Partial<RosterType>> =>
    axios.patch(getUrl(routes.ROSTER_SET_STATUS, { id }), {
      status,
      rejectReason: message,
    }),
  rosterTimesheet: ({ id, data }: Partial<{ id: number; data: any }>): Promise<Partial<RosterType>> =>
    axios.post(getUrl(routes.ROSTER_TIMESHEET, { id }), data),
  rosterTransfer: ({
    id,
    incidentId,
    rosterData,
  }: Partial<{
    id: number;
    incidentId: number;
    rosterData: Partial<RosterType>;
  }>): Promise<Partial<RosterType>> =>
    axios.post(getUrl(routes.ROSTER_TRANSFER, { id }), {
      incidentId,
      rosterData,
    }),
  rosterSplit: ({
    id,
    rosterData,
  }: Partial<{
    id: number;
    rosterData: Partial<RosterType>;
  }>): Promise<Partial<RosterType>> => axios.post(getUrl(routes.ROSTER_SPLIT, { id }), rosterData),
  rosterRevertVersion: ({ id }: { id: number }): Promise<Partial<RosterType>> => axios.patch(getUrl(routes.ROSTER_REVERT, { id })),
};

export default rosters;
