import { styled } from '@mui/material/styles';
import MuiAvatar from '@mui/material/Avatar';
import { P1R } from 'src/components';
import { themeColors } from 'src/theme';

const StyledAvatar = styled(MuiAvatar)({
  backgroundColor: themeColors.lightYellow,
  color: themeColors.yellowMain,
  borderRadius: '4px',
  width: '44px',
  height: '44px',
});

type Props = {
  name: string;
};

const Avatar = ({ name }: Props) => {
  const getInitials = (name: string) => {
    return `${name.split(' ')[0][0] || ''}${name.split(' ')[1] ? name.split(' ')[1][0] : ''}`;
  };

  return (
    <StyledAvatar>
      <P1R>{getInitials(name)}</P1R>
    </StyledAvatar>
  );
};

export default Avatar;
