import { Role } from 'src/types';
import { createReducer } from '../../utils/toolkit';

const initialState: Partial<Role[]> = [];

const {
  reducer,
  actions: { set: setRoles, merge: mergeRoles, reset: resetRoles },
} = createReducer('roles', initialState);

export default reducer;
export { setRoles, mergeRoles, resetRoles };
