import { createSelector } from 'reselect';
import { TYPES } from './constants';
import { createLoadingSelector } from '../@loadings';
import { createSuccessSelector } from '../@success';
import { createErrorSelector, createErrorPayloadSelector } from '../@errors';
import { VehicleDataState } from './types';
import { ReduxState } from '../types';

// Vehicle
export const selectVehicleData = (state: ReduxState): VehicleDataState => state.vehicleData;
export const selectVehicle = createSelector(selectVehicleData, ({ vehicle }) => vehicle);
export const selectVehicleId = createSelector(selectVehicle, (vehicle) => vehicle.id);

export const selectVehicleLoading = createLoadingSelector(TYPES.LOAD_VEHICLE.TYPE);
export const selectVehicleSuccess = createSuccessSelector(TYPES.LOAD_VEHICLE.TYPE);
export const selectVehicleError = createErrorSelector(TYPES.LOAD_VEHICLE.TYPE);

export const selectCreateVehicleLoading = createLoadingSelector(TYPES.CREATE_VEHICLE.TYPE);
export const selectCreateVehicleSuccess = createSuccessSelector(TYPES.CREATE_VEHICLE.TYPE);
export const selectCreateVehicleError = createErrorSelector(TYPES.CREATE_VEHICLE.TYPE);

export const selectSaveVehicleLoading = createLoadingSelector(TYPES.SAVE_VEHICLE.TYPE);
export const selectSaveVehicleSuccess = createSuccessSelector(TYPES.SAVE_VEHICLE.TYPE);
export const selectSaveVehicleErrors = createErrorPayloadSelector(TYPES.SAVE_VEHICLE.TYPE);
