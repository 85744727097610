import { useEffect, useState } from 'react';
import { AccordionDetails, AccordionProps, AccordionSummary, Box, Grid, IconButton, styled, useMediaQuery } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import config from 'src/config';
import { themeColors } from 'src/theme';
import { Button, H3, Icon, P1R, P2, P2R, Table } from 'src/components';
import RosterStatus from 'src/components/RostersTable/RosterStatus';
import { PATHS } from 'src/navigation';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectRoster } from 'src/redux/roster/selectors';
import { loadRoster } from 'src/redux/roster/actions';
import { formatTimestamp } from 'src/utils';
import { selectUser } from 'src/redux/user/selectors';
import { useTheme } from '@mui/material/styles';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { DashboardWrapper } from '../wrappers';
import RosterOverviewHeader from './RosterOverviewHeader';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  borderBottom: `1px solid ${themeColors.grayDisabled}`,
  '&:not(:last-child)': {
    borderBottom: `1px solid ${themeColors.grayDisabled}`,
  },
  '&::before': {
    display: 'none',
  },
}));

export default function RosterOverviewContainer() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id = '0' } = useParams();
  const { state } = useLocation();
  const rosterId = parseInt(id, 10);
  const dispatch = useDispatch();
  const roster = useSelector(selectRoster);
  const user = useSelector(selectUser);
  const [activeTab, setActiveTab] = useState('overview');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // @ts-ignore
  const myCrew = roster.linemen?.find((lineman) => lineman.uuid === user.uuid)?.assignments[0].crew || '-';

  useEffect(() => {
    dispatch(loadRoster.init({ id: rosterId, version: state?.version }));
  }, [dispatch, state]);

  const crewColumns: GridColDef[] = [
    {
      field: 'fullName',
      headerName: t('rosterOverviewPage.fullName'),
      minWidth: 150,
    },
    {
      field: 'unionClassification',
      headerName: t('rosterOverviewPage.unionClass'),
      renderCell: (params) => (params.value ? t(`union_classifications.${roster.incident?.ibewPay}.${params.value}`) : ''),
      minWidth: 300,
    },
    {
      field: 'phone',
      headerName: t('rosterOverviewPage.phone'),
      minWidth: 150,
    },
    {
      field: 'assignedVehicle',
      headerName: t('rosterOverviewPage.assignedVehicle'),
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => (params.value ? t(`vehicles.${params.value}`) : null),
    },
    {
      field: 'companyTag',
      headerName: t('rosterOverviewPage.companyTag'),
      minWidth: 150,
    },
  ];

  const crewRows = roster?.myCrew?.map((lineman) => {
    return {
      id: lineman.uuid,
      fullName: `${lineman.firstName} ${lineman.lastName}`,
      unionClassification: lineman.assignments[0].unionClassification,
      phone: lineman.phone,
      assignedVehicle: lineman.assignedVehicle,
      companyTag: lineman.companyTag,
    };
  });

  const equipmentColumns: GridColDef[] = [
    {
      field: 'type',
      headerName: t('rosterOverviewPage.type'),
      flex: 1,
      renderCell: (params: GridRenderCellParams) => t(`vehicles.${params.value}`),
    },
    {
      field: 'companyTag',
      headerName: t('rosterOverviewPage.companyTag'),
      minWidth: 200,
    },
    {
      field: 'crewId',
      headerName: t('rosterOverviewPage.crewId'),
      minWidth: 200,
    },
    {
      field: 'assignedTo',
      headerName: t('rosterOverviewPage.assignedTo'),
      flex: 1,
    },
  ];

  const equipmentRows = roster.myEquipments?.map((vehicle) => {
    return {
      id: vehicle.id,
      type: vehicle.type,
      companyTag: vehicle.companyTag,
      crewId: vehicle.crews?.map((crew) => crew.name).join(', '),
      assignedTo: vehicle.crews
        ?.map((crew) =>
          crew.linemen
            .map((lineman) => {
              return `${lineman.firstName} ${lineman.lastName}`;
            })
            .join(', '),
        )
        .join(', '),
    };
  });

  const arrowIcon = (
    <Box
      sx={{
        width: '20px',
        height: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        transform: 'rotate(90deg)',
        backgroundColor: themeColors.grayLight,
      }}
    >
      <Icon name="minimizeArrow" />
    </Box>
  );

  const noData = <P1R sx={{ color: themeColors.grayDisabled }}>{t('common.noData')}</P1R>;

  const rosterContent = (
    <Box
      component="main"
      sx={{
        p: 3,
        flexGrow: 1,
        overflow: 'auto',
      }}
    >
      <Box sx={{ mb: 3, display: 'flex', justifyContent: { xs: 'center', md: 'left' } }}>
        <Box
          sx={{
            width: '100%',
            gap: 2,
            display: 'flex',
            flexDirection: { xs: 'column', md: !roster.isLastVersion ? 'row-reverse' : 'row' },
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {!roster.isLastVersion && (
            <Box
              sx={{
                width: { xs: '100%', md: 'auto' },
                gap: 1,
                p: 1.5,
                display: 'flex',
                alignItems: 'center',
                bgcolor: themeColors.blueLight,
                borderRadius: '4px',
              }}
            >
              <Icon name="info" />
              <P2 sx={{ textTransform: 'none' }}>{t('rosterOverviewPage.archivedRoster')}</P2>
            </Box>
          )}

          <Button
            buttonType="group"
            items={[
              {
                title: t('rosterOverviewPage.overview'),
                onClick: () => {
                  setActiveTab('overview');
                },
                active: activeTab === 'overview',
              },
              {
                title: t('rosterOverviewPage.myCrew'),
                onClick: () => {
                  setActiveTab('myCrew');
                },
                active: activeTab === 'myCrew',
              },
              {
                title: t('rosterOverviewPage.myEqpt'),
                onClick: () => {
                  setActiveTab('myEqpt');
                },
                active: activeTab === 'myEqpt',
              },
            ]}
          />
        </Box>
      </Box>

      {activeTab === 'overview' && (
        <>
          <RosterOverviewHeader incident={roster?.incident ?? {}} />

          <Grid
            container
            rowSpacing={3}
            sx={{
              borderRadius: '4px',
              border: `1px solid ${themeColors.grayMedium}`,
              mt: 3,
              py: 2,
            }}
          >
            <Grid item xs={12} sx={{ mb: 1 }}>
              <H3 sx={{ px: 2 }}>{t('rosterOverviewPage.generalInfo')}</H3>
            </Grid>
            <Grid item xs={6} md={2} sx={{ px: 2, gap: 1.5, display: 'flex', flexDirection: 'column' }}>
              <P2 sx={{ fontWeight: 600 }}>{t('rosterOverviewPage.reportDay')}</P2>
              <P1R>{formatTimestamp(+roster.startDate * 1000)}</P1R>
            </Grid>
            <Grid item xs={6} md={2} sx={{ px: 2, gap: 1.5, display: 'flex', flexDirection: 'column' }}>
              <P2 sx={{ fontWeight: 600 }}>{t('rosterOverviewPage.reportTime')}</P2>
              <P1R>{roster?.startTime}</P1R>
            </Grid>
            <Grid item xs={6} md={2} sx={{ px: 2, gap: 1.5, display: 'flex', flexDirection: 'column' }}>
              <P2 sx={{ fontWeight: 600 }}>{t('rosterOverviewPage.reportToYard')}</P2>
              <P1R>
                {
                  // @ts-ignore
                  // eslint-disable-next-line
                  config.options.yards?.[roster?.yardFK - 1]?.value
                }
              </P1R>
            </Grid>
            <Grid item xs={6} md={2} sx={{ px: 2, gap: 1.5, display: 'flex', flexDirection: 'column' }}>
              <P2 sx={{ fontWeight: 600 }}>{t('rosterOverviewPage.myCrew')}</P2>
              <P1R>#{myCrew}</P1R>
            </Grid>
            <Grid item xs={6} md={2} sx={{ px: 2, gap: 1.5, display: 'flex', flexDirection: 'column' }}>
              <P2 sx={{ fontWeight: 600 }}>{t('rosterOverviewPage.ftes')}</P2>
              <P1R>{roster?.linemen?.length ?? 0}</P1R>
            </Grid>
            <Grid item xs={6} md={2} sx={{ px: 2, gap: 1.5, display: 'flex', flexDirection: 'column' }}>
              <P2 sx={{ fontWeight: 600 }}>{t('rosterOverviewPage.equipment')}</P2>
              <P1R>{roster?.vehicles?.length ?? 0}</P1R>
            </Grid>
          </Grid>

          <Grid
            container
            rowSpacing={3}
            sx={{
              borderRadius: '4px',
              border: `1px solid ${themeColors.grayMedium}`,
              mt: 3,
              py: 2,
            }}
          >
            <Grid item xs={12} sx={{ mb: 1 }}>
              <H3 sx={{ px: 2 }}>{t('rosterOverviewPage.contacts')}</H3>
            </Grid>
            <Grid item xs={12} md={3} sx={{ px: 2, gap: 1.5, display: 'flex', flexDirection: 'column' }}>
              <P2 sx={{ fontWeight: 600, pb: '10px' }}>{t('rosterOverviewPage.companyContact')}</P2>
              <P1R>{`${roster?.incident?.createdBy?.firstName || ''} ${roster?.incident?.createdBy?.lastName || ''}`}</P1R>
              <P1R>{roster?.incident?.createdBy?.phone || ''}</P1R>
              <P1R>{roster?.incident?.createdBy?.email || ''}</P1R>
            </Grid>
            <Grid item xs={12} md={3} sx={{ px: 2, gap: 1.5, display: 'flex', flexDirection: 'column' }}>
              <P2 sx={{ fontWeight: 600, pb: '10px' }}>{t('rosterOverviewPage.crewContact')}</P2>
              <P1R>{`${roster.ownedBy?.firstName || ''} ${roster.ownedBy?.lastName || ''}`}</P1R>
              <P1R>{roster.ownedBy?.phone}</P1R>
              <P1R>{roster.ownedBy?.email}</P1R>
            </Grid>
            <Grid item xs={12} md={3} sx={{ px: 2, gap: 1.5, display: 'flex', flexDirection: 'column' }}>
              <P2 sx={{ fontWeight: 600, pb: '10px' }}>{t('rosterOverviewPage.alternateContact')}</P2>
              {roster.contactFullName || roster.contactPhone || roster.contactEmail ? (
                <>
                  <P1R>{roster.contactFullName || ''}</P1R>
                  <P1R>{roster.contactPhone}</P1R>
                  <P1R>{roster.contactEmail}</P1R>
                </>
              ) : (
                noData
              )}
            </Grid>
          </Grid>
        </>
      )}

      {activeTab === 'myCrew' && (
        <Box sx={{ gap: 2, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ pl: 2 }}>
            <H3>
              {t('rosterOverviewPage.myCrew')} {typeof myCrew === 'number' ? `#${myCrew}` : myCrew}
            </H3>
          </Box>

          {isMobile ? (
            <Box sx={{ px: 2, py: 1, backgroundColor: themeColors.grayLight, borderRadius: '4px' }}>
              <P2>{t('rosterOverviewPage.fullName')}</P2>
            </Box>
          ) : null}

          {isMobile ? (
            <div>
              {roster.myCrew?.map((lineman) => (
                <Accordion>
                  <AccordionSummary expandIcon={arrowIcon} aria-controls="panel1-content" id="panel1-header">
                    <Box sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
                      <P1R>
                        {lineman.firstName} {lineman.lastName}
                      </P1R>
                      {lineman.assignments[0].isLead && <Icon name="foreman" />}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ gap: 2, display: 'flex', flexDirection: 'column' }}>
                      <Box sx={{ gap: 1.5, display: 'flex', flexDirection: 'column' }}>
                        <P2 sx={{ fontWeight: '600' }}>{t('rosterOverviewPage.unionClassification')}</P2>
                        <P1R>{lineman.assignments[0].unionClassification ?? noData}</P1R>
                      </Box>
                      <Box sx={{ gap: 1.5, display: 'flex', flexDirection: 'column' }}>
                        <P2 sx={{ fontWeight: '600' }}>{t('rosterOverviewPage.phone')}</P2>
                        <P1R>{lineman.phone ?? noData}</P1R>
                      </Box>
                      <Box sx={{ gap: 1.5, display: 'flex', flexDirection: 'column' }}>
                        <P2 sx={{ fontWeight: '600' }}>{t('rosterOverviewPage.assignedVehicle')}</P2>
                        <P1R>{lineman?.assignedVehicle ? t(`vehicles.${lineman?.assignedVehicle}`) : noData}</P1R>
                      </Box>
                      <Box sx={{ gap: 1.5, display: 'flex', flexDirection: 'column' }}>
                        <P2 sx={{ fontWeight: '600' }}>{t('rosterOverviewPage.companyTag')}</P2>
                        <P1R>{lineman?.companyTag ?? noData}</P1R>
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          ) : (
            <div>
              <Table type="advanced" columns={crewColumns} rows={crewRows || []} getRowId={(row) => row.id} />
            </div>
          )}
        </Box>
      )}

      {activeTab === 'myEqpt' && (
        <Box sx={{ gap: 2, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ pl: 2 }}>
            <H3>{t('rosterOverviewPage.myCrewEqpt')}</H3>
          </Box>

          {isMobile ? (
            <Box sx={{ px: 2, py: 1, backgroundColor: themeColors.grayLight, borderRadius: '4px' }}>
              <P2>{t('rosterOverviewPage.type')}</P2>
            </Box>
          ) : null}

          {isMobile ? (
            <div>
              {roster.myEquipments?.map((vehicle) => (
                <Accordion>
                  <AccordionSummary expandIcon={arrowIcon} aria-controls="panel1-content" id="panel1-header">
                    <Box sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
                      <Icon name={vehicle.type} fontSize="small" />
                      <P1R>{t(`vehicles.${vehicle.type}`)}</P1R>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ gap: 2, display: 'flex', flexDirection: 'column' }}>
                      <Box sx={{ gap: 1.5, display: 'flex', flexDirection: 'column' }}>
                        <P2 sx={{ fontWeight: '600' }}>{t('rosterOverviewPage.companyTag')}</P2>
                        <P1R>{vehicle.companyTag ?? noData}</P1R>
                      </Box>
                      <Box sx={{ gap: 0.5, display: 'flex', flexDirection: 'column' }}>
                        <P2 sx={{ fontWeight: '600' }}>{t('rosterOverviewPage.assignedPeople')}</P2>
                        {vehicle?.crews?.length
                          ? vehicle?.crews?.map((crew) => (
                              <Box>
                                <P2 sx={{ fontWeight: '600' }}>crew {crew.name}</P2>
                                <Box sx={{ pt: 2, gap: 1, display: 'flex', flexDirection: 'column' }}>
                                  {crew.linemen.map((lineman) => (
                                    <P1R>
                                      {lineman.firstName} {lineman.lastName}
                                    </P1R>
                                  ))}
                                </Box>
                              </Box>
                            ))
                          : noData}
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          ) : (
            <div>
              <Table type="advanced" columns={equipmentColumns} rows={equipmentRows || []} getRowId={(row) => row.id} />
            </div>
          )}
        </Box>
      )}
    </Box>
  );

  return (
    <>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <DashboardWrapper breadcrumbs={[['home'], ['Invites'], [`${roster.name}`]]}>{rosterContent}</DashboardWrapper>
      </Box>

      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Box sx={{ height: '70px', px: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: themeColors.grayLight }}>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <IconButton onClick={() => navigate(PATHS.INCIDENTS_PAGE)} aria-label="close">
              <Icon name="arrowBack" color="grayDark" />
            </IconButton>

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <H3>{roster.name}</H3>
              <P2R>ID {roster.id}</P2R>
            </Box>
          </Box>

          <RosterStatus status={roster.status} />
        </Box>

        {rosterContent}
      </Box>
    </>
  );
}
