import { IncidentType, UserType } from 'src/types';
import { getUrl } from 'src/utils';
import axios from '../axios';
import routes from '../routes';

const incidents = {
  loadIncidents: ({ query = '' }: { query: string }): Promise<Partial<IncidentType>[]> => axios.get(getUrl(routes.INCIDENTS, { query })),
  loadFilteredIncidents: ({ uuid, filter }: { uuid: string; filter: string }): Promise<Partial<IncidentType>[]> =>
    axios.get(getUrl(routes.FILTERED_INCIDENTS, { uuid, filter })),

  loadIncident: ({ id = '', query = '' }: { id: string; query: string }): Promise<Partial<IncidentType>> =>
    axios.get(getUrl(routes.INCIDENT_WITH_FILTERS, { id, query })),
  getIdIncident: (): Promise<Partial<IncidentType>> => axios.post(routes.INCIDENTS),
  saveIncident: ({ id, ...incident }: Partial<IncidentType>): Promise<Partial<IncidentType>> =>
    axios.patch(getUrl(routes.INCIDENT, { id }), {
      ...incident,
      // TODO: should be use one type object
      assignedTo: incident.assignedTo?.map((user: UserType) => user.uuid || user),
      benefitsPay: incident.benefitsPay || incident.benefitsPay || undefined,
      ibewPay: incident.ibewPay || undefined,
      lodgingProvided: incident.lodgingProvided !== null ? JSON.parse(incident?.lodgingProvided || 'false') : null,
      // TECHNIAL DEBT: should be removed after completing #ERMA-1203
      name: incident.name,
    }),
  deleteIncident: ({ id }: { id: number }): Promise<Partial<IncidentType>> => axios.delete(getUrl(routes.INCIDENT, { id })),
  setInviteIncident: ({ id, status, yardId, rosterId }: Partial<IncidentType>): Promise<Partial<IncidentType>> =>
    axios.patch(getUrl(routes.INCIDENT_INVITATION_STATUS, { id }), { status, yardId, rosterId }),
  incedentDeclineLinemen: ({ id, users, yards }: { id: string; users: string[]; yards: number[] }): Promise<Partial<IncidentType>> =>
    axios.delete(getUrl(routes.INCIDENT_INVITATION_STATUS, { id }), { data: { users, yards } }),
};

export default incidents;
