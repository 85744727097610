import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { Dialog, H1, P1R } from 'src/components';
import googlePlayBtn from 'src/assets/mobileStoreBtns/google-play_btn.png';
import appStoreBtn from 'src/assets/mobileStoreBtns/appstore_btn.png';

type Props = {
  onClose: () => void;
};

const UpdateInfoPopup = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog open fullScreen={fullScreen} onClose={onClose}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{
            gap: 2,
            py: 2,
            px: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <H1 sx={{ my: 2, textAlign: 'center', textTransform: 'capitalize' }}>{t('updateInfoPopup.title')}</H1>

          <Box sx={{ gap: 2, display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
            <P1R>{t('updateInfoPopup.subTitle1')}</P1R>
            <P1R>{t('updateInfoPopup.subTitle2')}</P1R>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, my: 2 }}>
            <Box sx={{ cursor: 'pointer' }}>
              <a href="https://play.google.com/store/apps/details?id=com.clearme.clearapp&hl=en_US" target="_blank" rel="noopener noreferrer">
                <img src={googlePlayBtn} alt="Google Play Button" />
              </a>
            </Box>
            <Box sx={{ cursor: 'pointer' }}>
              <a href="https://apps.apple.com/us/app/clear-travel-experiences/id1436333504" target="_blank" rel="noopener noreferrer">
                <img src={appStoreBtn} alt="AppStore Button" />
              </a>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UpdateInfoPopup;
