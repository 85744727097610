import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { P1R, H2, Button, Toolbar, UnsavedChangesPopup, rosterColumnVisibilityModel, Icon, RosterTable, EmptyState } from 'src/components';
import { selectUser } from 'src/redux/user/selectors';
import { selectRoster } from 'src/redux/roster/selectors';
import { UserType } from 'src/types';
import { useBeforeUnload } from 'src/hooks';
import { themeColors } from 'src/theme';
import config from 'src/config';

const boxStyle = {
  gap: '4px',
  display: 'flex',
  alignItems: 'center',
};

type Props = {
  rosterStatus: number;
  support: Partial<UserType>[];
  fte: Partial<UserType>[];
  selectedSupport: string[];
  setSelectedSupport: (selected: any) => void;
  selectedFte: string[];
  setSelectedFte: (selected: any) => void;
};

const SupportView = ({ rosterStatus, fte, support, selectedSupport, setSelectedSupport, selectedFte, setSelectedFte }: Props) => {
  const { t } = useTranslation();
  const roster = useSelector(selectRoster);
  const user = useSelector(selectUser);
  const [supportIds, setSupportIds] = useState<string[]>([]);
  const [fteIds, setFteIds] = useState<string[]>([]);
  const [supportTable, setSupportTable] = useState<UserType[]>([]);
  const [fteTable, setFteTable] = useState<UserType[]>([]);
  const isEditable = config.can('edit_roster', { user, roster });

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => !!(selectedSupport.length || selectedFte.length) && currentLocation.pathname !== nextLocation.pathname,
  );
  useBeforeUnload(!!(selectedSupport.length || selectedFte.length));

  const removeIntersection = (intersect: string[], array: string[]) => array.filter((item) => !intersect.includes(item));

  const moveToRosterTable = () => {
    const peopleToMove = supportTable.filter((item) => supportIds.includes(item.uuid));

    setFteTable((people) => [...people, ...peopleToMove]);
    setSupportTable((people) => people.filter((item) => !supportIds.includes(item.uuid)));

    const intersection = selectedFte.filter((id) => supportIds.includes(id));
    setSelectedSupport((peopleIds: string[]) => removeIntersection(intersection, [...peopleIds, ...supportIds]));
    setSelectedFte((peopleIds: string[]) => removeIntersection(intersection, peopleIds));
    setSupportIds([]);
  };

  const removeFromRosterTable = () => {
    const peopleToMove = fteTable.filter((item) => fteIds.includes(item.uuid));

    setSupportTable((people) => [...people, ...peopleToMove]);
    setFteTable((people) => people.filter((item) => !fteIds.includes(item.uuid)));

    const intersection = selectedSupport.filter((id) => fteIds.includes(id));
    setSelectedFte((peopleIds: string[]) => removeIntersection(intersection, [...peopleIds, ...fteIds]));
    setSelectedSupport((peopleIds: string[]) => removeIntersection(intersection, peopleIds));
    setFteIds([]);
  };

  const toolbarOptionsVisible = { quickFilter: true, filterBtn: true, columnsBtn: true };

  useEffect(() => {
    setSupportTable(support as UserType[]);
    setFteTable(fte as UserType[]);
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box mb={1} mt={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', minHeight: '50px' }}>
            <H2 sx={{ textTransform: 'capitalize' }}>{t('rosterStandByPage.availableToAddSupport')}</H2>
            <Box display="flex">
              {supportTable?.length ? (
                <Box sx={{ maxWidth: '250px' }}>
                  <Toolbar id="support-roster" options={toolbarOptionsVisible} />
                </Box>
              ) : null}
              {isEditable && (
                <Button
                  disabled={!supportIds.length}
                  onClick={moveToRosterTable}
                  sx={{
                    padding: 0,
                    ml: 1,
                    '&.MuiButtonBase-root': {
                      height: '48px',
                      width: '48px',
                      minWidth: '48px',
                    },
                  }}
                >
                  <ArrowForwardIcon sx={{ height: '18px', width: '16px' }} />
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box mb={1} mt={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', minHeight: '50px' }}>
            <H2 sx={{ textTransform: 'capitalize' }}>
              {t('rosterFleetPage.addedToRoster')} ({fteTable?.length})
            </H2>
            <Box display="flex">
              {fteTable?.length ? (
                <Box sx={{ maxWidth: '250px' }}>
                  <Toolbar id="fte-roster" options={toolbarOptionsVisible} />
                </Box>
              ) : null}
              {isEditable && (
                <Button
                  disabled={!fteIds.length}
                  onClick={removeFromRosterTable}
                  sx={{
                    padding: 0,
                    ml: 1,
                    '&.MuiButtonBase-root': {
                      height: '48px',
                      width: '48px',
                      minWidth: '48px',
                    },
                  }}
                >
                  <ArrowBackIcon sx={{ height: '18px', width: '16px' }} />
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ gap: '24px', display: 'flex', mb: 1 }}>
            <Box style={boxStyle}>
              <Icon name="foreman" />
              <P1R>{t('fteStatuses.crewLead')}</P1R>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ maxHeight: '50vh' }}>
          {supportTable?.length ? (
            <RosterTable
              id="support-roster"
              toolbarProps={{ id: 'support-roster', options: toolbarOptionsVisible }}
              linemen={supportTable as UserType[]}
              ibewPay={roster.incident?.ibewPay || ''}
              rosterId={roster.id as number}
              rosterStatus={rosterStatus}
              fleet={roster.vehicles}
              utilityId={roster.incident?.utilityId}
              columnVisibilityModel={{
                ...rosterColumnVisibilityModel,
                firstName: true,
                lastName: true,
                email: true,
              }}
              onRowSelectionModelChange={(selected: any) => {
                setSupportIds(selected);
              }}
              rowSelectionModel={supportIds}
              checkboxSelection={config.can('edit_roster')}
              hideFooter
              sx={{ border: `1px solid ${themeColors.grayMedium}` }}
            />
          ) : (
            <EmptyState type="people" size="small" withBorder subTitle={t('rosterPage.supportEmptyAvailable')} />
          )}
        </Grid>
        <Grid item xs={6} sx={{ maxHeight: '50vh' }}>
          {fteTable.length ? (
            <RosterTable
              id="fte-roster"
              toolbarProps={{ id: 'fte-roster', options: toolbarOptionsVisible }}
              ibewPay={roster.incident?.ibewPay || ''}
              rosterId={roster.id as number}
              rosterStatus={rosterStatus}
              linemen={fteTable}
              fleet={roster.vehicles}
              utilityId={roster.incident?.utilityId}
              columnVisibilityModel={{
                ...rosterColumnVisibilityModel,
                firstName: true,
                lastName: true,
                inviteStatus: true,
                unionClassification: true,
              }}
              onRowSelectionModelChange={(selected: any) => {
                setFteIds(selected);
              }}
              rowSelectionModel={fteIds}
              checkboxSelection={config.can('edit_roster')}
              hideFooter
              sx={{ border: `1px solid ${themeColors.grayMedium}` }}
            />
          ) : (
            <EmptyState type="people" size="small" withBorder subTitle={t('rosterPage.supportEmptyAdded')} />
          )}
        </Grid>
      </Grid>
      {blocker.state === 'blocked' && <UnsavedChangesPopup onProceed={blocker.proceed} onCancel={blocker.reset} />}
    </>
  );
};

export default SupportView;
