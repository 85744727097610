import { combineReducers } from 'redux';
import options from './options';

const optionsData = combineReducers({
  options,
});

export * from './options';

export default optionsData;
