import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import { Button } from 'src/components';
import { RosterType } from 'src/types';
import { DataCSVType, generateCSV, formatIncidentId } from 'src/utils';
import config from 'src/config';

function UtilityRosterCSV(roster: RosterType) {
  const { t } = useTranslation();
  // @ts-ignore
  const currUtility = config.options.utilities.find((obj: ListType) => obj.key === roster.incident?.utilityId)?.value;

  /*
  const classificationName = (classificationId: number): string =>
    config.classifications.find(
      (obj: { key: number; value: string }) => obj.key === classificationId,
      // @ts-ignore
    )?.value || '';
  */
  const csvData = [
    [
      'fullName',
      'firstName',
      'lastName',
      'phone',
      'contactPhone',
      'state',
      'gender',
      'utilityFteClassification',
      'crewId',
      'crewLead',
      'companyTag',
      'registrationState',
      'registrationPlate',
      'utilityClassification',
      'lodging',
      'supervisorName',
      'supervisorPhone',
    ],
    ...(roster.linemen || []).map((lineman) => {
      const assignment = lineman?.assignments?.[0];
      const assignedVehicle = roster.vehicles?.find((vehicle) => vehicle.id === assignment?.vehicleId);

      return [
        `${lineman.firstName} ${lineman.lastName}`,
        lineman.firstName,
        lineman.lastName,
        lineman.phone,
        lineman.contactPhone,
        lineman.state,
        lineman.gender,
        assignment?.utilityFteClassification && t(`utility_fte_classifications.${currUtility}.${assignment.utilityFteClassification}`),
        assignment?.crew,
        assignment?.isLead ? 'yes' : 'no',
        assignedVehicle?.companyTag,
        // TODO: Why does the FE and BE use differnt type definitions!?!?!
        // @ts-ignore
        assignedVehicle?.registrationState,
        assignedVehicle?.registrationPlate,
        assignedVehicle?.assignments?.[0].utilityClassification &&
          t(`utility_vehicle_classifications.${currUtility}.${assignedVehicle.assignments?.[0].utilityClassification}`),
        assignment?.lodging,
        `${roster.ownedBy?.firstName} ${roster.ownedBy?.lastName}`,
        roster.ownedBy?.phone,
      ];
    }),
  ];

  return csvData;
}

type Props = {
  roster: RosterType;
};

const UtilityRosterCSVButton: React.FC<Props> = ({ roster, ...props }): JSX.Element => {
  const { t } = useTranslation();
  const normalizeName = (name: any): string => `${name}`.replace(/[^-+a-zA-Z0-9]/g, '-').replace(/^-|-$/g, '');

  const csvFileName = `Storm360_${normalizeName(roster.incident?.name || formatIncidentId(roster.incident?.id))}_${normalizeName(
    roster.name || roster.id,
  )}_${dayjs().format('MM-DD-YYYY')}.csv`;

  const csvData = UtilityRosterCSV(roster);

  return (
    <Button
      href={generateCSV(csvData as DataCSVType)}
      // @ts-ignore
      download={csvFileName}
      primary={false}
    >
      <Tooltip title={t('rosterPage.downloadUtilityCSV')} placement="bottom">
        {/* <Icon name="downloadCSV" fontSize="small" /> */}
        <FileDownloadOutlinedIcon fontSize="small" />
      </Tooltip>
    </Button>
  );
};

export default UtilityRosterCSVButton;
