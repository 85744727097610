import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { SagaAction } from '../../types';
import { rosterAssignments } from '../actions';

type LinkRosterLinemanType = {
  rosterId: number;
  assignments: unknown;
};

function* rosterAssignmentsSaga({ payload: { rosterId, assignments } }: SagaAction<Partial<LinkRosterLinemanType>>): SagaIterator {
  try {
    yield put(rosterAssignments.pending());
    yield call(API.rosterAssignments, {
      id: rosterId || 0,
      assignments,
    });
    yield put(rosterAssignments.success());
  } catch (e) {
    yield put(rosterAssignments.failure());
  }
}

export default rosterAssignmentsSaga;
