import { ReducerActionsMap, ActionsMap, ActionTypesMap, DefaultActionTypeMap } from '../types';

export const DEFAULT_ACTION_TYPES: DefaultActionTypeMap = {
  SET: 'set',
  MERGE: 'merge',
  RESET: 'reset',
};

export const generateActionType = (reducerName: string, action: string): string => `${reducerName}(${action})`;

export const generateActionTypes = <State>(reducerName: string, customActions: ReducerActionsMap<State>): ActionTypesMap => {
  const actionsKeyMap = Object.keys({
    ...DEFAULT_ACTION_TYPES,
    ...customActions,
  });

  return actionsKeyMap.reduce(
    (actionTypes, action) => ({
      ...actionTypes,
      [action]: generateActionType(reducerName, action),
    }),
    {},
  );
};

const generateAction = (type: string) => (payload?: any) => ({
  type,
  payload,
});

export const generateActions = <T>(reducerName: string, actionTypes: ActionTypesMap): ActionsMap<T> => {
  const { SET, RESET, MERGE, ...customActionTypes } = actionTypes;
  const actionsKeyMap = Object.keys(customActionTypes);

  const customActions = actionsKeyMap.reduce(
    (actionCreators, actionName) => ({
      ...actionCreators,
      [actionName]: generateAction(actionTypes[actionName]),
    }),
    {},
  );

  return {
    set: generateAction(SET),
    reset: generateAction(RESET),
    merge: generateAction(MERGE),
    ...customActions,
  };
};
