import { UserType } from 'src/types';
import { createReducer } from '../../utils/toolkit';

const initialState: Partial<UserType[]> = [];

const {
  reducer,
  actions: { set: setSupport, merge: mergeSupport, reset: resetSupport },
} = createReducer('support', initialState);

export default reducer;
export { setSupport, mergeSupport, resetSupport };
