import { call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { selectUser } from 'src/redux/user/selectors';
import { setUser } from 'src/redux/user/reducers';
import { notifyUsers } from '../actions';
import { SagaAction } from '../../types';

type NotifyPayloadType = {
  incidentId: number;
  linemanUuids: string[];
  yardIds: number[];
};

function* notifyUsersSaga({ payload: { incidentId, linemanUuids, yardIds } }: SagaAction<NotifyPayloadType>): SagaIterator {
  const user = yield select(selectUser);

  try {
    yield put(notifyUsers.pending());
    yield call(API.notifyUsers, { incidentId, linemanUuids, yardIds });
    yield put(notifyUsers.success());
  } catch (error) {
    // @ts-ignore
    yield put(setUser({ ...user, errorMessage: error.data.error.messages.common[0] }));
    yield put(notifyUsers.failure(error));
  }
}

export default notifyUsersSaga;
