import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Link from '@mui/material/Link';
import { Box, Container, Grid } from '@mui/material';
import { resetUserPassword } from 'src/redux/user/actions';
import { selectSuccessResettingUserPassword, selectErrorResettingUserPassword, selectResettingUserPasswordFailure } from 'src/redux/user/selectors';
import { H1, P1R, Notification, P2 } from 'src/components';
import { themeColors } from 'src/theme';
import { useTranslation } from 'react-i18next';
import { BlankWrapper } from '../wrappers';

const CheckEmailContainer: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const resetPasswordSuccess = useSelector(selectSuccessResettingUserPassword);
  const resetPasswordErrors = useSelector(selectErrorResettingUserPassword) as Record<string, any>;
  const resetPasswordFalure = useSelector(selectResettingUserPasswordFailure);
  const userEmail = localStorage.getItem('email');

  const handleResendPassword = () => {
    dispatch(resetUserPassword.reset());
    dispatch(resetUserPassword.init({ email: userEmail }));
  };

  return (
    <BlankWrapper>
      <Container>
        <Grid container sx={{ padding: { xs: 0, md: 7 }, margin: '0 auto' }} maxWidth="sm">
          <Grid item xs={12} sx={{ pt: { xs: 0, md: 7 } }}>
            <H1 sx={{ marginBottom: '24px' }}>{t('checkEmailPage.title')}</H1>
            <Box sx={{ lineHeight: '18px' }}>
              <P1R>{t('checkEmailPage.subTitle')}</P1R>
              <P1R>{userEmail}</P1R>
            </Box>
            <Box sx={{ marginTop: 2, lineHeight: '18px' }}>
              <P1R>{t('checkEmailPage.subTitleInfo')}</P1R>
            </Box>
            <Box
              sx={{
                marginTop: 5,
                gap: 3,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <P1R>
                {t('checkEmailPage.didntReceive')}
                <Link
                  onClick={handleResendPassword}
                  variant="body2"
                  sx={{
                    ml: 0.5,
                    fontSize: '14px',
                    textDecoration: 'none',
                    color: themeColors.blue,
                    textTransform: 'capitalize',
                    cursor: 'pointer',
                  }}
                >
                  {t('checkEmailPage.resend')}
                </Link>
              </P1R>
              <P1R>
                {t('resetPasswordPage.backTo')}
                <Link
                  component={RouterLink}
                  to="/login"
                  variant="body2"
                  sx={{
                    ml: 0.5,
                    fontSize: '14px',
                    textDecoration: 'none',
                    color: themeColors.blue,
                    textTransform: 'capitalize',
                  }}
                >
                  {t('resetPasswordPage.linkLogin')}
                </Link>
              </P1R>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Notification open={resetPasswordSuccess} onClose={() => dispatch(resetUserPassword.reset())}>
        <P2 sx={{ color: themeColors.white, textTransform: 'none' }}>Link has been resent</P2>
      </Notification>
      <Notification severity="error" open={resetPasswordFalure} onClose={() => dispatch(resetUserPassword.reset())}>
        <P2 sx={{ color: themeColors.white, textTransform: 'none' }}>{resetPasswordErrors?.[0]}</P2>
      </Notification>
    </BlankWrapper>
  );
};

export default CheckEmailContainer;
