import MuiStepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import MuiStepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { styled } from '@mui/material/styles';
import { themeColors } from 'src/theme';

type Props = {
  steps: string[];
  activeStep: number;
};

const StepperStyles = {
  '& .MuiStep-root': {
    height: '100%',
  },
  '& .MuiStepLabel-root': {
    height: '100%',
    justifyContent: 'space-between',
  },
  '& .MuiStepLabel-label': { fontSize: '14px', fontWeight: 600 },
};

const Connector = styled(MuiStepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: themeColors.black,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: themeColors.black,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: themeColors.grayMedium,
    borderTopWidth: 1,
  },
}));

const StyledStepIconRoot = styled('div')<{ ownerState: { active?: boolean; completed?: boolean } }>(({ ownerState }) => ({
  zIndex: 1,
  width: 32,
  height: 32,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  color: themeColors.black,
  backgroundColor: themeColors.grayMedium,
  fontSize: '12px',
  marginTop: '6px',
  ...(ownerState.active && {
    width: 42,
    height: 42,
    color: themeColors.white,
    backgroundColor: themeColors.black,
    marginTop: 0,
  }),
  ...(ownerState.completed && {
    border: `1px solid ${themeColors.black}`,
    backgroundColor: themeColors.white,
  }),
}));

const StyledStepIcon = (props: StepIconProps, index: number) => {
  const { active, completed, className } = props;
  return (
    <StyledStepIconRoot ownerState={{ active, completed }} className={className}>
      {index + 1}
    </StyledStepIconRoot>
  );
};

const Stepper = ({ steps, activeStep }: Props) => {
  return (
    <MuiStepper activeStep={activeStep} connector={<Connector />} alternativeLabel nonLinear sx={StepperStyles}>
      {steps.map((label, index) => (
        <Step key={label} completed={activeStep > index}>
          <StepLabel StepIconComponent={(props) => StyledStepIcon(props, index)}>{label}</StepLabel>
        </Step>
      ))}
    </MuiStepper>
  );
};

export default Stepper;
