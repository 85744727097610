import axios, { AxiosError, AxiosResponse } from 'axios';
import { PATHS } from 'src/navigation';
import config from '../config';

const axiosInstance = axios.create({
  baseURL: config.API,
});
const requestParser = <T>(result: AxiosResponse<T>): T => result.data;
const errorHandler = <T>(error: AxiosError<T>): Promise<never> => Promise.reject(error?.response);
axiosInstance.interceptors.request.use((request) => request, errorHandler);

axiosInstance.interceptors.request.use(async (request) => {
  if (config.TOKEN && request.headers) {
    request.headers.Authorization = `Bearer ${config.TOKEN}`;
  }

  return request;
});

axiosInstance.interceptors.response.use(
  (result) => {
    if (result.request?.responseURL?.includes('/users/me') && !result.data.payload.currentRole) {
      config.update({ TOKEN: '' });
    }
    return requestParser(result);
  },
  (error) => {
    const { response } = error;

    if (!response) {
      // skip the error
    } else if (response.status === 401) {
      config.update({ TOKEN: '' });
      // Technical Debt: Improve error handling logic for API errors from external services(clearme.com)
    } else if (response.status === 403 && !response.config.url.includes('clearme')) {
      if (!config.TOKEN) {
        window.location.href = PATHS.LOGIN_PAGE;
      }

      window.location.href = PATHS.ERROR_403_PAGE;
    } else if (response.status === 404) {
      // Technical debt ERMA-777
      // window.location.href = PATHS.ERROR_404_PAGE;
    }

    return errorHandler(error);
  },
);

export default axiosInstance;
