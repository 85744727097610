import { combineReducers } from 'redux';
import utilityClassifications from './utilityClassifications';

const utilityClassificationsData = combineReducers({
  utilityClassifications,
});

export * from './utilityClassifications';

export default utilityClassificationsData;
