import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { SagaAction } from '../../types';
import { rosterDetachLineman } from '../actions';

type DetachType = {
  id: number;
  uuids: string[];
};

function* rosterDetachLinemanSaga({ payload: { id, uuids } }: SagaAction<DetachType>): SagaIterator {
  try {
    yield put(rosterDetachLineman.pending());
    yield call(API.rosterDetachLineman, { id, uuids });

    yield put(rosterDetachLineman.success());
  } catch (e) {
    yield put(rosterDetachLineman.failure(e));
  }
}

export default rosterDetachLinemanSaga;
