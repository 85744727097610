import { Container, Grid, Box } from '@mui/material';
import { Logo } from 'src/components';
import config from 'src/config';

type BlankProps = {
  children: React.ReactNode;
};

const Blank: React.FC<BlankProps> = ({ children }): JSX.Element => (
  <Container
    maxWidth="xl"
    sx={{
      py: { xs: 4, sm: 8 },
      px: { xs: 2, sm: 8 },
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Grid container>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Logo title={config.getVersion()} />
        </Grid>
      </Grid>
    </Grid>
    <Box component="main" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {children}
    </Box>
  </Container>
);

export default Blank;
