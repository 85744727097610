import { createSelector } from 'reselect';
import { ReduxState } from '../types';
import { ClearmeDataState } from './types';
import { createLoadingSelector } from '../@loadings';
import { createSuccessSelector } from '../@success';
import { createErrorSelector, createErrorPayloadSelector } from '../@errors';
import { TYPES } from './constants';

export const selectClearmeData = (state: ReduxState): ClearmeDataState => state.clearmeData;

export const selectClearme = createSelector(selectClearmeData, ({ clearme }) => clearme);
export const selectClearmeToken = createSelector(selectClearme, (clearme) => clearme.token);

export const selectClearmeLoading = createLoadingSelector(TYPES.LOAD_CLEARME.TYPE);
export const selectClearmeSuccess = createSuccessSelector(TYPES.LOAD_CLEARME.TYPE);
export const selectClearmeError = createErrorSelector(TYPES.LOAD_CLEARME.TYPE);

export const selectClearmeVerifyLoading = createLoadingSelector(TYPES.CLEARME_VERIFY.TYPE);
export const selectClearmeVerifySuccess = createSuccessSelector(TYPES.CLEARME_VERIFY.TYPE);
export const selectClearmeVerifyError = createErrorSelector(TYPES.CLEARME_VERIFY.TYPE);
export const selectClearmeVerifyErrors = createErrorPayloadSelector(TYPES.CLEARME_VERIFY.TYPE);
