import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { GridValueGetterParams, GridRenderCellParams, GridColDef } from '@mui/x-data-grid';

import { RosterType, ListType } from 'src/types';
import { formatTimestamp } from 'src/utils';
import { Table } from 'src/components';
import config from 'src/config';

import { TableProps } from '../Table';
import RosterStatus from './RosterStatus';

export const rostersColumnVisibilityModel = {
  id: false,
  name: false,
  yardFK: false,
  startDate: false,
  startTime: false,
  incident: false,
  status: false,
  ownedBy: false,
  createdBy: false,
};

type Props = {
  rosters: RosterType[];
  setRoster: (rosterId: number) => void;
  isEditable?: boolean;
} & Partial<TableProps>;

const RostersTable: React.FC<Props> = ({ rosters, setRoster, isEditable = false, ...props }): JSX.Element => {
  const { t } = useTranslation();

  const columns: GridColDef[] = [
    { field: 'id', headerName: t('rostersTable.id') },
    {
      field: 'name',
      headerName: t('rostersTable.name'),
      editable: isEditable,
      minWidth: 250,
    },
    {
      field: 'createdBy',
      headerName: t('rostersTable.createdBy'),
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => {
        return `${params.row.createdBy.firstName} ${params.row.createdBy.lastName}`;
      },
    },
    {
      field: 'ownedBy',
      headerName: t('rostersTable.ownedBy'),
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => {
        return `${params.row.ownedBy.firstName} ${params.row.ownedBy.lastName}`;
      },
    },
    {
      field: 'yardFK',
      headerName: t('rostersTable.yardFK'),
      editable: isEditable,
      minWidth: 160,
      valueGetter: (params: GridValueGetterParams) =>
        (config.options.yards.find((item: ListType) => item.key === params.row.yardFK) as ListType | undefined)?.value,
    },
    {
      field: 'startDate',
      headerName: t('rostersTable.startDate'),
      editable: isEditable,
      minWidth: 140,
      // Technical Debt: unixdate startDate should be consistent, either in seconds or milliseconds
      valueGetter: (params: GridValueGetterParams) => formatTimestamp(+params.row.startDate * 1000),
    },
    {
      field: 'startTime',
      headerName: t('rostersTable.startTime'),
      editable: isEditable,
      minWidth: 140,
    },
    {
      field: 'incident',
      headerName: t('rostersTable.eventName'),
      editable: isEditable,
      minWidth: 180,
      valueGetter: (params: GridValueGetterParams) => params.row.incident?.name || '',
    },
    {
      field: 'status',
      headerName: t('rostersTable.status'),
      editable: isEditable,
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Box sx={{ display: 'flex', justifyContent: 'left' }}>
          <RosterStatus status={params.row.status} />
        </Box>
      ),
    },
  ];

  return (
    <Table {...props} type="advanced" columns={columns} rows={rosters} getRowId={(row) => row.id} onRowClick={(row: any) => setRoster(row.id)} />
  );
};

export default RostersTable;
