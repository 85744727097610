import React, { useState, useEffect } from 'react';
import { Grid, Box, CircularProgress } from '@mui/material';
import { StyledWrapper } from './styled';

type Props = {
  message?: string;
};

const LoadingPage: React.FC<Props> = ({ message = '' }) => {
  const [inProgress, setInProgress] = useState(1);

  useEffect(() => {
    const timer = setTimeout(() => {
      setInProgress(0);
    }, 15000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <StyledWrapper>
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '90vh' }}>
        <Box sx={{ display: 'flex', opacity: inProgress }}>
          <CircularProgress />
        </Box>
        <Box sx={{ display: 'flex', opacity: +!inProgress }}>{message}</Box>
      </Grid>
    </StyledWrapper>
  );
};

export default LoadingPage;
