import { Box, MenuItem, Table, TableBody, TableCell, TableRow, TextField } from '@mui/material';
import { FormikProps } from 'formik';
import { useEffect, useState, useRef } from 'react';
import { FormikInputString, Icon, Input, P1M, P1R } from 'src/components';
import config from 'src/config';
import { themeColors } from 'src/theme';
import { ListType, ClassificationType, FleetType } from 'src/types';
import { useTranslation } from 'react-i18next';
import { FormValues } from '../IncidentDialog';

type Props = {
  formik: FormikProps<FormValues>;
  hidden?: boolean;
};

// TODO: move code to hooks.tsx
export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const ExpandIcon = (props: any) => (
  <Box
    sx={{
      width: '20px',
      height: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      backgroundColor: themeColors.white,
    }}
    {...props}
    classes={{
      iconOpen: `{
          transform: 'rotate(0deg)',
        }`,
    }}
  >
    <Icon name="popupIcon" />
  </Box>
);

function Requirements({ formik, hidden = false }: Props) {
  const { t } = useTranslation();
  const [classificationsData, setClassificationsData] = useState<ClassificationType[]>(
    formik.values.classifications.length ? formik.values.classifications : [{ classificationSlug: '', amount: 0 }],
  );
  const [freeClassifications, setFreeClassifications] = useState<string[]>();
  const [fleetData, setFleetData] = useState<FleetType[]>(formik.values.fleet.length > 0 ? formik.values.fleet : [{ vehicleType: '', amount: 0 }]);
  const [freeFleet, setFreeFleet] = useState<string[]>();
  // @ts-ignore
  const currUtility = config.options.utilities.find((utility: ListType) => utility.key === formik.values.utility)?.value || '';
  const prevUtility = usePrevious(currUtility);

  const handleAddClassificationRow = () => {
    const newRow: ClassificationType = { classificationSlug: '', amount: 0 };
    setClassificationsData((prevData) => [...prevData, newRow]);
  };

  const handleAddFleetRow = () => {
    const newRow: FleetType = { vehicleType: '', amount: 0 };
    setFleetData((prevData) => [...prevData, newRow]);
  };

  const handleDeleteClassificationRow = (rowIndex: number) => {
    if (rowIndex === 0 && classificationsData.length === 1) {
      const newRow: ClassificationType = { classificationSlug: '', amount: 0 };
      setClassificationsData([newRow]);
      formik.setFieldValue('classification', classificationsData);
    } else {
      const newData = classificationsData.filter((_, i) => i !== rowIndex);
      setClassificationsData(newData);
      formik.setFieldValue('classification', newData);
    }
  };

  const handleDeleteFleetRow = (rowIndex: number) => {
    if (rowIndex === 0 && fleetData.length === 1) {
      const newRow: FleetType = { vehicleType: '', amount: 0 };
      setFleetData([newRow]);
      formik.setFieldValue('fleet', fleetData);
    } else {
      const newData = fleetData.filter((_, i) => i !== rowIndex);
      setFleetData(newData);
      formik.setFieldValue('fleet', newData);
    }
  };

  useEffect(() => {
    // setFreeClassifications(config.classifications.filter((item: ListType) =>
    // !classificationsData.some((row) => row.classificationId === item.key)));
    setFreeClassifications(
      // @ts-ignore
      config.options.UTILITY_FTE_CLASSIFICATIONS[currUtility]?.filter((item) => !classificationsData?.some((row) => row.classificationSlug === item)),
    );
  }, [classificationsData]);

  useEffect(() => {
    setFreeFleet(
      // @ts-ignore
      config.options.UTILITY_VEHICLE_CLASSIFICATIONS[currUtility]?.filter((item) => !fleetData.some((row) => row.vehicleType === item)),
    );
  }, [fleetData]);

  useEffect(() => {
    if (currUtility && prevUtility && currUtility !== prevUtility) {
      // @ts-ignore
      setFreeFleet(config.options.UTILITY_VEHICLE_CLASSIFICATIONS[currUtility]);
      setFreeClassifications(config.options.UTILITY_FTE_CLASSIFICATIONS[currUtility]);
      formik.setFieldValue('classifications', []);
      formik.setFieldValue('fleet', []);
      setClassificationsData([{ classificationSlug: '', amount: 0 }]);
      setFleetData([{ vehicleType: '', amount: 0 }]);
    }
  }, [currUtility]);

  return (
    <Box sx={{ margin: '16px 0', display: hidden ? 'none' : 'block' }}>
      <Box sx={{ my: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <P1M>{t('eventsPage.fte')}</P1M>

        <Box sx={{ gap: 4, display: 'flex' }}>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
              justifyContent: 'space-between',
              border: `1px solid ${themeColors.grayMedium}`,
              bgcolor: themeColors.grayLight,
              borderRadius: '4px',
            }}
          >
            <Box
              sx={{
                pl: 2,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <P1R>{t('eventsPage.requested')}:</P1R>
            </Box>
            <Box sx={{ width: '82px', borderLeft: `1px solid ${themeColors.grayMedium}` }}>
              <FormikInputString
                type="number"
                formik={formik}
                field="fteRequested"
                label=""
                sx={{
                  mb: -3,
                  border: 'none',

                  '& fieldset': { border: 'none' },
                  '& .MuiInputBase-input': { pl: 4, backgroundColor: 'white', borderRadius: '0 4px 4px 0' },
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              gap: 2,
              display: 'flex',
              alignItems: 'center',
              border: `1px solid ${themeColors.grayMedium}`,
              borderRadius: '4px',
              bgcolor: themeColors.grayLight,
            }}
          >
            <P1R sx={{ pl: 2 }}>{t('eventsPage.disposition')}:</P1R>
            <Box
              sx={{
                width: '82px',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'white',
                borderLeft: `1px solid ${themeColors.grayMedium}`,
                borderRadius: '0 4px 4px 0',
              }}
            >
              <P1R>{classificationsData.reduce<number>((acc, cur) => acc + cur.amount, 0)}</P1R>
            </Box>
          </Box>
        </Box>
      </Box>

      <Table
        sx={{
          borderRadius: '10px',
          border: `1px solid ${themeColors.grayMedium}`,
        }}
      >
        <TableBody>
          {classificationsData.map((row, index) => (
            <TableRow key={index} sx={{ position: 'relative', height: '58px' }}>
              <TableCell
                sx={{
                  py: 0,
                  width: '400px',
                  backgroundColor: themeColors.grayLight,
                  borderRight: `1px solid ${themeColors.grayMedium}`,
                }}
              >
                {row.classificationSlug ? (
                  <P1R>
                    {t(
                      `utility_fte_classifications.${currUtility}.${(config.options.UTILITY_FTE_CLASSIFICATIONS[currUtility] as string[])?.find(
                        (item: string) => item === row.classificationSlug,
                      )}`,
                    )}
                  </P1R>
                ) : (
                  <Input
                    select
                    name="classifications"
                    onChange={(e) => {
                      const updatedData = [...classificationsData];
                      updatedData[index].classificationSlug = e.target.value;
                      setClassificationsData(updatedData);
                      formik.setFieldValue('classifications', updatedData);
                    }}
                    SelectProps={{
                      displayEmpty: true,
                      renderValue: (selected) => selected?.toString() || 'Select',
                      IconComponent: ExpandIcon,
                    }}
                    sx={{
                      margin: '0 0 -30px 0',
                      backgroundColor: 'transparent',
                      border: 'none',
                      pb: 0.5,
                      '& fieldset': { border: 'none' },
                      '& .MuiInputBase-input': { pl: 0, backgroundColor: themeColors.grayLight },
                    }}
                  >
                    {currUtility !== 'N/A' ? (
                      freeClassifications?.map((item: string) => (
                        <MenuItem key={item} value={item} sx={{ fontSize: '14px' }}>
                          {t(`utility_fte_classifications.${currUtility}.${item}`)}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" disabled>
                        <Box sx={{ height: 160, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <P1R>{t('eventsPage.goToGeneralTabs')}</P1R>
                        </Box>
                      </MenuItem>
                    )}
                  </Input>
                )}
              </TableCell>
              <TableCell sx={{ width: '75px', py: 0, pr: 0 }}>
                <TextField
                  type="number"
                  value={row.amount}
                  inputProps={{ min: 0 }}
                  onChange={(e) => {
                    const updatedData = [...classificationsData];
                    const value = parseInt(e.target.value, 10);
                    if (value > 999) return;
                    updatedData[index].amount = value;
                    setClassificationsData(updatedData);
                    formik.setFieldValue('classification', updatedData);
                  }}
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    '& .MuiOutlinedInput-root': { border: 'none' },
                    '& input': { textAlign: 'center' },
                  }}
                />
                {index < 1 && classificationsData[0].classificationSlug === '' ? null : (
                  <Box
                    onClick={() => handleDeleteClassificationRow(index)}
                    sx={{
                      top: '50%',
                      left: '100%',
                      position: 'absolute',
                      transform: 'translate(0%, -50%)',
                      cursor: 'pointer',
                    }}
                  >
                    <Icon name="delete" />
                  </Box>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {classificationsData.length < (config.options.UTILITY_FTE_CLASSIFICATIONS[currUtility] as ListType[])?.length ? (
        <Box
          onClick={handleAddClassificationRow}
          sx={{
            width: '40px',
            height: '40px',
            mt: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            backgroundColor: 'black',
            ':hover': { backgroundColor: themeColors.yellowMain },
          }}
        >
          <Icon name="add" color="white" hoverColor={themeColors.black} fontSize="small" />
        </Box>
      ) : null}

      <Box sx={{ mt: 6, mb: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <P1M>{t('eventsPage.eqpt')}</P1M>

        <Box sx={{ gap: 4, display: 'flex' }}>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
              justifyContent: 'space-between',
              border: `1px solid ${themeColors.grayMedium}`,
              bgcolor: themeColors.grayLight,
              borderRadius: '4px',
            }}
          >
            <Box
              sx={{
                pl: 2,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <P1R>{t('eventsPage.requested')}:</P1R>
            </Box>
            <Box sx={{ width: '82px', borderLeft: `1px solid ${themeColors.grayMedium}` }}>
              <FormikInputString
                type="number"
                formik={formik}
                field="eqptRequested"
                label=""
                sx={{
                  mb: -3,
                  border: 'none',

                  '& fieldset': { border: 'none' },
                  '& .MuiInputBase-input': { pl: 4, backgroundColor: 'white', borderRadius: '0 4px 4px 0' },
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              gap: 2,
              display: 'flex',
              alignItems: 'center',
              border: `1px solid ${themeColors.grayMedium}`,
              borderRadius: '4px',
              bgcolor: themeColors.grayLight,
            }}
          >
            <P1R sx={{ pl: 2 }}>{t('eventsPage.disposition')}:</P1R>
            <Box
              sx={{
                width: '82px',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'white',
                borderLeft: `1px solid ${themeColors.grayMedium}`,
                borderRadius: '0 4px 4px 0',
              }}
            >
              <P1R>{fleetData.reduce<number>((acc, cur) => acc + cur.amount, 0)}</P1R>
            </Box>
          </Box>
        </Box>
      </Box>

      <Table
        sx={{
          borderRadius: '10px',
          border: `1px solid ${themeColors.grayMedium}`,
        }}
      >
        <TableBody>
          {fleetData.map((row, index) => (
            <TableRow key={index} sx={{ position: 'relative', height: '58px' }}>
              <TableCell
                sx={{
                  py: 0,
                  width: '400px',
                  backgroundColor: themeColors.grayLight,
                  borderRight: `1px solid ${themeColors.grayMedium}`,
                }}
              >
                {row.vehicleType ? (
                  <P1R>
                    {t(
                      `utility_vehicle_classifications.${currUtility}.${// @ts-ignore
                      (config.options.UTILITY_VEHICLE_CLASSIFICATIONS[currUtility] as string[])?.find(
                        (vehicle: string) => vehicle === row.vehicleType,
                      )}`,
                    )}
                  </P1R>
                ) : (
                  <Input
                    select
                    name="fleet"
                    onChange={(e) => {
                      const updatedData = [...fleetData];
                      updatedData[index].vehicleType = e.target.value;
                      setFleetData(updatedData);
                      formik.setFieldValue('fleet', updatedData);
                    }}
                    SelectProps={{
                      displayEmpty: true,
                      renderValue: (selected) => selected?.toString() || 'Select',
                      IconComponent: ExpandIcon,
                    }}
                    sx={{
                      margin: '0 0 -30px 0',
                      backgroundColor: 'transparent',
                      border: 'none',
                      pb: 0.5,
                      '& fieldset': { border: 'none' },
                      '& .MuiInputBase-input': { pl: 0, backgroundColor: themeColors.grayLight },
                    }}
                  >
                    {currUtility !== 'N/A' ? (
                      freeFleet?.map((item: string) => (
                        <MenuItem key={item} value={item} sx={{ fontSize: '14px' }}>
                          {t(`utility_vehicle_classifications.${currUtility}.${item}`)}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" disabled>
                        <Box sx={{ height: 160, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <P1R>{t('eventsPage.goToGeneralTabs')}</P1R>
                        </Box>
                      </MenuItem>
                    )}
                  </Input>
                )}
              </TableCell>
              <TableCell sx={{ width: '75px', py: 0, pr: 0 }}>
                <TextField
                  type="number"
                  value={row.amount}
                  inputProps={{ min: 0 }}
                  onChange={(e) => {
                    const updatedData = [...fleetData];
                    const value = parseInt(e.target.value, 10);
                    if (value > 999) return;
                    updatedData[index].amount = value;
                    setFleetData(updatedData);
                    formik.setFieldValue('fleet', updatedData);
                  }}
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    '& .MuiOutlinedInput-root': { border: 'none' },
                    '& input': { textAlign: 'center' },
                  }}
                />
                {index < 1 && fleetData[0].vehicleType === '' ? null : (
                  <Box
                    onClick={() => handleDeleteFleetRow(index)}
                    sx={{
                      top: '50%',
                      left: '100%',
                      position: 'absolute',
                      transform: 'translate(0%, -50%)',
                      cursor: 'pointer',
                    }}
                  >
                    <Icon name="delete" />
                  </Box>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* @ts-ignore */}
      {fleetData.length < config.options.UTILITY_VEHICLE_CLASSIFICATIONS[currUtility]?.length ? (
        <Box
          onClick={handleAddFleetRow}
          sx={{
            width: '40px',
            height: '40px',
            display: 'flex',
            mt: 2,
            mb: 6,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            backgroundColor: 'black',
            ':hover': { backgroundColor: themeColors.yellowMain },
          }}
        >
          <Icon name="add" color="white" hoverColor={themeColors.black} fontSize="small" />
        </Box>
      ) : null}
    </Box>
  );
}

export default Requirements;
