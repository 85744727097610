import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { ProfileType } from 'src/types';

import { SagaAction } from '../../types';
import { saveProfile } from '../actions';

function* saveProfileSaga({ payload }: SagaAction<Partial<ProfileType>>): SagaIterator {
  try {
    yield put(saveProfile.pending());
    yield call(API.saveProfile, payload);
    yield put(saveProfile.success());
  } catch (error) {
    yield put(saveProfile.failure(error));
  }
}

export default saveProfileSaga;
