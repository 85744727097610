import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { VehicleType } from 'src/types';
import { setVehicle } from '../reducers';
import { saveVehicle } from '../actions';
import { SagaAction } from '../../types';
import loadVehiclesSaga from '../../vehicles/sagas/loadVehiclesSaga';

function* saveVehicleSaga({ payload: vehicle }: SagaAction<Partial<VehicleType>>): SagaIterator {
  try {
    yield put(saveVehicle.pending());
    const { payload } = yield call(API.saveVehicle, vehicle);

    yield put(setVehicle(payload));
    yield call(loadVehiclesSaga);

    yield put(saveVehicle.success());
  } catch (e) {
    yield put(saveVehicle.failure(e));
  }
}

export default saveVehicleSaga;
