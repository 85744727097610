import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { loadPermissions, updateRoles, loadRoles } from 'src/redux/roles/actions';
import { selectPermissions, selectPermissionsLoading, selectRoles } from 'src/redux/roles/selectors';
import RolesPermissionsTable from 'src/components/RolesPermissionsTable';
import { Role } from 'src/types';
import Loader from 'src/components/Loader';

type Props = {
  editable?: boolean;
};

function PermissionsTab({ editable }: Props) {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editMode, setEditMode] = useState<boolean>(false);
  const roles = useSelector(selectRoles);
  const permissions = useSelector(selectPermissions);
  const isPermissionsLoading = useSelector(selectPermissionsLoading);

  useEffect(() => {
    dispatch(loadRoles.init());
    dispatch(loadPermissions.init());
  }, []);

  const handleRowsUpdate = (newRows: any[], oldRows: any[]) => {
    const updatedRoles: Role[] = [];
    Object.keys(newRows).forEach((rowKey: any) => {
      roles.forEach((role) => {
        if (newRows[rowKey][role.slug] !== oldRows[rowKey][role.slug]) {
          const r = updatedRoles.find((val) => val.id === role.id) || (updatedRoles.push(role), role);
          /* eslint-disable */
          newRows[rowKey][role.slug]
            ? (r.permissions = r.permissions.concat([{ ...newRows[rowKey].permission }]))
            : (r.permissions = r.permissions.filter((perm) => perm.id != rowKey));
          /* eslint-enable */
        }
      });
    });
    dispatch(updateRoles.init({ roles: updatedRoles }));
    return newRows;
  };

  return (
    <Box sx={{ height: 'calc(100vh - 200px)' }}>
      {isPermissionsLoading ? (
        <Loader />
      ) : (
        <RolesPermissionsTable
          roles={roles}
          permissions={permissions}
          editable={editable}
          processRowsUpdate={(newRows, oldRows) => handleRowsUpdate(newRows, oldRows)}
          onBulkEditStart={() => setEditMode(true)}
          onBulkEditStop={() => setEditMode(false)}
        />
      )}
    </Box>
  );
}

export default PermissionsTab;
