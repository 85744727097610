import { Box, Grid } from '@mui/material';
import { Button, H1, P1R, Dialog } from 'src/components';
import warningSign from 'src/assets/warningYellowTriangle.svg';
import { useTranslation } from 'react-i18next';

type Props = {
  onClose: () => void;
  onConfirm: () => void;
  errorMessage: string;
};

function LinemanNotAvailableDialog({ onClose, onConfirm, errorMessage }: Props) {
  const { t } = useTranslation();
  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <Dialog open onClose={onClose} aria-labelledby="customized-dialog-title">
      <Box
        sx={{
          mb: 5,
          mt: 5,
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        <Box>
          <img src={warningSign} alt="warning" />
        </Box>
        <H1 align="center" sx={{ mt: 3, mb: 2, textTransform: 'capitalize' }}>
          {t('eventPage.somePeopleNotAdded')}
        </H1>
        <P1R sx={{ my: 3 }}>{errorMessage}</P1R>
      </Box>

      <Grid container sx={{ mb: 4 }}>
        <Grid item xs={12}>
          <Button
            onClick={handleConfirm}
            sx={{
              width: '100%',
              textTransform: 'capitalize',
            }}
          >
            {t('eventPage.goBack')}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default LinemanNotAvailableDialog;
