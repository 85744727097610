import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { RosterType } from 'src/types';
import { SagaAction } from '../../types';
import { setVehicles } from '../../vehicles/reducers';
import { loadAvailableVehicles } from '../../vehicles/actions';

function* loadRosterAvailableVehiclesSaga({ payload: { roster } }: SagaAction<Partial<{ roster: RosterType }>>): SagaIterator {
  try {
    yield put(loadAvailableVehicles.pending());
    const { payload } = yield call(API.rosterAvailableVehicles, {
      id: roster?.id || 0,
    });
    yield put(setVehicles(payload));
    yield put(loadAvailableVehicles.success());
  } catch (error) {
    yield put(loadAvailableVehicles.failure(error));
  }
}

export default loadRosterAvailableVehiclesSaga;
