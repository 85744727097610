import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { VehicleType } from 'src/types';
import { setVehicles } from '../reducers';
import { loadAvailableVehicles } from '../actions';

function* loadAvailableVehiclesSaga(): SagaIterator {
  try {
    yield put(loadAvailableVehicles.pending());
    // Technical Debt: This is a temporary solution to get the available vehicles
    // API.loadVehicles is not implemented yet
    const { payload } = yield call(API.availableVehicles);
    // yield put(setVehicles(payload));
    // @ts-ignore
    yield put(setVehicles(payload.filter((car: VehicleType) => !car.administrativeFlag && !car.maintenanceFlag && car.availability)));
    yield put(loadAvailableVehicles.success());
  } catch (e) {
    yield put(loadAvailableVehicles.failure());
  }
}

export default loadAvailableVehiclesSaga;
