export enum PermissionEnum {
  //  SYSTEM
  VIEW_ROLE = 'view_role',
  ADD_ROLE = 'add_role',
  EDIT_ROLE = 'edit_role',
  DELETE_ROLE = 'delete_role',
  DISABLE_ROLE = 'disable_role',
  VIEW_ROLE_PERMISSIONS = 'view_role_permissions',
  EDIT_ROLE_PERMISSIONS = 'edit_role_permissions',
  //  USERS & PROFILE
  CREATE_USER = 'create_user',
  APPROVE_USER = 'approve_user',
  ACTIVATE_USER = 'activate_user',
  BLOCK_USER = 'block_user',
  UNBLOCK_USER = 'unblock_user',
  VIEW_APPROVED_USER_LIST = 'view_approved_user_list',
  VIEW_ACTIVE_USERS_LIST = 'view_active_users_list',
  VIEW_DEACTIVATED_USER_LIST = 'view_deactivated_user_list',
  VIEW_BLOCKED_USERS_LIST = 'view_blocked_users_list',
  VIEW_FULL_USER_PROFILE = 'view_full_user_profile',
  VIEW_FIELD_USER_PROFILE = 'view_field_user_profile',
  VIEW_HIDDEN_ROLES = 'view_hidden_roles',
  EDIT_OWN_PROFILE = 'edit_own_profile',
  EDIT_PPE_DETAILS = 'edit_PPE_details',
  EDIT_EMERGENCY_DETAILS = 'edit_emergency_details',
  EDIT_ADDRESS_DETAILS = 'edit_address_details',
  VIEW_PUBLIC_DETAILS = 'view_public_details',
  UPLOAD_ANY_ATTACHMENT = 'upload_any_attachment',
  REMOVE_ANY_ATTACHMENT = 'remove_any_attachment',
  VIEW_ONBOARDING_DETAILS = 'view_onboarding_details',
  //  EVENT
  VIEW_LIST_EVENTS = 'view_list_events',
  VIEW_DETAILS_EVENTS = 'view_details_events',
  ADD_EVENT = 'add_event',
  EDIT_OWN_EVENT = 'edit_own_event',
  EDIT_ANY_EVENT = 'edit_any_event',
  DELETE_OWN_EVENT = 'delete_own_event',
  DELETE_ANY_EVENT = 'delete_any_event',
  //  ROSTER
  VIEW_LIST_ROSTERS = 'view_list_rosters',
  ADD_NEW_ROSTER_IN_ANY_EVENT = 'add_new_roster_in_any_event',
  ADD_NEW_ROSTER_AS_ASSIGNED_GF = 'add_new_roster_as_assigned_gf',
  VIEW_ANY_ROSTER_DETAILS = 'view_any_roster_details',
  VIEW_ROSTER_DETAILS_USER_IS_MEMBER = 'view_roster_details_user_is_member',
  SEND_ANY_ROSTER_FOR_APPROVAL = 'send_any_roster_for_approval',
  SEND_OWN_ROSTER_FOR_APPROVAL = 'send_own_roster_for_approval',
  APPROVE_AND_REJECT_ANY_ROSTER = 'approve_and_reject_any_roster',
  EDIT_OWN_ROSTER = 'edit_own_roster',
  EDIT_ANY_ROSTER = 'edit_any_roster',
  TRANSFER_OWN_ROSTER = 'transfer_own_roster',
  TRANSFER_ANY_ROSTER = 'transfer_any_roster',
  DELETE_OWN_ROSTER = 'delete_own_roster',
  DELETE_ANY_ROSTER = 'delete_any_roster',
  SPLIT_ANY_ROSTER = 'split_any_roster',
  SPLIT_OWN_ROSTER = 'split_own_roster',
  SPLIT_ANY_ROSTER_OF_OWN_EVENT = 'split_any_roster_of_own_event',
  //  FLEET
  VIEW_LIST_VEHICLES = 'view_list_vehicles',
  ADD_VEHICLES = 'add_vehicles',
  EDIT_VEHICLES = 'edit_vehicles',
  DELETE_VEHICLES = 'delete_vehicles',
  ASSIGN_VEHICLE = 'assign_vehicle',
  //  BROADCAST
  VIEW_LIST_INVITATIONS = 'view_list_invitations',
  SEND_INVITATION = 'send_invitation',
  ACCEPT_OR_REJECT_EVENT_INVITATION = 'accept_or_reject_event_invitation',

  // Deprecated. Should be removed
  EDIT_ANY_PUBLIC_DETAILS = 'edit_any_public_details',
  QUERY_LIST_INCIDENTS = 'query_list_incidents',
  APPROVE_AND_REJECT_ANY_ROSTER_OF_OWN_INCIDENT = 'approve_and_reject_any_roster_of_own_incident',
  SEND_ANY_ROSTER_FOR_APPROVAL_OF_OWN_INCIDENT = 'send_any_roster_for_approval_of_own_incident',
  EDIT_ANY_PRIVATE_DETAILS = 'edit_any_private_details',
  SPLIT_ANY_ROSTER_OF_OWN_INCIDENT = 'split_any_roster_of_own_incident',
  TRANSFER_ANY_ROSTER_OF_OWN_INCIDENT = 'transfer_any_roster_of_own_incident',
  DELETE_ANY_ROSTER_OF_OWN_INCIDENT = 'delete_any_roster_of_own_incident',
  EDIT_OWN_INCIDENT = 'edit_own_incident',
  ADD_INCIDENT = 'add_incident',
  VIEW_DETAILS_INCIDENT = 'view_details_incident',
  DELETE_OWN_INCIDENT = 'delete_own_incident',
  EDIT_ANY_ROSTER_OF_OWN_INCIDENT = 'edit_any_roster_of_own_incident',
  VIEW_LIST_INCIDENTS = 'view_list_incidents',
  EDIT_ANY_INCIDENT = 'edit_any_incident',
  DELETE_ANY_INCIDENT = 'delete_any_incident',
  ACCEPT_OR_REJECT_INCIDENT_INVITATION = 'accept_or_reject_incident_invitation',
}
