import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { Role } from 'src/types';
import { SagaAction } from 'src/redux/types';
import { createRoles } from '../actions';

type PayloadType = {
  roles: Role[];
};

function* createRolesSaga({ payload: { roles } }: SagaAction<PayloadType>): SagaIterator {
  try {
    yield put(createRoles.pending());
    yield call(API.createRoles, roles);

    yield call(API.loadRoles);
    yield put(createRoles.success());
  } catch (e) {
    yield put(createRoles.failure());
  }
}

export default createRolesSaga;
