import { ChangeEvent, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { Button, H1, Dialog, Input, P3 } from 'src/components';
import { themeColors } from 'src/theme';
import { useTranslation } from 'react-i18next';

type Props = {
  onClose: () => void;
  onReject: (message: string) => void;
};

const RejectUnionInfoDialog = ({ onClose, onReject }: Props) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [isLengthError, setIsLengthError] = useState(false);
  const [commentRequiredError, setCommentRequiredError] = useState(false);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newVal = e.target.value;
    setCommentRequiredError(false);
    if (newVal.length <= 512) {
      setIsLengthError(false);
      setInputValue(newVal);
    } else {
      setIsLengthError(true);
      setInputValue(newVal);
    }
  };

  const handleComment = () => {
    if (!inputValue) {
      setCommentRequiredError(true);
    } else {
      onReject(inputValue);
      onClose();
    }
  };

  const handleSkip = () => {
    onReject('Rejected');
    onClose();
  };

  return (
    <Dialog open onClose={onClose} aria-labelledby="customized-dialog-title">
      <Box
        sx={{
          mt: 1,
          mb: 2,
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        <H1 align="center" sx={{ mt: 3, mb: 2, textTransform: 'capitalize' }}>
          {t('profilePage.leaveComment')}
        </H1>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Input
          isOptional
          label={t('profilePage.comment')}
          placeholder={t('common.typeTextHere')}
          multiline
          rows={6}
          value={inputValue}
          onChange={handleInputChange}
          sx={{ '& .MuiInputBase-root': { height: '100%' }, width: '100%' }}
        />
        {isLengthError ? (
          <Box sx={{ mt: -2 }}>
            <P3 sx={{ color: themeColors.red }}>{t('profilePage.characterLimit')}</P3>
          </Box>
        ) : null}
        {commentRequiredError ? (
          <Box sx={{ mt: -2 }}>
            <P3 sx={{ color: themeColors.red }}>{t('profilePage.commentNotEmpty')}</P3>
          </Box>
        ) : null}
      </Box>

      <Grid container columnSpacing={2} sx={{ mb: 4 }}>
        <Grid item xs={6}>
          <Button
            primary={false}
            onClick={handleSkip}
            sx={{
              width: '100%',
              textTransform: 'capitalize',
            }}
          >
            {t('common.skip')}
          </Button>
        </Grid>

        <Grid item xs={6}>
          <Button
            onClick={handleComment}
            sx={{
              width: '100%',
              textTransform: 'capitalize',
            }}
          >
            {t('profilePage.sendComment')}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default RejectUnionInfoDialog;
