import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, styled } from '@mui/material';
import { Button, FormikInputString, H1, Icon } from 'src/components';
import { createRoles } from 'src/redux/roles/actions';
import { selectCreateRoles } from 'src/redux/roles/selectors';

export type FormValues = {
  name?: string;
  slug?: string;
  description?: string;
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '600px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(5),
    paddingBottom: theme.spacing(1),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: '8px',
            top: '8px',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon name="close" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

type RoleDialogProps = {
  onClose: () => void;
};

const RoleDialog: React.FC<RoleDialogProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const createSuccess = useSelector(selectCreateRoles);

  const initialValues: FormValues = {
    name: '',
    slug: '',
    description: '',
  };
  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    slug: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (data) => {
      dispatch(createRoles.init({ roles: [data] }));
    },
  });

  useEffect(() => {
    if (createSuccess) {
      formik.resetForm();
      dispatch(createRoles.reset());
      onClose();
    }
  }, [createSuccess]);

  return (
    <Box>
      <BootstrapDialog open onClose={onClose} aria-labelledby="customized-dialog-title">
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
          <H1 align="center" sx={{ mt: 3 }}>
            Create Role
          </H1>
        </BootstrapDialogTitle>

        <DialogContent>
          <Grid container sx={{ margin: '32px 0 16px 0' }}>
            <Grid item xs={12} sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '100%' }}>
                <FormikInputString formik={formik} field="name" label="Name" />
              </Box>
            </Grid>

            <Grid item xs={12} sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '100%' }}>
                <FormikInputString formik={formik} field="slug" label="Slug" />
              </Box>
            </Grid>

            <Grid item xs={12} sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '100%' }}>
                <FormikInputString formik={formik} field="description" label="Description" />
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              mb: 5,
              gap: 2,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ width: '100%' }}>
              <Button primary={false} onClick={onClose} sx={{ width: '100%', textTransform: 'capitalize' }}>
                {t('editVehicle.cancel')}
              </Button>
            </Box>
            <Box sx={{ width: '100%' }}>
              <Button
                onClick={() => {
                  formik.handleSubmit();
                }}
                sx={{ width: '100%', textTransform: 'capitalize' }}
              >
                {t('editVehicle.save')}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
};

export default RoleDialog;
