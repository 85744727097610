import { createReduxAsyncType } from '../utils/toolkit';

const TYPES = {
  LOAD_INCIDENT: createReduxAsyncType('LOAD_INCIDENT'),
  GET_ID_INCIDENT: createReduxAsyncType('GET_ID_INCIDENT'),
  SAVE_INCIDENT: createReduxAsyncType('SAVE_INCIDENT'),
  DELETE_INCIDENT: createReduxAsyncType('DELETE_INCIDENT'),
  SET_INVITE_INCIDENT: createReduxAsyncType('SET_INVITE_INCIDENT'),
  INCIDENT_DECLINE_LINEMEN: createReduxAsyncType('INCIDENT_DECLINE_LINEMEN'),
};

export { TYPES };
