import { call, put, fork } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import config from 'src/config';
import { resetUser } from '../reducers';
import { logout } from '../actions';

function* logoutSaga(): SagaIterator {
  try {
    yield put(logout.pending());
    yield fork(function* () {
      try {
        yield call(API.logout);
      } catch (e) {
        console.error('Logout request failed:', e);
      }
    });

    config.update({ TOKEN: '' });
    yield put(resetUser());
    yield put(logout.success());
  } catch (e) {
    config.update({ TOKEN: '' });
    yield put(logout.failure());
  }
}

export default logoutSaga;
