import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { Role } from 'src/types';
import { SagaAction } from 'src/redux/types';
import { updateRoles } from '../actions';

type PayloadType = {
  roles: Role[];
};

function* updateRolesSaga({ payload: { roles } }: SagaAction<PayloadType>): SagaIterator {
  try {
    yield put(updateRoles.pending());
    yield call(API.updateRoles, roles);

    yield call(API.loadRoles);
    yield put(updateRoles.success());
  } catch (e) {
    yield put(updateRoles.failure());
  }
}

export default updateRolesSaga;
