import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { setVehicles } from '../reducers';
import { loadFilteredVehicles } from '../actions';
import { SagaAction } from '../../types';

type FilterPayloadType = {
  assignedUuid?: string;
  administrativeFlag?: boolean;
  maintenanceFlag?: boolean;
  availability?: boolean;
};

function* loadFilteredVehiclesSaga({
  payload: { assignedUuid, administrativeFlag, maintenanceFlag, availability },
}: SagaAction<FilterPayloadType>): SagaIterator {
  try {
    yield put(loadFilteredVehicles.pending());
    const { payload } = yield call(API.loadFilteredVehicles, {
      assignedUuid,
      administrativeFlag,
      maintenanceFlag,
      availability,
    });

    yield put(setVehicles(payload));
    yield put(loadFilteredVehicles.success());
  } catch (e) {
    yield put(loadFilteredVehicles.failure());
  }
}

export default loadFilteredVehiclesSaga;
