import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs } from '@mui/material';

import { themeColors } from 'src/theme';
import { Button, Notification, P2, Toolbar } from 'src/components';
import { PermissionEnum } from 'src/types';
import config from 'src/config';

import { loadRoles } from 'src/redux/roles/actions';
import { selectCreateRolesSuccess } from 'src/redux/roles/selectors';
import { useDispatch, useSelector } from 'react-redux';
import RolesTab from './RbacMgmtTabs/RolesTab';
import PermissionsTab from './RbacMgmtTabs/PermissionsTab';
import RoleDialog from './RoleDialog';

type RbacMgmtProps = {
  editable?: boolean;
};

const RbacMgmt: React.FC<RbacMgmtProps> = ({ editable = false, ...props }): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [tabsValue, setTabsValue] = useState<number>(0);
  const [showDialog, setShowDialog] = useState(false);
  const canAddRole = config.can(PermissionEnum.ADD_ROLE);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [roleCreated, setRoleCreated] = useState(false);
  const createRolesSuccess = useSelector(selectCreateRolesSuccess);

  const handleTabsChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabsValue(newValue);
  };

  useEffect(() => {
    if (createRolesSuccess) {
      setRoleCreated(true);
      dispatch(loadRoles.init());
    }
  }, [createRolesSuccess]);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '32px', mt: 5 }}>
        <Tabs value={tabsValue} onChange={handleTabsChange} variant="scrollable" scrollButtons="auto">
          <Tab
            label={<Box sx={{ position: 'relative' }}> {t('rbacPage.roles')} </Box>}
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              color: themeColors.grayDark,
              '&.Mui-selected': { color: 'black' },
            }}
          />

          <Tab
            label={<Box sx={{ position: 'relative' }}> {t('rbacPage.permissions')} </Box>}
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              color: themeColors.grayDark,
              '&.Mui-selected': { color: 'black' },
            }}
          />
          <Toolbar />
          {tabsValue === 0 && canAddRole && (
            <Button
              disabled={editMode}
              onClick={() => setShowDialog(true)}
              sx={{
                ml: 1,
                padding: '0 16px',
                textTransform: 'none',
                whiteSpace: 'nowrap',
              }}
            >
              Create Role
            </Button>
          )}
          {showDialog && <RoleDialog onClose={() => setShowDialog(false)} />}
        </Tabs>
      </Box>

      {tabsValue === 0 && <RolesTab editable={editable} setEditMode={(mode: boolean) => setEditMode(mode)} />}
      {tabsValue === 1 && <PermissionsTab editable={editable} />}

      <Notification open={roleCreated} onClose={() => setRoleCreated(false)}>
        <P2 sx={{ color: themeColors.white, textTransform: 'none' }}>Role created</P2>
      </Notification>
    </>
  );
};

export default RbacMgmt;
