import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { loadClearme } from 'src/redux/clearme/actions';
import { logout } from 'src/redux/user/actions';
import { Grid, Box } from '@mui/material';
import { Button, H1, P1R } from 'src/components';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'src/navigation';
import noResponse from 'src/assets/noResponse.svg';
import { BlankWrapper } from 'src/containers/wrappers';

const NoResponseContainer: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadClearme.init());
  }, []);

  const handleSubmit = () => {
    try {
      // @ts-ignore
      if (CLEAR && CLEAR.createUI) {
        navigate(PATHS.CLEARME_AUTH, { state: { initClear: true }, replace: true });
      }
    } catch (err) {
      navigate(0);
    }
  };

  const handleLogout = () => dispatch(logout.init());

  return (
    <BlankWrapper>
      <Grid container spacing={2} textAlign="center" sx={{ pt: '100px' }}>
        <Grid item xs={12}>
          <Box>
            <img src={noResponse} alt="no response icon" />
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ mt: 8 }}>
          <H1>{t('noResponsePage.title')}</H1>
        </Grid>
        <Grid item xs={12} sx={{ my: 3 }}>
          <P1R>{t('noResponsePage.subTitle')}</P1R>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={() => handleSubmit()} style={{ width: '180px', textTransform: 'none' }}>
            {t('noResponsePage.button')}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', flexGrow: 1, alignItems: 'end' }}>
        <Button primary={false} onClick={handleLogout} style={{ textTransform: 'capitalize' }}>
          {t('common.goToLogout')}
        </Button>
      </Grid>
    </BlankWrapper>
  );
};

export default NoResponseContainer;
