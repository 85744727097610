import { combineReducers } from 'redux';
import users from './users';
import linemen from './linemen';
import support from './support';
import favorites from './favorites';
import linemenByYard from './linemenByYard';

const usersData = combineReducers({
  users,
  linemen,
  support,
  favorites,
  linemenByYard,
});

export * from './users';
export * from './linemen';
export * from './support';
export * from './favorites';
export * from './linemenByYard';

export default usersData;
