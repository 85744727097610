import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { RosterType } from 'src/types';
import { setRoster } from '../reducers';
import { SagaAction } from '../../types';
import { saveRoster } from '../actions';

function* saveRosterSaga({ payload: roster }: SagaAction<Partial<RosterType>>): SagaIterator {
  try {
    yield put(saveRoster.pending());
    yield call(API.saveRoster, roster);
    yield put(setRoster(roster));
    yield put(saveRoster.success());
  } catch (e) {
    yield put(saveRoster.failure(e));
  }
}

export default saveRosterSaga;
