import { OptionsType } from 'src/types/options';
import { createReducer } from '../../utils/toolkit';

export const initialState: Partial<OptionsType> = {};

const {
  reducer,
  actions: { set: setOptions, merge: mergeOptions, reset: resetOptions },
} = createReducer('options', initialState);

export default reducer;
export { setOptions, mergeOptions, resetOptions };
