import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { PayloadVehiclesType } from 'src/types';
import { SagaAction } from '../../types';
import { rosterAttachVehicles } from '../actions';

function* rosterAttachVehiclesSaga({ payload: { id, vehicles } }: SagaAction<Partial<PayloadVehiclesType>>): SagaIterator {
  try {
    yield put(rosterAttachVehicles.pending());
    yield call(API.rosterAttachVehicles, { id, vehicles });

    yield put(rosterAttachVehicles.success());
  } catch (e) {
    yield put(rosterAttachVehicles.failure());
  }
}

export default rosterAttachVehiclesSaga;
