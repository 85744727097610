import axios from 'axios';
import { SagaIterator } from 'redux-saga';
import { call, put, select, take } from 'redux-saga/effects';
import API from 'src/api';
import { UploadFileType } from 'src/types/user';
import { SagaAction } from '../../types';
import { uploadUserFile, cancelUploadingUserFile } from '../actions';
import { selectUser } from '../selectors';
import { setUser } from '../reducers';

const uploadingTasks: any = {};

function* setUserUploadSaga({ payload }: SagaAction<Partial<UploadFileType>>): SagaIterator {
  const user = yield select(selectUser);

  try {
    yield put(uploadUserFile.pending());
    const source = axios.CancelToken.source();

    // @ts-ignore
    uploadingTasks[payload.type] = { type: payload.type, source };
    const response = yield call(API.setUserUpload, {
      ...payload,
      cancelToken: source.token,
    });

    // yield put(setProfile({...user, attachments: {...user.attachments, union: response.payload} }));
    yield put(
      setUser({
        ...user,
        attachments: {
          ...user.attachments,
          [String(payload.type)]: response.payload,
        },
      }),
    );

    // @ts-ignore
    delete uploadingTasks[payload.type];

    yield put(uploadUserFile.success());
    // @ts-ignore
  } catch (error: { status: number }) {
    // Technical dept: replacing error mesages from backend. It should be removed.
    let errorText = 'Network or server error';

    if (error?.status === 413) {
      errorText = 'File too large';
      // @ts-ignore
    } else if (!payload.file || !['jpeg', 'jpg', 'png', 'pdf'].includes(payload.file.type.split('/')[1])) {
      errorText = 'File type not supported';
    } else if (error?.status === 415) {
      errorText = 'File is corrupted';
    }

    yield put(
      setUser({
        ...user,
        attachments: {
          ...user.attachments,
          [String(payload.type)]: {
            ...user.attachments[String(payload.type)],
            // @ts-ignore
            errorText,
          },
        },
      }),
    );
    yield put(uploadUserFile.failure(error));
  }
}

function* cancelUploadingUserFileSaga(): Generator {
  while (true) {
    const action = yield take('CANCEL_UPLOADING_USER_FILE_INIT');
    try {
      // @ts-ignore
      const { type = '' } = action.payload;
      yield put(cancelUploadingUserFile.pending());
      if (uploadingTasks[type]) {
        uploadingTasks[type].source.cancel(`Canceled Upload ${type}`);
      }

      yield put(cancelUploadingUserFile.success());
      yield put(uploadUserFile.reset());
    } catch (error) {
      yield put(cancelUploadingUserFile.failure(error));
    }
  }
}

export { setUserUploadSaga, cancelUploadingUserFileSaga };
