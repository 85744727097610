import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Tab, Tabs, styled } from '@mui/material';
import { getIdIncident, saveIncident } from 'src/redux/incident/actions';
import { loadFilterUsers } from 'src/redux/users/actions';
import { Button, H1, Icon, P3 } from 'src/components';
import { themeColors } from 'src/theme';
import { selectIncidentId, selectSaveIncidentSuccess } from 'src/redux/incident/selectors';
import { tomorrowDate, normalizerTimestamp } from 'src/utils';
import { IncidentType, ClassificationType, FleetType } from 'src/types';
import { RoleType } from 'src/shared/enums/roleType.enum';

import { useTranslation } from 'react-i18next';
import General from './IncidentDialogTabs/General';
import Compensation from './IncidentDialogTabs/Compensation';
import Requirements from './IncidentDialogTabs/Requirements';
import ReportTo from './IncidentDialogTabs/ReportTo';

export type FormValues = {
  status: number;
  name: string;
  utility: number | null;
  utilityContractor: number | null;
  workLocation: string;
  assignedTo: string[];
  ibewPay: string | null;
  benefitsPay: string | null;
  travelReimbursement: string;
  lodgingProvided: string | null;
  meals: string;
  receipts: {
    fuel: boolean;
    travel: boolean;
    lodging: boolean;
    meals: boolean;
  };
  fteRequested: number;
  eqptRequested: number;
  classifications: ClassificationType[];
  fleet: FleetType[];
  estimatedDuration: string;
  dailyHours: string;
  expectedStartDate: number;
  yardFK?: number;
  time: string;
};

const firstTabFields = ['name', 'utility', 'utilityContractor', 'workLocation', 'assignedTo'];
const secondTabFields = ['ibewPay', 'benefitsPay', 'travelReimbursement', 'lodgingProvided', 'meals', 'receipts'];
const thirdTabFields = ['classifications', 'fleet', 'fteRequested', 'eqptRequested'];
const fourthTabFields = ['estimatedDuration', 'dailyHours', 'expectedStartDate', 'yardFK', 'time'];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '600px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(5),
    paddingBottom: theme.spacing(1),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: '8px',
            top: '8px',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon name="close" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

type IncidentDialogProps = {
  onClose: () => void;
  isEdit?: boolean;
  incident?: IncidentType;
};

const IncidentDialog: React.FC<IncidentDialogProps> = ({ onClose, incident, isEdit = false }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const incidentId = useSelector(selectIncidentId);
  const saveIncidentSuccess = useSelector(selectSaveIncidentSuccess);
  const [tabsValue, setTabsValue] = useState<number>(0);
  // const [status, setStatus] = useState<number>(0);

  /*
  const handleStatusChange = (statusValue: number) => {
    setStatus(statusValue);
  }
  */

  const handleTabsChange = (event: React.SyntheticEvent, newValue: number) => setTabsValue(newValue);

  const onModalClose = (event?: React.SyntheticEvent, reason?: string | undefined) => {
    if (reason === 'backdropClick' && formik.dirty) {
      return;
    }

    onClose();
  };

  const halfDay: number = 12 * 60 * 60;
  const initialValues: FormValues = {
    status: incident?.status as number,
    name: incident?.name || '',
    utility: incident?.utility?.id || null,
    utilityContractor: incident?.utilityContractor?.id || null,
    workLocation: incident?.workLocation || '',
    assignedTo: incident?.assignedTo.map((lineman) => lineman.uuid || '') || [],
    ibewPay: incident?.ibewPay || null,
    benefitsPay: incident?.benefitsPay || null,
    travelReimbursement: incident?.travelReimbursement || '',
    lodgingProvided: incident?.lodgingProvided?.toString() ?? null,
    meals: incident?.meals || '',
    receipts: incident?.receipts || {
      fuel: false,
      travel: false,
      lodging: false,
      meals: false,
    },
    fteRequested: incident?.fteRequested || 0,
    eqptRequested: incident?.eqptRequested || 0,
    classifications: incident?.classifications.length ? incident.classifications : [],
    fleet: incident?.fleet.length ? incident.fleet : [],
    estimatedDuration: incident?.estimatedDuration || '',
    dailyHours: incident?.dailyHours || '',
    // Technical Debts: Trouble with "Daylight Saving Time" (DST)
    // For reproducing change on profile: 31/10/2023, save, 29/10/2023, save. Will change on 28/10/2023.
    // Hotfix: Add half day
    expectedStartDate: incident?.expectedStartDate ? normalizerTimestamp(incident?.expectedStartDate) + halfDay : tomorrowDate(),
    yardFK: incident?.yardFK,
    time: incident?.time || '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    utility: Yup.string().required('Required'),
    utilityContractor: Yup.string().required('Required'),
    workLocation: Yup.string().required('Required'),
    assignedTo: Yup.array().min(1, 'Required'),
    ibewPay: Yup.string().required('Required'),
    benefitsPay: Yup.string().required('Required'),
    travelReimbursement: Yup.string().required('Required'),
    lodgingProvided: Yup.string().required('Required'),
    meals: Yup.string().required('Required'),
    receipts: Yup.object().required('Required'),
    estimatedDuration: Yup.string().required('Required'),
    dailyHours: Yup.string().required('Required'),
    expectedStartDate: Yup.number().required('Required'),
    yardFK: Yup.string().required('Required'),
    time: Yup.string().required('Required'),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (data) => {
      const submitData = {
        ...data,
        id: incidentId,
        // TODO: fix hardcoded status setting
        status: data.status ? data.status : 1,
      };

      dispatch(saveIncident.init(submitData));
    },
  });

  useEffect(() => {
    if (!isEdit) {
      dispatch(getIdIncident.init());
      dispatch(
        loadFilterUsers.init({
          status: 1,
          available: true,
          types: [RoleType.ASSIGNEE_ROSTER],
        }),
      );
    } else {
      dispatch(
        loadFilterUsers.init({
          status: 1,
          types: [RoleType.ASSIGNEE_ROSTER],
        }),
      );
    }

    setTabsValue(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (saveIncidentSuccess) {
      formik.resetForm();
      dispatch(saveIncident.reset());
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveIncidentSuccess]);

  const firstTabError = Object.keys(formik.errors).filter((val) => firstTabFields.includes(val)).length;
  const secondTabError = Object.keys(formik.errors).filter((val) => secondTabFields.includes(val)).length;
  const thirdTabError = Object.keys(formik.errors).filter((val) => thirdTabFields.includes(val)).length;
  const fourthTabError = Object.keys(formik.errors).filter((val) => fourthTabFields.includes(val)).length;

  return (
    <Box>
      <BootstrapDialog
        open
        // @ts-ignore
        onClose={onModalClose}
        aria-labelledby="customized-dialog-title"
      >
        {/* @ts-ignore */}
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onModalClose}>
          <H1 align="center" sx={{ mt: 3 }}>
            {isEdit ? 'Edit An Event' : 'Create An Event'}
          </H1>
        </BootstrapDialogTitle>

        <DialogContent>
          <Tabs value={tabsValue} onChange={handleTabsChange}>
            <Tab
              label={
                <Box sx={{ position: 'relative' }}>
                  {t('eventsPage.general')}
                  {firstTabError && Object.keys(formik.touched).length > 0 ? (
                    <Box
                      sx={{
                        top: '-20px',
                        position: 'absolute',
                      }}
                    >
                      <P3 sx={{ color: 'red' }}>
                        {firstTabError} {firstTabError === 1 ? 'issue' : 'issues'}
                      </P3>
                    </Box>
                  ) : null}
                </Box>
              }
              sx={{
                textTransform: 'none',
                fontSize: '16px',
                color: firstTabError && Object.keys(formik.touched).length > 0 ? themeColors.red : themeColors.grayDark,
                '&.Mui-selected': { color: 'black' },
              }}
            />

            <Tab
              label={
                <Box sx={{ position: 'relative' }}>
                  {t('eventsPage.compensation')}
                  {secondTabError && Object.keys(formik.touched).length > 0 ? (
                    <Box
                      sx={{
                        top: '-20px',
                        position: 'absolute',
                      }}
                    >
                      <P3 sx={{ color: 'red' }}>
                        {secondTabError} {secondTabError === 1 ? 'issue' : 'issues'}
                      </P3>
                    </Box>
                  ) : null}
                </Box>
              }
              sx={{
                textTransform: 'none',
                fontSize: '16px',
                color: secondTabError && Object.keys(formik.touched).length > 0 ? themeColors.red : themeColors.grayDark,
                '&.Mui-selected': { color: 'black' },
              }}
            />

            <Tab
              label={
                <Box sx={{ position: 'relative' }}>
                  {t('eventsPage.requirements')}
                  {thirdTabError && Object.keys(formik.touched).length > 0 ? (
                    <Box
                      sx={{
                        top: '-20px',
                        position: 'absolute',
                      }}
                    >
                      <P3 sx={{ color: 'red' }}>
                        {thirdTabError} {thirdTabError === 1 ? 'issue' : 'issues'}
                      </P3>
                    </Box>
                  ) : null}
                </Box>
              }
              sx={{
                textTransform: 'none',
                fontSize: '16px',
                color: thirdTabError && Object.keys(formik.touched).length > 0 ? themeColors.red : themeColors.grayDark,
                '&.Mui-selected': { color: 'black' },
              }}
            />

            <Tab
              label={
                <Box sx={{ position: 'relative' }}>
                  {t('eventsPage.reportTo')}
                  {fourthTabError && Object.keys(formik.touched).length > 0 ? (
                    <Box
                      sx={{
                        top: '-20px',
                        position: 'absolute',
                      }}
                    >
                      <P3 sx={{ color: 'red' }}>
                        {fourthTabError} {fourthTabError === 1 ? 'issue' : 'issues'}
                      </P3>
                    </Box>
                  ) : null}
                </Box>
              }
              sx={{
                textTransform: 'none',
                fontSize: '16px',
                color: fourthTabError && Object.keys(formik.touched).length > 0 ? themeColors.red : themeColors.grayDark,
                '&.Mui-selected': { color: 'black' },
              }}
            />
          </Tabs>

          <General formik={formik} hidden={tabsValue !== 0} incidentStatus={incident?.status} />
          <Compensation formik={formik} hidden={tabsValue !== 1} />
          <Requirements formik={formik} hidden={tabsValue !== 2} />
          <ReportTo formik={formik} hidden={tabsValue !== 3} />

          <Box
            sx={{
              mb: 5,
              gap: 2,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ width: '100%' }}>
              {/* @ts-ignore */}
              <Button primary={false} onClick={onModalClose} sx={{ width: '100%', textTransform: 'capitalize' }}>
                {t('common.cancel')}
              </Button>
            </Box>
            {incident?.status ? (
              <Box sx={{ width: '100%' }}>
                <Button
                  onClick={() => {
                    // handleStatusChange(1);
                    formik.handleSubmit();
                  }}
                  sx={{ width: '100%', textTransform: 'capitalize' }}
                >
                  {t('eventsPage.update')}
                </Button>
              </Box>
            ) : (
              <>
                <Box sx={{ width: '100%' }}>
                  <Button
                    primary={false}
                    onClick={() => {
                      const submitData = {
                        ...formik.values,
                        id: incidentId,
                        status: 0,
                      };

                      dispatch(saveIncident.init(submitData));
                    }}
                    sx={{ width: '100%', textTransform: 'capitalize' }}
                  >
                    {t('eventsPage.saveAsDraft')}
                  </Button>
                </Box>
                <Box sx={{ width: '100%' }}>
                  <Button
                    onClick={() => {
                      // handleStatusChange(1);
                      formik.handleSubmit();
                    }}
                    sx={{ width: '154px', textTransform: 'capitalize' }}
                  >
                    {t('eventsPage.activate')}
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
};

export default IncidentDialog;
