import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { setVehicles } from '../reducers';
import { loadVehicles } from '../actions';

function* loadVehiclesSaga(): SagaIterator {
  try {
    yield put(loadVehicles.pending());
    // Technical Debt: This is a temporary solution to get the available vehicles
    // API.loadVehicles is not implemented yet
    const { payload } = yield call(API.loadVehicles);
    yield put(setVehicles(payload));
    yield put(loadVehicles.success());
  } catch (e) {
    yield put(loadVehicles.failure());
  }
}

export default loadVehiclesSaga;
