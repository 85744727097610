import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { SagaAction } from '../../types';
import * as actions from '../actions';

function* rosterTimesheetSaga({ payload: { rosterId, data } }: SagaAction<Partial<{ rosterId: number; data: any }>>): SagaIterator {
  try {
    yield put(actions.rosterTimesheet.pending());
    yield call(API.rosterTimesheet, { id: rosterId, data });

    yield put(actions.rosterTimesheet.success());
  } catch (e) {
    yield put(actions.rosterTimesheet.failure());
  }
}

export default rosterTimesheetSaga;
