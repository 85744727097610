import { combineReducers } from 'redux';
import clearme from './clearme';

const clearmeData = combineReducers({
  clearme,
});

export * from './clearme';

export default clearmeData;
