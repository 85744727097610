import { createReduxAsyncType } from '../utils/toolkit';

const TYPES = {
  LOAD_VEHICLE: createReduxAsyncType('LOAD_VEHICLE'),
  CREATE_VEHICLE: createReduxAsyncType('CREATE_VEHICLE'),
  SAVE_VEHICLE: createReduxAsyncType('SAVE_VEHICLE'),
  DELETE_VEHICLE: createReduxAsyncType('DELETE_VEHICLE'),
};

export { TYPES };
