import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { setIncident } from '../reducers';
import { loadIncident } from '../actions';
import { SagaAction } from '../../types';

const incidentDefaultQuery: { [key: string]: boolean } = {
  ownedBy: true,
  createdBy: true,
  classifications: true,
  fleet: true,
  rosters: true,
  assignedTo: true,
  utility: true,
  utilityContractor: true,
  rostersOwnedBy: true,
};

function* loadIncidentSaga({
  payload: { id = 0, query = null },
}: SagaAction<Partial<{ id: number; query?: { [key: string]: boolean } | null }>>): SagaIterator {
  try {
    yield put(loadIncident.pending());

    const queryOptions = { ...incidentDefaultQuery, ...query };
    const queryString = Object.keys(queryOptions)
      .map((key) => `${key}=${queryOptions[key]}`)
      .join('&');

    const { payload } = yield call(API.loadIncident, { id: id.toString(), query: queryString });

    // TODO: temporary fix for utilityContractor prop
    const updatedPayload = {
      ...payload,
      utilityContractor: payload.utilityContractor || payload.utilityCompany,
    };

    // Technical Debt: Updated current incident with details
    yield put(setIncident(updatedPayload));
    yield put(loadIncident.success());
  } catch (e) {
    yield put(loadIncident.failure());
  }
}

export default loadIncidentSaga;
