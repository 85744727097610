import { Permission } from 'src/types';
import { createReducer } from '../../utils/toolkit';

const initialState: Partial<Permission[]> = [];

const {
  reducer,
  actions: { set: setPermissions, merge: mergePermissions, reset: resetPermissions },
} = createReducer('permissions', initialState);

export default reducer;
export { setPermissions, mergePermissions, resetPermissions };
