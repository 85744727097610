import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { applyMiddleware, createStore, combineReducers, Store } from 'redux';
import reducers from './reducers';
import sagas from './sagas';

export const sagaMiddleware = createSagaMiddleware();
const rootReducer = combineReducers(reducers);

const middlewares = applyMiddleware(sagaMiddleware);
// Technical Debt: Need to split on dev and prod
// const enhancer = true ? composeWithDevTools(middlewares) : middlewares;
const enhancer = composeWithDevTools(middlewares);

const store: Store = createStore(rootReducer, enhancer);

sagaMiddleware.run(sagas);

export default store;
