import { IncidentType } from 'src/types';
import { createReducer } from '../../utils/toolkit';

const initialState: Partial<IncidentType[]> = [];

const {
  reducer,
  actions: { set: setIncidents, merge: mergeIncidents, reset: resetIncidents },
} = createReducer('incidents', initialState);

export default reducer;
export { setIncidents, mergeIncidents, resetIncidents };
