import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { PermissionEnum } from 'src/types';

import { setRoles } from '../reducers';
import { loadFilteredRoles } from '../actions';
import { SagaAction } from '../../types';

type FilterPayloadType = {
  permissions?: PermissionEnum[];
};

function* loadFilteredRolesSaga({ payload: { permissions } }: SagaAction<FilterPayloadType>): SagaIterator {
  try {
    yield put(loadFilteredRoles.pending());
    const { payload } = yield call(API.loadFilteredRoles, {
      permissions,
    });

    yield put(setRoles(payload));
    yield put(loadFilteredRoles.success());
  } catch (e) {
    yield put(loadFilteredRoles.failure());
  }
}

export default loadFilteredRolesSaga;
