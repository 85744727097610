import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateUserSettings } from 'src/redux/user/actions';
import { Box, Grid, Checkbox, FormControlLabel } from '@mui/material';
import { Button, H1, P1R, Dialog } from 'src/components';
import { useTranslation } from 'react-i18next';

type Props = {
  onClose: () => void;
  onConfirm: () => void;
};

function AddToRosterDialog({ onClose, onConfirm }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [autoAddToRosterConfirmed, setAutoAddToRosterConfirmed] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAutoAddToRosterConfirmed(event.target.checked);
  };

  const handleConfirm = () => {
    if (autoAddToRosterConfirmed) {
      dispatch(updateUserSettings.init({ autoAddToRosterConfirmation: true }));
    }
    onConfirm();
  };

  return (
    <Dialog open onClose={onClose} aria-labelledby="customized-dialog-title">
      <Box
        sx={{
          mb: 5,
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        <H1 align="center" sx={{ mt: 5, mb: 2, textTransform: 'capitalize' }}>
          {t('eventPage.addToRoster')}
        </H1>
        <P1R sx={{ my: 3 }}>{t('eventPage.byAddingPeople')}</P1R>
        <FormControlLabel
          control={<Checkbox checked={autoAddToRosterConfirmed} onChange={handleChange} />}
          label={<P1R>{t('eventPage.saveMyResponse')}</P1R>}
        />
      </Box>

      <Grid container columnSpacing={2} sx={{ mb: 5 }}>
        <Grid item xs={6}>
          <Button
            primary={false}
            onClick={onClose}
            sx={{
              width: '100%',
              textTransform: 'capitalize',
            }}
          >
            {t('common.cancel')}
          </Button>
        </Grid>

        <Grid item xs={6}>
          <Button
            onClick={handleConfirm}
            sx={{
              width: '100%',
              textTransform: 'capitalize',
            }}
          >
            {t('common.confirm')}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default AddToRosterDialog;
