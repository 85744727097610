import { takeLatest } from 'redux-saga/effects';
import createRolesSaga from './createRolesSaga';
import loadRolesSaga from './loadRolesSaga';
import loadFilteredRolesSaga from './loadFilteredRolesSaga';
import updateRolesSaga from './updateRolesSaga';
import deleteRolesSaga from './deleteRolesSaga';
import loadPermissionsSaga from './loadPermissionsSaga';
import { TYPES } from '../constants';

export default function* rolesSagas(): Generator {
  yield takeLatest(TYPES.CREATE_ROLES.INIT, createRolesSaga);
  yield takeLatest(TYPES.LOAD_ROLES.INIT, loadRolesSaga);
  yield takeLatest(TYPES.LOAD_FILTERED_ROLES.INIT, loadFilteredRolesSaga);
  yield takeLatest(TYPES.UPDATE_ROLES.INIT, updateRolesSaga);
  yield takeLatest(TYPES.DELETE_ROLES.INIT, deleteRolesSaga);
  yield takeLatest(TYPES.LOAD_PERMISSIONS.INIT, loadPermissionsSaga);
}
