import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { setLinemen, setSupport } from '../reducers';
import { SagaAction } from '../../types';
import { loadLinemen } from '../actions';

type LoadLinemenType = {
  incidentId: number;
  linemanUuids: string[];
};

function* loadLinemenSaga({ payload: { incidentId } }: SagaAction<LoadLinemenType>): SagaIterator {
  try {
    yield put(loadLinemen.pending());
    const { payload } = yield call(API.loadLinemen, { incidentId });

    yield put(setLinemen(payload.fte));
    yield put(setSupport(payload.supporters));
    yield put(loadLinemen.success());
  } catch (e) {
    yield put(loadLinemen.failure());
  }
}

export default loadLinemenSaga;
