import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { IncidentType } from 'src/types';
import { deleteIncident } from '../actions';
import { SagaAction } from '../../types';

function* deleteIncidentSaga({ payload: incident }: SagaAction<Partial<IncidentType>>): SagaIterator {
  try {
    yield put(deleteIncident.pending());
    yield call(API.deleteIncident, { id: incident.id || 0 });

    yield put(deleteIncident.success());
  } catch (e) {
    yield put(deleteIncident.failure(e));
  }
}

export default deleteIncidentSaga;
