import config from '../config';

export const getUnionClassifications = (localUnion: any, unionType = '', params = {}): string[] => {
  // @ts-ignore
  const { UNION_CLASSIFICATIONS = [], UNION_CLASSIFICATION_FILTERS = '' } = { ...config?.options, ...params?.options };
  const classifications = UNION_CLASSIFICATIONS[localUnion] || [];
  let filter = UNION_CLASSIFICATION_FILTERS && (UNION_CLASSIFICATION_FILTERS[unionType] ?? UNION_CLASSIFICATION_FILTERS.DEFAULT);
  filter = filter && new RegExp(filter, 'i');

  return !filter ? classifications : classifications.filter((classification: string) => filter.test(classification));
};
