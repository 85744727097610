import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { SagaAction } from '../../types';
import { rosterLinemanSetOptions } from '../actions';

type LinkRosterLinemanType = {
  rosterId: number;
  userUuid: string;
  options: unknown;
};

function* rosterLinemanSetOptionsSaga({ payload: { rosterId, userUuid, options } }: SagaAction<Partial<LinkRosterLinemanType>>): SagaIterator {
  try {
    yield put(rosterLinemanSetOptions.pending());
    yield call(API.rosterLinemanSetOptions, {
      id: rosterId || 0,
      uuid: userUuid || '',
      options,
    });
    yield put(rosterLinemanSetOptions.success());
  } catch (e) {
    yield put(rosterLinemanSetOptions.failure());
  }
}

export default rosterLinemanSetOptionsSaga;
