import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';

import { SagaAction } from '../../types';
import { updateUserRoles } from '../actions';

type UserPayloadType = {
  uuid: string;
  roles: string[];
};

function* updateUserRolesSaga({ payload: { uuid, roles } }: SagaAction<UserPayloadType>): SagaIterator {
  try {
    yield put(updateUserRoles.pending());
    yield call(API.updateUserRoles, { uuid, roles });
    yield put(updateUserRoles.success());
  } catch (e) {
    yield put(updateUserRoles.failure());
  }
}

export default updateUserRolesSaga;
