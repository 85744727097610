import { Reducer } from 'redux';
import { ERROR_PAYLOAD } from '../../@errors/reducer';
import { ReduxAction, SagaAction } from '../../types';
import { buildReducer, buildReducerActions } from './generateReducer/reducers';
import { generateActionTypes, generateActions } from './generateReducer/actions';
import { ReducerActionsMap, ReduxAsyncAction, ReduxAsyncType, ActionsMap, StateType } from './types';

const ASYNC_TYPES = {
  INIT: 'INIT',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  RESET: 'RESET',
};

// Reducer
export const createReducer = <T>(
  reducerName: string,
  initState: T,
  customActions: ReducerActionsMap<T> = {},
): { reducer: Reducer; actions: ActionsMap<T> } => {
  const actionTypes = generateActionTypes(reducerName, customActions);
  const actions = generateActions<T>(reducerName, actionTypes);

  const reducer = buildReducer(buildReducerActions(actionTypes, initState, customActions), initState);

  return { reducer, actions };
};

export const createReduxType = (TYPE: string): string => TYPE;

export const createSagaPayload = <T>(object: T): SagaAction<T> => ({
  // The empty string here to call a saga directly without dispatching an action
  type: '',
  payload: object,
});

// ReduxType
export const createReduxAsyncType = (TYPE: string): ReduxAsyncType => ({
  INIT: `${TYPE}_${ASYNC_TYPES.INIT}`,
  PENDING: `${TYPE}_${ASYNC_TYPES.PENDING}`,
  SUCCESS: `${TYPE}_${ASYNC_TYPES.SUCCESS}`,
  FAILURE: `${TYPE}_${ASYNC_TYPES.FAILURE}`,
  RESET: `${TYPE}_${ASYNC_TYPES.RESET}`,
  TYPE,
});

// ReduxAction
export const createReduxAction =
  (type = '') =>
  (payload: any = {}): ReduxAction => ({ type, payload });

export const createReduxAsyncAction = (type: ReduxAsyncType): ReduxAsyncAction => ({
  init: (payload = {}) => ({ type: type.INIT, payload }),
  pending: (payload = {}) => ({ type: type.PENDING, payload }),
  success: (payload = {}) => ({ type: type.SUCCESS, payload }),
  failure: (payload = {}) => ({ type: type.FAILURE, payload }),
  reset: () => ({ type: type.RESET }),
});

// Loading and Error selectors
export const createSelectorByActionName =
  <T = boolean>(reducerName: string) =>
  (actionNames: string | string[], errorPayload?: boolean) =>
  (state: StateType) => {
    const getByActionName = (actionName: string): T => {
      const actionNameWithSuffix = errorPayload ? `${actionName}_${ERROR_PAYLOAD}` : actionName;
      return (state as object)[reducerName as keyof StateType][actionNameWithSuffix];
    };
    return Array.isArray(actionNames) && !errorPayload ? actionNames.some(getByActionName) : getByActionName(actionNames as string);
  };
