import axios from 'axios';
import { themeColors } from './theme';
import ROUTES from './api/routes';
import updateTranslate from './localizations/update';
import { timeList, can, canAll } from './utils';
import { ListType } from './types';

const config = {
  async refresh() {
    const { data } = await axios(`${this.API}${ROUTES.OPTIONS}`);

    // Technical Debt: "options" should be remove
    const updatedConfig = { ...data.payload, options: data.payload };
    this.update(updatedConfig);
  },
  update(config: { [k: string]: any }) {
    const TOKEN = localStorage.getItem('TOKEN');

    Object.keys(this).forEach((key) => {
      if (!config.hasOwnProperty(key)) {
        // Skip
      } else if (config.hasOwnProperty(key)) {
        if (key === 'API') {
          (this as any)[key] = config[key].replace(/https?:/, '');
        } else {
          (this as any)[key] = config[key];
        }
      }
    });

    if (config.TOKEN) {
      // Tecnical Debt: Disabled for testing
      localStorage.setItem('TOKEN', config.TOKEN as string);
    } else if (config.TOKEN === '') {
      localStorage.removeItem('TOKEN');
      // Tecnical Debt: Multi reload page. hot fix
      setTimeout(() => window.location.assign('/login'), 100);
    }

    if (!this.TOKEN) {
      this.TOKEN = TOKEN || '';
    }

    return config;
  },

  URL: window.location.origin,

  // Configuration
  API: (process.env.REACT_APP_API as string).replace(/https?:/, ''),

  // Technical Debt: should be add for all envs
  // For disabling LogRocket in console run: localStorage.setItem('LOGROCKET_ENABLED', 'false')
  LOGROCKET_APP_ID: process.env.REACT_APP_LOGROCKET_APP_ID || 'ypq2sm/erma',
  LOGROCKET_ENABLED:
    localStorage.getItem('LOGROCKET_ENABLED') !== null
      ? localStorage.getItem('LOGROCKET_ENABLED') === 'true'
      : process.env.REACT_APP_LOGROCKET_ENABLED !== 'false',

  HOTJAR_SITE_ID: +(process.env.REACT_APP_HOTJAR_SITE_ID || 5020774),
  HOTJAR_VERSION: +(process.env.REACT_APP_HOTJAR_VERSION || 6),
  // For disabling Hotjar in console run: localStorage.setItem('HOTJAR_ENABLED', 'false')
  HOTJAR_ENABLED:
    localStorage.getItem('HOTJAR_ENABLED') !== null
      ? localStorage.getItem('HOTJAR_ENABLED') === 'true'
      : process.env.REACT_APP_HOTJAR_ENABLED !== 'false',

  CLEARME_DELAY: 20000, // 20 seconds
  CLEARME_MAX_REPLAY: 5,

  getVersion() {
    const empty = { value: '' };
    const [tag = empty, hash = empty, changed = empty] = this.version;
    // eslint-disable-next-line no-underscore-dangle
    this._API_VERSION = `${tag.value} - ${hash.value} [${changed.value}]`;
    // eslint-disable-next-line no-underscore-dangle
    return `SPA ${this._SPA_VERSION}`;
  },

  _SPA_VERSION: `${process.env.REACT_APP_TAG} - ${process.env.REACT_APP_HASH} [${process.env.REACT_APP_CHANGED}]`,
  _API_VERSION: '',
  version: [],

  I18N_RETURN_NULL: false,
  updateTranslate: (url = ROUTES.TRANSLATES.replace('{language}', 'en'), lang = 'en') => updateTranslate(url, lang),

  TOKEN: localStorage.getItem('TOKEN') || '',
  IS_ACTIVE: true,
  IS_PRODUCTION: false,
  uuid: '',
  times: timeList(),

  // Tags
  curPermissions: [''] as string[],
  allPermissions: [''] as string[],
  can,
  canAll,

  options: {
    CDL_ENDORSEMENTS: [],
    CDL_RESTRICTIONS: [],
    STATES: { US: [], CA: [] },
    UNIONS: [],
    UNION_CLASSIFICATIONS: [],
    UNION_CLASSIFICATION_FILTERS: '',
    UTILITY_VEHICLE_CLASSIFICATIONS: {},
    TIMESHEET_VEHICLE_CLASSIFICATIONS: {},
    UTILITY_FTE_CLASSIFICATIONS: [],
    TIMESHEET_FTE_CLASSIFICATIONS: [],
    COUNTRY: [],
    emergencyContactRelationships: [],
    incidentStatus: [], // TODO: not used on UI
    roles: [],
    rosterApprovalStatus: [], // TODO: not used on UI
    rosterStatus: [] as Array<ListType>,
    rubberGloveSize: [],
    rubberSleeveSize: [],
    userStatus: [], // TODO: not used on UI
    utilities: [],
    utilityContractors: [],
    vehicleOwnerType: [],
    vehicleRegistrationType: [],
    vehicleStatusType: [],
    vehicleType: [],
    version: [],
    workGloveSize: [],
    workVestSize: [],
    yards: [],
  },

  // Technical Debt start using formatDate for every date.
  formatDate: 'DD MMM YYYY',

  rosterStatusColors: [
    themeColors.redLight,
    themeColors.lightYellow,
    themeColors.greenLight,
    themeColors.blueLight,
    themeColors.grayMedium,
    themeColors.grayLight,
  ],

  classifications: [],
  utilityClassifications: [],
  driverLicenseClass: [],
  cdlEndorsements: [],
  cdlRestrictions: [],

  // Incidents
  incidentCompensationPay: [],
  incidentLodgingProvided: [],
};

config.can = can.bind(config);
config.canAll = canAll.bind(config);

export default config;
