import React, { useEffect, useState, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { GridRowParams, GridActionsCellItem, GridActionsCellItemProps } from '@mui/x-data-grid';

import UnsavedChangesPopup from 'src/components/UserProfile/UnsavedChangesPopup';
import Icon from 'src/components/Icon';
import { loadRoles, updateRoles, deleteRoles } from 'src/redux/roles/actions';
import { selectRoles, selectRolesLoading } from 'src/redux/roles/selectors';
import RolesTable from 'src/components/RolesTable';
import { Role } from 'src/types';
import { useBlocker } from 'react-router-dom';
import { PermissionEnum } from 'src/shared/enums/permission.enum';
import config from 'src/config';
import Loader from 'src/components/Loader';
import DeleteConfirmationPopup from '../DeleteConfirmationPopup';

type Props = {
  editable?: boolean;
  setEditMode: (mode: boolean) => void;
};

function RolesTab({ editable, ...props }: Props) {
  const dispatch = useDispatch();
  const roles = useSelector(selectRoles);
  const isRolesLoading = useSelector(selectRolesLoading);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editingRole, setEditingRole] = useState<Role | null>(null);
  const [hasUnsavedData, setHasUsavedData] = useState(false);
  const blocker = useBlocker(({ currentLocation, nextLocation }) => hasUnsavedData && currentLocation.pathname !== nextLocation.pathname);

  useEffect(() => {
    dispatch(loadRoles.init());
  }, []);

  const onDeleteClick = (role: Role | null) => {
    dispatch(deleteRoles.init({ slugs: [role?.slug] }));
  };

  const hadleDeleteClick = (event: MouseEvent<HTMLElement>, role: Role) => {
    event.stopPropagation();
    setEditingRole(role);
  };

  const handleBulkEditStart = () => {
    setEditMode(true);
    props.setEditMode(true);
  };

  const handleRowsUpdate = (newRows: any) => {
    const newRoles = Object.values(newRows);
    dispatch(updateRoles.init({ roles: newRoles }));
    return newRows;
  };

  const handleBulkEditStop = () => {
    props.setEditMode(false);
    setEditMode(false);
  };

  // TODO: Use the AdvancedTable delete function
  const getActions = (params: GridRowParams<any>): React.ReactElement<GridActionsCellItemProps>[] => [
    <GridActionsCellItem
      disabled={!editable || editMode}
      key="deleteCell"
      icon={<Icon name="delete" />}
      label="Delete"
      onClick={(event: MouseEvent<HTMLElement>) => hadleDeleteClick(event, params.row)}
    />,
  ];

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasUnsavedData) {
        const confirmationMsg = 'You have unsaved changes.';
        e.returnValue = confirmationMsg;

        return confirmationMsg;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedData]);

  return (
    <Box sx={{ height: 'calc(100vh - 200px)' }}>
      {isRolesLoading ? (
        <Loader />
      ) : (
        <RolesTable
          roles={roles}
          editable={editable}
          getActions={editable && config.can(PermissionEnum.DELETE_ROLE) ? getActions : undefined}
          processRowsUpdate={(newRow) => handleRowsUpdate(newRow)}
          onBulkEditStart={() => handleBulkEditStart()}
          onBulkEditStop={() => handleBulkEditStop()}
          hasUnsavedData={(hasUsavedRows) => setHasUsavedData(hasUsavedRows)}
        />
      )}

      <DeleteConfirmationPopup
        name={`${editingRole?.name}`}
        open={!!editingRole}
        onDelete={() => onDeleteClick(editingRole)}
        onClose={() => setEditingRole(null)}
      />
      {blocker.state === 'blocked' && <UnsavedChangesPopup onProceed={blocker.proceed} onCancel={blocker.reset} />}
    </Box>
  );
}

export default RolesTab;
