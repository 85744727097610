import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { SagaAction } from '../../types';
import { loadUserUuid } from '../actions';
import { setUserUuid } from '../reducers';

type UserPayloadType = {
  uuid: string;
};

function* loadUserUuidSaga({ payload: { uuid } }: SagaAction<UserPayloadType>): SagaIterator {
  try {
    yield put(loadUserUuid.pending());
    const { payload: user } = yield call(API.getUserUuid, uuid);

    // Technical Debt: permissions should be in the user object
    try {
      const {
        payload: { curPermissions, allPermissions },
      } = yield call(API.getUserPermissions);
      user.curPermissions = curPermissions;
      user.allPermissions = allPermissions;
    } catch (e) {
      user.curPermissions = [];
      user.allPermissions = [];
    }
    yield put(setUserUuid(user));

    yield put(loadUserUuid.success());
  } catch (error) {
    yield put(loadUserUuid.failure(error));
  }
}

export default loadUserUuidSaga;
