import { FC } from 'react';
// import { useTranslation } from 'react-i18next';
import { Checkbox } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import { Permission, Role } from 'src/types';

import { Table } from '..';
import { TableProps } from '../Table';

type RolesPermissionsTableProps = {
  roles: Role[];
  permissions: Permission[];
  editable?: boolean;
} & Partial<TableProps>;

const RolesPermissionsTable: FC<RolesPermissionsTableProps> = ({ roles, permissions, editable, ...props }): JSX.Element => {
  // const { t } = useTranslation();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Permission',
      minWidth: 225,
      flex: 1,
    },
  ];
  roles.forEach((role) => {
    columns.push({
      field: role.slug,
      headerName: role.name,
      type: 'boolean',
      minWidth: 135,
      renderCell: (params) => <Checkbox size="small" checked={!!params.value} />,
      editable,
    });
  });

  const rows = permissions.map((permission) => {
    const row: { [key: string]: any } = {};
    roles.forEach((role) => {
      row[role.slug] = role.permissions.find((perm) => perm.slug === permission.slug) !== undefined;
    });
    return {
      name: permission.name,
      permission,
      ...row,
    };
  });

  // We don't want user to overide key props, so add after
  return <Table {...props} type="advanced" columns={columns} rows={rows} getRowId={(row) => row.permission.id} disableRowSelectionOnClick />;
};

export default RolesPermissionsTable;
