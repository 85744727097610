import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { logout } from 'src/redux/user/actions';
import { Grid, Box } from '@mui/material';
import { Button, H1, P1R } from 'src/components';
import phoneImg from 'src/assets/phoneDuplicate.svg';
import { BlankWrapper } from 'src/containers/wrappers';

const PhoneInUseContainer: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout.init());
  };

  return (
    <BlankWrapper>
      <Grid container spacing={2} textAlign="center" sx={{ pt: '100px' }}>
        <Grid item xs={12}>
          <Box>
            <img src={phoneImg} alt="phone in use icon" />
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ mt: 4 }}>
          <H1>{t('phoneInUsePage.title')}</H1>
        </Grid>
        <Grid item xs={12} sx={{ my: 2 }}>
          <P1R sx={{ whiteSpace: 'pre-line' }}>{t('phoneInUsePage.subTitle')}</P1R>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handleLogout} style={{ textTransform: 'none' }}>
            {t('phoneInUsePage.button')}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', flexGrow: 1, alignItems: 'end' }}>
        <Button primary={false} onClick={handleLogout} style={{ textTransform: 'capitalize' }}>
          {t('common.goToLogout')}
        </Button>
      </Grid>
    </BlankWrapper>
  );
};

export default PhoneInUseContainer;
