import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { VehicleType } from 'src/types';
import { Table } from 'src/components';
import { TableProps } from 'src/components/Table';

import { numberCol, getGridColumnDef, getGridColumGroupingModelDef } from './utils';

type ReportRowType = {
  yardFK: number;
  equipmentCount: number;
  dielectricCompliant: number;
  dielectricDue: number;
  dielectricExpired: number;
  ansiCompliant: number;
  ansiDue: number;
  ansiExpired: number;
  fmcsaCompliant: number;
  fmcsaDue: number;
  fmcsaExpired: number;
  regCompliant: number;
  regDue: number;
  regExpired: number;
  iftaCompliant: number;
  iftaDue: number;
  iftaExpired: number;
};

const defaultRow: ReportRowType = {
  yardFK: -1,
  equipmentCount: 0,
  dielectricCompliant: 0,
  dielectricDue: 0,
  dielectricExpired: 0,
  ansiCompliant: 0,
  ansiDue: 0,
  ansiExpired: 0,
  fmcsaCompliant: 0,
  fmcsaDue: 0,
  fmcsaExpired: 0,
  regCompliant: 0,
  regDue: 0,
  regExpired: 0,
  iftaCompliant: 0,
  iftaDue: 0,
  iftaExpired: 0,
};

type FleetAdminTableProps = {
  fleet: VehicleType[];
} & Partial<TableProps>;

const FleetAdminTable: FC<FleetAdminTableProps> = ({ fleet, ...props }): JSX.Element => {
  const { t } = useTranslation();

  // Configure the columns
  const columnFields: string[] = ['dielectric', 'ansi', 'fmcsa', 'reg', 'ifta'];
  const columnAttributes = [
    { id: 'Compliant', type: numberCol },
    { id: 'Due', type: numberCol },
    { id: 'Expired', type: numberCol },
  ];
  const columns = getGridColumnDef('fleetAdminPage', columnFields, columnAttributes, [
    {
      field: 'equipmentCount',
      headerName: t('fleetAdminPage.equipmentCount'),
      description: t('fleetAdminPage.equipmentCount_desc'),
      ...numberCol,
    },
  ]);
  const columnGroupingModel = getGridColumGroupingModelDef('fleetAdminPage', columnFields, columnAttributes);

  const rows: ReportRowType[] = [];
  const total: ReportRowType = { ...defaultRow };

  // TODO: Need enumerated type for vehicle type, does it exist?
  fleet.forEach((item: VehicleType) => {
    // Only interested in bucket trucks and diggers for this report
    if (item.type !== 'BUCKET_TRUCK' && item.type !== 'SQUIRT_BUCKET' && item.type !== 'DIGGER_DERRICK') return;

    let currRow = rows.find((yard) => yard.yardFK === item.yardFK);
    if (!currRow) {
      const len = rows.push({
        ...defaultRow,
        yardFK: item.yardFK,
      });
      currRow = rows[len - 1];
    }
    currRow.equipmentCount += 1;
    total.equipmentCount += 1;

    const triageDate = (date: number, row: any, compliant: string, due: string, expired: string) => {
      if (!date) return;
      const now = dayjs();
      const plus30 = now.add(30, 'day');
      const itemDate = dayjs(date);

      if (itemDate.isBefore(now)) {
        row[expired] += 1;
      } else if (itemDate.isBefore(plus30)) {
        row[due] += 1;
      } else {
        row[compliant] += 1;
      }
    };

    // TODO: Need to figure out how the status and date fields relate in the vehicle.
    triageDate(item.dielectricExpirationDate, currRow, 'dielectricCompliant', 'dielectricDue', 'dielectricExpired');
    triageDate(item.dielectricExpirationDate, total, 'dielectricCompliant', 'dielectricDue', 'dielectricExpired');
    triageDate(item.ansiExpirationDate, currRow, 'ansiCompliant', 'ansiDue', 'ansiExpired');
    triageDate(item.ansiExpirationDate, total, 'ansiCompliant', 'ansiDue', 'ansiExpired');
    triageDate(item.fmcsaExpirationDate, currRow, 'fmcsaCompliant', 'fmcsaDue', 'fmcsaExpired');
    triageDate(item.fmcsaExpirationDate, total, 'fmcsaCompliant', 'fmcsaDue', 'fmcsaExpired');
    triageDate(item.registrationExpirationDate, currRow, 'regCompliant', 'regDue', 'regExpired');
    triageDate(item.registrationExpirationDate, total, 'regCompliant', 'regDue', 'regExpired');
    triageDate(item.iftaTagExpirationDate, currRow, 'iftaCompliant', 'iftaDue', 'iftaExpired');
    triageDate(item.iftaTagExpirationDate, total, 'iftaCompliant', 'iftaDue', 'iftaExpired');
  });
  rows.push(total);

  // We don't want user to overide key props, so add after
  return (
    <Table
      {...props}
      type="advanced"
      columns={columns}
      rows={rows}
      getRowId={(row) => row.yardFK}
      experimentalFeatures={{ columnGrouping: true }}
      columnGroupingModel={columnGroupingModel}
      slots={{ toolbar: undefined }}
    />
  );
};

export default FleetAdminTable;
