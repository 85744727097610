import { combineReducers } from 'redux';
import incidents from './incidents';

const incidentsData = combineReducers({
  incidents,
});

export * from './incidents';

export default incidentsData;
