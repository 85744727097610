import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { Button, Icon } from 'src/components';
import { selectClearmeToken } from 'src/redux/clearme/selectors';
import { useEffect, useState } from 'react';
import { loadClearme } from 'src/redux/clearme/actions';
import { logout } from 'src/redux/user/actions';
import { useNavigate, useLocation } from 'react-router-dom';
import { PATHS } from 'src/navigation';
import { useTranslation } from 'react-i18next';
import { BlankWrapper } from '../wrappers';

// TODO: old view. remove

type OnExitData = {
  name: string;
  timestamp: number;
  exitReason?: 'user_cancelled' | 'server_error' | 'identity_denied';
};

const EmailVerifiedContainer: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const token = useSelector(selectClearmeToken);
  const { state } = useLocation();
  const [isActive, setIsActive] = useState(false);
  const [isClearPopupOpen, setIsClearPopupOpen] = useState(false);

  useEffect(() => {
    dispatch(loadClearme.init());
  }, []);

  useEffect(() => {
    if (state?.initClear && token) {
      handleSubmit();
    }
  }, [state, token]);

  const handleSubmit = async () => {
    try {
      setIsClearPopupOpen(true);
      // @ts-ignore
      const clearUI = CLEAR.createUI(token, {
        onSuccess: (data: any) => {
          console.log('onSuccess');
          console.log(data);
          navigate(PATHS.CREATE_ACCOUNT_CONFIRMATION_PAGE, { replace: true });
        },
        onExit: (data: OnExitData) => {
          const { exitReason } = data;
          console.log('onExit', data);

          setIsClearPopupOpen(false);
          if (exitReason && exitReason !== 'user_cancelled') {
            navigate(PATHS.CREATE_ACCOUNT_CONFIRMATION_PAGE, { replace: true });
          }
        },
        onEvent: ({ name }: { name: string }) => {
          console.log('onEvent');
          console.log(name);
          if (name === 'manual_review') {
            navigate(PATHS.CREATE_ACCOUNT_CONFIRMATION_PAGE, { replace: true });
          }
        },
      });
      clearUI.launch();
    } catch (err) {
      navigate(PATHS.CLEARME_NO_RESPONSE);
    }
  };

  const handleLogout = () => dispatch(logout.init());

  return (
    <BlankWrapper>
      {!isClearPopupOpen ? (
        <Grid container spacing={2} textAlign="center" sx={{ pt: '100px', display: 'flex' }}>
          {/* <Grid item xs={12}>
            <Box sx={{ margin: '0 0 0 12px', display: 'inline-block' }}>
              <img src={email} alt="email icon" style={{ width: '110px' }} />
            </Box>
          </Grid>
          <Grid item xs={12} style={{ margin: '45px 0 0 0' }}>
            <H1>{t('emailVerifiedPage.title')}</H1>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 16, margin: '10px 0 0 0' }}>
            <P1R>{t('emailVerifiedPage.welcome')}</P1R>
            <Box sx={{ lineHeight: '10px', width: '280px' }}>
              <P1R>{t('emailVerifiedPage.description')}</P1R>
            </Box>
          </Grid> */}
          <Grid item xs={12} style={{ margin: '25px 0 0 0' }}>
            <Button
              primary={false}
              onClick={handleSubmit}
              onMouseEnter={() => setIsActive(true)}
              onMouseLeave={() => setIsActive(false)}
              style={{ width: '280px', textTransform: 'none' }}
            >
              {isActive ? <Icon name="clearmeIconWhite" sx={{ mr: 2 }} /> : <Icon name="clearmeIcon" sx={{ mr: 2 }} />}
              {t('emailVerifiedPage.buttonText')}
            </Button>
          </Grid>
        </Grid>
      ) : null}
      <Grid item xs={12} sx={{ display: 'flex', flexGrow: 1, alignItems: 'end' }}>
        <Button primary={false} onClick={handleLogout} style={{ textTransform: 'capitalize' }}>
          {t('common.goToLogout')}
        </Button>
      </Grid>
    </BlankWrapper>
  );
};

export default EmailVerifiedContainer;
