import React from 'react';
import Grid from '@mui/material/Grid';
import { StyledWrapper } from './styled';

const NotFoundPage: React.FC = () => (
  <StyledWrapper>
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '90vh' }} />
  </StyledWrapper>
);

export default NotFoundPage;
