import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { PayloadLinemenType } from 'src/types';
import { SagaAction } from '../../types';
import { rosterAttachLinemen } from '../actions';

function* rosterAttachLinemenSaga({ payload: { id, linemen, type } }: SagaAction<Partial<PayloadLinemenType>>): SagaIterator {
  try {
    yield put(rosterAttachLinemen.pending());
    yield call(API.rosterAttachLinemen, { id, linemen, type });

    yield put(rosterAttachLinemen.success());
  } catch (e) {
    yield put(rosterAttachLinemen.failure(e));
  }
}

export default rosterAttachLinemenSaga;
