import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { setRoles } from '../reducers';
import { loadRoles } from '../actions';

function* loadRolesSaga(): SagaIterator {
  try {
    yield put(loadRoles.pending());
    const { payload } = yield call(API.loadRoles);

    yield put(setRoles(payload));
    yield put(loadRoles.success());
  } catch (e) {
    yield put(loadRoles.failure());
  }
}

export default loadRolesSaga;
