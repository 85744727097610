import { createSelector } from 'reselect';
import { UserType, PermissionEnum } from 'src/types';
import { RoleType } from 'src/shared/enums/roleType.enum';
import { TYPES } from './constants';
import { createLoadingSelector } from '../@loadings';
import { createSuccessSelector } from '../@success';
import { createErrorSelector } from '../@errors';
import { UsersDataState } from './types';
import { ReduxState } from '../types';
import { selectUser } from '../user/selectors';

export const selectUsersData = (state: ReduxState): UsersDataState => state.usersData;

// This controls what the current user can see based on role
export const selectUsers = createSelector(selectUsersData, selectUser, ({ users }, me) => {
  const viewDeactivatedUsers = me.curPermissions?.includes(PermissionEnum.VIEW_DEACTIVATED_USER_LIST);
  const viewActivateUsers = me.curPermissions?.includes(PermissionEnum.VIEW_ACTIVE_USERS_LIST);
  const viewBlockedUsers = me.curPermissions?.includes(PermissionEnum.VIEW_BLOCKED_USERS_LIST);
  const viewApprovedUsers = me.curPermissions?.includes(PermissionEnum.VIEW_APPROVED_USER_LIST);

  return users?.filter((user: UserType) => [viewDeactivatedUsers, viewActivateUsers, viewBlockedUsers, viewApprovedUsers][user.status]);
});

// Currently the same as selectUsers, but a placholder for when we have more specific filters
export const selectMyUsers = createSelector(selectUsers, (users) => users);

// Trick to get the users if the current list of users are without filter
export const selectAvailableLinemen = createSelector(selectUsers, (users) => users);

export const selectAvailableGeneralForemen = createSelector(selectUsers, (users) => users);

export const selectVehicleAssignees = createSelector(selectUsers, (users) =>
  users.filter((user: UserType) => user.roles && user.roles.find((role) => role.types.includes(RoleType.ASSIGNEE_VEHICLE))),
);

export const selectLinemen = createSelector(selectUsersData, ({ linemen }) => linemen);
export const selectLinemenByYard = createSelector(selectUsersData, ({ linemenByYard }) => linemenByYard);
export const selectSupport = createSelector(selectUsersData, ({ support }) => support);
export const selectFavorites = createSelector(selectUsersData, ({ favorites }) => favorites);

export const selectUsersLoading = createLoadingSelector(TYPES.LOAD_USERS.TYPE);
export const selectUsersSuccess = createSuccessSelector(TYPES.LOAD_USERS.TYPE);
export const selectUsersError = createErrorSelector(TYPES.LOAD_USERS.TYPE);

export const selectUsersFavoriteLoading = createLoadingSelector(TYPES.LOAD_USERS_FAVORITES.TYPE);
export const selectUsersFavoriteSuccess = createSuccessSelector(TYPES.LOAD_USERS_FAVORITES.TYPE);
export const selectUsersFavoriteError = createErrorSelector(TYPES.LOAD_USERS_FAVORITES.TYPE);

export const selectNotifyUsersLoading = createLoadingSelector(TYPES.NOTIFY_USERS.TYPE);
export const selectNotifyUsersSuccess = createSuccessSelector(TYPES.NOTIFY_USERS.TYPE);
export const selectNotifyUsersError = createErrorSelector(TYPES.NOTIFY_USERS.TYPE);

export const loadLinemenByLocationLoading = createLoadingSelector(TYPES.LOAD_LINEMEN_BY_LOCATION.TYPE);
export const loadLinemenByLocationSuccess = createSuccessSelector(TYPES.LOAD_LINEMEN_BY_LOCATION.TYPE);
export const loadLinemenByLocationError = createErrorSelector(TYPES.LOAD_LINEMEN_BY_LOCATION.TYPE);
