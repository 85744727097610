import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { IncidentType } from 'src/types';
import { setIncidents } from '../../incidents/reducers';

import { SagaAction } from '../../types';
import { getUserInvites } from '../actions';

type UserPayloadType = {
  uuid: string;
};

function* getUserInvitesSaga({ payload: { uuid } }: SagaAction<UserPayloadType>): SagaIterator {
  try {
    yield put(getUserInvites.pending());
    const { payload } = yield call(API.getUserInvites, uuid);

    // TODO: user invites info should match incident info structure in api
    const mappedPayload = payload.map((info: { incident: IncidentType; participationStatus: number }) => ({
      ...info.incident,
      participationStatus: info.participationStatus,
    }));

    yield put(setIncidents(mappedPayload));
    yield put(getUserInvites.success());
  } catch (e) {
    yield put(getUserInvites.failure());
  }
}

export default getUserInvitesSaga;
