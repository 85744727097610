import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { themeColors } from 'src/theme';
import { PermissionEnum } from 'src/types';
import { Loader, P3, RbacMgmt } from 'src/components';
import { loadUser } from 'src/redux/user/actions';
import { selectIsUserLoading, selectUser } from 'src/redux/user/selectors';
import config from 'src/config';

import { DashboardWrapper } from '../wrappers';

export default function RbacMgmtContainer() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const isUserLoading = useSelector(selectIsUserLoading);
  const editable = config.can(PermissionEnum.EDIT_ROLE);

  useEffect(() => {
    dispatch(loadUser.init());
  }, []);

  const approvalLabel = (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: { xs: 'center', sm: 'left' } }}>
      <Box
        sx={{
          width: '120px',
          height: '32px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: themeColors.lightYellow,
          borderRadius: '4px',
        }}
      >
        <P3>{t('common.waitingForApproval')}</P3>
      </Box>
    </Box>
  );

  return (
    <DashboardWrapper breadcrumbs={[['RBAC Management']]} navigationChildren={user?.step && user.step < 3 ? approvalLabel : null}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        {isUserLoading ? <Loader /> : <RbacMgmt editable={editable} />}
      </Box>
    </DashboardWrapper>
  );
}
