import { takeLatest } from 'redux-saga/effects';
import loadVehicleSaga from './loadVehicleSaga';
import createVehicleSaga from './createVehicleSaga';
import saveVehicleSaga from './saveVehicleSaga';
import { TYPES } from '../constants';
import deleteVehicleSaga from './deleteVehicleSaga';

export default function* vehicleSagas(): Generator {
  yield takeLatest(TYPES.LOAD_VEHICLE.INIT, loadVehicleSaga);
  yield takeLatest(TYPES.CREATE_VEHICLE.INIT, createVehicleSaga);
  yield takeLatest(TYPES.SAVE_VEHICLE.INIT, saveVehicleSaga);
  yield takeLatest(TYPES.DELETE_VEHICLE.INIT, deleteVehicleSaga);
}
