import { Box, Breadcrumbs } from '@mui/material';
import { themeColors } from 'src/theme';
import { PATHS } from 'src/navigation';
import { H1, H2 } from '../Typography';
import Icon from '../Icon';

type Props = {
  breadcrumbs?: [string, string?][];
  navigate: (path: string) => void;
};

function Breadcrumb({ breadcrumbs = [], navigate }: Props) {
  if (breadcrumbs.length) {
    return (
      <Breadcrumbs separator="/" aria-label="breadcrumb">
        {breadcrumbs[0][0] === 'home' ? (
          <Box onClick={() => navigate(PATHS.INCIDENTS_PAGE)} sx={{ cursor: 'pointer' }}>
            <Icon name="home" />
          </Box>
        ) : null}

        {breadcrumbs.length === 1 ? (
          <H1 sx={{ color: themeColors.black }}>{breadcrumbs[0]}</H1>
        ) : (
          breadcrumbs.slice(1).map(([label, path], index) => (
            <H2
              key={index}
              onClick={() => path && navigate(path)}
              sx={{
                color: index === breadcrumbs.length - 2 ? themeColors.black : themeColors.grayDark,
                textTransform: 'capitalize',
                cursor: path ? 'pointer' : 'default',
              }}
            >
              {label}
            </H2>
          ))
        )}
      </Breadcrumbs>
    );
  }
  return null;
}

export default Breadcrumb;
