import { RosterType } from 'src/types';
import { createReducer } from '../../utils/toolkit';

const initialState: Partial<RosterType> = {
  name: '',
  yardFK: 0,
  startDate: 0,
  status: 0,
  contactFullName: '',
  contactPhone: '',
  contactEmail: '',
};

const {
  reducer,
  actions: { set: setRoster, merge: mergeRoster, reset: resetRoster },
} = createReducer('roster', initialState);

export default reducer;
export { setRoster, mergeRoster, resetRoster };
