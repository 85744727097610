import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { H2, Button, Toolbar, rosterColumnVisibilityModel, RosterTable, EmptyState } from 'src/components';
import { selectUser } from 'src/redux/user/selectors';
import { selectRoster } from 'src/redux/roster/selectors';
import { UserType } from 'src/types';
import { themeColors } from 'src/theme';
import config from 'src/config';

type Props = {
  linemenTable: UserType[];
  fteTable: UserType[];
  selectedLinemen: string[];
  setSelectedLinemen: (selected: any) => void;
  selectedFte: string[];
  setSelectedFte: (selected: any) => void;
  setLinemenTable: (selected: any) => void;
  setFteTable: (selected: any) => void;
};

const FTEsStep = ({
  fteTable,
  linemenTable,
  selectedLinemen,
  setSelectedLinemen,
  selectedFte,
  setSelectedFte,
  setLinemenTable,
  setFteTable,
}: Props) => {
  const { t } = useTranslation();
  const roster = useSelector(selectRoster);
  const user = useSelector(selectUser);
  const [linemenIds, setLinemenIds] = useState<string[]>([]);
  const [fteIds, setFteIds] = useState<string[]>([]);
  const isEditable = config.can('edit_roster', { user, roster });

  const removeIntersection = (intersect: string[], array: string[]) => array.filter((item) => !intersect.includes(item));

  const moveToRosterTable = () => {
    const peopleToMove = linemenTable.filter((item) => linemenIds.includes(item.uuid));

    setFteTable((people: UserType[]) => [...people, ...peopleToMove]);
    setLinemenTable((people: UserType[]) => people.filter((item) => !linemenIds.includes(item.uuid)));

    const intersection = selectedFte.filter((id) => linemenIds.includes(id));
    setSelectedLinemen((peopleIds: string[]) => removeIntersection(intersection, [...peopleIds, ...linemenIds]));
    setSelectedFte((peopleIds: string[]) => removeIntersection(intersection, peopleIds));
    setLinemenIds([]);
  };

  const removeFromRosterTable = () => {
    const peopleToMove = fteTable.filter((item) => fteIds.includes(item.uuid));

    setLinemenTable((people: UserType[]) => [...people, ...peopleToMove]);
    setFteTable((people: UserType[]) => people.filter((item) => !fteIds.includes(item.uuid)));

    const intersection = selectedLinemen.filter((id) => fteIds.includes(id));
    setSelectedFte((peopleIds: string[]) => removeIntersection(intersection, [...peopleIds, ...fteIds]));
    setSelectedLinemen((peopleIds: string[]) => removeIntersection(intersection, peopleIds));
    setFteIds([]);
  };

  const toolbarOptionsVisible = { quickFilter: true, filterBtn: true, columnsBtn: true };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Box mb={1} mt={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', minHeight: '50px' }}>
          <H2 sx={{ textTransform: 'capitalize' }}>{t('splitRosterPage.ftes')}</H2>
          <Box display="flex">
            {fteTable?.length ? (
              <Box sx={{ maxWidth: '250px' }}>
                <Toolbar id="fte-roster" options={toolbarOptionsVisible} />
              </Box>
            ) : null}
            {isEditable && (
              <Button
                disabled={!fteIds.length}
                onClick={removeFromRosterTable}
                sx={{
                  padding: 0,
                  ml: 1,
                  '&.MuiButtonBase-root': {
                    height: '48px',
                    width: '48px',
                    minWidth: '48px',
                  },
                }}
              >
                <ArrowForwardIcon sx={{ height: '18px', width: '16px' }} />
              </Button>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box mb={1} mt={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', minHeight: '50px' }}>
          <H2 sx={{ textTransform: 'capitalize' }}>{t('splitRosterPage.newRoster')}</H2>
          <Box display="flex">
            {linemenTable?.length ? (
              <Box sx={{ maxWidth: '250px' }}>
                <Toolbar id="lineman-roster" options={toolbarOptionsVisible} />
              </Box>
            ) : null}
            {isEditable && (
              <Button
                disabled={!linemenIds.length}
                onClick={moveToRosterTable}
                sx={{
                  padding: 0,
                  ml: 1,
                  '&.MuiButtonBase-root': {
                    height: '48px',
                    width: '48px',
                    minWidth: '48px',
                  },
                }}
              >
                <ArrowBackIcon sx={{ height: '18px', width: '16px' }} />
              </Button>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} sx={{ maxHeight: '50vh' }}>
        {fteTable.length ? (
          <RosterTable
            id="fte-roster"
            toolbarProps={{ id: 'fte-roster', options: toolbarOptionsVisible }}
            ibewPay={roster.incident?.ibewPay || ''}
            rosterId={roster.id as number}
            rosterStatus={roster.status}
            linemen={fteTable}
            fleet={roster.vehicles}
            utilityId={roster.incident?.utilityId}
            columnVisibilityModel={{
              ...rosterColumnVisibilityModel,
              firstName: true,
              lastName: true,
              unionClassification: true,
            }}
            onRowSelectionModelChange={(selected: any) => {
              setFteIds(selected);
            }}
            rowSelectionModel={fteIds}
            checkboxSelection
            hideFooter
            sx={{ border: `1px solid ${themeColors.grayMedium}` }}
          />
        ) : (
          <EmptyState type="people" size="small" withBorder subTitle={t('splitRosterPage.noFtesToMove')} />
        )}
      </Grid>
      <Grid item xs={6} sx={{ maxHeight: '50vh' }}>
        {linemenTable?.length ? (
          <RosterTable
            id="lineman-roster"
            toolbarProps={{ id: 'lineman-roster', options: toolbarOptionsVisible }}
            linemen={linemenTable as UserType[]}
            ibewPay={roster.incident?.ibewPay || ''}
            rosterId={roster.id as number}
            rosterStatus={roster.status}
            fleet={roster.vehicles}
            utilityId={roster.incident?.utilityId}
            columnVisibilityModel={{
              ...rosterColumnVisibilityModel,
              firstName: true,
              lastName: true,
              unionClassification: true,
            }}
            onRowSelectionModelChange={(selected: any) => {
              setLinemenIds(selected);
            }}
            rowSelectionModel={linemenIds}
            checkboxSelection
            hideFooter
            sx={{ border: `1px solid ${themeColors.grayMedium}` }}
          />
        ) : (
          <EmptyState type="people" size="small" withBorder subTitle={t('splitRosterPage.selectFTEsToMove')} />
        )}
      </Grid>
    </Grid>
  );
};

export default FTEsStep;
