import { combineReducers } from 'redux';
import vehicles from './vehicles';

const vehiclesData = combineReducers({
  vehicles,
});

export * from './vehicles';

export default vehiclesData;
