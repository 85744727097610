import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { Button, H1, P1R, Dialog } from 'src/components';

type Props = {
  rosterVersion: string;
  onClose: () => void;
  onConfirm: () => void;
};

const RestoreVersionPopup = ({ rosterVersion, onClose, onConfirm }: Props) => {
  const { t } = useTranslation();
  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <Dialog open onClose={onClose} aria-labelledby="customized-dialog-title">
      <Box
        sx={{
          my: 4,
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        <H1 align="center" sx={{ mt: 3, mb: 2, textTransform: 'capitalize' }}>
          {t('rosterPage.restoreVersion')}?
        </H1>
        <P1R sx={{ my: 3 }}>
          {t('rosterPage.restoreVersionPopupSub')} {rosterVersion}? {t('rosterPage.restoreVersionPopupSub2')}{' '}
        </P1R>
      </Box>

      <Grid container columnSpacing={2} sx={{ mb: 4 }}>
        <Grid item xs={6}>
          <Button
            primary={false}
            onClick={onClose}
            sx={{
              width: '100%',
              textTransform: 'capitalize',
            }}
          >
            {t('eventPage.cancel')}
          </Button>
        </Grid>

        <Grid item xs={6}>
          <Button
            onClick={handleConfirm}
            sx={{
              width: '100%',
              textTransform: 'capitalize',
            }}
          >
            {t('eventPage.confirm')}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default RestoreVersionPopup;
