import loadings from './@loadings/reducer';
import errors from './@errors/reducer';
import success from './@success/reducer';
import rolesData from './roles/reducers';
import userData from './user/reducers';
import usersData from './users/reducers';
import incidentData from './incident/reducers';
import incidentsData from './incidents/reducers';
import rosterData from './roster/reducers';
import rostersData from './rosters/reducers';
import vehicleData from './vehicle/reducers';
import vehiclesData from './vehicles/reducers';
import utilityClassificationsData from './utilityClassifications/reducers';
import optionsData from './options/reducers';
import clearmeData from './clearme/reducers';

const reducers = {
  loadings,
  errors,
  success,
  rolesData,
  userData,
  usersData,
  incidentData,
  incidentsData,
  rosterData,
  rostersData,
  vehicleData,
  vehiclesData,
  utilityClassificationsData,
  optionsData,
  clearmeData,
};

export default reducers;
