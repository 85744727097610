import { styled } from '@mui/material/styles';
import { P3 } from 'src/components';
import { themeColors } from 'src/theme';
import { UserStatus as UserStatusEnum } from 'src/shared/enums/userStatus.enum';

const StyledUserStatus = styled(P3)({
  padding: '0 10px',
  display: 'flex',
  alignItems: 'center',
  textTransform: 'lowercase',
  borderRadius: '4px',
});

type Props = {
  status: UserStatusEnum;
};

function UserStatus({ status }: Props) {
  const getUserStatus = (status: number) => {
    const statuses = {
      0: 'Deactivated',
      1: 'Active',
      2: 'Blocked',
      3: 'Approved',
    };
    // @ts-ignore
    return statuses[status];
  };
  const getUserStatusColor = (status: number) => {
    const statuses = {
      0: themeColors.grayLight,
      1: themeColors.greenLight,
      2: themeColors.redLight,
      3: themeColors.blueLight,
    };
    // @ts-ignore
    return statuses[status];
  };
  return (
    <StyledUserStatus
      sx={{
        backgroundColor: () => getUserStatusColor(status),
      }}
    >
      {getUserStatus(status)}
    </StyledUserStatus>
  );
}

export default UserStatus;
