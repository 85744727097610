import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { setLinemenByYard, setSupport, setFavorites } from '../reducers';
import { SagaAction } from '../../types';
import { loadLinemenByLocation } from '../actions';

type LoadLinemenType = {
  incidentId: number;
  yardId: number;
  favorites?: boolean;
};

function* loadLinemenByLocationSaga({ payload: { incidentId, yardId, favorites = false } }: SagaAction<LoadLinemenType>): SagaIterator {
  try {
    yield put(loadLinemenByLocation.pending());
    const { payload } = yield call(API.loadLinemenByLocation, { incidentId, yardId, favorites });

    yield put(setLinemenByYard(payload.fte));
    yield put(setSupport(payload.supporters));
    yield put(setFavorites(payload.favorites));
    yield put(loadLinemenByLocation.success());
  } catch (e) {
    yield put(loadLinemenByLocation.failure());
  }
}

export default loadLinemenByLocationSaga;
