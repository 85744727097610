import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';

import { PATHS } from 'src/navigation';
import { setParams } from 'src/utils';
import { Toolbar, RostersTable, Loader, H1 } from 'src/components';
import { loadRosters } from 'src/redux/rosters/actions';
import { selectMyApprovalRosters, selectRostersLoading } from 'src/redux/rosters/selectors';

import { useTranslation } from 'react-i18next';
import { DashboardWrapper } from '../wrappers';
import myApprovalsEmptyImg from '../../assets/MyApprovalsEmptyState.svg';

export default function MyApprovals() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myRosters = useSelector(selectMyApprovalRosters);
  const isMyRostersLoading = useSelector(selectRostersLoading);

  useEffect(() => {
    dispatch(loadRosters.init());
  }, [dispatch]);

  return (
    <DashboardWrapper breadcrumbs={[['My Approvals']]} navigationChildren={myRosters?.length ? <Toolbar options="mini" /> : null}>
      <Box
        component="main"
        sx={{
          height: 'calc(100vh - 100px)',
          pt: 4,
        }}
      >
        {isMyRostersLoading ? (
          <Loader />
        ) : myRosters?.length ? (
          <RostersTable rosters={myRosters} setRoster={(rid: number): void => navigate(setParams(PATHS.ROSTER_PAGE, { id: rid.toString() }))} />
        ) : (
          <Box
            sx={{
              mt: 14,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ ml: 1 }}>
              <img src={myApprovalsEmptyImg} alt="Empty list" />
            </Box>
            <H1 sx={{ mb: 3 }}>{t('approvalsPage.noApprovals')}</H1>
          </Box>
        )}
      </Box>
    </DashboardWrapper>
  );
}
