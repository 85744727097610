import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import API from 'src/api';
import { VehicleType } from 'src/types';
import { setVehicle } from '../reducers';
import { loadVehicle } from '../actions';
import { SagaAction } from '../../types';

function* loadVehicleSaga({ payload: { id = 0 } }: SagaAction<Partial<VehicleType>>): SagaIterator {
  try {
    yield put(loadVehicle.pending());
    const { payload } = yield call(API.loadVehicle, { id: id.toString() });

    // Technical Debt: Updated current vehicle with details
    yield put(setVehicle(payload));
    yield put(loadVehicle.success());
  } catch (e) {
    yield put(loadVehicle.failure());
  }
}

export default loadVehicleSaga;
