import { UserType } from 'src/types';
import { createReducer } from '../../utils/toolkit';

const initialState: Partial<UserType> = {
  uuid: '',
  // isActive: false,
  email: '',
  emailConfirmed: false,
  phone: '',
  phoneConfirmed: false,
  // clearStatus: 0,
  firstName: '',
  lastName: '',
  street: '',
  stateCode: '',
  city: '',
  zipcode: '',
  password: '',
  // roles: [],
  curPermissions: [],
  allPermissions: [],
};

const {
  reducer,
  actions: { set: setUser, merge: mergeUser, reset: resetUser },
} = createReducer('user', initialState);

export default reducer;
export { setUser, mergeUser, resetUser };
